import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Fade, Modal, Form, Col, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';
import { showMessage } from '../actions/messages';
import edit from '../images/edit_white_24dp.svg';
import {showCategoryModal, hideCategoryModal, createCategory, selectCategory, editCategory, addOrRemoveChannelFromCategory, deleteCategory, getRtvCategories, deleteCategoryImageFile} from '../actions/admin'
import {Formik} from 'formik';
import Dialog from 'react-bootstrap-dialog';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {imageTypes} from '../constants';
import rIcon from '../images/Replayer_favicon.png'

const AdminRtvCategories = ({
  token,
  partners,
  goto,
  selectedCategory,
  showCategoryModal,
  hideCategoryModal,
  isCategoryModalVisible,
  createCategory,
  editCategory,
  rtvCategories,
  selectCategory,
  deselectCategory,
  addOrRemoveChannel,
  deleteCategory,
  removeFile,
}) => {
  const {t, i18n} = useTranslation();

  const [channelFilter, setchannelFilter] = useState("");

  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  let dialog = null;

  const confirmCategoryDelete = () => {
    dialog.show({
      title: t('DELETE_CATEGORY', {name: selectedCategory.label}),
      body: t('CONFIRM_DELETE_CATEGORY'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteCategory(selectedCategory.id),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const isSelectedRtvChannel = rtvChannelId => {
    if (rtvCategories.find(c => {return c.id === selectedCategory.id}).rtvChannels.includes(rtvChannelId)) {
      return true
    }
    return false
  };

  const refreshAndOpenModal = () => {
    selectCategory(rtvCategories.find(c => {return c.id === selectedCategory.id}) || {})
    showCategoryModal();
  }

  const getCategoryLogo = (categoryId, imageName) => {
    if (imageName.length > 0) {
      return `${Configuration.apiEndPoint}/admin/categories/image/${categoryId}?load=${imageName[0]}`;
    }
    return rIcon;
  };

  const renderEmptyPageString = () => {
    let channelNumber = 0;
    partners.forEach(p => {
      p.rtvChannels.forEach(c => {
        if((channelFilter === "" || c.name.toLowerCase().indexOf(channelFilter.toLowerCase()) > -1) && (Object.keys(selectedCategory).length === 0 || selectedCategory.rtvChannels.includes(c.id))){
          channelNumber++;
        }
      });
    });
    if (channelNumber === 0) {
      return <h6>{t('SEARCH_CHANNEL_FAILED')}</h6>;
    }
    return null;
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
        <div className="page-header header-filter clear-filter" style={{height: 500}} data-parallax="true">
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div>
                          <h4 className="section_title_neg_rp">{t('RTV_CHANNELS_CATEGORIES')}</h4>
                        </div>
                      </div>
                      <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "-40px"}}>
                        <Button onClick={refreshAndOpenModal} variant="custom" style={{backgroundColor: null, borderColor: "white"}}>
                          <img src={edit} alt={t('EDIT_CATEGORY')}/>
                          {selectedCategory.id ? t('EDIT_CATEGORY') : t('CREATE_CATEGORY')}
                        </Button>
                      </div>
                      <Form>
                      <Form.Group>
                        <Form.Control name="channelFilter" style={{maxWidth: 250}} placeholder={t('CHANNEL_SEARCH')} type="text" maxLength="50" onChange={e => setchannelFilter(e.target.value)}/>
                      </Form.Group>
                      </Form>
                      {renderEmptyPageString()}
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          {rtvCategories.map(c =>
                            <Button onClick={() => c.id === selectedCategory.id ? deselectCategory() : selectCategory(c)} variant="categoryButton" style={{borderColor: c.color, backgroundColor: c.id === selectedCategory.id ? c.color : null}}>
                            <img style={{height: 40, width: 40}} src={getCategoryLogo(c.id, c.imageName)} alt={t('CATEGORY_LOGO')}/>
                            {" " + c.label}
                          </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {partners.map(p => p.rtvChannels.map(channel =>
                    (
                      channelFilter === "" || channel.name.toLowerCase().indexOf(channelFilter.toLowerCase()) > -1 ?
                      <div key={channel.id} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group">
                        <div className={channel.type === 0 ? "card video_store_bg_rp ease_fx" : "card video_store_bg_rp_specialist ease_fx"}>
                          <div className="card-horizontal">
                            <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                              {
                                channel.logoNames && channel.logoNames.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/partners/rtv_channels/${channel.id}/images?load=${channel.logoNames[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  p.storeImage && p.storeImage.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.storeImage[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  <div className="align-self-center video_store_nologo_overlay_rp"/>
                              }
                            </div>
                            <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                              <h5 className="card-title mb-0">{channel.name}</h5>                              
                            </div>
                            {selectedCategory.id ?
                            <div className="card-body d-flex align-items-center col-sm-1 col-lg-1">
                              {
                                isSelectedRtvChannel(channel.id) ?
                                  <div className="categories_checkbox_btn_active" onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    addOrRemoveChannel(channel.id, false, selectedCategory.id);
                                  }}/> :
                                  <div className="categories_checkbox_btn" onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    addOrRemoveChannel(channel.id, true, selectedCategory.id);
                                  }}/>
                              }
                            </div>
                            : null}
                          </div>
                        </div>
                      </div>
                    : null)
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isCategoryModalVisible} onHide={hideCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">{selectedCategory.id ? t('EDIT_CATEGORY') : t('CREATE_CATEGORY')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-4">
            <div className="col-12">
              <div>
                <Formik
                  enableReinitialize
                  initialValues={{...selectedCategory, color: selectedCategory.color || '#3567ad'}}
                  onSubmit={v => v.id ? editCategory(v) : createCategory(v)}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    setFieldValue
                  }) => {
                    const imageCategory = (selectedCategory.imageName || []).map(f => {
                      return {
                        source: f,
                        options: {
                          type: 'local'
                        }
                      };
                    });
                    return (
                      <div className="card-body d-flex flex-column">
                        <Form onSubmit={handleSubmit}>
                          <Form.Group>
                            <Form.Label>{t('NAME')}</Form.Label>
                            <Form.Control name="label" type="text" maxLength={25} value={values.label || ''} onChange={handleChange}/>
                            <Form.Text className="text-muted">{t('CATEGORY_NAME_INFO')}</Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{t('TAG_COLOR')}*</Form.Label><br />
                                <OverlayTrigger
                                  trigger="click"
                                  key="bottom"                          
                                  placement="bottom"
                                  overlay={
                                    <Popover>
                                      <Popover.Title as="h3">{t('TAG_COLOR')}</Popover.Title>
                                      <Popover.Content>
                                        <Row style={{marginBottom: 10}}>
                                          <Col><Button style={{backgroundColor: '#3567ad', border: 0, height: 25}} onClick={() => setFieldValue('color', '#3567ad')}/></Col>
                                          <Col><Button style={{backgroundColor: '#5e35b1', border: 0, height: 25}} onClick={() => setFieldValue('color', '#5e35b1')}/></Col>
                                          <Col><Button style={{backgroundColor: '#ca035e', border: 0, height: 25}} onClick={() => setFieldValue('color', '#ca035e')}/></Col>
                                          <Col><Button style={{backgroundColor: '#e74f3c', border: 0, height: 25}} onClick={() => setFieldValue('color', '#e74f3c')}/></Col>
                                          <Col><Button style={{backgroundColor: '#01842e', border: 0, height: 25}} onClick={() => setFieldValue('color', '#01842e')}/></Col>                                  
                                        </Row>
                                        <Row>                                  
                                          <Col><Button style={{backgroundColor: '#05adca', border: 0, height: 25}} onClick={() => setFieldValue('color', '#05adca')}/></Col>
                                          <Col><Button style={{backgroundColor: '#9d86c3', border: 0, height: 25}} onClick={() => setFieldValue('color', '#9d86c3')}/></Col>                  
                                          <Col><Button style={{backgroundColor: '#f24973', border: 0, height: 25}} onClick={() => setFieldValue('color', '#f24973')}/></Col>
                                          <Col><Button style={{backgroundColor: '#f39c12', border: 0, height: 25}} onClick={() => setFieldValue('color', '#f39c12')}/></Col>
                                          <Col><Button style={{backgroundColor: '#8eb002', border: 0, height: 25}} onClick={() => setFieldValue('color', '#8eb002')}/></Col>
                                        </Row>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button block size='lg' style={{backgroundColor: values.color, border: 0, height: 25}}/>
                                </OverlayTrigger>
                          </Form.Group>
                          {selectedCategory.id ?
                          <Form.Group>
                            <FilePond
                              files={imageCategory}
                              server={
                                {
                                  url: `${Configuration.apiEndPoint}/`,
                                  process: {
                                    url: `./admin/categories/image/${values.id}`,
                                    ondata: formData => {
                                      formData.append('image_type', imageTypes.category);
                                      return formData;
                                    }
                                  },
                                  revert: `./admin/categories/image/${values.id}`,
                                  restore: `./admin/categories/image/${values.id}`,
                                  fetch: `./admin/categories/image/${values.id}`,
                                  load: `./admin/categories/image/${values.id}?load=`,
                                  headers: {Authorization: `Bearer ${token}`},
                                  remove: (source, load) => {
                                    removeFile(source, values.id, selectedCategory);
                                    load();
                                  }
                                }
                              }
                            />
                            <Form.Text className="text-muted">{t('CATEGORY_LOGO_INFO')}</Form.Text>
                            </Form.Group>
                          : null}
                          <div className="d-flex flex-row-reverse">
                            <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                            {selectedCategory.id ?
                              <Button className="btn btn-primary ml-1 mr-1" onClick={() => confirmCategoryDelete()}>{t('DELETE')}</Button>
                            : null}
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <button type="button" className="btn btn_red_outline_rp" onClick={hideCategoryModal}>{t('CLOSE_BUTTON')}</button>
        </div>
      </Modal>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

AdminRtvCategories.propTypes = {
  token: PropTypes.string,
  partners: PropTypes.array,
};

AdminRtvCategories.defaultProps = {
  token: null,
  partners: [],
  selectedCategory: {},
};

const mapStateToProps = ({login: {token}, admin: {partners, selectedCategory, isCategoryModalVisible, rtvCategories}}) => ({
  token,
  partners,
  selectedCategory,
  isCategoryModalVisible,
  rtvCategories
});

const mapDispatchToProps = dispatch => ({
  addOrRemoveChannel: (rtvChannelId, add, categoryId) => {
    dispatch(addOrRemoveChannelFromCategory(rtvChannelId, add, categoryId));
  },
  showCategoryModal: () => {
    dispatch(showCategoryModal());
  },
  hideCategoryModal: () => {
    dispatch(hideCategoryModal());
    dispatch(getRtvCategories());
  },
  createCategory: values => {
    dispatch(createCategory(values));
  },
  editCategory: values => {
    dispatch(editCategory(values));
  },
  selectCategory: category => {
    dispatch(selectCategory(category));
  },
  deselectCategory: () => {
    dispatch(selectCategory({}));
  },
  deleteCategory: id => {
    dispatch(deleteCategory(id));
  },
  removeFile: (fileName, categoryId, selectedCategory) => {
    dispatch(deleteCategoryImageFile(fileName, categoryId));
    dispatch(getRtvCategories());
    dispatch(selectCategory({...selectedCategory, imageName: []}));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminRtvCategories));
