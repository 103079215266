/* eslint complexity: ["error", 40] */

import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Fade, Form} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import {useTranslation} from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';
import { getLiveVideos, getComingSoonVideos } from '../actions/player';
import Configuration from '../config';
import specialEvent from '../images/special_event.svg';
import replayerlogoBianco from '../images/replayer_logo_bianco_40x32px.png';
import { rtvType, SPORTS } from '../constants';

const PlayerComingSoonVideos = ({
  token,
  profile,
  getComingSoonVideos,
  comingSoonVideos,
  gotoLiveVideos,
}) => {
  const {t, i18n} = useTranslation();

  const [videoFilter, setVideoFilter] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Codice da eseguire ogni due minuti
      getComingSoonVideos();
    }, 120000); // 120000 millisecondi = 2 minuti

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []); // L'array vuoto [] assicura che l'effetto venga eseguito solo una volta quando il componente viene montato

  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }


  let dialog = null;

  const showComingSoonVideosInfo = () => {
    dialog.show({
      title: t('THESE_EVENTS_ARE_STARTING'),
      body: t('WATCH_LIVE_DIALOG'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const videoScheduled = () => {
    dialog.show({
      title: t('VIDEO_SCHEDULED_TITLE'),
      body: t('VIDEO_SCHEDULED_INFO_TWO'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const styleBackgroundImageVideoWrapper = (partnerId, fieldId, fieldImage) => {
    if (partnerId && fieldImage) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage})`, backgroundSize: 'cover'};
    }

    return null;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
        <div className="page-header header-filter clear-filter replayertv_sportcenter_hero_rp" data-parallax="true">
            <div className="replayertv_sportcenter_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div class="wrapper" style={{paddingBottom: 20}}>
                      <a class="cta" href="#" onClick={gotoLiveVideos}>
                        <span className="live-now-span" >LIVE NOW</span>
                        <span className="live-now-span">
                          <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                            <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <path class="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#DD004A"></path>
                              <path class="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#DD004A"></path>
                              <path class="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#DD004A"></path>
                            </g>
                          </svg>
                        </span> 
                      </a>
                    </div>
                    <div className="detail_head_rp video_store_head rounded">
                      <div>
                        <h4 className="section_title_neg_rp">{t('THESE_EVENTS_ARE_STARTING')}</h4>
                        <div className="rtv_info_btn" onClick={showComingSoonVideosInfo}/>
                      </div>
                      <p>{t('THESE_EVENTS_ARE_STARTING_DESC')}</p>
                      <Form>
                      <Form.Group>
                        <Form.Control name="videoFilter" style={{maxWidth: 250}} placeholder={t('EVENT_SEARCH')} type="text" maxLength="50" onChange={e => setVideoFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                      </Form.Group>
                      </Form>
                    </div>
                    <div className="row">
                        {
                        comingSoonVideos && comingSoonVideos.length > 0 ?
                            comingSoonVideos.map(v =>
                            videoFilter === "" || (v.description !== null && v.description.toLowerCase().indexOf(videoFilter.toLowerCase()) > -1) || v.fieldName.toLowerCase().indexOf(videoFilter.toLowerCase()) > -1 || v.rtvChannels.some(channel => channel.name.toLowerCase().indexOf(videoFilter.toLowerCase()) > -1) ?
                            (
                                <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group" style={{marginTop: 20}}>
                                <div className="card video_card_bg_rp replayer_tv_card">
                                    <div className= "video_card_image" style={styleBackgroundImageVideoWrapper(v.partnerId, v.fieldId, v.fieldImage)}>
                                    <div className="videoWrapper">
                                        <Fragment>
                                          <div className="preload h-100 justify-content-start align-items-start">
                                            <div className="sportcenter_rp_title_icon">
                                              <img src={SPORTS[v.sport] ? SPORTS[v.sport].icon : null} alt={t('ICON_SPORT')} style={{ marginTop: 5, marginLeft: 5 }}/>
                                            </div>
                                          </div>
                                          <div className="preload h-100 justify-content-end align-items-start">
                                            { v.rtvChannels && v.rtvChannels.length === 1 && v.rtvChannels[0].logoNames && v.rtvChannels[0].logoNames.length > 0?
                                            <img className="logo-partner" src={`${Configuration.apiEndPoint}/partners/rtv_channels/${v.rtvChannels[0].id}/images?load=${v.rtvChannels[0].logoNames[0]}`}/>
                                            : <img className="logo-replayer" src={v.event ? specialEvent : replayerlogoBianco} style={{marginRight: 5}}/>
                                            }
                                          </div>
                                          <div className="preload h-100 justify-content-center align-items-end">
                                            <div className="video-description justify-content-center align-items-center">{v.description ? v.description.substring(0,60) : null}</div>
                                          </div>
                                        </Fragment>
                                    </div>
                                    </div>
                                    <div className="card-body d-flex flex-column card_border_bottom_white">
                                    <p className="mb-4">
                                      <strong>ReplayerTV:</strong> {v.rtvChannels && v.rtvChannels.length > 0 ? v.rtvChannels.map(channel => channel.name).join(' - ') : null}
                                    </p>
                                    <p className="mb-4">
                                        <strong>{v.description}</strong>
                                        {
                                        v.rtvOrAcademy === rtvType.academy?
                                            <div className="replayertv_card_academy_icon"/>
                                        : null
                                        }        
                                    </p>
                                    <h6 className="card-title regular_title_rp mb-1">{new Date(v.startDatetime).toLocaleDateString()} {new Date(v.startDatetime).toTimeString().slice(0, 5)}</h6>
                                    <h5 className="card-title">{v.fieldName}</h5>
                                    </div>
                                    <div className="text-center ml-auto p-3">
                                      <a href="#" className="btn btn_blue_rp" onClick={videoScheduled}>{t('VIDEO_SCHEDULED')}</a>
                                    </div>
                                </div>
                                </div>
                            )
                            : null
                            )
                        : 
                        <Fragment>
                          <div className="col-12 text-center" style={{background: '#00152f50', marginBottom: 400, paddingTop: 15, marginTop: 40}}>
                            <p><h5>{t('NO_VIDEO')}</h5></p>
                          </div>
                        </Fragment>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerComingSoonVideos.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object.isRequired,
  comingSoonVideos: PropTypes.array,
  getLiveVideos: PropTypes.func.isRequired,
  getComingSoonVideos: PropTypes.func.isRequired,
  gotoUpcomingEvents: PropTypes.func.isRequired
};

PlayerComingSoonVideos.defaultProps = {
  token: null,
  comingSoonVideos: []
};

const mapStateToProps = ({login: {token, profile}, player: {comingSoonVideos}}) => ({
  token,
  profile,
  comingSoonVideos
});

const mapDispatchToProps = dispatch => ({
    getComingSoonVideos: () => {
        dispatch(getComingSoonVideos());
    },
    gotoLiveVideos: () => {
        dispatch(getLiveVideos());
        dispatch(push('/player-live-videos'));
    }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerComingSoonVideos));