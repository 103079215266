import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import login from './login';
import message from './message';
import loading from './loading';
import partner from './partner';
import player from './player';
import admin from './admin';

export default history => combineReducers({
  router: connectRouter(history),
  login,
  message,
  loading,
  partner,
  player,
  admin
});
