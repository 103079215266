import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Carousel, Fade, Container, Row, Col, Card} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';

const PartnerEvents = ({
  isLogged,
  ownedEvents,
  newEvent,
  showEvent
}) => {
  const {t, i18n} = useTranslation();
  if (!isLogged) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100}}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={12}>
              <Row>
                <Col>
                  <h4 style={{marginTop: 20, marginBottom: 30}}>{t('SPECIAL_EVENTS')}</h4>
                </Col>
                <Col>
                  <Button className="btn btn-secondary float-right" onClick={() => newEvent()} style={{marginTop: 20, marginBottom: 30}}>{t('CREATE_SPECIAL_EVENT')}</Button>
                </Col>
              </Row>
              <Row>
                {
                  ownedEvents.map(e =>
                    (
                      <Col key={e.id} xs={12} sm={12} md={12} xl={6} lg={6}>
                        <Card style={{marginBottom: 10}}>
                          <Card.Body>
                          {
                            e.imageName.length === 0 ?
                              <Fragment>
                                <div className="card event_card_bg_rp">
                                  <div className="card_image_rp">
                                    <div className="videoWrapper card_image_sportcenter_rp"/>
                                </div>
                              </div> 
                              </Fragment> :
                              <Carousel style={{marginBottom: 10}} interval={null}>
                                {
                                  e.imageName.map(i =>
                                    (
                                      <Carousel.Item key={i}>
                                        <img
                                          className="d-block w-100"
                                          src={`${Configuration.apiEndPoint}/partners/events/${e.id}/images?load=${i}`}
                                          alt={t('FIRST_SLIDE')}
                                          style={{width: '100%'}}
                                        />
                                      </Carousel.Item>
                                    )
                                  )
                                }
                              </Carousel>
                            }
                            <Card.Title style={{marginTop: 30}}>
                              {e.title}
                            </Card.Title>
                            <Card.Text>
                              {e.description}
                            </Card.Text>
                            <Card.Text>
                             {t('SPECIAL_EVENT_DATES', {start_date: new Date(e.startDatetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'}), end_date: new Date(e.endDatetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}
                            </Card.Text>
                            <Row className="justify-content-center" style={{marginBottom: 10}}>
                              <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                              <Button block variant="outline-primary" size="lg" onClick={showEvent(e)}>{t('CHANGE')}</Button>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  )
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

PartnerEvents.propTypes = {
  ownedEvents: PropTypes.array,
  isLogged: PropTypes.bool,
  newEvent: PropTypes.func.isRequired,
  showEvent: PropTypes.func.isRequired,
};

PartnerEvents.defaultProps = {
  ownedEvents: [],
  isLogged: false
};

export default PartnerEvents;
