import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Form, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {permanentVideoSelected, hideVideoSharingModal, permanentVideosSharing} from '../actions/partner'
import {sharingType} from '../constants';
import Configuration from '../config';
import iconPrivate from '../images/user_icon_private_red.svg';
import iconReplayer from '../images/user_icon_replayer_red.svg';
import iconPublic from '../images/user_icon_public_red.svg';
import {useTranslation} from 'react-i18next';

const PartnerVideoSharing = ({video, hideVideoSharingModal, isVideoSharingModalVisible, saveSharing}) => {
  const {t, i18n} = useTranslation();
  const copyToClipboard = () => {
    const inputText = document.getElementById("url_video");
    inputText.select();
    inputText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isVideoSharingModalVisible} onHide={hideVideoSharingModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('SHARE_VIDEO')}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{videoId: video.videoId, sharing: video.sharing, playlistId: video.id}}
        onSubmit={saveSharing}
      >
        {({
          handleSubmit,
          values,
          setFieldValue
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <h5>{video.name}</h5>
                <h6 className="lightblue_text">{t('WHO_CAN_SEE_VIDEO')}</h6>
                <div className="input-group share_radio_rp utente_privato_radio_rp mb-1">
                  <label className="form-control" htmlFor="optradio">
                    <img src={iconPrivate} alt={t('ICON_USER_PRIVATE')}/> {t('JUST_ME_PRIVATE')}
                  </label>
                  <div className="input-group-append">
                    <div className="input-group-text form-check">
                      <input type="radio" className="form-check-input" name="optradio" checked={values.sharing === sharingType.none} onClick={() => setFieldValue('sharing', sharingType.none)}/>
                    </div>
                  </div>
                </div>
                <div className="input-group share_radio_rp utente_replayer_radio_rp mb-1">
                  <label className="form-control" htmlFor="optradio">
                    <img src={iconReplayer} alt={t('ICON_USER_REPLAYER')}/> {t('COMMUNITY_MEMBERS')}
                  </label>
                  <div className="input-group-append">
                    <div className="input-group-text form-check">
                      <input type="radio" className="form-check-input" name="optradio" checked={values.sharing === sharingType.replayer} onClick={() => setFieldValue('sharing', sharingType.replayer)}/>
                    </div>
                  </div>
                </div>
                <div className="input-group share_radio_rp utente_pubblico_radio_rp mb-4">
                  <label className="form-control" htmlFor="optradio">
                    <img src={iconPublic} alt={t('ICON_USER_PUBLIC')}/> {t('ANYONE_PUBLIC')}
                  </label>
                  <div className="input-group-append">
                    <div className="input-group-text form-check">
                      <input type="radio" className="form-check-input" name="optradio" checked={values.sharing === sharingType.public} onClick={() => setFieldValue('sharing', sharingType.public)}/>
                    </div>
                  </div>
                </div>
                <div className="input-group">
                  <input readOnly type="text" id="url_video" className="form-control" value={`${Configuration.sharedVideoBaseUrl}/${video.sharingId}`}/>
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button" onClick={copyToClipboard}>{t('COPY')}</button>
                  </div>
                </div>
              </Modal.Body>
              <div className="modal-footer">
                <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('SAVE')}</button>
                <button type="button" className="btn btn_red_outline_rp" onClick={hideVideoSharingModal}>{t('CANCEL')}</button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

PartnerVideoSharing.propTypes = {
  video: PropTypes.object.isRequired,
  hideVideoSharingModal: PropTypes.func.isRequired,
  isVideoSharingModalVisible: PropTypes.bool,
  saveSharing: PropTypes.func.isRequired
};

PartnerVideoSharing.defaultProps = {
  isVideoSharingModalVisible: false
};

const mapStateToProps = ({login: {token}, partner: {selectedPermanentVideo, isVideoSharingModalVisible}}) => ({
  token,
  selectedPermanentVideo,
  isVideoSharingModalVisible
});

const mapDispatchToProps = dispatch => ({
  hideVideoSharingModal: () => {
    dispatch(hideVideoSharingModal());
    dispatch(permanentVideoSelected(null));
  },
  saveSharing: values => {
    dispatch(permanentVideosSharing(values.videoId, values.sharing, values.playlistId));
    dispatch(hideVideoSharingModal());
    dispatch(permanentVideoSelected(null));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerVideoSharing));
