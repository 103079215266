import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerBookingCalendar from '../components/partner-booking-calendar';
import {getOwnedFieldBookings, showBookingModal, hideBookingModal, newOwnedFieldBooking, saveBooking, ownedFieldBookingSelected, deleteBooking, ownedFieldSlotSelected, bookingMultiSlotsSelected, getOwnedFieldSlots} from '../actions/partner';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({partner: {ownedField, ownedFields, isBookingModalVisible, ownedFieldSlots, ownedFieldSlot, ownedFieldBookings, ownedFieldBooking, bookingSlots}, login: {token, profile}}) => ({
  profile,
  ownedField,
  ownedFields,
  isBookingModalVisible,
  isLogged: token !== null,
  ownedFieldSlots,
  ownedFieldSlot,
  ownedFieldBookings,
  ownedFieldBooking,
  bookingSlots
});

const mapDispatchToProps = dispatch => ({
  eventSelected: ev => {
    if (ev.slot.state === 'reserved') {
      dispatch(showMessage('CALENDAR', 'CALENDAR_CANNOT_MODIFY_RESERVED_EVENT'));
    } else if (new Date(ev.slot.startDatetime) < Date.now() && ev.slot.bookingId === null) {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
    } else if (ev.slot.state === "unavailable") {
        dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_UNAVAILABLE'));
    } else if (ev.slot.bookingId) {
      dispatch(ownedFieldSlotSelected(ev.slot));
      dispatch(ownedFieldBookingSelected(ev.booking));
      if (ev.booking !== null && ev.booking !== undefined) {
        dispatch(showBookingModal());
      }
      else{
        dispatch(showMessage('BOOKINGS', 'LOADING_DATA_IN_PROGRESS'));
      }
    } else {
      dispatch(ownedFieldSlotSelected(ev.slot));
      dispatch(newOwnedFieldBooking({slots: [ev.slot.slotId], description: null, payment: false, playersNumber: 1, otherPlayers: [''], otherPlayersPrice: [0], otherPlayersPayment: [false], bookingNotes: ""}));
      dispatch(showBookingModal());
    }
  },
  bookingMultiSlots: slots => {
    dispatch(bookingMultiSlotsSelected(slots));
    dispatch(newOwnedFieldBooking({description: null, payment: false, playersNumber: 1, otherPlayers: [''], otherPlayersPrice: [0], otherPlayersPayment: [false], bookingNotes: "", slots: slots.map(s => s.slotId)}));
    dispatch(showBookingModal());
  },
  hideBookingModal: () => {
    dispatch(hideBookingModal());
  },
  saveBooking: booking => {
    dispatch(saveBooking(booking));
  },
  deleteBooking: (fieldId, bookingId) => {
    dispatch(deleteBooking(fieldId, bookingId));
  },
  showMessage: (title, body) => {
    dispatch(showMessage(title, body));
  },
  getSlotsByWeek: fieldId => targetDate => {
    dispatch(getOwnedFieldBookings(targetDate, 'WEEK'));
    dispatch(getOwnedFieldSlots(fieldId, true, 'field', targetDate, 'WEEK'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerBookingCalendar));
