import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Col, Form, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {hideUserVideoTagModal, newUserVideoTag, userVideoTagSelected, saveUserVideoTag} from '../actions/player';
import {showMessage} from '../actions/messages';
import {useTranslation} from 'react-i18next';

const PlayerVideoTag = ({isUserVideoTagModalVisible, hideUserVideoTagModal, selectedUserVideoTag, saveUserVideoTag, cancelSelection, userTags}) => {
  const {t} = useTranslation();
  if (selectedUserVideoTag === null) {
    return null;
  }

  const hmsToSeconds = val => {
    const p = val.split(':');
    let s = 0;
    let m = 1;

    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
    }

    return s;
  };

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isUserVideoTagModalVisible} onHide={hideUserVideoTagModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('TAG_APPLY')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-4">
          <div className="col-12">
            <div>
              <Formik
                enableReinitialize
                initialValues={{...selectedUserVideoTag, startStr: new Date(selectedUserVideoTag.startTime * 1000).toISOString().slice(11, 19), endStr: new Date(selectedUserVideoTag.stopTime * 1000).toISOString().slice(11, 19)}}
                onSubmit={saveUserVideoTag}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  setFieldValue
                }) => {
                  const setStart = ev => {
                    const startSeconds = hmsToSeconds(ev.target.value);
                    setFieldValue('startStr', ev.target.value);
                    setFieldValue('startTime', startSeconds);
                  };

                  const setEnd = ev => {
                    const endSeconds = hmsToSeconds(ev.target.value);
                    setFieldValue('endStr', ev.target.value);
                    setFieldValue('stopTime', endSeconds);
                  };

                  const handleUserTagIdChange = ev => {
                    if (ev.target.value) {
                      setFieldValue('userTagId', Number(ev.target.value));
                      let currentUserTag = userTags.filter(el => el.id === Number(ev.target.value))[0];
                      let endTime = new Date(selectedUserVideoTag.stopTime * 1000);
                      endTime.setSeconds(endTime.getSeconds() - currentUserTag.duration);
                      const timeString = endTime.toISOString().slice(11, 19);
                      if(timeString.slice(0,2) != "23"){
                        const startSeconds = hmsToSeconds(timeString);
                        setFieldValue('startStr', timeString);
                        setFieldValue('startTime', startSeconds);
                      } else{
                        const startSeconds = hmsToSeconds("00:00:00");
                        setFieldValue('startStr', "00:00:00");
                        setFieldValue('startTime', startSeconds);
                      }
                    } else {
                      setFieldValue('userTagId', null);
                    }
                  };

                  return (
                    <div className="card-body d-flex flex-column">
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label>{t('TAG_LABEL')}*</Form.Label>
                          <Form.Control required style={{fontSize: '1em'}} name="userTagId" as="select" placeholder={t('TAG_CHOOSE')} value={values.userTagId} onChange={handleUserTagIdChange}>
                            <option value="">{t('TAG_CHOOSE')}</option>
                            {userTags.map(t => (<option key={t.id} value={t.id} style={{background: t.color, margin: 10, color: '#fff'}}>{t.label}</option>))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>{t('DESCRIPTION')}</Form.Label>
                          <Form.Control name="description" as="textarea" maxLength={150} value={values.description || ''} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>{t('START')}*</Form.Label>
                              <Form.Control required name="startStr" type="text" placeholder={t('START')} maxLength={8} value={values.startStr || '00:00:00'} onChange={setStart}/>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>{t('END')}*</Form.Label>
                              <Form.Control required name="endStr" type="text" placeholder={t('END')} maxLength={8} value={values.endStr || '00:00:00'} onChange={setEnd}/>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <p>{t('DURATION')} {hmsToSeconds(values.endStr) - hmsToSeconds(values.startStr)}</p>
                        <div className="d-flex flex-row-reverse">
                          <a className="btn btn_red_outline_rp ml-1" onClick={cancelSelection}>{t('CANCEL')}</a>
                          <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <button type="button" className="btn btn_red_outline_rp" onClick={hideUserVideoTagModal}>{t('CLOSE_BUTTON')}</button>
      </div>
    </Modal>
  );
};

PlayerVideoTag.propTypes = {
  hideUserVideoTagModal: PropTypes.func.isRequired,
  saveUserVideoTag: PropTypes.func.isRequired,
  cancelSelection: PropTypes.func.isRequired,
  isUserVideoTagModalVisible: PropTypes.bool,
  selectedUserVideoTag: PropTypes.object,
  userTags: PropTypes.array
};

PlayerVideoTag.defaultProps = {
  isUserVideoTagModalVisible: false,
  selectedUserVideoTag: null,
  userTags: []
};

const mapStateToProps = ({login: {token}, player: {isUserVideoTagModalVisible, selectedUserVideoTag, userTags}}) => ({
  token,
  isUserVideoTagModalVisible,
  selectedUserVideoTag,
  newUserVideoTag,
  userTags
});

const mapDispatchToProps = dispatch => ({
  hideUserVideoTagModal: () => {
    dispatch(hideUserVideoTagModal());
  },
  saveUserVideoTag: values => {
    if (values.startTime >= values.stopTime || values.stopTime - values.startTime > 180) {
      dispatch(showMessage('TAGS', 'TAG_BAD_START_TIME'));
      return;
    }
    dispatch(saveUserVideoTag(values));
    dispatch(userVideoTagSelected(null));
  },
  cancelSelection: () => {
    dispatch(userVideoTagSelected(null));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerVideoTag));
