import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Tab, Tabs} from 'react-bootstrap';
import PlayerBookingCalendar from '../containers/player-booking-calendar';
import PlayerServiceBookingCalendar from '../containers/player-service-booking-calendar';
import CommonMetaTag from './common-meta-tag';
import {useTranslation} from 'react-i18next';


const PlayerCalendars = ({selectedPartner}) => {
    const {t, i18n} = useTranslation();

    return(
        <Fragment>
            <CommonMetaTag/>
            <Tabs defaultActiveKey={selectedPartner && selectedPartner.isSpecialist ? "servizi" : "campi"} className="myvideo-tabs" style={{marginTop: 100, marginLeft: 130, marginRight: 130}} mountOnEnter unmountOnExit>
                <Tab eventKey="campi" title={t('FIELDS')} tabClassName="myvideo-tab">
                    <PlayerBookingCalendar/>
                </Tab>
                <Tab eventKey="servizi" title={t('SERVICES')} tabClassName="myvideo-tab">
                    <PlayerServiceBookingCalendar/>
                </Tab>
            </Tabs>
        </Fragment>
    );
};

const mapStateToProps = ({player: {selectedPartner}}) => ({
    selectedPartner,
  });

  export default withRouter(connect(
    mapStateToProps,
  )(PlayerCalendars));