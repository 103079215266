import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Form, Fade, Container, Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import CommonMetaTag from './common-meta-tag';
import {reportTransactions} from '../actions/admin';
import r from '../images/title_r.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import it from 'date-fns/locale/it';
registerLocale('it', it);

const AdminReportTransactions = ({createReport}) => {
  const {t, i18n} = useTranslation();

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{start: null, end: null, email:'', surname:''}}
      onSubmit={createReport}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <img
                      src={r}
                      height="30"
                      className="mx-auto d-block"
                      alt={t('LOGO')}
                    />
                    <Row className="justify-content-center" style={{marginTop: 20}}>
                      <h1>{t('TITLE_REPLAYER')}</h1>
                    </Row>
                    <Row className="justify-content-center">
                      <h4>{t('MOTTO')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <h4 style={{marginTop: 30}}>{t('TRANSACTION_REGISTER')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <p style={{marginTop: 30, textAlign: 'center'}}>{t('SELECT_PERIOD')}</p>
                    </Row>
                    <Form onSubmit={handleSubmit}>
                      <Form.Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>{t('START_DATE')}</Form.Label><br/>
                            <DatePicker
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              selected={(values.start && new Date(values.start)) || null}
                              onChange={val => setFieldValue('start', val ? new Date(val).toISOString() : null)}/>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>{t('END_DATE')}</Form.Label><br/>
                            <DatePicker
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              selected={(values.end && new Date(values.end).addDays(-2)) || null}
                              onChange={val => setFieldValue('end', val ? new Date(val).addDays(2).toISOString() : null)}/>
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Group>
                        <Form.Label>{t('EMAIL')}</Form.Label>
                        <Form.Control name="email" type="text" maxLength={255} placeholder={t('EMAIL')} value={values.email || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Cognome Player/Ragione sociale Partner</Form.Label>
                        <Form.Control name="surname" type="text" maxLength={255} placeholder='Cognome Player/Ragione sociale Partner' value={values.surname || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>{t('EXTRACT_XLS')}</Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

AdminReportTransactions.propTypes = {
  createReport: PropTypes.func.isRequired
};

const mapStateToProps = ({partner: {ownedFields}}) => ({
  ownedFields
});

const mapDispatchToProps = dispatch => ({
  createReport: values => {
    dispatch(reportTransactions(values));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportTransactions));
