import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import PlayerHome from '../components/player-home';
import AdminHome from '../components/admin-home';
import {ROLES} from '../constants';

const App = ({
  token,
  profile
}) => {
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (profile.user.role === ROLES.player) {
    return (
      <PlayerHome/>
    );
  }

  if (profile.user.role === ROLES.admin) {
    return (
      <AdminHome/>
    );
  }

  return null;
};

App.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object.isRequired
};

App.defaultProps = {
  token: null
};

const mapStateToProps = ({login: {token, profile}}) => ({
  token,
  profile
});

export default withRouter(connect(
  mapStateToProps,
  null
)(App));
