import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {Fade, Form, Button, Row, Col, Container} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const SignUpPartner = ({
  signUp
}) => {
  const {t, i18n} = useTranslation();
  return (
    <Formik
      initialValues={{businessName: null, IVA: null, email: null}}
      onSubmit={signUp}
    >
      {({
        handleSubmit,
        handleChange,
        values
      }) => {
        return (
          <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 50}}>
            <Container>
              <Row className="justify-content-center">
                <Col xs={12} sm={8} md={6}>
                  <h4>{t('SIGN_IN_PARTNER')}</h4>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>{t('BUSINESS_NAME')}</Form.Label>
                      <Form.Control required name="businessName" placeholder={t('BUSINESS_NAME')} type="text" maxLength={255} value={values.businessName || ''} onChange={handleChange}/>
                      <Form.Text className="text-muted">
                      {t('BUSINESS_NAME')}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('VAT_NUMBER')}</Form.Label>
                      <Form.Control required name="IVA" type="text" placeholder={t('VAT_NUMBER')} maxLength={20} value={values.IVA || ''} onChange={handleChange}/>
                      <Form.Text className="text-muted">
                      {t('VAT_NUMBER')}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('EMAIL')}</Form.Label>
                      <Form.Control required name="email" type="email" placeholder={t('EMAIL')} maxLength={255} value={values.email || ''} onChange={handleChange}/>
                      <Form.Text className="text-muted">
                      {t('EMAIL')}
                      </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      {t('SIGN_IN_BUTTON')}
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Fade>
        );
      }}
    </Formik>
  );
};

SignUpPartner.propTypes = {
  signUp: PropTypes.func.isRequired
};

export default SignUpPartner;
