import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-bootstrap-dialog';
import {Button, ToggleButtonGroup, ToggleButton, Form, Row, Col, Popover, Modal} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import it from 'date-fns/locale/it';
import {rtvType, groupTypes} from '../constants';
import {showMessage} from '../actions/messages';
import {useTranslation} from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';
import {showPlayersModal, hidePlayersModal, cleanPlayersReceived, searchPlayers} from '../actions/partner'
import defaultAvatar from '../images/Replayer_default_Avatar.png'
import binIcon from '../images/bin_icon.svg';
import Configuration from '../config';

registerLocale('it', it);

const Slot = ({slot, save, remove, profile, ownedFields, showMessageRemovingVideo, ownedRtvChannels, showPlayersModal, hidePlayersModal, isPlayersModalVisible, searchPlayers, playersList}) => {
  const {t, i18n} = useTranslation();
  const hasGroupCoach = profile.groups.find(g => g.groupType === groupTypes.coach);
  const field = fieldId => ownedFields.find(f => f.id === fieldId);

  const [selectedPlayers, setSelectedPlayers] = useState(slot.selectedPlayers || []);
  const [selectedPlayer, setSelectedPlayer] = useState({});

  const getCamerasSelectArray = (cameras, disabled) => {
    let res = [];
    cameras.sort((a,b) => a.position - b.position).forEach(c => {
      res.push({label: c.name, value: c.id, disabled: disabled})
    });
    return res;
  };

  const cameraOptions = getCamerasSelectArray(field(slot.fieldId).cameras, slot.slotId && new Date(slot.startDatetime) < new Date());

  const rtvChannelsTypes = {
    "replayerVideo": 0,
    "partnerVideo": 1,
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={Object.assign(
          {},
          slot,
          {untilDate: slot.endDatetime, overwriting: false, weekDays: [], remove: false, event: slot.event || false, presetPlaces: slot.presetPlaces || 1, color: slot.color || '#ADB5BD', minimumPlayers: slot.minimumPlayers || null, selectedCameras: slot.selectedCameras || cameraOptions.filter(o => o.value === field(slot.fieldId).cameras.filter(c => c.position === 1)[0].id), public: slot.public, costIds: slot.costIds || [], costs: slot.costs || []}
        )}
        onSubmit={values => values.remove ? remove(values) : save(values, selectedPlayers)}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values
        }) => {
          const setVideoSellPermissionByState = state => {
            if (state.target.value === 'available') {
              setFieldValue('videoSellPermission', true);
              setFieldValue('public', null);
            }

            if(state.target.value === 'reserved') {
              setFieldValue('videoSellPermission', false);
            }

            handleChange(state);
          };

          let dialog = null;
          const onRemoveVideo = (message, fieldName, newValue) => {
            dialog.show({
              title: t('CONFIRM'),
              body: message,
              actions: [
                Dialog.Action(
                  t('OK'),
                  () => setFieldValue(fieldName, newValue),
                  'btn btn-secondary'
                ),
                Dialog.Action(
                  t('CANCEL'),
                  null,
                  'btn btn_red_outline_rp'
                )
              ],
              bsSize: 'small',
              onHide: dialog => {
                dialog.hide();
              }
            });
          };

          const renderPlayer = (players) => {
            return(
              <div>
                <ul class="list-group list-group-light mb-4">
                  {
                    players.map( (p, index) =>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                          {p.image && p.image[0] ?
                          <img
                            className="img-fluid"
                            src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.image[0]}`}
                            alt={t('PROFILE_IMAGE')}
                            style={{width: 45, height: 45, paddingLeft:0}}
                            class="rounded-circle"
                          />
                          :
                          <img
                            className="img-fluid"
                            src={defaultAvatar}
                            alt={t('PROFILE_IMAGE')}
                            style={{width: 45, height: 45, paddingLeft:0}}
                            class="rounded-circle"
                          />
                          }
                          <div class="ms-3">
                            <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.name + " " + p.surname}</p>
                            <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.nickname}</p>
                          </div>
                        </div>
                        <li className="card_video_archive_list_icons_item"><a href="#" onClick={() => players.splice(index, 1)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
                      </li>
                    )
                  }
                </ul>
              </div>
            )
          }

          const changePresetPlaces = (value) => {
            if(field(values.fieldId).payment && field(values.fieldId).unbookableHours != null){
              if(values.presetPlaces === 1 && value > 1){
                setFieldValue('minimumPlayers', 0);
              }
              else if(values.presetPlaces > 1 && value === 1){
                setFieldValue('minimumPlayers', null);
              }
            }
            setFieldValue('presetPlaces', value)
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>{t('STATE')}*</Form.Label>
                <Form.Control required as="select" name="state" value={values.state || ''} onChange={setVideoSellPermissionByState}>
                  <option value="">{t('GIVE_STATE')}</option>
                  <option value="available">{t('AVAILABLE')}</option>
                  <option value="reserved">{t('RESERVED')}</option>
                  <option value="unavailable">{t('UNAVAILABLE')}</option>
                </Form.Control>
              </Form.Group>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('START_TIME')}*</Form.Label><br/>
                    <DatePicker
                      required
                      showTimeSelect
                      showTimeSelectOnly
                      locale="it"
                      className="form-control"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      timeIntervals={15}
                      selected={(values.startDatetime && new Date(values.startDatetime)) || null}
                      onChange={val => {
                        if (slot.startDatetime && val) {
                          const newDate = new Date(slot.startDatetime);
                          newDate.setHours(val.getHours());
                          newDate.setMinutes(val.getMinutes());
                          setFieldValue('startDatetime', newDate);
                        } else {
                          setFieldValue('startDatetime', val);
                        }
                      }}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('FINISH_TIME')}*</Form.Label><br/>
                    <DatePicker
                      required
                      showTimeSelect
                      showTimeSelectOnly
                      locale="it"
                      className="form-control"
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      timeIntervals={15}
                      selected={(values.endDatetime && new Date(values.endDatetime)) || null}
                      onChange={val => {
                        if (slot.endDatetime && val) {
                          const newDate = new Date(slot.endDatetime);
                          newDate.setHours(val.getHours());
                          newDate.setMinutes(val.getMinutes());
                          setFieldValue('endDatetime', newDate);
                        } else {
                          setFieldValue('endDatetime', val);
                        }

                        if (val) {
                          const newUntilDate = new Date(values.untilDate);
                          newUntilDate.setHours(val.getHours());
                          newUntilDate.setMinutes(val.getMinutes());
                          setFieldValue('untilDate', newUntilDate);
                        }
                      }}/>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="align-items-center">
                <Col>
                  <Form.Group>
                    <Form.Label>{t('REPEAT')}</Form.Label><br/>
                    <DatePicker
                      required
                      locale="it"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={(values.untilDate && new Date(values.untilDate)) || null}
                      onChange={val => setFieldValue('untilDate', val)}/>
                  </Form.Group>
                </Col>
                <Col className="d-flex flex-column">
                  <Form.Group>
                    <Form.Check style={{marginTop: 10, marginLeft: 20}} name="overwriting-remove" type="radio" label={t('KEEP')} checked={!values.overwriting} onChange={() => {setFieldValue('overwriting', !(values.overwriting)); setFieldValue('remove', false);}}/>
                    <Form.Check style={{marginLeft: 20}} name="overwriting-remove" type="radio" label={t('OVERWRITE')} checked={values.overwriting} onChange={() => {setFieldValue('overwriting', !(values.overwriting)); setFieldValue('remove', false);}}/>
                    {
                      values.slotId ?
                        <Form.Check style={{marginLeft: 20}} name="overwriting-remove" type="radio" label={t('DELETE')} checked={values.remove} onChange={() => setFieldValue('remove', !(values.remove))}/> :
                        null
                    }
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <ToggleButtonGroup name="weekDays" type="checkbox" value={values.weekDays} onChange={weekDays => setFieldValue('weekDays', weekDays)}>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={0}
                      size="sm"
                    >
                      {t('MONDAY')}
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={1}
                      size="sm"
                    >
                      {t('TUESDAY')}
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={2}
                      size="sm"
                    >
                      {t('WEDNESDAY')}
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={3}
                      size="sm"
                    >
                      {t('THURSDAY')}
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={4}
                      size="sm"
                    >
                      {t('FRIDAY')}
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={5}
                      size="sm"
                    >
                      {t('SATURDAY')}
                    </ToggleButton>
                    <ToggleButton
                      type="checkbox"
                      variant="success"
                      value={6}
                      size="sm"
                    >
                      {t('SUNDAY')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              </Form.Row>
              {
                values.state === 'reserved' ?
                  <Fragment>
                    <Form.Group>
                      <Form.Check
                        name="videoSellPermission"
                        type="checkbox"
                        label={t('SALABLE_VIDEO')}
                        checked={values.videoSellPermission}
                        onChange={() => {
                          const newValue = !values.videoSellPermission;
                          if (!newValue) {
                            onRemoveVideo(t('VIDEO_PARTNER_REMOVING_SALABLE'), 'videoSellPermission', newValue);
                          } else {
                            setFieldValue('videoSellPermission', newValue);
                          }
                          if (newValue && values.rtvOrAcademy === rtvType.academy) {
                            setFieldValue('rtvOrAcademy', null);
                          }
                        }}
                      />
                    </Form.Group>
                    {
                      values.videoSellPermission ?
                      <Fragment>
                        <Form.Group>
                          <Form.Label>{t('VIDEO_VISIBILITY')}</Form.Label>
                          <Form.Check required name="public" type="radio" label={t('PUBLIC')} checked={values.public} onChange={() => setFieldValue('public', true)}/>
                          <Form.Check required name="public" type="radio" label={t('PRIVATE')} checked={values.public === false} onChange={() => setFieldValue('public', false)}/>
                        </Form.Group>
                        {values.public === false ?
                        <Form.Group>
                          <Form.Label>{t('PLAYERS_SELECTION')}</Form.Label>
                          {renderPlayer(selectedPlayers)}
                          <Button variant="secondary" onClick={showPlayersModal}>{t('ADD_PLAYER')}</Button>
                        </Form.Group>
                      : null}
                      </Fragment>
                    : null}
                    {
                      ownedRtvChannels.filter(c => c.type === rtvChannelsTypes.replayerVideo).length > 0 && field(values.fieldId).rtv ?
                        <Fragment>                                           
                          {ownedRtvChannels.filter(c => c.type === rtvChannelsTypes.replayerVideo).map(c => (
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                label={`Rtv ${c.name}`}
                                checked={values.rtvChannels.some(channel => channel.id === c.id)}
                                onChange={() => {
                                  if (values.rtvChannels.some(channel => channel.id === c.id)) {
                                    setFieldValue('rtvChannels', values.rtvChannels.filter(channel => channel.id !== c.id));
                                  } else {
                                    setFieldValue('rtvChannels', [...values.rtvChannels, c]);
                                    setFieldValue('rtvOrAcademy', null);
                                  }
                                }}/>
                            </Form.Group>
                          ))}
                          {
                            hasGroupCoach ?
                              <Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label="Academy"
                                  checked={values.rtvOrAcademy === rtvType.academy}
                                  onChange={() => {
                                    if (values.rtvOrAcademy === rtvType.academy) {
                                      onRemoveVideo(t('VIDEO_PARTNER_REMOVING_RTV'), 'rtvOrAcademy', null);
                                    } else {
                                      const newValue = values.rtvOrAcademy === rtvType.academy ? null : rtvType.academy;
                                      setFieldValue('rtvOrAcademy', newValue);
                                      if (newValue === rtvType.academy) {
                                        setFieldValue('videoSellPermission', false);
                                        setFieldValue('rtvChannels', []);
                                      }
                                    }
                                  }}/>
                              </Form.Group> :
                              null
                          }
                        </Fragment> :
                        null
                    }
                    <Form.Group>
                      <Form.Check
                        name="event"
                        type="checkbox"
                        label={t('SPECIAL_EVENT')}
                        checked={values.event}
                        onChange={() => setFieldValue('event', !(values.event && values.event))}
                      />
                    </Form.Group>
                    {cameraOptions.length > 0 && (values.videoSellPermission || values.rtvChannels.length > 0 || values.rtvOrAcademy === rtvType.academy) ?
                      <Form.Group>
                        <Form.Label>{t('CAMERAS')}</Form.Label>
                        <div style={{color: 'black'}}>
                          <MultiSelect
                            options={cameraOptions}
                            value={values.selectedCameras}
                            onChange={value => setFieldValue('selectedCameras', value)}
                            labelledBy="SelectCamera"
                            overrideStrings={{
                              "allItemsAreSelected": t('ALL_CAMERAS'),
                              "search": t('SEARCH'),
                              "selectAll": t('SELECT_ALL'),
                              "selectSomeItems": t('CAMERA_SELECT'),
                              "selectAllFiltered": t('SELECT_ALL'),
                            }}
                            ClearSelectedIcon={null}
                          />
                          <input
                            name="hiddenRequired"
                            style={{ height: 0, width: 0, opacity: 0}}
                            value={values.selectedCameras.length ? "1" : ""}
                            required
                          />
                        </div>
                      </Form.Group>
                    : null}
                    <Form.Group>
                      <Form.Label>{t('TAG_COLOR')}*</Form.Label><br />                    
                          <OverlayTrigger
                            trigger="click"
                            key="bottom"                          
                            placement="bottom"
                            overlay={
                              <Popover>
                                <Popover.Title as="h3">{t('TAG_COLOR')}</Popover.Title>
                                <Popover.Content>
                                  <Row style={{marginBottom: 10}}>
                                    <Col><Button style={{backgroundColor: '#E53935', border: 0, height: 25}} onClick={() => setFieldValue('color', '#E53935')}/></Col>
                                    <Col><Button style={{backgroundColor: '#D81B60', border: 0, height: 25}} onClick={() => setFieldValue('color', '#D81B60')}/></Col>
                                    <Col><Button style={{backgroundColor: '#8E24AA', border: 0, height: 25}} onClick={() => setFieldValue('color', '#8E24AA')}/></Col>
                                    <Col><Button style={{backgroundColor: '#5E35B1', border: 0, height: 25}} onClick={() => setFieldValue('color', '#5E35B1')}/></Col>
                                    <Col><Button style={{backgroundColor: '#9D86C3', border: 0, height: 25}} onClick={() => setFieldValue('color', '#9D86C3')}/></Col>                                  
                                  </Row>
                                  <Row>                                  
                                    <Col><Button style={{backgroundColor: '#FFB300', border: 0, height: 25}} onClick={() => setFieldValue('color', '#FFB300')}/></Col>                                  
                                    <Col><Button style={{backgroundColor: '#FB8C00', border: 0, height: 25}} onClick={() => setFieldValue('color', '#FB8C00')}/></Col>
                                    <Col><Button style={{backgroundColor: '#F4511E', border: 0, height: 25}} onClick={() => setFieldValue('color', '#F4511E')}/></Col>
                                    <Col><Button style={{backgroundColor: '#AB6158', border: 0, height: 25}} onClick={() => setFieldValue('color', '#AB6158')}/></Col>
                                    <Col><Button style={{backgroundColor: '#ADB5BD', border: 0, height: 25}} onClick={() => setFieldValue('color', '#ADB5BD')}/></Col>
                                  </Row>
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <Button block size='lg' style={{backgroundColor: values.color, border: 0, height: 25}}/>
                          </OverlayTrigger>
                    </Form.Group>
                  </Fragment> :
                  null
              }
              {
                values.state === 'available' ?
                  <Fragment>
                    <Form.Group>
                      <Form.Label>{t('PRICE')}</Form.Label>
                      <Form.Control required={field(values.fieldId).payment} name="price" type="number" min="0.00" step="any" value={values.price || ''} onChange={handleChange}/>
                    </Form.Group>
                    {
                      ownedRtvChannels.filter(c => c.type === rtvChannelsTypes.replayerVideo).length > 0 ?                      
                        <Form.Label>{t('ALLOW_PUBLICATION_ON')}</Form.Label>
                        :
                        null
                    }
                    {ownedRtvChannels.filter(c => c.type === rtvChannelsTypes.replayerVideo).map(c => (
                      <Form.Group key={c.id}>
                        <Form.Check
                          type="checkbox"
                          label={`Rtv ${c.name}`}
                          checked={values.rtvChannels.some(channel => channel.id === c.id)}
                          onChange={() => {
                            if (values.rtvChannels.some(channel => channel.id === c.id)) {
                              setFieldValue('rtvChannels', values.rtvChannels.filter(channel => channel.id !== c.id));
                            } else {
                              setFieldValue('rtvChannels', [...values.rtvChannels, c]);
                            }
                          }}/>
                      </Form.Group>
                    ))}
                    {cameraOptions.length > 0 ?
                      <Form.Group>
                        <Form.Label>{t('CAMERAS')}</Form.Label>
                        <div style={{color: 'black'}}>
                          <MultiSelect
                            options={cameraOptions}
                            value={values.selectedCameras}
                            onChange={value => setFieldValue('selectedCameras', value)}
                            labelledBy="SelectCamera"
                            overrideStrings={{
                              "allItemsAreSelected": t('ALL_CAMERAS'),
                              "search": t('SEARCH'),
                              "selectAll": t('SELECT_ALL'),
                              "selectSomeItems": t('CAMERA_SELECT'),
                              "selectAllFiltered": t('SELECT_ALL'),
                            }}
                            ClearSelectedIcon={null}
                          />
                          <input
                            name="hiddenRequired"
                            style={{ height: 0, width: 0, opacity: 0}}
                            value={values.selectedCameras.length ? "1" : ""}
                            required
                          />
                        </div>
                      </Form.Group>
                    : null}
                    <Form.Group>
                      <Form.Label>{t('PLACES')}</Form.Label>
                      <Form.Control name="presetPlaces" type="number" min="1" max="30" step="1" value={values.presetPlaces || 1} onChange={v => changePresetPlaces(Number(v.target.value))}/>
                    </Form.Group>
                  </Fragment> :
                  null
              }
              {
                values.state === 'reserved' || values.state === 'unavailable' ?
                  <Form.Group>
                    <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                    <Form.Control required name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')}value={values.description || ''} onChange={handleChange}/>
                  </Form.Group>
                : null
              }
              {
                values.state === 'available' && values.presetPlaces > 1 && field(values.fieldId).payment && field(values.fieldId).unbookableHours != null ?
                  <Form.Group>
                    <Form.Label>{t('BOOKING_TYPE')}*</Form.Label>
                      <Form.Check required name="groupBooking" type="radio" label={t('INDIVIDUAL_BOOKING')} checked={values.minimumPlayers === null} onChange={() => setFieldValue('minimumPlayers', null)}/>
                      <Form.Check required name="groupBooking" type="radio" label={t('GROUP_BOOKING')} checked={values.minimumPlayers !== null} onChange={() => setFieldValue('minimumPlayers', 0)}/>
                  </Form.Group>
                : null
              }
              {
                values.state === 'available' && values.minimumPlayers !== null && values.presetPlaces > 1?
                <Form.Group>
                  <Form.Label>{t('MINIMUM_PLAYERS')}</Form.Label>
                  <Form.Control name="minimumPlayers" type="number" min="1" max={values.presetPlaces} step="1" value={values.minimumPlayers} onChange={handleChange}/>
                </Form.Group>
                : null
              }
              {
                values.state === 'available' && ((field(values.fieldId).costs && field(values.fieldId).costs.length > 0) || values.costs && values.costs.length > 0) && (values.presetPlaces === 1 || (values.presetPlaces > 1 && values.minimumPlayers !== null)) ?
                  <Form.Group>
                    <Form.Label>{t('FIXED_COSTS')}</Form.Label>
                    {field(values.fieldId).costs.map(c => (
                      <Form.Check 
                      type="checkbox" 
                      label={c.name + ` (${c.hourPrice}€/h)`}
                      checked={values.costIds.includes(c.id)} 
                      onChange={() => {
                        if (values.costIds.includes(c.id)) {
                          setFieldValue('costIds', values.costIds.filter(id => id !== c.id));
                        } else {
                          setFieldValue('costIds', [...values.costIds, c.id]);
                        }
                      }}/>
                    ))}
                    {
                      values.costs.map(c => (
                        !(field(values.fieldId).costs.map(fc => fc.id).includes(c.costId)) ?
                          <Form.Check
                            type="checkbox" 
                            label={c.name+ ` (${c.hourPrice}€/h)`} 
                            checked={values.costIds.includes(c.costId)} 
                            onChange={() => {
                              if (values.costIds.includes(c.costId)) {
                                setFieldValue('costIds', values.costIds.filter(id => id !== c.costId));
                              } else {
                                setFieldValue('costIds', [...values.costIds, c.costId]);
                              }
                            }}/>
                        : null  
                    ))}
                  </Form.Group>
                : null
              }
              {
                values.state === 'available' && values.presetPlaces > 1 ?
                  <Form.Group>
                    <Form.Label>{t('DESCRIPTION')}</Form.Label>
                    <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')}value={values.description || ''} onChange={handleChange}/>
                  </Form.Group>
                : null
              }
              <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>{values.remove ? t('DELETE') : t('SAVE')}</Button>
              <Dialog
                ref={el => {
                  dialog = el;
                }}/>
            </Form>
          );
        }}
      </Formik>
      {isPlayersModalVisible ?
        <Modal show={isPlayersModalVisible} onHide={hidePlayersModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('ADD_PLAYER')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
                enableReinitialize
                initialValues={{selectedPlayers}}
                onSubmit={values => setSelectedPlayers(values.selectedPlayers)}
              >
                {({
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  values
                }) => {

                const addInvitedPlayer = (player) => {
                  let alreadyInvited = false;
                  values.selectedPlayers.forEach(p => {
                    if(p.uid === player.uid) alreadyInvited = true;
                  });
                  if(!alreadyInvited) values.selectedPlayers.push(player);
                  setSelectedPlayer({});
                  values.playerName = "";
                  values.playerSurname = "";
                  values.playerNickname = "";
                  hidePlayersModal();
                };

                const renderPlayerSearch = player => {
                  return(
                    <div key={player.uid}>
                      <ul class="list-group list-group-light mb-4">
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            {player && player.image && player.image[0] ?
                            <img
                              className="img-fluid"
                              src={`${Configuration.apiEndPoint}/images/${player.uid}?load=${player.image[0]}`}
                              alt={t('PROFILE_IMAGE')}
                              style={{width: 45, height: 45, paddingLeft:0}}
                              class="rounded-circle"
                            />
                            :
                            <img
                              className="img-fluid"
                              src={defaultAvatar}
                              alt={t('PROFILE_IMAGE')}
                              style={{width: 45, height: 45, paddingLeft:0}}
                              class="rounded-circle"
                            />
                            }
                            <div class="ms-3">
                              <p class="fw-bold mb-1" style={{marginLeft: 10}}>{player.name + " " + player.surname}</p>
                              <p class="fw-bold mb-1" style={{marginLeft: 10}}>{player.nickname + (player.groupType ? " - " + player.groupType : "")}</p>
                            </div>
                          </div>
                          <Form.Check required name="selectedPlayer" type="radio" onChange={() => setSelectedPlayer(player)}/>
                        </li>
                      </ul>
                    </div>
                  )
                }

                return(
                  <Form onSubmit={handleSubmit}>
                      <Fragment>
                        <p>{t('THREE_CHAR_MINIMUM')}</p>
                        <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                          <Form.Control name="playerNickname" style={{maxWidth: 150}} placeholder={t('NICKNAME')} type="text" maxLength="25" minLength="3" value={values.playerNickname || ""} onChange={handleChange}/>
                          <Form.Control name="playerName" style={{maxWidth: 150}} placeholder={t('NAME')} type="text" maxLength="25" minLength="3" value={values.playerName || ""} onChange={handleChange}/>
                          <Form.Control name="playerSurname" style={{maxWidth: 150}} placeholder={t('SURNAME')} type="text" maxLength="25" minLength="3" value={values.playerSurname || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
                          <Form.Check name="playerAffiliate" type="checkbox" label={t('PLAYER_AFFILIATE',{partner: profile.name})} checked={values.playerAffiliate === true} onChange={() => setFieldValue('playerAffiliate', !values.playerAffiliate)}/>
                          <Button disabled={!(values.playerNickname && values.playerNickname.length > 2 || values.playerName && values.playerName.length > 2 || values.playerSurname && values.playerSurname.length > 2)} variant="secondary" onClick={() => searchPlayers(values.playerNickname, values.playerName, values.playerSurname, values.playerAffiliate)}>{t('SEARCH')}</Button>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', flexDirection: 'column'}}>
                        {playersList.map(p => 
                          renderPlayerSearch(p)
                        )}
                        <Button style={{maxWidth: 100}}disabled={selectedPlayer.uid === undefined} variant="secondary" onClick={() => addInvitedPlayer(selectedPlayer)}>{t('SELECT')}</Button>
                        </Form.Group>
                      </Fragment>
                  </Form>
                )}}
            </Formik>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={hidePlayersModal}>
              {t('CLOSE_BUTTON')}
            </Button>
          </Modal.Footer>
        </Modal>
      : null}
    </Fragment>
  );
};

Slot.propTypes = {
  slot: PropTypes.object,
  ownedFields: PropTypes.array,
  save: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  showMessageRemovingVideo: PropTypes.func.isRequired,
  ownedRtvChannels: PropTypes.array,
};

Slot.defaultProps = {
  slot: null,
  ownedFields: [],
  ownedRtvChannels: [],
};

const mapStateToProps = ({login: {profile}, partner: {ownedFields, ownedRtvChannels, isPlayersModalVisible, playersList}}) => ({
  profile,
  ownedFields,
  ownedRtvChannels,
  isPlayersModalVisible,
  playersList,
});

const mapDispatchToProps = dispatch => ({
  showMessageRemovingVideo: (message) => {
    dispatch(showMessage('VIDEOS', message));
  },
  showPlayersModal: () => {
    dispatch(showPlayersModal());
  },
  hidePlayersModal: () => {
    dispatch(cleanPlayersReceived());
    dispatch(hidePlayersModal());
  },
  searchPlayers: (playerNickname, playerName, playerSurname, playerAffiliate) => {
    if (playerAffiliate === undefined) playerAffiliate = false;
    if (playerName) playerName = playerName.replace(/ /g,'_');
    if (playerSurname) playerSurname = playerSurname.replace(/ /g,'_');
    if (playerNickname) playerNickname = playerNickname.replace(/ /g,'_');
    const validRegex = /^[A-Za-z0-9_àèéìòù'-]*$/;
    if ((playerName && !playerName.match(validRegex)) || (playerSurname && !playerSurname.match(validRegex)) || (playerNickname && !playerNickname.match(validRegex)))
      dispatch(showMessage('ACCEPTED_CHARACTERS', "ACCEPTED_CHARACTERS_INFO"));
    else dispatch(searchPlayers(playerNickname, playerName, playerSurname, playerAffiliate));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Slot));
