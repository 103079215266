/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Container, Fade, Tabs, Tab, Button} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import Dialog from 'react-bootstrap-dialog';
import Configuration from '../config';
import {push} from 'connected-react-router';
import {addPartnerVideoToPlayerArea, getRtvPartnerVideos, myVideoActiveTab, postUsageData} from '../actions/player';
import playArrowWhite from '../images/play_arrow_white_48dp.png';
import replayerlogoBianco from '../images/replayer_logo_bianco_40x32px.png';
import noLogo from '../images/club_icon_grey.svg';
import defaultFieldImage from '../images/default_field_image.png'
import {useTranslation} from 'react-i18next';

const PlayerReplayerTvVideoPartner = ({
  token,
  selectedPartner,
  rtvPartnerVideos,
  addVideoToPlayerArea,
  profile,
  updateVideos,
  selectedRtvChannel,
  sendUsageData,
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (!selectedPartner || !selectedRtvChannel) {
    return null;
  }

  const styleBackgroundImage = () => {
    if (selectedRtvChannel.imagesNames && selectedRtvChannel.imagesNames.length > 0) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/rtv_channels/${selectedRtvChannel.id}/images?load=${selectedRtvChannel.imagesNames[0]})`};
    }

    return null;
  };

  let dialog = null;

  const getPartnerLogo = () => {
    if(selectedRtvChannel.logoNames && selectedRtvChannel.logoNames.length > 0) {
      return `${Configuration.apiEndPoint}/partners/rtv_channels/${selectedRtvChannel.id}/images?load=${selectedRtvChannel.logoNames[0]}`
    }
    if (selectedPartner && selectedPartner.storeImage && selectedPartner.storeImage.length > 0) {
      return `${Configuration.apiEndPoint}/images/${selectedPartner.uid}?load=${selectedPartner.storeImage[0]}`;
    }

    return noLogo;
  };

  const getCoverImage = (partnerId, coverImage, defaultImage, videoId) => {
    if (coverImage && coverImage[0]) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/video_partners/${videoId}/images?load=${coverImage[0]}`, backgroundSize: 'cover'};
    }
    if (defaultImage && defaultImage[0]) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/images/${partnerId}?load=${defaultImage[0]}`, backgroundSize: 'cover'};
    }

    return {backgroundImage: defaultFieldImage, backgroundSize: 'cover'};;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_lightgrey_rp" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter replayertv_sportcenter_hero_rp" style={styleBackgroundImage()} data-parallax="true">
            <div className={selectedPartner.isSpecialist ? "replayertv_sportcenter_hero_overlay_rp_specialist" : "replayertv_sportcenter_hero_overlay_rp"}/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12 d-flex align-items-center flex-column">
                    <div className="replayertv_sportcenter_logo mb-2">
                      {
                        selectedRtvChannel.logoNames && selectedRtvChannel.logoNames.length > 0 ?
                        <div className="align-self-center">
                          <img
                            className="img-fluid"
                            src={`${Configuration.apiEndPoint}/partners/rtv_channels/${selectedRtvChannel.id}/images?load=${selectedRtvChannel.logoNames[0]}`}
                            alt={t('LOGO')}
                          />
                        </div> :
                          selectedPartner.storeImage && selectedPartner.storeImage.length > 0 ?
                            <div className="align-self-center">
                              <img
                                className="img-fluid"
                                src={`${Configuration.apiEndPoint}/images/${selectedPartner.uid}?load=${selectedPartner.storeImage[0]}`}
                                alt={t('LOGO')}
                              />
                            </div> :
                            <div className="align-self-center replayertv_nologo_overlay_rp"/>
                      }
                    </div>
                    <h4 className="section_title_neg_rp text-center" style={{marginBottom: 50}}>{selectedRtvChannel.name} <span className="lightblue_text">Replayer.tv</span></h4>
                  </div>
                  <div className="col-12">
                        <div className="row">
                            {
                            rtvPartnerVideos && rtvPartnerVideos.length > 0 ?
                                rtvPartnerVideos.map(v =>
                                (
                                    <div key={v.videoId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                    <div className="card video_card_bg_rp replayer_tv_card">
                                        <div className= "video_card_image" style={getCoverImage(v.partnerId, v.coverImage, v.defaultImage, v.videoId)}>
                                        <div className="videoWrapper" onClick={() => {sendUsageData(selectedPartner.uid, selectedRtvChannel.id, v.title, v.videoName); window.open(`${Configuration.krpanoPlayerUrl}?videoPartnerId=${v.videoId}&token=${v.token}`, '_blank');}}>
                                            <Fragment>
                                            <div className="preload h-100 justify-content-center align-items-center">
                                                <img className="play_arrow" src={playArrowWhite}/>
                                            </div>
                                            <div className="preload h-100 justify-content-start align-items-start">
                                                <img className="logo-replayer" src={replayerlogoBianco}/>
                                            </div>
                                            <div className="preload h-100 justify-content-end align-items-start">
                                                <img className="logo-partner" src={getPartnerLogo()}/>
                                            </div>
                                            <div className="preload h-100 justify-content-center align-items-end">
                                                <div className="video-description justify-content-center align-items-center">{v.title}</div>
                                            </div>
                                            </Fragment>
                                        </div>
                                        </div>
                                        <div className="card-body d-flex flex-column card_border_bottom_white">
                                        <h5 className="card-title">{v.title}</h5>
                                        <p className="mb-4">{v.description}</p>
                                        <h6 className="card-title regular_title_rp mb-1">{new Date(v.uploadDate).toLocaleDateString()}</h6>
                                        {v.filesize ?
                                        <div className="d-flex">
                                            <span className="bold_title_rp mr-2">{t('FILE_SIZE')}</span>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB
                                        </div>
                                        : null}
                                        <div className="d-flex">
                                            <span className="bold_title_rp">{t('VIDEO_DURATION')}</span>{": " + v.duration_minutes + ":" + String(Math.floor(v.duration_seconds)).padStart(2,'0')}
                                        </div>
                                        </div>
                                        <div className="text-center ml-auto p-3">
                                        <a href="#" className="btn btn_red_rp" onClick={addVideoToPlayerArea(v.videoId)}>{t('COPY_TO_MY_VIDEO')}</a>
                                        </div>
                                    </div>
                                    </div>
                                )
                                )
                            : <Fragment>
                                <div className="col-12 text-center" style={{background: '#00152f50', marginBottom: 400, paddingTop: 15}}>
                                    <p><h5>{t('NO_VIDEO')}</h5></p>
                                </div>
                                </Fragment>
                            }
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerReplayerTvVideoPartner.propTypes = {
  token: PropTypes.string,
  selectedPartner: PropTypes.object,
  rtvVideos: PropTypes.array,
  rtvVideosSoon: PropTypes.array,
  addVideoToPlayerArea: PropTypes.func.isRequired,
  deleteRtvVideo: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  rtvVideosPlaying: PropTypes.array,
  addRtvVideoPlaying: PropTypes.func.isRequired,
  updateVideos: PropTypes.func.isRequired,
  selectedRtvChannel: PropTypes.object,
}

PlayerReplayerTvVideoPartner.defaultProps = {
  token: null,
  selectedPartner: null,
  rtvPartnerVideos: [],
  selectedRtvChannel: null
};

const mapStateToProps = ({login: {token, profile}, player: {selectedPartner, rtvPartnerVideos, selectedRtvChannel}}) => ({
  token,
  selectedPartner,
  rtvPartnerVideos,
  profile,
  selectedRtvChannel
});

const mapDispatchToProps = dispatch => ({
  addVideoToPlayerArea: videoId => () => {
    dispatch(addPartnerVideoToPlayerArea(videoId));
    dispatch(myVideoActiveTab("partner_videos"));
    dispatch(push('player-video'));
  },
  updateVideos: channel => {
    dispatch(getRtvPartnerVideos(channel.partnerUid, channel.id));
  },
  sendUsageData: (partnerId, rtvChannelId, videoTitle, videoName) => {
    dispatch(postUsageData(2, partnerId, rtvChannelId, null, false, 1, videoTitle, videoName));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerReplayerTvVideoPartner));