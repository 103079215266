/* eslint complexity: ["error", 40] */

import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Fade, Form} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import {partnerSelected, getPartnerFields, getVideosStore, getVideosStorePartnerVideo, getRtvVideos, showPlayerHowPurchaseModal, getPartnerServices, getRtvPartnerVideos, setPlayerVideoStoreActiveTab} from '../actions/player';
import PlayerHowPurchase from './player-how-purchase';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';
import {subscriptionStatuses} from '../constants';
import {getSubscriptionStatus} from '../util';

const PlayerVideoStore = ({
  token,
  partners,
  profile,
  goto,
  showVideoStorePartner,
  showPlayerHowPurchaseModal,
  subscriptions
}) => {
  const {t, i18n} = useTranslation();

  const [partnerFilter, setPartnerFilter] = useState("");

  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const renderEmptyPageString = () => {
    let channelNumber = 0;
    partners.forEach(p => {
      if(p.videoStore && (partnerFilter === "" || p.name.toLowerCase().indexOf(partnerFilter.toLowerCase()) > -1)){
        channelNumber++;
      }
    });
    if (channelNumber === 0) {
      return <h6>{t('SEARCH_PARTNER_OR_PLAYER_RESULT_FAILED')}</h6>;
    }
    return null;
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded" style={{background: '#0c213d'}}>
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('VIDEO_STORE')}</h4></div>
                        <div className="ml-auto d-xs-block d-md-none"><label className="mr-2">{t('WALLET')} </label><Button className="btn btn_red_outline_rp" onClick={goto('user-top-up-account')}>&euro; {profile.user.wallet}</Button></div>
                      </div>
                      <Form>
                      <Form.Group>
                        <Form.Control name="partnerFilter" style={{maxWidth: 250}} placeholder={t('SEARCH_PARTNER')} type="text" maxLength="50" onChange={e => setPartnerFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                      </Form.Group>
                      </Form>
                      {renderEmptyPageString()}
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('VIDEO_AVAILABLE_SPORT_CENTER_TO_BUY')} <a style={{color: 'white', textDecoration: 'underline'}} href="#" onClick={showPlayerHowPurchaseModal}>{t('CREDITS_AVAILABLE_REPLAYER_ACCOUNT')}</a>{" " + t('VIDEO_AVAILABLE_SPORT_CENTER_TO_BUY_TWO')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {partners.map(p =>
                    (
                      p.videoStore && (partnerFilter === "" || p.name.toLowerCase().indexOf(partnerFilter.toLowerCase()) > -1)?
                        <div key={p.uid} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group">
                          <a href="#" className={p.isSpecialist ? "card video_store_bg_rp_specialist ease_fx" : "card video_store_bg_rp ease_fx"} onClick={showVideoStorePartner(p, subscriptions)}>
                            <div className="card-horizontal">
                              <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                                {
                                  p.storeImage && p.storeImage.length > 0 ?
                                    <div className="align-self-center">
                                      <img
                                        className="img-fluid"
                                        src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.storeImage[0]}`}
                                        alt={t('LOGO')}
                                      />
                                    </div> :
                                    <div className="align-self-center video_store_nologo_overlay_rp"/>
                                }
                              </div>
                              <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                                <h5 className="card-title mb-0">{p.name}</h5>
                              </div>
                            </div>
                          </a>
                        </div>
                      : null
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <PlayerHowPurchase/>
    </Fragment>
  );
};

PlayerVideoStore.propTypes = {
  token: PropTypes.string,
  partners: PropTypes.array,
  profile: PropTypes.object.isRequired,
  goto: PropTypes.func.isRequired,
  showVideoStorePartner: PropTypes.func.isRequired,
  showPlayerHowPurchaseModal: PropTypes.func.isRequired,
  subscriptions: PropTypes.object
};

PlayerVideoStore.defaultProps = {
  token: null,
  partners: [],
  subscriptions: []
};

const mapStateToProps = ({login: {token, profile}, player: {partners, subscriptions}}) => ({
  token,
  profile,
  partners,
  subscriptions
});

const mapDispatchToProps = dispatch => ({
  showVideoStorePartner: (partner, subscriptions) => () => {
    dispatch(partnerSelected(partner));
    dispatch(getPartnerFields(partner.uid));
    dispatch(getPartnerServices(partner.uid));
    dispatch(getVideosStore(partner.uid));
    dispatch(getVideosStorePartnerVideo(partner.uid));
    dispatch(setPlayerVideoStoreActiveTab('watch_now'));
    const subscriptionStatus = getSubscriptionStatus(partner, subscriptions);
    if (subscriptionStatus.status === subscriptionStatuses.valid || subscriptionStatus.status === subscriptionStatuses.unlimited) {
      dispatch(getRtvVideos(partner.uid));
      if (partner.rtvChannels.filter(c => c.type === 1).length){
        dispatch(getRtvPartnerVideos(partner.uid, partner.rtvChannels.filter(c => c.type === 1)[0].id));
      }
    }
    dispatch(push('/player-video-store-partner'));
  },
  showPlayerHowPurchaseModal: () => {
    dispatch(showPlayerHowPurchaseModal());
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerVideoStore));
