import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Form, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {hidePlayerNewEventSubscriptionModal, setSubscription} from '../actions/player';
import {subscriptionKind} from '../constants';
import {useTranslation} from 'react-i18next';
import {push} from 'connected-react-router';

const PlayerHowPurchase = ({isPlayerNewEventSubscriptionModalVisible, hidePlayerNewEventSubscriptionModal, saveSubscription, selectedPartner, subscriptions, goto, selectedRtvChannel}) => {
  const {t, i18n} = useTranslation();
  if (selectedPartner === null) {
    return null;
  }

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isPlayerNewEventSubscriptionModalVisible} onHide={hidePlayerNewEventSubscriptionModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('WATCH_RTV_SPECIAL_EVENT')}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{kind: subscriptionKind.rtvEvent, partnerId: selectedPartner ? selectedPartner.uid : null, channelId: selectedRtvChannel ? selectedRtvChannel.id : null}}
        onSubmit={saveSubscription}
      >
        {({
          handleSubmit,
          values,
          setFieldValue
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <h6 className="mt-3">
                {t('WELCOME_TO')}
                </h6>
                <label className="form-check-label">
                  <p className="lightblue_text">
                  {selectedRtvChannel && selectedRtvChannel.type === 1 ? t('REPLAYER_TV_SPECIAL_EVENT_PARTNER_VIDEO_DURATION', {days: '30'}) : t('REPLAYER_TV_SPECIAL_EVENT_DURATION', {days: '30'})}
                  </p>
                </label>
                <label className="form-check-label">
                  <p className="lightblue_text">
                  {t('REPLAYER_TV_SPECIAL_EVENT_PAYMENT') }
                  <a  href="/user-top-up-account" onClick={goto('user-top-up-account')}>{t('RECHARGE_ACCOUNT')}</a>
                  </p>
                </label>
                <div className="form-check" style={{marginBottom: 20}}>
                  <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio1" checked/>
                  <label className="form-check-label" htmlFor="GuardaReplayerTVradio1">
                    <span className="bold_title_rp">
                      {t('REPLAYER_TV_SPECIAL_EVENT_PRICE', {channel: selectedRtvChannel ? selectedRtvChannel.name : '', price: selectedRtvChannel ? parseFloat(selectedRtvChannel.rtvPriceEvent/100).toFixed(2) : 0})}
                    </span>
                    <p className="form-check-label">
                      {selectedRtvChannel.type === 0 ? t('REPLAYER_TV_SUBSCRIPTIONS_DESC', {channel: selectedRtvChannel ? selectedRtvChannel.name : ''}) : t('REPLAYER_TV_SUBSCRIPTIONS_DESC_PARTNER_VIDEO', {channel: selectedRtvChannel ? selectedRtvChannel.name : ''})}
                    </p>
                  </label>
                </div>
                {!selectedRtvChannel || selectedRtvChannel.type === 0 ?
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio2" checked={values.kind === subscriptionKind.rtvUnlimited} onClick={() => setFieldValue('kind', subscriptionKind.rtvUnlimited)}/>
                  <label className="form-check-label" htmlFor="GuardaReplayerTVradio2">
                    <span className="bold_title_rp">{t('UNLIMITED_SUBSCRIPTION_RTV')}</span>
                    <p className="lightblue_text">
                      {t('DO_NOT_MISS_VIDEO')}
                      <span className="bold_title_rp"> {t('ALL_SPORTS_CENTERS_CHANNELS')} {t('REPLAYER_TV_PRICE_PROMO', {price: '7,99'})} </span><br/>
                    </p>
                  </label>
                </div>
                : null}
              </Modal.Body>
              <div className="modal-footer">
                <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('SUBSCRIBE')}</button>
                <button type="button" className="btn btn_red_outline_rp" onClick={hidePlayerNewEventSubscriptionModal}>{t('CANCEL')}</button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

PlayerHowPurchase.propTypes = {
  hidePlayerNewEventSubscriptionModal: PropTypes.func.isRequired,
  isPlayerNewEventSubscriptionModalVisible: PropTypes.bool,
  saveSubscription: PropTypes.func.isRequired,
  selectedPartner: PropTypes.object.isRequired,
  subscriptions: PropTypes.object,
  goto: PropTypes.func.isRequired,
  selectedRtvChannel: PropTypes.object
};

PlayerHowPurchase.defaultProps = {
  isPlayerNewEventSubscriptionModalVisible: false,
  subscriptions: null,
  selectedRtvChannel: null
};

const mapStateToProps = ({login: {token}, player: {isPlayerNewEventSubscriptionModalVisible, selectedPartner, subscriptions, selectedRtvChannel}}) => ({
  token,
  isPlayerNewEventSubscriptionModalVisible,
  selectedPartner,
  subscriptions,
  selectedRtvChannel
});

const mapDispatchToProps = dispatch => ({
  hidePlayerNewEventSubscriptionModal: () => {
    dispatch(hidePlayerNewEventSubscriptionModal());
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  saveSubscription: values => {
    dispatch(setSubscription(values.kind, values.partnerId, values.channelId));
    dispatch(hidePlayerNewEventSubscriptionModal());
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerHowPurchase));
