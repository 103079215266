import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import PartnerService from '../components/partner-service';
import {saveOwnedService, deleteServiceImageFile, deletePartnerService, getOwnedFieldSlots, getOwnedFieldBookings} from '../actions/partner';

const mapStateToProps = ({partner: {ownedService}, login: {token, profile}}) => ({
  ownedService,
  token,
  profile,
});

const mapDispatchToProps = dispatch => ({
  save: serviceData => {
    if (serviceData.bookable === null){
      serviceData.bookable = false;
    }
    dispatch(saveOwnedService(serviceData));
  },
  removeFile: (fileName, serviceId) => {
    dispatch(deleteServiceImageFile(fileName, serviceId));
  },
  showCalendar: serviceId => () => {
    dispatch(getOwnedFieldSlots(serviceId, false, 'service'));
    dispatch(push('partner-service-calendar'));
  },
  showBookingCalendar: serviceId => () => {
    dispatch(getOwnedFieldSlots(serviceId, true, 'service', new Date(), 'WEEK'));
    dispatch(getOwnedFieldBookings(new Date(), 'WEEK'));
    dispatch(push('partner-service-booking-calendar'));
  },
  deleteService: serviceId => {
    dispatch(deletePartnerService(serviceId));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerService));