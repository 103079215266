import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import SignUpPlayer from '../components/sign-up-player';
import {signUpPlayer} from '../actions/login';
import {showMessage} from '../actions/messages';

const mapDispatchToProps = dispatch => ({
  signUp: signUpData => {
    const fourteenYearsAgo = moment().subtract(14, 'years');
    const birthday = moment(signUpData.birthDate);

    if (!birthday.isValid()) {
      dispatch(showMessage('SIGNING_UP', 'INVALID_BIRTH_DATE'));
      return;
    }

    if (fourteenYearsAgo.isBefore(birthday)) {
      dispatch(showMessage('SIGNING_UP', 'BIRTH_DATE_LESS_THAN_14'));
      return;
    }

    dispatch(signUpPlayer(signUpData));
  }
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(SignUpPlayer));
