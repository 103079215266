import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {push} from 'connected-react-router';
import PlayerProfile from '../components/player-profile';
import {savePlayerProfile, deletePlayer, refreshPlayerProfile, deletePlayerImageFile, showPlayerNewSubscriptionModal, partnerSelected, rtvChannelSelected} from '../actions/player';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({login: {profile, token}, player: {partners, subscriptions, selectedPlayerGroups, storagePrices}}) => ({
  profile,
  token,
  partners,
  subscriptions: subscriptions ? subscriptions.subscriptions : [],
  selectedPlayerGroups,
  storagePrices,
});

const mapDispatchToProps = dispatch => ({
  save: profile => {
    const fourteenYearsAgo = moment().subtract(14, 'years');
    const birthday = moment(profile.birthDate);

    if (!birthday.isValid()) {
      dispatch(showMessage('SIGNING_UP', 'INVALID_BIRTH_DATE'));
      return;
    }

    if (fourteenYearsAgo.isBefore(birthday)) {
      dispatch(showMessage('SIGNING_UP', 'BIRTH_DATE_LESS_THAN_14'));
      return;
    }

    delete profile.topupAmount;
    dispatch(savePlayerProfile(profile));
  },
  removeFile: fileName => {
    dispatch(deletePlayerImageFile(fileName));
  },
  deleteProfile: () => {
    dispatch(deletePlayer());
  },
  close: () => {
    dispatch(push('/'));
  },
  topUpWalletSuccess: () => {
    dispatch(refreshPlayerProfile());
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_SUCCESSFUL'));
  },
  showNotClosePaypalMessage: () => {
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_ORDER_IN_PROGRESS'));
  },
  showReplayerTV: (partner, rtvChannel = null) => () => {
    dispatch(partnerSelected(partner));
    if(rtvChannel != null) 
      dispatch(rtvChannelSelected(rtvChannel));
    dispatch(showPlayerNewSubscriptionModal());
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerProfile));
