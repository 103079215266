import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Form, Fade, Container, Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import CommonMetaTag from './common-meta-tag';
import {downloadNvrTracking} from '../actions/admin';
import r from '../images/title_r.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import it from 'date-fns/locale/it';
registerLocale('it', it);

const AdminNvrTracking = ({downloadNvrTracking}) => {
  const {t, i18n} = useTranslation();

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{date: null, slotId: null}}
      onSubmit={downloadNvrTracking}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        handleChange
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <img
                      src={r}
                      height="30"
                      className="mx-auto d-block"
                      alt={t('LOGO')}
                    />
                    <Row className="justify-content-center" style={{marginTop: 20}}>
                      <h1>{t('TITLE_REPLAYER')}</h1>
                    </Row>
                    <Row className="justify-content-center">
                      <h4>{t('MOTTO')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <h4 style={{marginTop: 30}}>Tracking NVR</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <p style={{marginTop: 30, textAlign: 'center'}}>Seleziona giorno</p>
                    </Row>
                    <Form onSubmit={handleSubmit}>
                      <Form.Row className="justify-content-center">
                          <Form.Group>
                            <Form.Label>Data</Form.Label><br/>
                            <DatePicker
                              required
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              selected={(values.date && new Date(values.date).addDays(-1)) || null}
                              onChange={val => setFieldValue('date', val ? new Date(val).addDays(1).toISOString() : null)}/>
                          </Form.Group>
                      </Form.Row>
                      <Form.Row className="justify-content-center">
                        <Form.Group>
                        <Form.Label>Slot ID</Form.Label><br/>
                        <Form.Control type="number" name="slotId" onChange={handleChange}/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="justify-content-center">
                        <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 50}}>Scarica file</Button>
                      </Form.Row>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = ({}) => ({
});

const mapDispatchToProps = dispatch => ({
  downloadNvrTracking: values => {
    if (values.slotId === "") {
      values.slotId = null;
    }
    dispatch(downloadNvrTracking(values));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNvrTracking));