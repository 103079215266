import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Button, Fade, Modal, Row} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import CommonMetaTag from './common-meta-tag';
import PartnerBooking from './partner-booking';
import {calendarMessages} from '../constants';
import {useTranslation} from 'react-i18next';

const getCurrentWeek = (date) => {
  return moment(date).week();
};

const PartnerBookingCalendar = ({
  profile,
  ownedField,
  ownedFields,
  isLogged,
  ownedFieldSlots,
  ownedFieldSlot,
  eventSelected,
  isBookingModalVisible,
  hideBookingModal,
  ownedFieldBooking,
  saveBooking,
  deleteBooking,
  ownedFieldBookings,
  showMessage,
  bookingSlots,
  bookingMultiSlots,
  getSlotsByWeek
}) => {
  const {t, i18n} = useTranslation();

  const [currentWeek, setCurrentWeek] = useState(getCurrentWeek(new Date()));

  if (!isLogged) {
    return (
      <Redirect to="/login"/>
    );
  }

  const getBooking = bookingId => {
    return ownedFieldBookings.find(b => b.bookingId === bookingId);
  };

  const getEventDescription = slot => {
    if (slot.state === 'reserved' || slot.state === 'unavailable') {
      return slot.description;
    }

    if (slot.bookingId) {
      const booking = getBooking(slot.bookingId);
      if (booking) {
        return `${slot.presetPlaces === 1 ? booking.otherPlayersPayment[0] ? t('PAID') : t('TO_PAY') : t('PLACES_AVAILABLE', {places: slot.availablePlaces})} ${booking.description || ''}`;
      }

      return null;
    }

    if (slot.presetPlaces > 1) {
      if(slot.description !== null && slot.description !== "")
        return (t('EVENT_DESCRIPTION_PLACES', {description: slot.description, places: slot.availablePlaces}) + " - " + slot.price.toFixed(2) + "€ cad.") + (slot.costsPrice && slot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (slot.costsPrice/100).toFixed(2)}) : "");
      else
        return (t('EVENT_PLACES', {places: slot.availablePlaces}) + " - " + slot.price.toFixed(2) + "€ cad.") + (slot.costsPrice && slot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (slot.costsPrice/100).toFixed(2)}) : "");
    }

    if (slot.price > 0 || slot.costsPrice > 0) {
      return (t('EVENT_DESCRIPTION', {price: slot.price.toFixed(2)})) + (slot.costsPrice && slot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (slot.costsPrice/100).toFixed(2)}) : "");
    }
    else return t('AVAILABLE');
  };

  const localizer = momentLocalizer(moment);
  const events = ownedFieldSlots.map(slot => {
    return {
      id: slot.slotId,
      title: getEventDescription(slot),
      start: new Date(slot.startDatetime),
      end: new Date(slot.endDatetime),
      slot,
      resourceId: slot.fieldId,
      booking: slot.bookingId ? getBooking(slot.bookingId) : null
    };
  });

  const filteredFields = ownedField ? ownedFields.filter(f => f.id === ownedField.id) : ownedFields;

  const resourceMap = filteredFields.map(f => {
    return {
      resourceId: f.id, resourceTitle: f.name
    };
  });

  const field = fieldId => ownedFields.find(f => f.id === fieldId);

  let dialog = null;
  const onRemove = id => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('DELETE_BOOKING_CONFIRM'),
      actions: [
        Dialog.Action(
          t('YES'),
          () => deleteBooking(ownedField ? ownedField.id : null, id),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const onUpload = id => () => {
    window.location.href = "/upload?slot_id=" + id;
  };

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(23, 58, 59);

  const eventStyleGetter = event => {
    let backgroundColor = event.slot.state === 'reserved' ? event.slot.color ? event.slot.color : '#ADB5BD' : event.slot.state === "unavailable" ? '#444444' : event.slot.bookingId ? '#3598DB' : '#31BC8C';
    
    //individuale
    if (event.slot.state === 'available' && event.slot.availablePlaces > 0) {
      backgroundColor = '#31BC8C';
    }
    //multipla singola
    if (event.slot.state === 'available' && event.slot.availablePlaces > 0 && event.slot.presetPlaces > 1 && event.slot.minimumPlayers === null) {
      backgroundColor = '#25C75B';
    }
    //multipla di gruppo
    if (event.slot.state === 'available' && event.slot.availablePlaces > 0 && event.slot.presetPlaces > 1 && event.slot.minimumPlayers !== null) {
      backgroundColor = '#308771';
    }

    const color = '#fff';
    const style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 1,
      color,
      border: '1px',
      borderStyle: 'solid',
      borderColor: '#00152F',
      display: 'block'
    };
    return {
      style
    };
  };

  const handleSlotMultiselection = event => {
    // Seleziono slot nel range
    const selectedSlots = ownedFieldSlots.filter(s => s.fieldId === event.resourceId && new Date(s.startDatetime) < event.end && new Date(s.endDatetime) > event.start);
    // La selezione non contiene elementi
    if (selectedSlots.length === 0) {
      showMessage('BOOKING', 'BOOKING_EMPTY_SELECTION');
      return;
    }

    // Controllo che non ci siano eventi non disponibili
    const unavailableSlots = selectedSlots.filter(s => s.state === 'unavailable');
    if (unavailableSlots.length !== 0) {
      showMessage('BOOKING', 'BOOKING_EVENT_UNAVAILABLE');
      return;
    }

    // Controllo che non ci siano eventi riservati
    const reservedSlots = selectedSlots.filter(s => s.state === 'reserved');
    if (reservedSlots.length !== 0) {
      showMessage('BOOKING', 'BOOKING_EVENT_RESERVED');
      return;
    }

    // Controllo che non ci siamo eventi con la prenotazione multipla
    if (selectedSlots.length > 1) {
      for (let i = 0; i < selectedSlots.length; i++) {
        if (selectedSlots[i].presetPlaces > 1) {
          showMessage('BOOKINGS', 'BOOKING_EVENT_NOT_ALLOWED');
          return;
        }
      }
    }

    // Controllo che non ci siano eventi prenotati
    const bookingSlots = selectedSlots.filter(s => s.bookingId);
    if (bookingSlots.length !== 0) {
      showMessage('BOOKING', 'EVENT_ALREADY_BOOKED');
      return;
    }

    // Ordino per startDate
    selectedSlots.sort((a, b) => new Date(a.startDatetime) - new Date(b.startDatetime));
    // Controllo che siano adiacenti (startDate === endDate precedente)
    let endDateTarget = null;
    for (let i = 0; i < selectedSlots.length; i++) {
      if (endDateTarget !== null && endDateTarget !== selectedSlots[i].startDatetime) {
        showMessage('BOOKING', 'BOOKING_SELECTION_NOT_ADJACENT');
        return;
      }

      endDateTarget = selectedSlots[i].endDatetime;
    }

    // Ultimo controllo!! La data di inizio del primo evento non deve essere nel passato
    if (new Date(selectedSlots[0].startDatetime) < new Date()) {
      showMessage('BOOKING', 'BOOKING_EVENT_HAS_PASSED');
      return;
    }

    // La selezione è valida, posso prenotare!!
    bookingMultiSlots(selectedSlots);
  };
  
  const onNavigate = (date) => {
    const newWeek = getCurrentWeek(date);
    if (newWeek !== currentWeek) {
      setCurrentWeek(newWeek);
      getSlotsByWeek(ownedField ? ownedField.id : null)(date);
    }
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 100}}>
        <Row className="justify-content-center">
          <h4 className="text-center" style={{width: '100%'}}>{ownedField ? ownedField.name : profile.name} : {t('BOOKING_ADMINISTRATION')}</h4>
          <h6 className="text-left" style={{width: '80%'}}>{ownedField ? ownedField.bookingPolicy : null}</h6>
          <Calendar
            selectable
            localizer={localizer}
            culture={i18n.language ===  'en' ? 'en-GB' : i18n.language}
            defaultDate={new Date()}
            defaultView={Views.DAY}
            views={['day', 'week']}
            min={minTime}
            max={maxTime}
            events={events}
            resources={resourceMap}
            step={15}
            timeslots={4}
            style={{minHeight: 600}}
            eventPropGetter={eventStyleGetter}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            messages={i18n.language === 'it' ? calendarMessages : null}
            onSelectEvent={eventSelected}
            onSelectSlot={handleSlotMultiselection}
            onNavigate={onNavigate}
          />
        </Row>
      </Fade>
      {
        ownedFieldSlot === null && bookingSlots === null ?
          null :
          <Modal show={isBookingModalVisible} onHide={hideBookingModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('BOOKING')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PartnerBooking booking={ownedFieldBooking} save={saveBooking} partner={profile} slot={ownedFieldSlot} slots={bookingSlots} field={ownedFieldSlot ? field(ownedFieldSlot.fieldId) : field(bookingSlots[0].fieldId)} ownedFieldSlots={ownedFieldSlots}/>
            </Modal.Body>
            <Modal.Footer>
              {
                ownedFieldBooking === null || !ownedFieldBooking.users || (ownedFieldSlot && ownedFieldSlot.state === 'unavailable') || (bookingSlots && bookingSlots[0].state === 'unavailable') ?
                  null :
                  <Button variant="warning" onClick={onUpload(ownedFieldSlot ? ownedFieldSlot.slotId : bookingSlots[0].slotId)}>
                    {t('VIDEO_UPLOAD')}
                  </Button>
              }
              <Button variant="primary" onClick={hideBookingModal}>
                {t('CLOSE_BUTTON')}
              </Button>
              {
                ownedFieldBooking === null || !ownedFieldBooking.bookingId ?
                  null :
                  <Button type="button" variant="secondary" style={{marginLeft: 10}} onClick={onRemove(ownedFieldBooking.bookingId)}>{ownedFieldSlot && ownedFieldSlot.presetPlaces > 1 ? t('DELETE_ALL') : t('DELETE')}</Button>
              }
            </Modal.Footer>
          </Modal>
      }
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PartnerBookingCalendar.propTypes = {
  isLogged: PropTypes.bool,
  profile: PropTypes.object,
  ownedField: PropTypes.object,
  ownedFieldSlots: PropTypes.array,
  ownedFieldSlot: PropTypes.object,
  ownedFieldBooking: PropTypes.object,
  isBookingModalVisible: PropTypes.bool,
  eventSelected: PropTypes.func.isRequired,
  hideBookingModal: PropTypes.func.isRequired,
  saveBooking: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  ownedFieldBookings: PropTypes.array,
  ownedFields: PropTypes.array,
  showMessage: PropTypes.func.isRequired,
  bookingMultiSlots: PropTypes.func.isRequired,
  bookingSlots: PropTypes.array,
  getSlotsByWeek: PropTypes.func.isRequired
};

PartnerBookingCalendar.defaultProps = {
  isLogged: false,
  ownedField: null,
  profile: null,
  ownedFieldSlots: [],
  ownedFieldSlot: null,
  ownedFieldBooking: null,
  isBookingModalVisible: false,
  ownedFieldBookings: [],
  ownedFields: [],
  bookingSlots: null
};

export default PartnerBookingCalendar;
