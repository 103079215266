/* eslint camelcase: ["error", {properties: "never"}] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Fade, Container, Row, Col, Form, Button} from 'react-bootstrap';
import {Redirect, withRouter} from 'react-router-dom';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import {PayPalButton} from 'react-paypal-button-v2';
import Configuration from '../config';
import {refreshPlayerProfile} from '../actions/player';
import {refreshPartnerProfile} from '../actions/partner';
import {showMessage} from '../actions/messages';
import {ROLES} from '../constants';
import {useTranslation} from 'react-i18next';
import {push} from 'connected-react-router';

const UserTopUpAccount = ({
  profile, token, topUpWalletSuccessPlayer, topUpWalletSuccessPartner, showNotClosePaypalMessage, goto
}) => {
  const {t, i18n} = useTranslation();

  if (profile === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <div>
    {profile.user.role === ROLES.partner || (!profile.isBusiness && profile.country && profile.address && profile.fiscalCode) || (profile.isBusiness && profile.country && profile.businessName && profile.registeredOffice && profile.IVA && profile.sdiPec) ?
    <Formik
      enableReinitialize
      initialValues={profile}
    >
      {({
        handleChange,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 100}}>
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={6}>
                    <h4>{t('MY_CREDIT')}</h4>
                    <Form>
                      <Form.Group>
                        <Form.Label>{t('CREDIT_VALUE', {value: values.user.wallet})}</Form.Label>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('ACCOUNT_TOPUP')}</Form.Label>
                        <Form.Control required as="select" name="topupAmount" value={values.topupAmount || '5,00'} onChange={handleChange}>
                          <option value="5.00">5,00 &euro;</option>
                          <option value="10.00">10,00 &euro;</option>
                          <option value="20.00">20,00 &euro;</option>
                          <option value="50.00">50,00 &euro;</option>
                        </Form.Control>
                      </Form.Group>
                      <PayPalButton
                        options={{
                          clientId: Configuration.paypalClientId,
                          currency: 'EUR'
                        }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [{
                              amount: {
                                value: values.topupAmount || '5.00'
                              }
                            }],
                            application_context: {
                              locale: 'it-IT',
                              shipping_preference: 'NO_SHIPPING'
                            }
                          }).then(orderID => {
                            // Send order created to the server
                            return fetch(`${Configuration.apiEndPoint}/paypal/order_created`, {
                              method: 'POST',
                              headers: {
                                'content-type': 'application/json',
                                Accept: 'application/json',
                                Authorization: `Bearer ${token}`
                              },
                              mode: 'cors',
                              body: JSON.stringify({
                                orderID
                              })
                            }).then(res => {
                              // TODO: Mostrare errore in caso di fallimento di questa richiesta
                              return res.json();
                            }).then(data => {
                              return orderID;
                            });
                          });
                        }}
                        onApprove={(data, actions) => {
                          showNotClosePaypalMessage();
                          // Capture the funds from the transaction
                          return actions.order.capture().then(details => {
                            // Send approved order to the server
                            return fetch(`${Configuration.apiEndPoint}/paypal/order_approved`, {
                              method: 'POST',
                              headers: {
                                'content-type': 'application/json',
                                Accept: 'application/json',
                                Authorization: `Bearer ${token}`
                              },
                              mode: 'cors',
                              body: JSON.stringify({
                                orderID: data.orderID
                              })
                            }).then(res => {
                              return res.json();
                            }).then(() => {
                              profile.user.role === ROLES.player ? topUpWalletSuccessPlayer() : topUpWalletSuccessPartner()
                            });
                          });
                        }}
                        onShippingChange={(data,actions) => {
                          return actions.resolve();
                        }}
                      />
                    </Form>
                    <div style={{border: '1px solid #DD004A', padding: 20, marginTop: 30}}>
                      <h4>{t('WARNING')}</h4>
                      <p>{t('ACCOUNT_TOPUP_NOTES')}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
    :
    <Fragment>
      <CommonMetaTag/>
        <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 100}}>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} sm={8} md={6}>
                <h4>{t('MY_CREDIT')}</h4>
                <Form>
                  <Form.Group>
                    <Form.Label>{t('CREDIT_VALUE', {value: profile.user.wallet})}</Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <h4>{t('ACCOUNT_TOPUP')}</h4>
                    <Form.Label>{t('TAX_DATA_NOT_GIVEN')}</Form.Label>
                  </Form.Group>
                  <Button type="button" variant="outline-primary" style={{marginBottom: 50}} onClick={goto('player-profile')}>{t('PROFILE')}</Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Fade>
    </Fragment>
    }
  </div>
  );
};

UserTopUpAccount.propTypes = {
  profile: PropTypes.object,
  token: PropTypes.string,
  topUpWalletSuccessPlayer: PropTypes.func.isRequired,
  topUpWalletSuccessPartner: PropTypes.func.isRequired,
  showNotClosePaypalMessage: PropTypes.func.isRequired
};

UserTopUpAccount.defaultProps = {
  profile: null,
  token: null
};

const mapStateToProps = ({login: {profile, token}}) => ({
  profile,
  token
});

const mapDispatchToProps = dispatch => ({
  topUpWalletSuccessPlayer: () => {
    dispatch(refreshPlayerProfile());
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_SUCCESSFUL'));
  },
  topUpWalletSuccessPartner: () => {
    dispatch(refreshPartnerProfile());
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_SUCCESSFUL'));
  },
  showNotClosePaypalMessage: () => {
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_ORDER_IN_PROGRESS'));
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTopUpAccount));
