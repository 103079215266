import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {push} from 'connected-react-router';
import {hidePlayerInsufficientFundsModal} from '../actions/player';
import {useTranslation} from 'react-i18next';

const PlayerInsufficientFunds = ({isPlayerInsufficientFundsModalVisible, hidePlayerInsufficientFundsModal, topUpAccount}) => {
  const {t, i18n} = useTranslation();
  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isPlayerInsufficientFundsModalVisible} onHide={hidePlayerInsufficientFundsModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('WATCH_RTV')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="lightblue_text">
        {t('CREDIT_NOT_ENOUGHT')} 
        </p>
        <p className="lightblue_text">
        {t('RECHARGE_ACCOUNT_TO_RENEW')}
        </p>
      </Modal.Body>
      <div className="modal-footer">
        <button type="submit" className="btn btn-secondary" data-dismiss="modal" onClick={topUpAccount}>{t('RECHARGE_ACCOUNT')}</button>
        <button type="button" className="btn btn_red_outline_rp" onClick={hidePlayerInsufficientFundsModal}>{t('CANCEL')}</button>
      </div>
    </Modal>
  );
};

PlayerInsufficientFunds.propTypes = {
  hidePlayerInsufficientFundsModal: PropTypes.func.isRequired,
  isPlayerInsufficientFundsModalVisible: PropTypes.bool,
  topUpAccount: PropTypes.func.isRequired
};

PlayerInsufficientFunds.defaultProps = {
  isPlayerInsufficientFundsModalVisible: false
};

const mapStateToProps = ({login: {token}, player: {isPlayerInsufficientFundsModalVisible}}) => ({
  token,
  isPlayerInsufficientFundsModalVisible
});

const mapDispatchToProps = dispatch => ({
  hidePlayerInsufficientFundsModal: () => {
    dispatch(hidePlayerInsufficientFundsModal());
  },
  topUpAccount: () => {
    dispatch(hidePlayerInsufficientFundsModal());
    dispatch(push('user-top-up-account'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerInsufficientFunds));
