/* eslint complexity: ["error", 40] */

import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Fade, Form, Modal} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import {useTranslation} from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';
import liveStream  from '../images/live_stream.png';
import { getLiveVideos, getComingSoonVideos, setSubscription, showBuySubscriptionOrVideoModal, hideBuySubscriptionOrVideoModal, purchaseVideo } from '../actions/player';
import {RiTimeLine} from 'react-icons/ri';
import Configuration from '../config';
import playArrowWhite from '../images/play_arrow_white_48dp.png';
import specialEvent from '../images/special_event.svg';
import replayerlogoBianco from '../images/replayer_logo_bianco_40x32px.png';
import {Formik} from 'formik';
import {subscriptionKind, SPORTS, rtvType} from '../constants';


const PlayerLiveVideos = ({
  token,
  profile,
  getLiveVideos,
  liveVideos,
  gotoUpcomingEvents,
  showBuySubscriptionOrVideoModal,
  hideBuySubscriptionOrVideoModal,
  isBuySubscriptionOrVideoModalVisible,
  saveSubscription
}) => {
  const {t, i18n} = useTranslation();

  const [videoFilter, setVideoFilter] = useState("");

  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Codice da eseguire ogni due minuti
      getLiveVideos();
    }, 120000); // 120000 millisecondi = 2 minuti

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(intervalId);
  }, []); // L'array vuoto [] assicura che l'effetto venga eseguito solo una volta quando il componente viene montato

  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }


  let dialog = null;

  const showLiveVideosInfo = () => {
    dialog.show({
      title: t('WATCH_LIVE'),
      body: t('WATCH_LIVE_DIALOG'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const videoWaiting = () => {
    dialog.show({
      title: t('WATCH_LIVE'),
      body: t('VIDEO_WAITING'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const styleBackgroundImageVideoWrapper = (partnerId, fieldId, fieldImage) => {
    if (partnerId && fieldImage) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage})`, backgroundSize: 'cover'};
    }

    return null;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
        <div className="page-header header-filter clear-filter replayertv_sportcenter_hero_rp" data-parallax="true">
            <div className="replayertv_sportcenter_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                        <img
                          style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                          src={liveStream}
                          alt={t('live_stream_logo')}
                        />
                      <div>
                        <h4 className="section_title_neg_rp">{t('WATCH_LIVE_VIDEOS_NOW')}</h4>
                        <div className="rtv_info_btn" onClick={showLiveVideosInfo}/>
                      </div>
                      <p>{t('WATCH_LIVE_VIDEOS_NOW_DESC')}</p>
                      <Form>
                      <Form.Group>
                        <Form.Control name="videoFilter" style={{maxWidth: 250}} placeholder={t('LIVE_VIDEO_SEARCH')} type="text" maxLength="50" onChange={e => setVideoFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                      </Form.Group>
                      </Form>
                      <hr className="hr_blue"/>
                      <div className="d-flex justify-content-end">
                        <Button className="btn btn_transparent_rp" onClick={gotoUpcomingEvents}> <RiTimeLine size={24}/> {t('DISCOVER_UPCOMING_EVENTS')}</Button>
                      </div>
                    </div>
                    <div className="row">
                        {
                        liveVideos && liveVideos.length > 0 ?
                            liveVideos.map(v =>
                            videoFilter === "" || (v.description !== null && v.description.toLowerCase().indexOf(videoFilter.toLowerCase()) > -1) || v.fieldName.toLowerCase().indexOf(videoFilter.toLowerCase()) > -1 || v.rtvChannels.some(channel => channel.name.toLowerCase().indexOf(videoFilter.toLowerCase()) > -1) ?
                            (
                                <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group" style={{marginTop: 20}}>
                                <div className="card video_card_bg_rp replayer_tv_card">
                                    <div className= "video_card_image" style={styleBackgroundImageVideoWrapper(v.partnerId, v.fieldId, v.fieldImage)}>
                                    <div className="videoWrapper" onClick={() => {
                                      if (v.token) {
                                        window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&token=${v.token}`, '_blank');
                                      } else if (!v.authorized){
                                        setSelectedVideo(v);
                                        showBuySubscriptionOrVideoModal();
                                      }
                                      }}>
                                        <Fragment>
                                        {v.validVideos ?
                                        <div className="preload h-100 justify-content-center align-items-center">
                                            <img className="play_arrow" src={playArrowWhite}/>
                                        </div>
                                        : null}
                                        <div className="preload h-100 justify-content-start align-items-start">
                                          <div className="sportcenter_rp_title_icon">
                                            <img src={SPORTS[v.sport] ? SPORTS[v.sport].icon : null} alt={t('ICON_SPORT')} style={{ marginTop: 5, marginLeft: 5 }}/>
                                          </div>
                                        </div>
                                        <div className="preload h-100 justify-content-end align-items-start">
                                          { v.rtvChannels && v.rtvChannels.length === 1 && v.rtvChannels[0].logoNames && v.rtvChannels[0].logoNames.length > 0?
                                          <img className="logo-partner" src={`${Configuration.apiEndPoint}/partners/rtv_channels/${v.rtvChannels[0].id}/images?load=${v.rtvChannels[0].logoNames[0]}`}/>
                                          : <img className="logo-replayer" src={v.event ? specialEvent : replayerlogoBianco} style={{marginRight: 5}}/>
                                          }
                                        </div>
                                        <div className="preload h-100 justify-content-center align-items-end">
                                            <div className="video-description justify-content-center align-items-center">{v.description ? v.description.substring(0,60) : null}</div>
                                        </div>
                                        </Fragment>
                                    </div>
                                    </div>
                                    <div className="card-body d-flex flex-column card_border_bottom_white">
                                    <p className="mb-4">
                                      <strong>ReplayerTV:</strong> {v.rtvChannels && v.rtvChannels.length > 0 ? v.rtvChannels.map(channel => channel.name).join(' - ') : null}
                                    </p>
                                    <p className="mb-4">
                                        <strong>{v.description}</strong>
                                        {
                                        v.rtvOrAcademy === rtvType.academy?
                                            <div className="replayertv_card_academy_icon"/>
                                        : null
                                        }        
                                    </p>
                                    <h6 className="card-title regular_title_rp mb-1">{new Date(v.startDatetime).toLocaleDateString()} {new Date(v.startDatetime).toTimeString().slice(0, 5)}</h6>
                                    <h5 className="card-title">{v.fieldName}</h5>
                                    </div>
                                    <div className="text-center ml-auto p-3">
                                        {v.validVideos ?
                                        <a href="#" className="btn btn_blue_rp" onClick={() => window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&token=${v.token}`, '_blank')}>LIVE NOW!</a>
                                        : 
                                        <a href="#" className="btn btn_black_rp" onClick={videoWaiting}>{t('WAITING_DATA')}</a>}
                                    </div>
                                </div>
                                </div>
                            )
                            : null
                            )
                        : 
                        <div className="col-md-12 col-lg-4 mb-sm-4 card-group" style={{marginTop: 20}}>
                            <div className="empty_slot card video_card_bg_rp">
                            <div className="card_image_rp">
                                <div className="videoWrapper"/>
                            </div>
                            <div className="card-body d-flex flex-column">
                                <h5 className="card-title" style={{marginTop: 20, marginBottom: 20}}>{t('NO_LIVE_EVENTS_NOW')}</h5>
                                <p style={{marginBottom: 20}}>{t('NO_LIVE_EVENTS_NOW_DESC')}</p>
                                <div className="text-center mt-auto">
                                <a href="#" className="btn btn_red_rp mb-2" onClick={gotoUpcomingEvents}>{t('IS_IT_UPCOMING')}</a>
                                </div>
                            </div>
                            </div>
                        </div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isBuySubscriptionOrVideoModalVisible} onHide={hideBuySubscriptionOrVideoModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">LIVE NOW!</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{kind: null, selectedVideo : selectedVideo, partnerId: selectedVideo ? selectedVideo.partnerId : null, channelId: null, slotId: selectedVideo ? selectedVideo.slotId : null}}
        onSubmit={saveSubscription}
      >
        {({
          handleSubmit,
          values,
          setFieldValue
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <label className="form-check-label">
                  <p className="lightblue_text">
                  {t('CHOOSE_YOUR_SOLUTION')}
                  </p>
                </label>
                {values.selectedVideo.videoPurchasable && values.selectedVideo.videoSellPermission ?
                <div className="form-check">
                      <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio1" checked={values.kind === null} onClick={() => setFieldValue('kind', null)}/>
                      <label className="form-check-label" htmlFor="GuardaReplayerTVradio1">
                        <span className="bold_title_rp">{t('BUY_VIDEO_FROM_STORE')}</span>
                        <p className="lightblue_text">
                          {t('WATCH_THE_EVENT')}
                          <span className="bold_title_rp">{values.selectedVideo.videoPrice.toFixed(2) + " €"}</span>
                        </p>
                      </label>
                </div>
                : null}
                {
                  selectedVideo.rtvChannels.map(channel => {
                    return (
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio1" checked={values.kind === subscriptionKind.rtvDedicated && values.channelId === channel.id} onClick={() => {setFieldValue('kind', subscriptionKind.rtvDedicated); setFieldValue('channelId', channel.id);}}/>
                      <label className="form-check-label" htmlFor="GuardaReplayerTVradio1">
                        <span className="bold_title_rp">{channel.type === 1 ? t('REPLAYER_TV_SUBSCRIPTIONS_PARTNER_VIDEO', {partner: channel.name}) :t('REPLAYER_TV_SUBSCRIPTIONS', {partner: channel.name})}</span>
                        <p className="lightblue_text">
                          {channel.type === 1 ? t('REPLAYER_TV_SUBSCRIPTIONS_PARTNER_VIDEO_DESC', {channel: channel.name}) : t('REPLAYER_TV_SUBSCRIPTIONS_DESC', {channel: channel.name})}
                          <span className="bold_title_rp">{t('REPLAYER_TV_PRICE', {price: parseFloat(channel.rtvPrice/100).toFixed(2)})}</span>
                        </p>
                      </label>
                    </div>);
                })}
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio2" checked={values.kind === subscriptionKind.rtvUnlimited} onClick={() => {setFieldValue('kind', subscriptionKind.rtvUnlimited); setFieldValue('channelId', null);}}/>
                  <label className="form-check-label" htmlFor="GuardaReplayerTVradio2">
                    <span className="bold_title_rp">{t('UNLIMITED_SUBSCRIPTION_RTV')}</span>
                    <p className="lightblue_text">
                      {t('DO_NOT_MISS_VIDEO')}
                      <span className="bold_title_rp"> {t('ALL_SPORTS_CENTERS_CHANNELS')} {t('REPLAYER_TV_PRICE_PROMO', {price: '7,99'})} </span><br/>
                    </p>
                  </label>
                </div>
              </Modal.Body>
              <div className="modal-footer">
                <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('EXECUTE_PAYMENT')}</button>
                <button type="button" className="btn btn_red_outline_rp" onClick={hideBuySubscriptionOrVideoModal}>{t('CANCEL')}</button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerLiveVideos.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object.isRequired,
  liveVideos: PropTypes.array,
  getLiveVideos: PropTypes.func.isRequired,
  gotoUpcomingEvents: PropTypes.func.isRequired
};

PlayerLiveVideos.defaultProps = {
  token: null,
  liveVideos: [],
  isBuySubscriptionOrVideoModalVisible: false
};

const mapStateToProps = ({login: {token, profile}, player: {liveVideos, isBuySubscriptionOrVideoModalVisible}}) => ({
  token,
  profile,
  liveVideos,
  isBuySubscriptionOrVideoModalVisible
});

const mapDispatchToProps = dispatch => ({
    getLiveVideos: () => {
        dispatch(getLiveVideos());
    },
    gotoUpcomingEvents: () => {
      dispatch(getComingSoonVideos());
      dispatch(push('/player-coming-soon-videos'));
    },
    saveSubscription: values => {
      if(values.kind === null){
        dispatch(purchaseVideo([values.slotId], true));
        dispatch(hideBuySubscriptionOrVideoModal());
      }
      else {
        dispatch(setSubscription(values.kind, values.partnerId, values.channelId));
        dispatch(hideBuySubscriptionOrVideoModal());
      }
    },
    hideBuySubscriptionOrVideoModal: () => {
      dispatch(hideBuySubscriptionOrVideoModal());
    },
    showBuySubscriptionOrVideoModal: () => {
      dispatch(showBuySubscriptionOrVideoModal());
    }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerLiveVideos));
