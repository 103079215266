import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerProfile from '../components/partner-profile';
import {savePartnerProfile, deletePartnerImageFile, groupSelected, showPartnerGroupModal, getGroupPlayers, partnerGroupPlayersReceived, deleteRtvChannelImageFile, refreshPartnerProfile} from '../actions/partner';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({login: {profile, token}, partner: {ownedRtvChannels}}) => ({
  profile,
  token,
  ownedRtvChannels
});

const mapDispatchToProps = dispatch => ({
  save: profile => {
    dispatch(savePartnerProfile(profile));
  },
  removeFile: fileName => {
    dispatch(deletePartnerImageFile(fileName));
  },
  groupSelected: group => () => {
    dispatch(getGroupPlayers(group.groupId));
    dispatch(groupSelected(group));
    dispatch(showPartnerGroupModal());
  },
  newGroup: () => {
    dispatch(partnerGroupPlayersReceived([]));
    dispatch(groupSelected({groupId: null, description: null, groupType: null}));
    dispatch(showPartnerGroupModal());
  },
  deleteRtvChannelImageFile: (file, rtvChannelId) => {
    dispatch(deleteRtvChannelImageFile(file, rtvChannelId));
  },
  topUpWalletSuccess: () => {
    dispatch(refreshPartnerProfile());
    dispatch(showMessage('ACCOUNT_TOPUP', 'ACCOUNT_TOPUP_SUCCESSFUL'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerProfile));
