import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {push} from 'connected-react-router';
import {
  hidePlayerChooseRtvChannelModal,
  rtvChannelSelected,
  showPlayerNewSubscriptionModal,
  getRtvVideos,
  getRtvPartnerVideos
} from '../actions/player';
import {useTranslation} from 'react-i18next';
import Configuration from '../config';
import {subscriptionKind, subscriptionStatuses} from '../constants';
import { showMessage } from '../actions/messages';

const PlayerChooseRtvChannel = ({isPlayerChooseRtvChannelModalVisible, hidePlayerChooseRtvChannelModal, selectedPartner, selectRtvChannel, selectRtvChannelPartnerVideo, subscriptions}) => {
  const {t, i18n} = useTranslation();
  if (selectedPartner === null) {
    return null;
  }

  if (!isPlayerChooseRtvChannelModalVisible) {
    return null;
  }

  const getSubscriptionStatus = (partner, channel) => {
    if (subscriptions) {
      const sub = subscriptions.subscriptions.find(s => s.partner === partner.uid && s.rtv_channel_id === channel.id);
      if (sub && new Date(sub.expiry) >= new Date()) {
        return {status: subscriptionStatuses.valid};
      }

      const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
      if (unlimitedSubscription) {
        return {status: subscriptionStatuses.unlimited};
      }

      if (sub && new Date(sub.expiry) < new Date()) {
        return {status: subscriptionStatuses.expired, subscriptionId: sub.id};
      }

      return {status: subscriptionStatuses.noSubscription};
    }

    return {status: subscriptionStatuses.noSubscription};
  };

  if (selectedPartner.rtvChannels.length === 1) {
    if (selectedPartner.rtvChannels[0].type === 0){
      selectRtvChannel(selectedPartner.rtvChannels[0], selectedPartner, getSubscriptionStatus(selectedPartner, selectedPartner.rtvChannels[0]))();
    }
    else{
      selectRtvChannelPartnerVideo(selectedPartner.rtvChannels[0], selectedPartner, getSubscriptionStatus(selectedPartner, selectedPartner.rtvChannels[0]))();
    }
  }

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isPlayerChooseRtvChannelModalVisible} onHide={hidePlayerChooseRtvChannelModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('CHOOSE_RTV_CHANNEL')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 mb-2 mb-md-4 card-group">
            <h6>{t('CHOOSE_RTV_CHANNEL_DESC')}</h6>
          </div>
          {selectedPartner.rtvChannels.map(channel =>
            (              
              <div key={channel.id} className="col-md-12 mb-2 mb-md-4 card-group">
                <a href="#" className={channel.type === 0 ? "card video_store_bg_rp ease_fx" : "card video_store_bg_rp_specialist ease_fx"} onClick={channel.type === 0 ? selectRtvChannel(channel, selectedPartner, getSubscriptionStatus(selectedPartner, channel)) : selectRtvChannelPartnerVideo(channel, selectedPartner, getSubscriptionStatus(selectedPartner, channel))}>
                  <div className="card-horizontal">
                    <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                      {
                        channel.logoNames && channel.logoNames.length > 0 ?
                          <div className="align-self-center">
                            <img
                              className="img-fluid"
                              src={`${Configuration.apiEndPoint}/partners/rtv_channels/${channel.id}/images?load=${channel.logoNames[0]}`}
                              alt={t('LOGO')}
                            />
                          </div> :
                          selectedPartner && selectedPartner.storeImage && selectedPartner.storeImage.length > 0 ?
                            <div className="align-self-center">
                              <img
                                className="img-fluid"
                                src={`${Configuration.apiEndPoint}/images/${selectedPartner.uid}?load=${selectedPartner.storeImage[0]}`}
                                alt={t('LOGO')}
                              />
                            </div> :
                          <div className="align-self-center video_store_nologo_overlay_rp"/>
                      }
                    </div>
                    <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                      <h5 className="card-title mb-0">{channel.name}</h5>
                    </div>
                  </div>
                </a>
              </div>
            )
          )}
        </div>
      </Modal.Body>
      <button type="button" className="btn btn_red_outline_rp" onClick={hidePlayerChooseRtvChannelModal}>{t('CANCEL')}</button>
    </Modal>
  );
};

PlayerChooseRtvChannel.propTypes = {
  hidePlayerChooseRtvChannelModal: PropTypes.func.isRequired,
  isPlayerChooseRtvChannelModalVisible: PropTypes.bool,  
  selectedPartner: PropTypes.object.isRequired,
  selectRtvChannel: PropTypes.func.isRequired,
  subscriptions: PropTypes.object,
};

PlayerChooseRtvChannel.defaultProps = {
  isPlayerChooseRtvChannelModalVisible: false,
  subscriptions: null
};

const mapStateToProps = ({login: {token}, player: {isPlayerChooseRtvChannelModalVisible, selectedPartner, subscriptions}}) => ({
  token,
  isPlayerChooseRtvChannelModalVisible,
  selectedPartner,
  subscriptions
});

const mapDispatchToProps = dispatch => ({
  hidePlayerChooseRtvChannelModal: () => {
    dispatch(hidePlayerChooseRtvChannelModal());
  },
  selectRtvChannel: (rtvChannel, partner, subscriptionStatus) => () => {    
    dispatch(rtvChannelSelected(rtvChannel));
    dispatch(hidePlayerChooseRtvChannelModal());
    
    if (subscriptionStatus.status === subscriptionStatuses.valid || subscriptionStatus.status === subscriptionStatuses.unlimited || (rtvChannel.rtvPrice === 0 && rtvChannel.rtvPriceEvent === 0 && rtvChannel.rtvPriceMembership === 0)) {
      dispatch(getRtvVideos(partner.uid, rtvChannel.id));
      dispatch(push('/player-replayer-tv'));
      return;
    }

    if (rtvChannel.blocked) {
      dispatch(showMessage('RTV_CHANNELS', 'RTV_CHANNEL_IS_BLOCKED'));
      return;
    }

    dispatch(showPlayerNewSubscriptionModal());    
  },
  selectRtvChannelPartnerVideo: (rtvChannel, partner, subscriptionStatus) => () => {    
    dispatch(rtvChannelSelected(rtvChannel));
    dispatch(hidePlayerChooseRtvChannelModal());
    
    if (subscriptionStatus.status === subscriptionStatuses.valid || (rtvChannel.rtvPrice === 0 && rtvChannel.rtvPriceEvent === 0 && rtvChannel.rtvPriceMembership === 0)) {
      dispatch(getRtvPartnerVideos(partner.uid, rtvChannel.id));
      dispatch(push('/player-replayer-tv-video-partner'));
      return;
    }

    if (rtvChannel.blocked) {
      dispatch(showMessage('RTV_CHANNELS', 'RTV_CHANNEL_IS_BLOCKED'));
      return;
    }

    dispatch(showPlayerNewSubscriptionModal());    
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerChooseRtvChannel));
