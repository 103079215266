import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PlayerVideo from '../components/player-video';
import {addTaggedVideoPlaying, getTaggedUserVideos, getUserVideoTags, selectVideoForTagging, myVideoActiveTab, showUserTagModal, showVideoHighlightModal, permanentVideoSelected, hideVideoHighlightModal, deletePlayerPermanentVideo, deletePlayerPermanentPartnerVideo, deletePlaylist, modifyPlayerPermanentVideo, modifyPlaylist, showVideoSharingModal, addPermanentVideoPlaying, changeStorageType, showStorageModal, hideStorageModal, postUsageData} from '../actions/player';
import {push} from 'connected-react-router';

const mapStateToProps = ({login: {token, profile}, player: {partners, permanentVideos, permanentPartnerVideos, storagePrices, selectedPermanentVideo, isVideoHighlightModalVisible, permanentVideosPlaying, userTags, myVideoActiveTab, selectedVideoForTagging, taggedUserVideos, taggedVideosPlaying, isStorageModalVisible, playlists}}) => ({
  token,
  profile,
  partners,
  permanentVideos,
  permanentPartnerVideos,
  storagePrices,
  selectedPermanentVideo,
  isVideoHighlightModalVisible,
  permanentVideosPlaying,
  userTags,
  myVideoActiveTab,
  selectedVideoForTagging,
  taggedUserVideos,
  taggedVideosPlaying,
  isStorageModalVisible,
  playlists,
});

const mapDispatchToProps = dispatch => ({
  selectVideoForHighlight: permanentVideo => () => {
    dispatch(permanentVideoSelected(permanentVideo));
    dispatch(showVideoHighlightModal());
  },
  hideVideoHighlightModal: () => {
    dispatch(hideVideoHighlightModal());
    dispatch(permanentVideoSelected(null));
  },
  deletePlayerPermanentVideo: videoId => {
    dispatch(deletePlayerPermanentVideo(videoId));
  },
  deletePlayerPermanentPartnerVideo: videoId => {
    dispatch(deletePlayerPermanentPartnerVideo(videoId));
  },
  modifyPlayerPermanentVideo: (videoId, videoData) => {
    dispatch(modifyPlayerPermanentVideo(videoId, videoData));
  },
  deletePlaylist: playlistId => {
    dispatch(deletePlaylist(playlistId));
  },
  modifyPlaylist: (playlistId, videoData) => {
    dispatch(modifyPlaylist(playlistId, videoData));
  },
  showVideosSharing: permanentVideo => () => {
    dispatch(permanentVideoSelected(permanentVideo));
    dispatch(showVideoSharingModal());
  },
  addPermanentVideoPlaying: videoId => () => {
    dispatch(addPermanentVideoPlaying(videoId));
  },
  showPlayerTags: () => {
    dispatch(showUserTagModal());
  },
  setActiveTab: t => {
    dispatch(selectVideoForTagging(null));
    dispatch(myVideoActiveTab(t));
  },
  showVideoTagging: video => () => {
    dispatch(getUserVideoTags(video.videoId));
    dispatch(selectVideoForTagging(video));
    dispatch(myVideoActiveTab('tags'));
  },
  searchTaggedVideo: tagIds => {
    dispatch(getTaggedUserVideos(tagIds));
  },
  addTaggedVideoPlaying: videoId => () => {
    dispatch(addTaggedVideoPlaying(videoId));
  },
  changeStorageType: newStorageType => {
    dispatch(changeStorageType(newStorageType));
    dispatch(hideStorageModal());
  },
  hideStorageModal: () => {
    dispatch(hideStorageModal());
  },
  showStorageModal: () => {
    dispatch(showStorageModal());
  },
  goto: (path, action = null) => e => {
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  sendUsageData: (usageType, partnerId, slotId, videoPartnerTitle = null, videoPartnerName = null) => {
    dispatch(postUsageData(usageType, partnerId, null, slotId, false, 2, videoPartnerTitle, videoPartnerName))
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerVideo));
