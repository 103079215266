import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Carousel, Fade, Container, Row, Col, Card, Image, Tab, Tabs} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import {SPORTS} from '../constants';
import coveredIcon from '../images/coperto-scoperto_campo.svg';
import terrainIcon from '../images/terreno_campo.svg';
import swimmingPoolIcon from '../images/terreno_piscina.svg';
import {useTranslation} from 'react-i18next';
import {newOwnedField, newOwnedService} from '../actions/partner';

const PartnerFields = ({
  ownedFields,
  ownedServices,
  isLogged,
  showField,
  showService,
  profile,
  partnerFieldsActiveTab,
  showCalendar,
  showServiceCalendar,
  showBookingCalendar,
  showServiceBookingCalendar,
  setActiveTab,
  goto
}) => {
  const {t, i18n} = useTranslation();
  if (!isLogged) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100}}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={12}>
              <Row>
                <Col>
                  <h4 style={{marginTop: 20, marginBottom: 30}}>{t('RESOURCES')}</h4>
                </Col>
              </Row>
              <Tabs activeKey={partnerFieldsActiveTab} className="myvideo-tabs" onSelect={t => setActiveTab(t)}>
                <Tab eventKey="campi" title={t('FIELDS')} tabClassName="myvideo-tab">
                  <Row>
                    <Col>
                      <p style={{marginTop: 30, marginBottom: 60}}>{t('CREATE_AND_ADD_FIELDS')}</p>
                    </Col>
                    <Col>
                      <Button className="btn btn-secondary float-right" onClick={goto('partner-field', newOwnedField)} style={{marginTop: 20, marginBottom: 30}}>{t('ADD_FIELD')}</Button>
                    </Col>
                  </Row>
                  <Row>
                    {
                      ownedFields.map(f =>
                        (
                          <Col key={f.id} xs={12} sm={12} md={12} xl={6} lg={6}>
                            <Card style={{marginBottom: 10}}>
                              <Card.Body>
                                {
                                  f.imagesNames.length === 0 ?
                                    <Fragment>
                                      <div className="card sportcenter_card_bg_rp">
                                        <div className="card_image_rp">
                                          <div className="videoWrapper card_image_sportcenter_rp"/>
                                      </div>
                                    </div> 
                                    </Fragment> :
                                    <Carousel style={{marginBottom: 10}} interval={null}>
                                      {
                                        f.imagesNames.map(i =>
                                          (
                                            <Carousel.Item key={i}>
                                              <img
                                                className="d-block w-100"
                                                src={`${Configuration.apiEndPoint}/partners/fields/${f.id}/images?load=${i}`}
                                                alt={t('FIRST_SLIDE')}
                                                style={{width: '100%'}}
                                              />
                                            </Carousel.Item>
                                          )
                                        )
                                      }
                                    </Carousel>
                                }
                                <Card.Title>
                                  <Row style={{marginBottom: 25, marginTop: 25}}>
                                    <Col xs={3} sm={3} md={3} xl={2} lg={2}>
                                      <Image
                                        style={{width: '80%'}}
                                        src={SPORTS[f.sport] ? SPORTS[f.sport].icon : null}
                                      />
                                    </Col>
                                    <Col>
                                      <Row style={{marginBottom: 10}}>{f.name}</Row>
                                      <Row><Card.Subtitle className="text-muted">{f.sport}</Card.Subtitle></Row>
                                    </Col>
                                  </Row>
                                </Card.Title>
                                <Card.Text>
                                  {f.description}
                                </Card.Text>
                                <Row className="justify-content-center align-items-center" style={{marginBottom: 10}}>
                                  <Col xs={2} sm={2} md={2} xl={2} lg={2}>
                                    <Image
                                      style={{width: '100%', maxWidth: 50}}
                                      src={coveredIcon}
                                    />
                                  </Col>
                                  <Col>
                                    {f.covered ? t('COVERED_FIELD') : t('OPEN_FIELD')}
                                  </Col>
                                  <Col xs={2} sm={2} md={2} xl={2} lg={2}>
                                    <Image
                                      style={{width: '100%', maxWidth: 50}}
                                      src={f.terrain === 'Piscina' ? swimmingPoolIcon : terrainIcon}
                                    />
                                  </Col>
                                  <Col>
                                    {f.terrain}
                                  </Col>
                                </Row>
                                <Row className="justify-content-center" style={{marginBottom: 10}}>
                                  <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                                    <Button block variant="outline-primary" size="lg" onClick={showField(f)}>{t('CHANGE')}</Button>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                                    <Button block variant="outline-primary" size="lg" onClick={showBookingCalendar(f)}>{t('BOOK')}</Button>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                                    <Button block variant="outline-primary" size="lg" onClick={showCalendar(f)}>{t('PLAN')}</Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      )
                    }
                  </Row>
                </Tab>
                <Tab eventKey="servizi" title={t('SERVICES')} tabClassName="myvideo-tab">
                  <Row>
                    <Col>
                      <p style={{marginTop: 30, marginBottom: 60}}>{t('CREATE_AND_ADD_SERVICES')}</p>
                    </Col>
                    <Col>
                      <Button className="btn btn-secondary float-right" onClick={goto('partner-service', newOwnedService)} style={{marginTop: 20, marginBottom: 30}}>{t('ADD_SERVICE')}</Button>
                    </Col>
                  </Row>
                  <Row>
                    {
                      ownedServices.map(s =>
                        (
                          <Col key={s.id} xs={12} sm={12} md={12} xl={6} lg={6}>
                            <Card style={{marginBottom: 10}}>
                              <Card.Body>
                                {
                                  s.imagesNames && s.imagesNames.length === 0 ?
                                    <Fragment>
                                      <div className="card sportcenter_card_bg_rp">
                                        <div className="card_image_rp">
                                          <div className="videoWrapper card_image_sportcenter_rp"/>
                                      </div>
                                    </div> 
                                    </Fragment> :
                                    <Carousel style={{marginBottom: 10}} interval={null}>
                                      {
                                        (s.imagesNames || []).map(i =>
                                          (
                                            <Carousel.Item key={i}>
                                              <img
                                                className="d-block w-100"
                                                src={`${Configuration.apiEndPoint}/partners/services/${s.id}/images?load=${i}`}
                                                alt={t('FIRST_SLIDE')}
                                                style={{width: '100%'}}
                                              />
                                            </Carousel.Item>
                                          )
                                        )
                                      }
                                    </Carousel>
                                }
                                <Card.Title>
                                  <Row style={{marginBottom: 25, marginTop: 25}}>
                                    <Col>
                                      <Row style={{marginBottom: 10, marginLeft: 0}}>{s.name}</Row>
                                      <Row style={{marginLeft: 0}}><Card.Subtitle className="text-muted">{s.briefDescription}</Card.Subtitle></Row>
                                    </Col>
                                  </Row>
                                </Card.Title>
                                <Card.Text>
                                  {s.description}
                                </Card.Text>
                                <Row className="justify-content-center" style={{marginBottom: 10}}>
                                  <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                                    <Button block variant="outline-primary" size="lg" onClick={showService(s)}>{t('CHANGE')}</Button>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                                    <Button block variant="outline-primary" size="lg" onClick={showServiceBookingCalendar(s)}>{t('BOOK')}</Button>
                                  </Col>
                                  <Col xs={12} sm={12} md={4} xl={4} lg={4} className="d-flex justify-content-center text-center">
                                    <Button block variant="outline-primary" size="lg" onClick={showServiceCalendar(s)}>{t('PLAN')}</Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      )
                    }
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

PartnerFields.propTypes = {
  ownedFields: PropTypes.array,
  ownedServices: PropTypes.array,
  newField: PropTypes.func.isRequired,
  isLogged: PropTypes.bool,
  showField: PropTypes.func.isRequired,
  profile: PropTypes.object,
  partnerFieldsActiveTab: PropTypes.string,
  setActiveTab: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  showServiceCalendar: PropTypes.func.isRequired,
  showBookingCalendar: PropTypes.func.isRequired,
  showServiceBookingCalendar: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired
};

PartnerFields.defaultProps = {
  profile: null,
  partnerFieldsActiveTab: "campi",
  ownedFields: [],
  ownedServices: [],
  isLogged: false
};

export default PartnerFields;
