import React, {Fragment} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {showPlayersModal, hidePlayersModal, searchPlayers, hideEditPlayersModal, cleanPlayersReceived, saveEditedPlayers} from '../actions/player';
import { showMessage } from '../actions/messages';

const PlayerBookingEditModal = ({
    isEditPlayersModalVisible,
    slotPrice,
    slotPriceNoDiscount,
    profile,
    setSelectedPlayer,
    startSlot,
    presetPlaces,
    renderPlayer,
    isPlayersModalVisible,
    partner,
    playersList,
    selectedPlayer,
    renderPlayerSearch,
    searchPlayers,
    showPlayersModal,
    hidePlayersModal,
    showMessage,
    hideEditPlayersModal,
    saveEditedPlayers,
    booking
}) => {
    const {t, i18n} = useTranslation();

    return(
        <Modal show={isEditPlayersModalVisible} onHide={hideEditPlayersModal}>
            <Modal.Header closeButton>
            <Modal.Title>{t('EDIT_PLAYERS')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Formik
            enableReinitialize
            initialValues={{invitedPlayers: [], invitedGuests: []}}
            onSubmit={v => saveEditedPlayers(v.invitedPlayers, v.invitedGuests, startSlot.slotId, booking.bookingId)}
        >
            {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue
            }) => {
    
            const addInvitedPlayer = (player, inviteType) => {
                let playerGuests = values.invitedPlayers.filter(el => el.inviteType === 'guest')
                if(inviteType === 'guest' && (slotPrice() + ((slotPriceNoDiscount() + ((startSlot.costsPrice / 100) / startSlot.minimumPlayers)) * (values.invitedGuests.length + playerGuests.length + 2)) - slotPriceNoDiscount()) > profile.user.wallet + (booking.ownerPaid / 100)){
                showMessage(t('INSUFFICIENT_CREDIT'), t('INSUFFICIENT_CREDIT_GUEST'));
                return;
                }
                if(inviteType === 'partecipant' && !player.hasSufficientFunds){
                showMessage(t('CANNOT_INVITE_PARTECIPANT'), t('CANNOT_INVITE_PARTECIPANT_INFO',{name: player.name, surname: player.surname}));
                return;
                }
                player["inviteType"] = inviteType
                let alreadyInvited = false;
                values.invitedPlayers.forEach(p => {
                if(p.uid === player.uid) alreadyInvited = true;
                });
                if(!alreadyInvited) setFieldValue('invitedPlayers', [...values.invitedPlayers, player]);
                setSelectedPlayer({});
                setFieldValue('playerName', "");
                setFieldValue('playerSurname', "");
                setFieldValue('playerNickname', "");
                hidePlayersModal();
            };

            const addInvitedGuest = (guest) => {
                var validEmailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(guest.email && !guest.email.match(validEmailRegex)) {
                showMessage(t('EMAIL'), t('INVALID_EMAIL'));
                return;
                }

                if(guest.email === ""){
                guest.email = null
                }

                if(!guest.surname){
                showMessage(t('MANDATORY_FIELDS'), t('INSERT_GUEST_NAME_SURNAME'));
                return;
                }

                if(!guest.name){
                guest.name = ""
                }

                let playerGuests = values.invitedPlayers.filter(el => el.inviteType === 'guest')
                if(slotPrice() + ((slotPriceNoDiscount() + ((startSlot.costsPrice / 100) / startSlot.minimumPlayers)) * (values.invitedGuests.length + playerGuests.length + 2)) - slotPriceNoDiscount() > profile.user.wallet + (booking.ownerPaid / 100)){
                showMessage(t('INSUFFICIENT_CREDIT'), t('INSUFFICIENT_CREDIT_GUEST'));
                return;
                }
            
                setFieldValue('invitedGuests', [...values.invitedGuests, guest]);
                setFieldValue('friendName', "");
                setFieldValue('friendSurname', "");
                setFieldValue('friendEmail', null);
                setFieldValue('friendPhone', "");
                hidePlayersModal();
            };
        
            return(
                <Fragment>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                    <Form.Label>{startSlot.minimumPlayers !== presetPlaces ? t('PLAYERS_MIN_MAX',{min: startSlot.minimumPlayers, max: presetPlaces}) : t('PLAYERS_MIN_EQUALS_MAX',{minmax: presetPlaces})}</Form.Label>
                    {renderPlayer(values.invitedPlayers, values.invitedGuests)}
                    {values.invitedPlayers.length + values.invitedGuests.length + 1 < presetPlaces ?
                    values.invitedGuests.length + values.invitedPlayers.length + 1 < startSlot.minimumPlayers ?
                    <Button variant="secondary" style={{marginBottom: 50}} onClick={showPlayersModal}>{t('INVITE_PLAYER_REQUIRED', {n: values.invitedGuests.length + values.invitedPlayers.length + 2})}</Button>
                    : <Button variant="secondary" style={{marginBottom: 50}} onClick={showPlayersModal}>{t('INVITE_PLAYER_NOT_REQUIRED', {n: values.invitedGuests.length + values.invitedPlayers.length + 2})}</Button>
                    : null}
                    {
                        <Form.Group>
                        <Button variant="primary" type="submit" disabled={values.invitedGuests.length + values.invitedPlayers.length + 1 < startSlot.minimumPlayers}>{t('SAVE')}</Button>
                        </Form.Group>
                    }
                    </Form.Group>
                </Form>
                {isPlayersModalVisible ?
                <Modal show={isPlayersModalVisible} onHide={hidePlayersModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>{t('BOOKING_PLAYERS_NUMBER_INDEX', {index: (values.invitedGuests || []).length + (values.invitedPlayers || []).length + 2})}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Label>{t('CHOOSE_INVITE_TYPE')}</Form.Label>
                        <Form.Check required name="inviteType" type="radio" label={t('INVITE_GUEST')} checked={values.inviteType === 'guest'} onChange={() => setFieldValue('inviteType', 'guest')}/>
                        <Form.Text className='text-muted' style={{paddingLeft: 20}}>{t('INVITE_GUEST_INFO')}</Form.Text>
                        <Form.Check required name="inviteType" type="radio" label={t('INVITE_PARTECIPANT')} checked={values.inviteType === 'partecipant'} onChange={() => setFieldValue('inviteType', 'partecipant')}/>
                        <Form.Text className='text-muted' style={{paddingLeft: 20, paddingBottom: 30}}>{t('INVITE_PARTECIPANT_INFO')}</Form.Text>
                        {values.inviteType === 'guest' ? 
                        <Fragment>
                        <Form.Label>{t('CHOOSE_GUEST')}</Form.Label>
                        <Form.Check required name="guestType" type="radio" label={t('PLAYER_GUEST')} checked={values.guestType === 'player'} onChange={() => setFieldValue('guestType', 'player')}/>
                        <Form.Text className='text-muted' style={{paddingLeft: 20}}>{t('PLAYER_GUEST_INFO')}</Form.Text>
                        <Form.Check required name="guestType" type="radio" label={t('FRIEND_GUEST')} checked={values.guestType === 'friend'} onChange={() => setFieldValue('guestType', 'friend')}/>
                        <Form.Text className='text-muted' style={{paddingLeft: 20, paddingBottom: 30}}>{t('FRIEND_GUEST_INFO')}</Form.Text>
                        </Fragment>
                        : null }
                        {values.inviteType === 'partecipant' || (values.inviteType === 'guest' && values.guestType === 'player') ?
                        <Fragment>
                            <p>{t('THREE_CHAR_MINIMUM')}</p>
                            <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                            <Form.Control name="playerNickname" style={{maxWidth: 150}} placeholder={t('NICKNAME')} type="text" maxLength="25" minLength="3" value={values.playerNickname || ""} onChange={handleChange}/>
                            <Form.Control name="playerName" style={{maxWidth: 150}} placeholder={t('NAME')} type="text" maxLength="25" minLength="3" value={values.playerName || ""} onChange={handleChange}/>
                            <Form.Control name="playerSurname" style={{maxWidth: 150}} placeholder={t('SURNAME')} type="text" maxLength="25" minLength="3" value={values.playerSurname || ""} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Form.Check name="playerAffiliate" type="checkbox" label={t('PLAYER_AFFILIATE',{partner: partner.name})} checked={values.playerAffiliate === true} onChange={() => setFieldValue('playerAffiliate', !values.playerAffiliate)}/>
                            <Button disabled={!(values.playerNickname && values.playerNickname.length > 2 || values.playerName && values.playerName.length > 2 || values.playerSurname && values.playerSurname.length > 2)} variant="secondary" onClick={() => searchPlayers(values.playerNickname, values.playerName, values.playerSurname, values.playerAffiliate, startSlot.slotId, partner.uid)}>{t('SEARCH')}</Button>
                            </Form.Group>
                            <Form.Group style={{display: 'flex', flexDirection: 'column'}}>
                            {playersList.map(p => 
                            renderPlayerSearch(p)
                            )}
                            <Button style={{maxWidth: 100}}disabled={selectedPlayer.uid === undefined} variant="secondary" onClick={() => addInvitedPlayer(selectedPlayer, values.inviteType)}>{t('SELECT')}</Button>
                            </Form.Group>
                        </Fragment>
                        : values.guestType === 'friend' ?
                        <Fragment>
                        <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                            <Form.Control required name="friendName" placeholder={t('NAME')} style={{marginRight: 20}} type="text" maxLength="25" minLength="2" value={values.friendName || ""} onChange={handleChange}/>
                            <Form.Control required name="friendSurname" placeholder={t('SURNAME')} type="text" maxLength="25" minLength="2" value={values.friendSurname || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                            <Form.Control required name="friendEmail" placeholder={t('EMAIL')} style={{marginRight: 20}} type="text" maxLength="30" minLength="5" value={values.friendEmail || ""} onChange={handleChange}/>
                            <Form.Control name="friendPhone" className='no-arrow' placeholder={t('PHONE')} type="number" maxLength="10" minLength="10" value={values.friendPhone || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Button type="button" style={{maxWidth: 100}} variant="secondary" onClick={() => addInvitedGuest({email: values.friendEmail, name: values.friendName, surname: values.friendSurname, phoneNumber: values.friendPhone})}>{t('SELECT')}</Button>
                        </Fragment>
                        : null}
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={hidePlayersModal}>
                        {t('CLOSE_BUTTON')}
                    </Button>
                    </Modal.Footer>
                </Modal>
                : null}
                </Fragment>
            )}}
            </Formik>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={hideEditPlayersModal}>
                {t('CLOSE_BUTTON')}
            </Button>
            </Modal.Footer>
        </Modal>
    )
};

const mapStateToProps = ({login: {profile}, player: {isPlayersModalVisible, playersList, isEditPlayersModalVisible}}) => ({
    profile,
    isPlayersModalVisible,
    playersList,
    isEditPlayersModalVisible
  });
  
  const mapDispatchToProps = dispatch => ({
    showPlayersModal: () => {
      dispatch(showPlayersModal());
    },
    hidePlayersModal: () => {
      dispatch(hidePlayersModal());
      dispatch(cleanPlayersReceived());
    },
    searchPlayers: (playerNickname, playerName, playerSurname, playerAffiliate, slotId, partnerId) => {
      if (playerAffiliate === undefined) playerAffiliate = false;
      if (playerName) playerName = playerName.replace(/ /g,'_');
      if (playerSurname) playerSurname = playerSurname.replace(/ /g,'_');
      if (playerNickname) playerNickname = playerNickname.replace(/ /g,'_');
      const validRegex = /^[A-Za-z0-9_àèéìòù'-]*$/;
      if ((playerName && !playerName.match(validRegex)) || (playerSurname && !playerSurname.match(validRegex)) || (playerNickname && !playerNickname.match(validRegex)))
        dispatch(showMessage('ACCEPTED_CHARACTERS', "ACCEPTED_CHARACTERS_INFO"));
      else dispatch(searchPlayers(playerNickname, playerName, playerSurname, playerAffiliate, slotId, partnerId));
    },
    showMessage: (title, body) => {
      dispatch(showMessage(title,body));
    },
    hideEditPlayersModal: () => {
      dispatch(hideEditPlayersModal());
    },
    saveEditedPlayers: (invitedPlayers, invitedGuests, slotId, bookingId) => {
      dispatch(saveEditedPlayers(invitedPlayers, invitedGuests, slotId, bookingId));
    }
  });

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlayerBookingEditModal));
