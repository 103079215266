/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Modal, Row, Table} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import {searchPlayers, showChangePasswordModal, hideChangePasswordModal, selectPlayer, changePassword} from '../actions/admin';
import {useTranslation} from 'react-i18next';

const AdminPlayerManagement = ({
  token,
  searchUsers,
  players,
  isChangePasswordModalVisible,
  selectPlayer,
  selectedPlayer,
  hideChangePasswordModal,
  changePassword
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Fragment>
      <Formik
        initialValues={{surname: null}}
        onSubmit={searchUsers}
      >
        {({
          handleSubmit,
          handleChange,
          values
        }) => {
          return (
            <Fragment>
              <CommonMetaTag/>
              <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
                <Container>
                  <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                    <Col xs={12} sm={8} md={6}>
                      <h4 style={{marginTop: 30}}>{('SEARCH_PLAYER')}</h4>
                      <p>{t('SEARCH_PLAYER_DESC')}</p>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label>{t('SURNAME')}</Form.Label>
                          <Form.Control required name="surname" placeholder="inserisci alcune lettere del cognome" type="text" maxLength="50" value={values.surname || ''} onChange={handleChange}/>
                        </Form.Group>
                        <Button type="submit">{t('SEARCH')}</Button>
                      </Form>
                    </Col>
                  </Row>
                  <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                    <Col xs={12} sm={8} md={6}>
                      <h4 style={{marginTop: 30}}>{t('SEARCH_PLAYER_RESULT_SUCCESSFUL')}</h4>
                      {
                        players && players.length > 0 ?
                          <Table hover responsive size="sm">
                            <thead>
                              <tr>
                                <th>{t('PLAYER')}</th>
                                <th>{t('EMAIL')}</th>
                                <th/>
                              </tr>
                            </thead>
                            <tbody>
                              {players.map(p => (
                                <tr key={p.uid}>
                                  <td>{p.surname} {p.name}</td>
                                  <td>{p.user.email}</td>
                                  <td><Button variant="danger" onClick={selectPlayer(p)}>{t('RESET')}</Button></td>
                                </tr>
                              ))}
                            </tbody>
                          </Table> :
                          <p>{t('SEARCH_PARTNER_OR_PLAYER_RESULT_FAILED')}</p>
                      }
                    </Col>
                  </Row>
                </Container>
              </Fade>
            </Fragment>
          );
        }}
      </Formik>
      {
        selectedPlayer ?
          <Modal show={isChangePasswordModalVisible} onHide={hideChangePasswordModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('PASSWORD_RESET')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{playerId: selectedPlayer.uid, newPassword: null, newPasswordConfirm: null, sendEmail: false}}
                onSubmit={changePassword}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('PASSWORD_NEW')}</Form.Label>
                        <Form.Control required name="newPassword" type="password" value={values.newPassword || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('PASSWORD_CONFIRM')}</Form.Label>
                        <Form.Control required name="newPasswordConfirm" type="password" value={values.newPasswordConfirm || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="submit" variant="secondary">
                        {t('SET')}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={hideChangePasswordModal}>
                {t('CLOSE_BUTTON')}
              </Button>
            </Modal.Footer>
          </Modal> :
          null
      }
    </Fragment>
  );
};

AdminPlayerManagement.propTypes = {
  token: PropTypes.string,
  searchUsers: PropTypes.func.isRequired,
  players: PropTypes.array,
  isChangePasswordModalVisible: PropTypes.bool,
  selectPlayer: PropTypes.func.isRequired,
  selectedPlayer: PropTypes.object,
  hideChangePasswordModal: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired
};

AdminPlayerManagement.defaultProps = {
  token: null,
  players: [],
  isChangePasswordModalVisible: false,
  selectedPlayer: null
};

const mapStateToProps = ({login: {token}, admin: {players, selectedPlayer, isChangePasswordModalVisible}}) => ({
  token,
  players,
  isChangePasswordModalVisible,
  selectedPlayer
});

const mapDispatchToProps = dispatch => ({
  searchUsers: values => {
    dispatch(searchPlayers(values.surname));
  },
  showChangePasswordModal: () => {
    dispatch(showChangePasswordModal());
  },
  hideChangePasswordModal: () => {
    dispatch(hideChangePasswordModal());
  },
  selectPlayer: player => () => {
    dispatch(selectPlayer(player));
    dispatch(showChangePasswordModal());
  },
  changePassword: credential => {
    dispatch(changePassword(credential));
    dispatch(hideChangePasswordModal());
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPlayerManagement));
