import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import PartnerFields from '../components/partner-fields';
import {newOwnedField, ownedFieldSelected, ownedServiceSelected, getOwnedFieldSlots, getOwnedFieldBookings, partnerFieldsActiveTab} from '../actions/partner';

const mapStateToProps = ({partner: {ownedFields, ownedServices, partnerFieldsActiveTab}, login: {token, profile}}) => ({
  ownedFields,
  ownedServices,
  isLogged: token !== null,
  profile,
  partnerFieldsActiveTab
});

const mapDispatchToProps = dispatch => ({
  newField: () => {
    dispatch(newOwnedField());
    dispatch(push('/partner-field'));
  },
  showField: field => () => {
    dispatch(ownedFieldSelected(field));
    dispatch(push('partner-field'));
  },
  showService: service => () => {
    dispatch(ownedServiceSelected(service));
    dispatch(push('partner-service'));
  },
  showCalendar: field => () => {
    dispatch(ownedFieldSelected(field));
    dispatch(getOwnedFieldSlots(field.id));
    dispatch(push('partner-field-calendar'));
  },
  showServiceCalendar: service => () => {
    dispatch(ownedServiceSelected(service));
    dispatch(getOwnedFieldSlots(service.id, false, 'service'));
    dispatch(push('partner-service-calendar'));
  },
  showBookingCalendar: field => () => {
    dispatch(getOwnedFieldBookings(new Date(), 'WEEK'));
    dispatch(ownedFieldSelected(field));
    dispatch(getOwnedFieldSlots(field.id, true, 'field', new Date(), 'WEEK'));
    dispatch(push('partner-booking-calendar'));
  },
  showServiceBookingCalendar: service => () => {
    dispatch(getOwnedFieldBookings(new Date(), 'WEEK', 'service'));
    dispatch(ownedServiceSelected(service));
    dispatch(getOwnedFieldSlots(service.id, true, 'service', new Date(), 'WEEK'));
    dispatch(push('partner-service-booking-calendar'));
  },
  setActiveTab: t => {
    dispatch(partnerFieldsActiveTab(t));
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerFields));
