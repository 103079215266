import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Fade, Container, Row} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import queryString from 'query-string';
import {confirmPlayerGroup} from '../actions/player';
import {useTranslation} from 'react-i18next';

const ConfirmPlayerGroup = ({location: {search}, confirmPlayerGroup}) => {
  const {t, i18n} = useTranslation();
  const {groupId, playerId, token} = queryString.parse(search);
  confirmPlayerGroup(groupId, playerId, token);

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
        <Container>
          <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <h4>{t('PLAYER_GROUP_CONFIRM')}</h4>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

ConfirmPlayerGroup.propTypes = {
  location: PropTypes.object.isRequired,
  confirmPlayerGroup: PropTypes.func.isRequired
};

export default (connect(
  null,
  dispatch => ({
    confirmPlayerGroup: (groupId, playerId, token) => {
      dispatch(confirmPlayerGroup(groupId, playerId, token));
    }
  })
)(ConfirmPlayerGroup));

