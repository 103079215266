import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PlayerServiceBookingCalendar from '../components/player-service-booking-calendar';
import {push} from 'connected-react-router';
import queryString from 'querystring';
import {addToBookingQueue, filterBookingFieldId, bookingSlotSelected, bookingMultiSlotsSelected, showBookingServiceSlotModal, hideBookingServiceSlotModal, newBooking, saveServiceBooking, bookingSelected, purchaseVideo, acceptPrivacy, getSlots, getVideosStore, getVideosStorePartnerVideo, deleteServiceBooking} from '../actions/player';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({player: {filterBookableFields, selectedPartner, selectedPartnerServices, selectedPlayerGroups, slots, isBookingServiceSlotModalVisible, bookingSlot, bookingSlots, bookingFieldId, bookings, booking}, login: {token, profile}}) => ({
  isLogged: token !== null,
  selectedPartner,
  selectedPartnerServices,
  slots,
  isBookingServiceSlotModalVisible,
  bookingSlot,
  bookingSlots,
  bookingFieldId,
  bookings,
  booking,
  profile,
  filterBookableFields,
  memberships: selectedPlayerGroups,
});

const mapDispatchToProps = dispatch => ({
  bookingSlotSelected: ev => {
    if (new Date(ev.slot.startDatetime) < Date.now()) {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
    } else if (ev.slot.state === 'reserved') {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_RESERVED'));
    }
      else if (ev.slot.state === "unavailable") {
      dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_UNAVAILABLE'));
    } else if (ev.slot.bookingId && ev.booking) {
      dispatch(bookingSlotSelected(ev.slot));
      dispatch(bookingSelected(ev.booking));
      dispatch(showBookingServiceSlotModal());
    } else {
      dispatch(bookingSlotSelected(ev.slot));
      dispatch(newBooking({description: null, payment: false, playersNumber: 1, otherPlayers: [''], slots: [ev.slot.slotId], rtvOrAcademy: false, invitedPlayers: [], invitedGuests: []}));
      dispatch(showBookingServiceSlotModal());
    }
  },
  bookingMultiSlots: slots => {
    dispatch(bookingMultiSlotsSelected(slots));
    dispatch(newBooking({description: null, payment: false, playersNumber: 1, otherPlayers: [''], slots: slots.map(s => s.slotId), rtvOrAcademy: false, invitedPlayers: [], invitedGuests: []}));
    dispatch(showBookingServiceSlotModal());
  },
  addToBookingQueue: slotId => {
    dispatch(addToBookingQueue(slotId));
  },
  hideBookingSlotModal: () => {
    dispatch(hideBookingServiceSlotModal());
  },
  saveBooking: booking => {
    dispatch(saveServiceBooking(booking));
  },
  requestDelete: phoneNumber => {
    dispatch(showMessage('BOOKINGS', 'BOOKING_HOW_CANCEL', {phoneNumber}));
  },
  purchaseVideo: slots => {
    dispatch(purchaseVideo(slots));
  },
  showMessage: (title, body) => {
    dispatch(showMessage(title, body));
  },
  acceptPrivacy: () => {
    dispatch(acceptPrivacy());
  },
  getSlotsByWeek: (partnerId, serviceId) => targetDate => {
    dispatch(getSlots(partnerId, serviceId, targetDate, 'service'));
  },
  showVideoStore: (partnerUid, serviceId, fromDatetime, toDatetime) => {
    dispatch(getVideosStore(partnerUid, null, serviceId, fromDatetime, toDatetime));
    dispatch(getVideosStorePartnerVideo(partnerUid));
    const params = Object.fromEntries(Object.entries({serviceId, fromDatetime, toDatetime}).filter(([_, v]) => v !== null));

    dispatch(push(`/player-video-store-partner?${queryString.stringify(params)}`));
  },
  showCalendar: (partnerId, serviceId = null) => () => {
    dispatch(filterBookingFieldId(serviceId));
    dispatch(getSlots(partnerId, serviceId, new Date(), 'service'));
    dispatch(push('player-service-booking-calendar'));
  },
  deleteBooking: (bookingId, partnerId, multiBooking) => {
    dispatch(deleteServiceBooking(bookingId, partnerId, multiBooking));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerServiceBookingCalendar));
