const defaultState = {
  players: [],
  storagePrices: {},
  isChangePasswordModalVisible: false,
  selectedPlayer: null,
  partners: [],
  selectedPartner: null,
  selectedPartnerFields: [],
  selectedPartnerServices: [],
  isSponsorModalVisible: false,
  sponsors: [],
  selectedSponsor: null,
  selectedPartnerRtvChannels: [],
  isXmlModalVisible: false,
  isXmlPartnerVideoModalVisible: false,
  isCameraModalVisible: false,
  selectedXml: null,
  selectedCamera: null,
  slot: null,
  selectedCategory: {},
  isCategoryModalVisible: false,
  rtvCategories: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ADMIN_PLAYERS_LIST_RECEIVED': {
      return Object.assign({}, state, {players: action.players});
    }

    case 'ADMIN_STORAGE_PRICES_RECEIVED': {
      return Object.assign({}, state, {storagePrices: action.prices});
    }

    case 'ADMIN_SHOW_CHANGE_PASSWORD_MODAL': {
      return Object.assign({}, state, {isChangePasswordModalVisible: true});
    }

    case 'ADMIN_HIDE_CHANGE_PASSWORD_MODAL': {
      return Object.assign({}, state, {isChangePasswordModalVisible: false});
    }

    case 'ADMIN_PLAYER_SELECTED': {
      return Object.assign({}, state, {selectedPlayer: action.selectedPlayer});
    }

    case 'ADMIN_PARTNERS_LIST_RECEIVED': {
      return Object.assign({}, state, {partners: action.partners});
    }

    case 'ADMIN_SLOT_RECEIVED': {
      return Object.assign({}, state, {slot: action.slot});
    }

    case 'ADMIN_PARTNER_SELECTED': {
      return Object.assign({}, state, {selectedPartner: action.selectedPartner});
    }

    case 'ADMIN_PARTNER_FIELDS_RECEIVED': {
      return Object.assign({}, state, {selectedPartnerFields: action.partnerFields});
    }

    case 'ADMIN_PARTNER_SERVICES_RECEIVED': {
      return Object.assign({}, state, {selectedPartnerServices: action.partnerServices});
    }

    case 'ADMIN_SHOW_SPONSOR_MODAL': {
      return Object.assign({}, state, {isSponsorModalVisible: true});
    }

    case 'ADMIN_HIDE_SPONSOR_MODAL': {
      return Object.assign({}, state, {isSponsorModalVisible: false});
    }

    case 'ADMIN_SPONSORS_RECEIVED': {
      return Object.assign({}, state, {sponsors: action.sponsors});
    }

    case 'ADMIN_SPONSOR_SELECTED': {
      return Object.assign({}, state, {selectedSponsor: action.selectedSponsor});
    }

    case 'ADMIN_NEW_SPONSOR': {
      return Object.assign({}, state, {selectedSponsor: {name: null, link: null, sponsorType: null}});
    }

    case 'ADMIN_NEW_XML': {
      return Object.assign({}, state, {selectedXml: {xmlType: null, xml: "", fieldId: action.fieldId}});
    }

    case 'ADMIN_NEW_XML_PARTNER_VIDEO': {
      return Object.assign({}, state, {selectedXml: {xmlType: null, xml: "", partnerId: action.partnerId}});
    }

    case 'ADMIN_SHOW_XML_MODAL': {
      return Object.assign({}, state, {isXmlModalVisible: true});
    }

    case 'ADMIN_HIDE_XML_MODAL': {
      return Object.assign({}, state, {isXmlModalVisible: false});
    }

    case 'ADMIN_SHOW_XML_PARTNER_VIDEO_MODAL': {
      return Object.assign({}, state, {isXmlPartnerVideoModalVisible: true});
    }

    case 'ADMIN_HIDE_XML_PARTNER_VIDEO_MODAL': {
      return Object.assign({}, state, {isXmlPartnerVideoModalVisible: false});
    }

    case 'ADMIN_SHOW_CAMERA_MODAL': {
      return Object.assign({}, state, {isCameraModalVisible: true});
    }

    case 'ADMIN_HIDE_CAMERA_MODAL': {
      return Object.assign({}, state, {isCameraModalVisible: false});
    }

    case 'ADMIN_NEW_CAMERA': {
      return Object.assign({}, state, {selectedCamera: {id: null, name: "", position: null, fieldId: action.fieldId}});
    }

    case 'ADMIN_EDIT_CAMERA': {
      return Object.assign({}, state, {selectedCamera: action.camera});
    }

    case 'ADMIN_PARTNER_RTV_CHANNELS_RECEIVED': {
      return Object.assign({}, state, {selectedPartnerRtvChannels: action.partnerRtvChannels});
    }

    case 'ADMIN_SHOW_CATEGORY_MODAL': {
      return Object.assign({}, state, {isCategoryModalVisible: true});
    }

    case 'ADMIN_HIDE_CATEGORY_MODAL': {
      return Object.assign({}, state, {isCategoryModalVisible: false});
    }

    case 'ADMIN_RTV_CATEGORIES_RECEIVED': {
      return Object.assign({}, state, {rtvCategories: action.categories});
    }

    case 'ADMIN_SELECT_RTV_CATEGORY': {
      return Object.assign({}, state, {selectedCategory: action.category});
    }

    default:
      return state;
  }
};
