import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import Dialog from 'react-bootstrap-dialog';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {subscriptionKind, rtvType} from '../constants';
import {useTranslation} from 'react-i18next';
import Configuration from '../config';
import {showPlayersModal, hidePlayersModal, searchPlayers, cleanPlayersReceived, showEditPlayersModal, hideEditPlayersModal} from '../actions/player';
import PlayerBookingEditModal from './player-booking-edit-players'
import defaultAvatar from '../images/Replayer_default_Avatar.png'
import binIcon from '../images/bin_icon.svg';
import { showMessage } from '../actions/messages';

const PlayerBooking = ({booking, slot, slots, field, partner, profile, save, subscriptions, isPlayersModalVisible, showPlayersModal, hidePlayersModal, searchPlayers, playersList, showMessage, discount, showEditPlayersModal, hideEditPlayersModal, isEditPlayersModalVisible, resourceType = 'field'}) => {
  const {t, i18n} = useTranslation();
  const resizeArray = (arr, newSize, defaultValue) => {
    if (newSize > arr.length) {
      return [...arr, ...new Array(Math.max(newSize - arr.length, 0)).fill(defaultValue)];
    }
    arr.length = newSize;
    return arr;
  };

  const [selectedPlayer, setSelectedPlayer] = useState({});

  let dialog = null;
  const confirm = values => {
    dialog.show({
      title: t('CONFIRM_RESERVATIONS'),
      body: <Fragment>
              <Form.Label>
                {
                  field.payment ? 
                    t('MAKING_RESERVATION_PAID') 
                  : t('MAKING_RESERVATION_TO_PAY')
                }
              </Form.Label>
              {
                field.bookingPolicy ?
                  <Form.Label>
                    {t('BOOKING_POLICY', {bookingPolicy: field.bookingPolicy})}
                  </Form.Label>
                : null
              }
            </Fragment>,
      actions: [
        Dialog.Action(
          t('CONFIRM'),
          () => save(values),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const checkPrivacy = () => {
    dialog.show({
      title: t('PRIVACY_CONSENT'),
      body: <Fragment>
              <Form.Label>
                <strong>{t('VIDEO_RECORDING_ACTIVE')}</strong>
              </Form.Label>
              <Form.Label>
                {t('GIVE_CONSENT_NOW')}
              </Form.Label>
            </Fragment>,
      actions: [
        Dialog.Action(
          t('I_AGREE'),
          confirm,
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,          
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const hasSubscription = p => {
    if (subscriptions) {
      const sub = subscriptions.subscriptions.find(s => s.partner === p.uid);
      if (sub && new Date(sub.expiry) >= new Date()) {
        return true;
      }

      const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
      if (unlimitedSubscription) {
        return true;
      }
    }

    return false;
  };

  const slotPrice = () => {
    if (slots) {
      var price = 0;
      for (slot of slots) {
        price += slot.price;
      }
      return price - (price * discount / 100);
    }
    else
    {
      return slot.price - (slot.price * discount / 100);
    }
  }

  const slotPriceNoDiscount = () => {
    if (slots) {
      var price = 0;
      for (slot of slots) {
        price += slot.price;
      }
      return price;
    }
    else
    {
      return slot.price;
    }
  }

  const rtvOrAcademy = slot ? slot.rtvOrAcademy : slots[0].rtvOrAcademy;
  const description = slot ? slot.description : slots[0].description;
  const presetPlaces = slot ? slot.presetPlaces : 1;
  const startSlot = slot ? slot : slots[0];
  const endSlot = slot ? slot : slots[slots.length - 1];
  const otherPlayers = booking.otherPlayers[0] ? booking.otherPlayers : [profile.name + " " + profile.surname]
  const otherPlayersPrice = [slotPrice()]
  let rtvChannels = slots && slots.length > 0 ? [...new Map(slots.map(s => s.rtvChannels).flat().map((item) => [item["id"], item])).values()] : slot.rtvChannels;
  
  const rtvChannelAssociations = slots && slots.length > 0 ? [...new Map(slots.map(s => s.rtvChannelAssociations).flat().map((item) => [item["rtvChannelId"], item])).values()] : slot.rtvChannelAssociations;
  rtvChannels.map(c => c.canPublish = rtvChannelAssociations.find(a => a.rtvChannelId === c.id).canPublish)

  if (field === null) {
    return null;
  }

  const renderPlayer = (players, guests) => {
    return(
      <div>
      <ul class="list-group list-group-light mb-4">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            {profile.image && profile.image[0] ?
            <img
              className="img-fluid"
              src={`${Configuration.apiEndPoint}/images/${profile.uid}?load=${profile.image[0]}`}
              alt={t('PROFILE_IMAGE')}
              style={{width: 45, height: 45, paddingLeft:0}}
              class="rounded-circle"
            />
            :
            <img
              className="img-fluid"
              src={defaultAvatar}
              alt={t('PROFILE_IMAGE')}
              style={{width: 45, height: 45, paddingLeft:0}}
              class="rounded-circle"
            />
            }
            <div class="ms-3">
              <p class="fw-bold mb-1" style={{marginLeft: 10}}>{profile.name + " " + profile.surname}</p>
              <p class="fw-bold mb-1" style={{marginLeft: 10}}>{profile.nickname + ' - ' + t('REFERENT')}</p>
              <p class="text-muted mb-0" style={{marginLeft: 10}}>{(t('GROUP_BOOKING_PLAYER_ONE',{price: slotPrice().toFixed(2)})) + (startSlot.costsPrice && startSlot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (startSlot.costsPrice/100/(players.length + guests.length + 1)).toFixed(2)}) + ` (${startSlot.costs.map(c => c.name).join(', ')})` : "")}</p>
            </div>
          </div>
        </li>
        {
          players.map( (p, index) =>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                {p.image && p.image[0] ?
                <img
                  className="img-fluid"
                  src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.image[0]}`}
                  alt={t('PROFILE_IMAGE')}
                  style={{width: 45, height: 45, paddingLeft:0}}
                  class="rounded-circle"
                />
                :
                <img
                  className="img-fluid"
                  src={defaultAvatar}
                  alt={t('PROFILE_IMAGE')}
                  style={{width: 45, height: 45, paddingLeft:0}}
                  class="rounded-circle"
                />
                }
                <div class="ms-3">
                  <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.name + " " + p.surname}</p>
                  <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.nickname + ' - ' +(p.inviteType === 'partecipant' ? t('PARTECIPANT') : t('GUEST'))}</p>
                  <p class="text-muted mb-0" style={{marginLeft: 10}}>
                    {index + 2 <= slot.minimumPlayers ? p.inviteType === 'partecipant' ? 
                    t('GROUP_BOOKING_OTHER_PLAYER_MANDATORY',{index: index+2}) : (t('GROUP_BOOKING_OTHER_PLAYER_MANDATORY_WITH_PRICE',{index: index+2, price: slotPriceNoDiscount().toFixed(2)}) + (startSlot.costsPrice && startSlot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (startSlot.costsPrice/100/(players.length + guests.length + 1)).toFixed(2)}) + ` (${startSlot.costs.map(c => c.name).join(', ')})` : "")) 
                    : p.inviteType === 'partecipant' ? t('GROUP_BOOKING_OTHER_PLAYER_OPTIONAL',{index: index+2}) : 
                    (t('GROUP_BOOKING_OTHER_PLAYER_OPTIONAL_WITH_PRICE',{index: index+2, price: slotPriceNoDiscount().toFixed(2)}) + (startSlot.costsPrice && startSlot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (startSlot.costsPrice/100/(players.length + guests.length + 1)).toFixed(2)}) + ` (${startSlot.costs.map(c => c.name).join(', ')})` : ""))}
                  </p>
                </div>
              </div>
              <li className="card_video_archive_list_icons_item"><a href="#" onClick={() => players.splice(index, 1)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
            </li>
          )
        }
        {
          guests.map( (g, index) =>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <img
                  className="img-fluid"
                  src={defaultAvatar}
                  alt={t('PROFILE_IMAGE')}
                  style={{width: 45, height: 45, paddingLeft:0}}
                  class="rounded-circle"
                />
                <div class="ms-3">
                  <p class="fw-bold mb-1" style={{marginLeft: 10}}>{g.name + " " + g.surname}</p>
                  <p class="fw-bold mb-1" style={{marginLeft: 10}}>{g.email || "No e-mail" + ' - ' + t('GUEST')}</p>
                  <p class="text-muted mb-0" style={{marginLeft: 10}}>
                    {index + 2 + players.length <= slot.minimumPlayers ?
                    (t('GROUP_BOOKING_OTHER_PLAYER_MANDATORY_WITH_PRICE',{index: index+2+players.length, price: slotPriceNoDiscount().toFixed(2)}) + (startSlot.costsPrice && startSlot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (startSlot.costsPrice/100/(players.length + guests.length + 1)).toFixed(2)}) + ` (${startSlot.costs.map(c => c.name).join(', ')})` : ""))
                    : (t('GROUP_BOOKING_OTHER_PLAYER_OPTIONAL_WITH_PRICE',{index: index+2+players.length, price: slotPriceNoDiscount().toFixed(2)}) + (startSlot.costsPrice && startSlot.costsPrice > 0 ? t('SLOT_COST_PRICE', {price: (startSlot.costsPrice/100/(players.length + guests.length + 1)).toFixed(2)}) + ` (${startSlot.costs.map(c => c.name).join(', ')})` : ""))}
                  </p>
                </div>
              </div>
              <li className="card_video_archive_list_icons_item"><a href="#" onClick={() => guests.splice(index, 1)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li>
            </li>
          )
        }
      </ul>
      </div>
    )
  }

  const renderPlayerAfterBooking = (players) => {
    return(
      <div>
      <ul class="list-group list-group-light mb-4">
        {
          players.map( p =>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
              {p.image && p.image[0] ?
                <img
                  className="img-fluid"
                  src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.image[0]}`}
                  alt={t('PROFILE_IMAGE')}
                  style={{width: 45, height: 45, paddingLeft:0}}
                  class="rounded-circle"
                />
                :
                <img
                  className="img-fluid"
                  src={defaultAvatar}
                  alt={t('PROFILE_IMAGE')}
                  style={{width: 45, height: 45, paddingLeft:0}}
                  class="rounded-circle"
                />
                }
                <div class="ms-3">
                  <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.partnerBookingName + " " + p.partnerBookingSurname}</p>
                  <p class="fw-bold mb-1" style={{marginLeft: 10}}>{p.partnerBookingEmail + ' - ' +(p.inviteType === 0 ? t('REFERENT') : p.inviteType === 1 ? t('PARTECIPANT') : t('GUEST'))}</p>
                </div>
              </div>
            </li>
          )
        }
      </ul>
      </div>
    )
  }

  const renderPlayerSearch = player => {
    return(
      <div key={player.uid}>
        <ul class="list-group list-group-light mb-4">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              {player && player.image && player.image[0] ?
              <img
                className="img-fluid"
                src={`${Configuration.apiEndPoint}/images/${player ? player.uid : profile.uid}?load=${player ? player.image[0] : profile.image[0]}`}
                alt={t('PROFILE_IMAGE')}
                style={{width: 45, height: 45, paddingLeft:0}}
                class="rounded-circle"
              />
              :
              <img
                className="img-fluid"
                src={defaultAvatar}
                alt={t('PROFILE_IMAGE')}
                style={{width: 45, height: 45, paddingLeft:0}}
                class="rounded-circle"
              />
              }
              <div class="ms-3">
                <p class="fw-bold mb-1" style={{marginLeft: 10}}>{player.name + " " + player.surname}</p>
                <p class="fw-bold mb-1" style={{marginLeft: 10}}>{player.nickname + (player.groupType ? " - " + player.groupType : "")}</p>
              </div>
            </div>
            <Form.Check required name="selectedPlayer" type="radio" onChange={() => setSelectedPlayer(player)}/>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={Object.assign({}, booking, {rtvOrAcademy, description, presetPlaces, startSlot, endSlot, otherPlayers, otherPlayersPrice, discount, rtvChannels})}
        onSubmit={booking.bookingId ? save : !field.recording || startSlot.minimumPlayers != null ? confirm : profile.privacy1 ? confirm : checkPrivacy}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          setFieldValue
        }) => {

          const playerNumberChange = args => {
            const num = parseInt(args.target.value, 10);
            values.otherPlayers = resizeArray(values.otherPlayers, num, '');
            setFieldValue('playersNumber', num);
          };
          
          const addInvitedPlayer = (player, inviteType) => {
            let playerGuests = values.invitedPlayers.filter(el => el.inviteType === 'guest')
            if(inviteType === 'guest' && (slotPrice() + ((slotPriceNoDiscount() + ((startSlot.costsPrice / 100) / startSlot.minimumPlayers)) * (values.invitedGuests.length + playerGuests.length + 2)) - slotPriceNoDiscount())>profile.user.wallet){
              showMessage(t('INSUFFICIENT_CREDIT'), t('INSUFFICIENT_CREDIT_GUEST'));
              return;
            }
            if(inviteType === 'partecipant' && !player.hasSufficientFunds){
              showMessage(t('CANNOT_INVITE_PARTECIPANT'), t('CANNOT_INVITE_PARTECIPANT_INFO',{name: player.name, surname: player.surname}));
              return;
            }
            player["inviteType"] = inviteType
            let alreadyInvited = false;
            values.invitedPlayers.forEach(p => {
              if(p.uid === player.uid) alreadyInvited = true;
            });
            if(!alreadyInvited) values.invitedPlayers.push(player);
            setSelectedPlayer({});
            values.playerName = "";
            values.playerSurname = "";
            values.playerNickname = "";
            hidePlayersModal();
          };

          const addInvitedGuest = (guest) => {
            var validEmailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if(guest.email && !guest.email.match(validEmailRegex)) {
              showMessage(t('EMAIL'), t('INVALID_EMAIL'));
              return;
            }

            if(guest.email === ""){
              guest.email = null
            }

            if(!guest.surname){
              showMessage(t('MANDATORY_FIELDS'), t('INSERT_GUEST_NAME_SURNAME'));
              return;
            }

            if(!guest.name){
              guest.name = ""
            }

            let playerGuests = values.invitedPlayers.filter(el => el.inviteType === 'guest')
            if(slotPrice() + ((slotPriceNoDiscount() + ((startSlot.costsPrice / 100) / startSlot.minimumPlayers)) * (values.invitedGuests.length + playerGuests.length + 2)) - slotPriceNoDiscount() > profile.user.wallet){
              showMessage(t('INSUFFICIENT_CREDIT'), t('INSUFFICIENT_CREDIT_GUEST'));
              return;
            }
           
            values.invitedGuests.push(guest);
            values.friendName = "";
            values.friendSurname = "";
            values.friendEmail = null;
            values.friendPhone = "";
            hidePlayersModal();
          };

          const onRemoveVideo = (message, fieldName, newValue) => {
            dialog.show({
              title: t('CONFIRM'),
              body: message,
              actions: [
                Dialog.Action(
                  t('OK'),
                  () => setFieldValue(fieldName, newValue),
                  'btn btn-secondary'
                ),
                Dialog.Action(
                  t('CANCEL'),
                  null,
                  'btn btn_red_outline_rp'
                )
              ],
              bsSize: 'small',
              onHide: dialog => {
                dialog.hide();
              }
            });
          };
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Label>{booking.bookingId ? resourceType === 'field' ? t('BOOKED_FIELD') : t('BOOKED_SERVICE') : resourceType === 'field' ? t('BOOKING_FIELD') : t('BOOKING_SERVICE')}: {field.name}, {field.sport || ""}</Form.Label>
              <Form.Group>
                <Form.Label>{partner.name}, {partner.city}</Form.Label>
              </Form.Group>
              <Form.Label>{t('BOOKING_DAY', {day: new Date(values.startSlot.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})})} {t('FROM_TO_TIME', {fromTime: new Date(values.startSlot.startDatetime).toLocaleTimeString(i18n.language).slice(0, 5), toTime: new Date(values.endSlot.endDatetime).toLocaleTimeString(i18n.language).slice(0, 5)})}</Form.Label>
              {
                slots && slots.length > 1 ?
                  <Form.Label>{t('BOOKING_MULTI_SLOT') }({slots.length})</Form.Label> :
                  null
              }
              {
                values.presetPlaces === 1 ?
                  <Fragment>
                    <Form.Group>
                      <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                      <Form.Control required name="description" as="textarea" maxLength={255} placeholder={resourceType === 'field' ? t('ENTER_MATCH_DESCRIPTION'): ''} value={values.description || ''} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{resourceType === 'field' ? t('BOOKING_PLAYERS_NUMBER') : t('BOOKING_USERS_NUMBER')}*</Form.Label>
                      <Form.Control required as="select" name="playersNumber" value={values.playersNumber || ''} onChange={playerNumberChange}>
                        <option value="">{t('BOOKING_PLAYERS_NUMBER_DESC')}</option>
                        {
                          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(nPlayer => (<option value={nPlayer} key={nPlayer}>{nPlayer}</option>))
                        } 
                      </Form.Control>
                    </Form.Group>
                    {
                      values.otherPlayers.map((others, index) => (
                        <Form.Group key={`player_${index}`}>
                          <Form.Label>{resourceType === 'field' ? t('BOOKING_PLAYERS_NUMBER_INDEX', {index: index + 1}) : t('BOOKING_USERS_NUMBER_INDEX', {index: index + 1})}</Form.Label>
                          <Form.Control name={`otherPlayers[${index}]`} type="text" maxLength={50} value={values.otherPlayers[index] || ''} onChange={handleChange}/>
                        </Form.Group>
                      ))
                    }
                    {
                      resourceType === 'field' ?
                        values.rtvChannels.length > 0 && field.rtv && resourceType ?
                        <Fragment>
                          <Form.Label>
                            {t('WHERE_PUBLISH')}
                          </Form.Label>
                          {
                            values.rtvChannels.map((c, index) => (
                              <Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label={`Rtv ${c.name}`}
                                  checked={c.canPublish}
                                  onChange={() => {
                                    values.rtvChannels[index].canPublish = !values.rtvChannels[index].canPublish;
                                    setFieldValue('rtvChannels', values.rtvChannels);                                
                                  }}/>                              
                              </Form.Group>
                            ))
                          }
                          <Form.Label>
                            {t('BOOKING_REPLAYER_TV_DESC')}
                          </Form.Label>
                        </Fragment> :
                          <Form.Group>
                            <Form.Label>
                              {t('BOOKING_VIDEO_STORE')}
                            </Form.Label>
                          </Form.Group>
                        : null
                    }
                  </Fragment>
                : 
                <Fragment>
                  {
                    values.presetPlaces > 1 && startSlot.minimumPlayers !== null ?
                      <Form.Group>
                        <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                        <Form.Control required name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                      </Form.Group>
                    :
                      <Form.Group>
                      <Form.Label>{t('DESCRIPTION')}: {values.description || ''} </Form.Label>
                      </Form.Group>
                  }
                  <Form.Group>
                    <Form.Label>{t('BOOKING_REPLAYER_TV_LIST', {list: values.rtvChannels.length > 0 ? Array.from(values.rtvChannels, x => x.name).join(', ') : " No"})}</Form.Label>
                  </Form.Group>
                </Fragment>
              }
              {
                field.payment && startSlot.minimumPlayers === null ?
                  <Form.Group>
                    <Form.Label className="font-weight-bold">{(resourceType === 'field' ? t('FIELD_PRICE', {price: slotPrice().toFixed(2)}) : t('SERVICE_PRICE', {price: slotPrice().toFixed(2)})) + (resourceType === 'field' && startSlot.costsPrice > 0 && values.presetPlaces === 1 ? t('SLOT_COST_PRICE', {price: (startSlot.costsPrice/100).toFixed(2)}) + ` (${startSlot.costs.map(c => c.name).join(', ')})` : "")} </Form.Label>
                  </Form.Group>
                : null
              }
              {
                field.unbookableHours ?
                <Form.Group>
                  <Form.Label className="font-weight-bold">{
                    new Date() < new Date(values.startSlot.startDatetime).setHours(new Date(values.startSlot.startDatetime).getHours() - field.unbookableHours) ?
                      t('BOOKING_CANCEL_LIMIT', {hours: Math.floor((new Date(values.startSlot.startDatetime).setHours(new Date(values.startSlot.startDatetime).getHours() - field.unbookableHours) - new Date())/(1000 * 60 * 60))})
                      : t('BOOKING_CANCEL_LIMIT_PASSED') }
                  </Form.Label>
                </Form.Group>
                : null
              }
              {
                resourceType === 'field' && presetPlaces > 1 && startSlot.minimumPlayers !== null && !booking.bookingId ?
                <Form.Group>
                    <Form.Label>{startSlot.minimumPlayers !== presetPlaces ? t('PLAYERS_MIN_MAX',{min: startSlot.minimumPlayers, max: presetPlaces}) : t('PLAYERS_MIN_EQUALS_MAX',{minmax: presetPlaces})}</Form.Label>
                    {renderPlayer(values.invitedPlayers, values.invitedGuests)}
                    {values.invitedPlayers.length + values.invitedGuests.length + 1 < presetPlaces ?
                    values.invitedGuests.length + values.invitedPlayers.length + 1 < startSlot.minimumPlayers ?
                    <Button variant="secondary" style={{marginBottom: 50}} onClick={showPlayersModal}>{t('INVITE_PLAYER_REQUIRED', {n: values.invitedGuests.length + values.invitedPlayers.length + 2})}</Button>
                    : <Button variant="secondary" style={{marginBottom: 50}} onClick={showPlayersModal}>{t('INVITE_PLAYER_NOT_REQUIRED', {n: values.invitedGuests.length + values.invitedPlayers.length + 2})}</Button>
                    : null}
                </Form.Group>
                : null
              }
              {
                resourceType === 'field' && presetPlaces > 1 && startSlot.minimumPlayers !== null && booking.bookingId && !isEditPlayersModalVisible ?
                <Form.Group>
                  {renderPlayerAfterBooking(booking.users)}
                  {booking.users.some(u => u.uid === profile.uid && u.inviteType === 0 ) ? 
                    <Button variant="secondary" onClick={showEditPlayersModal}>{t('EDIT_PLAYERS')}</Button> 
                  : null}
                </Form.Group>
                : null
              }
              {
                values.presetPlaces > 1 && booking && booking.bookingId ?
                  null
                : <Button disabled={resourceType === 'field' && presetPlaces > 1 && startSlot.minimumPlayers !== null && values.invitedGuests.length + values.invitedPlayers.length + 1 < startSlot.minimumPlayers} type="submit" variant="secondary" style={{marginBottom: 50}}>{t('BOOK')}</Button>
              }
              {isPlayersModalVisible ?
              <Modal show={isPlayersModalVisible} onHide={hidePlayersModal}>
                <Modal.Header closeButton>
                  <Modal.Title>{t('BOOKING_PLAYERS_NUMBER_INDEX', {index: (values.invitedGuests || []).length + (values.invitedPlayers || []).length + 2})}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Label>{t('CHOOSE_INVITE_TYPE')}</Form.Label>
                    <Form.Check required name="inviteType" type="radio" label={t('INVITE_GUEST')} checked={values.inviteType === 'guest'} onChange={() => setFieldValue('inviteType', 'guest')}/>
                    <Form.Text className='text-muted' style={{paddingLeft: 20}}>{t('INVITE_GUEST_INFO')}</Form.Text>
                    <Form.Check required name="inviteType" type="radio" label={t('INVITE_PARTECIPANT')} checked={values.inviteType === 'partecipant'} onChange={() => setFieldValue('inviteType', 'partecipant')}/>
                    <Form.Text className='text-muted' style={{paddingLeft: 20, paddingBottom: 30}}>{t('INVITE_PARTECIPANT_INFO')}</Form.Text>
                    {values.inviteType === 'guest' ? 
                    <Fragment>
                      <Form.Label>{t('CHOOSE_GUEST')}</Form.Label>
                      <Form.Check required name="guestType" type="radio" label={t('PLAYER_GUEST')} checked={values.guestType === 'player'} onChange={() => setFieldValue('guestType', 'player')}/>
                      <Form.Text className='text-muted' style={{paddingLeft: 20}}>{t('PLAYER_GUEST_INFO')}</Form.Text>
                      <Form.Check required name="guestType" type="radio" label={t('FRIEND_GUEST')} checked={values.guestType === 'friend'} onChange={() => setFieldValue('guestType', 'friend')}/>
                      <Form.Text className='text-muted' style={{paddingLeft: 20, paddingBottom: 30}}>{t('FRIEND_GUEST_INFO')}</Form.Text>
                    </Fragment>
                    : null }
                    {values.inviteType === 'partecipant' || (values.inviteType === 'guest' && values.guestType === 'player') ?
                      <Fragment>
                        <p>{t('THREE_CHAR_MINIMUM')}</p>
                        <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                          <Form.Control name="playerNickname" style={{maxWidth: 150}} placeholder={t('NICKNAME')} type="text" maxLength="25" minLength="3" value={values.playerNickname || ""} onChange={handleChange}/>
                          <Form.Control name="playerName" style={{maxWidth: 150}} placeholder={t('NAME')} type="text" maxLength="25" minLength="3" value={values.playerName || ""} onChange={handleChange}/>
                          <Form.Control name="playerSurname" style={{maxWidth: 150}} placeholder={t('SURNAME')} type="text" maxLength="25" minLength="3" value={values.playerSurname || ""} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
                          <Form.Check name="playerAffiliate" type="checkbox" label={t('PLAYER_AFFILIATE',{partner: partner.name})} checked={values.playerAffiliate === true} onChange={() => setFieldValue('playerAffiliate', !values.playerAffiliate)}/>
                          <Button disabled={!(values.playerNickname && values.playerNickname.length > 2 || values.playerName && values.playerName.length > 2 || values.playerSurname && values.playerSurname.length > 2)} variant="secondary" onClick={() => searchPlayers(values.playerNickname, values.playerName, values.playerSurname, values.playerAffiliate, slot.slotId, partner.uid)}>{t('SEARCH')}</Button>
                        </Form.Group>
                        <Form.Group style={{display: 'flex', flexDirection: 'column'}}>
                        {playersList.map(p => 
                          renderPlayerSearch(p)
                        )}
                        <Button style={{maxWidth: 100}}disabled={selectedPlayer.uid === undefined} variant="secondary" onClick={() => addInvitedPlayer(selectedPlayer, values.inviteType)}>{t('SELECT')}</Button>
                        </Form.Group>
                      </Fragment>
                    : values.guestType === 'friend' ?
                    <Fragment>
                      <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                        <Form.Control required name="friendName" placeholder={t('NAME')} style={{marginRight: 20}} type="text" maxLength="25" minLength="2" value={values.friendName || ""} onChange={handleChange}/>
                        <Form.Control required name="friendSurname" placeholder={t('SURNAME')} type="text" maxLength="25" minLength="2" value={values.friendSurname || ""} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group style={{display: 'flex', flexDirection: 'row'}} required>
                        <Form.Control required name="friendEmail" placeholder={t('EMAIL')} style={{marginRight: 20}} type="text" maxLength="30" minLength="5" value={values.friendEmail || ""} onChange={handleChange}/>
                        <Form.Control name="friendPhone" className='no-arrow' placeholder={t('PHONE')} type="number" maxLength="10" minLength="10" value={values.friendPhone || ""} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="button" style={{maxWidth: 100}} variant="secondary" onClick={() => addInvitedGuest({email: values.friendEmail, name: values.friendName, surname: values.friendSurname, phoneNumber: values.friendPhone})}>{t('SELECT')}</Button>
                    </Fragment>
                    : null}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={hidePlayersModal}>
                    {t('CLOSE_BUTTON')}
                  </Button>
                </Modal.Footer>
              </Modal>
              : null}
            </Form>
          );
        }}
      </Formik>
      <PlayerBookingEditModal 
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        slotPrice={slotPrice}
        slotPriceNoDiscount={slotPriceNoDiscount}
        startSlot={startSlot}
        presetPlaces={presetPlaces}
        renderPlayer={renderPlayer}
        partner={partner}
        renderPlayerSearch={renderPlayerSearch}
        booking={booking}
      />
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerBooking.propTypes = {
  booking: PropTypes.object,
  slot: PropTypes.object,
  field: PropTypes.object,
  partner: PropTypes.object,
  profile: PropTypes.object,
  save: PropTypes.func.isRequired,
  slots: PropTypes.array,
  subscriptions: PropTypes.array,
  isPlayersModalVisible: PropTypes.bool
};

PlayerBooking.defaultProps = {
  booking: null,
  slot: null,
  field: null,
  partner: null,
  profile: null,
  slots: null,
  subscriptions: [],
  isPlayersModalVisible: false,
  playersList: []
};

const mapStateToProps = ({login: {profile}, player: {subscriptions, isPlayersModalVisible, playersList, isEditPlayersModalVisible}}) => ({
  profile,
  subscriptions,
  isPlayersModalVisible,
  playersList,
  isEditPlayersModalVisible
});

const mapDispatchToProps = dispatch => ({
  showPlayersModal: () => {
    dispatch(showPlayersModal());
  },
  hidePlayersModal: () => {
    dispatch(hidePlayersModal());
    dispatch(cleanPlayersReceived());
  },
  searchPlayers: (playerNickname, playerName, playerSurname, playerAffiliate, slotId, partnerId) => {
    if (playerAffiliate === undefined) playerAffiliate = false;
    if (playerName) playerName = playerName.replace(/ /g,'_');
    if (playerSurname) playerSurname = playerSurname.replace(/ /g,'_');
    if (playerNickname) playerNickname = playerNickname.replace(/ /g,'_');
    const validRegex = /^[A-Za-z0-9_àèéìòù'-]*$/;
    if ((playerName && !playerName.match(validRegex)) || (playerSurname && !playerSurname.match(validRegex)) || (playerNickname && !playerNickname.match(validRegex)))
      dispatch(showMessage('ACCEPTED_CHARACTERS', "ACCEPTED_CHARACTERS_INFO"));
    else dispatch(searchPlayers(playerNickname, playerName, playerSurname, playerAffiliate, slotId, partnerId));
  },
  showMessage: (title, body) => {
    dispatch(showMessage(title,body));
  },
  showEditPlayersModal: () => {
    dispatch(showEditPlayersModal());
  },
  hideEditPlayersModal: () => {
    dispatch(hideEditPlayersModal());
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerBooking));
