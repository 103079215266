import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Col, Button, Fade, Container, Row, Form} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import {resetPassword} from '../actions/login';
import {useTranslation} from 'react-i18next';

const ResetPassword = ({match: {params: {token}}, resetPassword}) => {
  const {t, i18n} = useTranslation();
  return (
    <Formik
      initialValues={{password: null, passwordConfirm: null}}
      onSubmit={resetPassword(token)}
    >
      {({
        handleChange,
        handleSubmit,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4>{t('PASS_RECOVERY')}</h4>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('PASSWORD')}*</Form.Label>
                        <Form.Control required name="password" type="password" placeholder={t('PASSWORD')} value={values.password || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                        {t('PASSWORD')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('PASSWORD_CONFIRM')}*</Form.Label>
                        <Form.Control required name="passwordConfirm" type="password" placeholder={t('PASSWORD_CONFIRM')} value={values.passwordConfirm || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                        {t('PASSWORD_CONFIRM')}
                        </Form.Text>
                      </Form.Group>
                      <Button type="submit">{t('SEND')}</Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired
};

export default (connect(
  null,
  dispatch => ({
    resetPassword: token => data => {
      dispatch(resetPassword(data, token));
    }
  })
)(ResetPassword));

