import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Form, Modal, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import Configuration from '../config';
import Iframe from 'react-iframe';
import {saveHighlight, permanentVideoSelected, hideVideoHighlightModal} from '../actions/partner'
import {showMessage} from '../actions/messages';
import 'rc-slider/assets/index.css';
import {useTranslation} from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';

const PartnerHighLight = ({video, saveHighlight, showMessageCreatingHighlight, showMessageHighlightDurationError, hideVideoHighlightModal, isVideoHighlightModalVisible}) => {
  const {t, i18n} = useTranslation();

  const hmsToSeconds = val => {
    const p = val.split(':');
    let s = 0;
    let m = 1;

    while (p.length > 0) {
      s += m * parseInt(p.pop(), 10);
      m *= 60;
    }

    return s;
  };

  const onSubmit = data => {
    let iframeRef = document.getElementById("higlightIframe");
    if (!iframeRef) {
      return;
    }

    // controllo durata 
    const maxSeconds = 60 *3;
    if (data.end - data.start > maxSeconds) {
      showMessageHighlightDurationError();
      return;
    }

    showMessageCreatingHighlight();
    const tracking = [
      {
        seconds: data.start,
      },
      {
        seconds: data.end,
      }
    ]
    const videoData = {videoId: data.videoId, name: data.name, description: data.description, tracking, selectedCameras: data.selectedCameras};
    saveHighlight(videoData);
  };


  return (
    <Modal className="modal_rp" size="xl" contentClassName="bg_blue_rp" show={isVideoHighlightModalVisible} onHide={hideVideoHighlightModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('CREATE_HIGHLIGHT')}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{videoId: video.videoId, name: null, description: null, start: 0, startYaw: 0, startPitch: 0, startFov: 0, end: 0, endYaw: 0, endPitch: 0, endFov: 0, startStr: '00:00:00', endStr: '00:00:00', cameras: video.cameras, selectedCameras: video.cameras && video.cameras.length === 1 ? video.cameras : []}}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          setFieldValue
        }) => {

          const setStartFromSeekTo = () => {
            let iframeRef = document.getElementById("higlightIframe");
            if (!iframeRef) {
              return;
            }

            const startSeconds = iframeRef.contentWindow.krpano.get("plugin[video].time");
            if (startSeconds === null) {
              return;
            }

            setFieldValue('start', startSeconds);
            setFieldValue('startStr', new Date(startSeconds * 1000).toISOString().slice(11, 19));
          };

          const setEndFromSeekTo = () => {
            let iframeRef = document.getElementById("higlightIframe");
            if (iframeRef === null) {
              return;
            }

            const endSeconds = iframeRef.contentWindow.krpano.get("plugin[video].time");
            if (endSeconds === null) {
              return;
            }

            setFieldValue('end', endSeconds);
            setFieldValue('endStr', new Date(endSeconds * 1000).toISOString().slice(11, 19));
          };

          const setStart = ev => {
            let iframeRef = document.getElementById("higlightIframe");
            if (!iframeRef) {
              return;
            }

            const startSeconds = hmsToSeconds(ev.target.value);
            setFieldValue('start', startSeconds);
            setFieldValue('startStr', ev.target.value);
            iframeRef.contentWindow.krpano.set("plugin[video].time", startSeconds);
          };

          const setEnd = ev => {
            let iframeRef = document.getElementById("higlightIframe");
            if (iframeRef === null) {
              return;
            }

            const endSeconds = hmsToSeconds(ev.target.value);
            setFieldValue('end', endSeconds);
            setFieldValue('endStr', ev.target.value);
            iframeRef.contentWindow.krpano.set("plugin[video].time", endSeconds);
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <h6>{video.name}</h6>
                <div className="card_image_rp">
                  <div className="videoWrapper">
                    <Row>
                      <Iframe
                        id="higlightIframe"
                        url={`${Configuration.krpanoPlayerUrl}?slotId=${video.slotId}&videoId=${video.videoId}&token=${video.token}`}
                        allowFullScreen
                      />
                    </Row>
                  </div>
                </div>
                <p className="mt-3">
                {t('USE_SLIDER_TO_SCROLL')}
                </p>
                <div className="row mb-5">
                  <div className="input-group col-6">
                    <Form.Control required name="startStr" type="text" placeholder={t('START')} maxLength={8} value={values.startStr || '00:00:00'} onChange={setStart}/>
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button" onClick={setStartFromSeekTo}>{t('START')}</button>
                    </div>
                  </div>
                  <div className="input-group col-6">
                    <Form.Control required name="endStr" type="text" placeholder={t('END')} maxLength={8} value={values.endStr || '00:00:00'} onChange={setEnd}/>
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button" onClick={setEndFromSeekTo}>{t('END')}</button>
                    </div>
                  </div>
                </div>
                <Form.Group>
                  <Form.Label>{t('TITLE')}*</Form.Label>
                  <Form.Control required name="name" type="text" placeholder={t('TITLE')} maxLength={255} value={values.name || ''} onChange={handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                  <Form.Control required name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('CAMERAS')}</Form.Label>
                  <div style={{color: 'black'}}>
                    <MultiSelect
                      options={values.cameras}
                      value={values.selectedCameras}
                      onChange={value => setFieldValue('selectedCameras', value)}
                      labelledBy="SelectCamera"
                      overrideStrings={{
                        "allItemsAreSelected": t('ALL_CAMERAS'),
                        "search": t('SEARCH'),
                        "selectAll": t('SELECT_ALL'),
                        "selectSomeItems": t('CAMERA_SELECT'),
                        "selectAllFiltered": t('SELECT_ALL'),
                      }}
                      ClearSelectedIcon={null}
                    />
                    <input
                      name="hiddenRequired"
                      style={{ height: 0, width: 0, opacity: 0}}
                      value={values.selectedCameras.length ? "1" : ""}
                      required
                    />
                  </div>
                </Form.Group>
              </Modal.Body>
              <div className="modal-footer">
                <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('SAVE')}</button>
                <button type="button" className="btn btn_red_outline_rp" onClick={hideVideoHighlightModal}>{t('CANCEL')}</button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

PartnerHighLight.propTypes = {
  video: PropTypes.object.isRequired,
  saveHighlight: PropTypes.func.isRequired,
  showMessageCreatingHighlight: PropTypes.func.isRequired,
  showMessageHighlightDurationError: PropTypes.func.isRequired,
  hideVideoHighlightModal: PropTypes.func.isRequired,
  isVideoHighlightModalVisible: PropTypes.bool
};

PartnerHighLight.defaultProps = {
  isVideoHighlightModalVisible: false
};

const mapStateToProps = ({login: {token}, partner: {permanentVideos, selectedPermanentVideo, isVideoHighlightModalVisible}}) => ({
  token,
  permanentVideos,
  selectedPermanentVideo,
  isVideoHighlightModalVisible
});

const mapDispatchToProps = dispatch => ({
  saveHighlight: (videoData) => {
    if (videoData.tracking[0].seconds >= videoData.tracking[1].seconds) {
      dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_BAD_START_TIME'));
      return;
    }
    
    dispatch(saveHighlight(videoData));
  },
  showMessageCreatingHighlight: () => {
    dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_OPERATION_IN_PROGRESS'));
  },
  showMessageHighlightDurationError: () => {
    dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_TOO_LONG'));
  },
  hideVideoHighlightModal: () => {
    dispatch(hideVideoHighlightModal());
    dispatch(permanentVideoSelected(null));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerHighLight));