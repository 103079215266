/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import {Button, Modal, Form} from 'react-bootstrap';
import Iframe from 'react-iframe';
import {useTranslation} from 'react-i18next';
import {Formik, Field, FieldArray} from 'formik';
import {selectVideoForTaggingPartner, showUserVideoTagModal, newUserVideoTag, deleteUserVideoTags, userVideoTagSelected, getTaggedUserVideosPartner, showUserTagModal, exportHighlights, exportPlaylists, showCameraModal, hideCameraModal, highlightOrPlaylistSelected, setSelectedUserVideoTags, clearSelectedUserVideoTags} from '../actions/partner'
import Configuration from '../config';
import PartnerVideoTag from './partner-video-tag';
import { MultiSelect } from 'react-multi-select-component';

const PartnerVideoTagging = ({
  gotoAllTaggetVideo,
  selectedVideoForTagging,
  userTags,
  showUserVideoTagModal,
  userVideoTags,
  deleteUserVideoTags,
  editUserVideoTagModal,
  exportHighlights,
  exportPlaylists,
  showPlayerTags,
  isCameraModalVisible,
  showCameraModal,
  hideCameraModal,
  exportHighlightOrPlaylist,
  selectedUserVideoTags
}) => {
  const {t, i18n} = useTranslation();
  if (selectedVideoForTagging === null) {
    return;
  }

  const getStopTimeFromSeekTo = () => {
    const iframeRef = document.getElementById("videoTaggingIframe");
    if (!iframeRef) {
      return;
    }

    const stopTime = iframeRef.contentWindow.krpano.get("plugin[video].time");
    if (stopTime === null) {
      return 0;
    }

    return stopTime;
  };

  const onClickApplyTag = (videoId, userTag = null) => () => {
    let startTime = 0;
    let userTagId = null;
    const stopTime = Math.round(getStopTimeFromSeekTo());

    if (userTag) {
      userTagId = userTag.id;
      if (stopTime > userTag.duration) {
        startTime = stopTime - userTag.duration;
      }
    }

    showUserVideoTagModal(videoId, stopTime, userTagId, startTime);
  };

  const setMarker = seconds => () => {
    const iframeRef = document.getElementById("videoTaggingIframe");
    if (!iframeRef) {
      return;
    }

    iframeRef.contentWindow.krpano.set("plugin[video].time", seconds);
  };

  let dialog = null;
  const onRemove = (userVideoTags, videoId) => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('CONFIRM_DELETE_TAG'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteUserVideoTags(userVideoTags, videoId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const exportSelected = cameras => {
    exportHighlights(selectedUserVideoTags, selectedVideoForTagging.videoId, cameras);
    hideCameraModal();
  };

  const exportPlaylist = cameras => {
    exportPlaylists(selectedUserVideoTags, selectedVideoForTagging.videoId, cameras);
    hideCameraModal();
  };

  return (
    <div className="container pt-5">
      <div className="row mb-4">
        <div className="col-12">
          <div>
            <div className="d-flex" style={{flexDirection: 'row', flexWrap: 'wrap'}}>
              <div><h4 className="section_title_neg_rp">{t('VIDEO_TAGGING', {videoName: selectedVideoForTagging.name})}</h4></div>
              <div className="ml-auto d-xs-block"><Button variant="secondary" onClick={gotoAllTaggetVideo}>{t('ALL_TAGGED_VIDEO')}</Button></div>
            </div>
            <hr className="hr_blue"/>
            <div className="row">
              <div className="col-lg-8 mt-sm-2">
                <div className="videoWrapper">
                  <Iframe allowFullScreen id="videoTaggingIframe" url={`${Configuration.krpanoPlayerUrl}?slotId=${selectedVideoForTagging.slotId}&token=${selectedVideoForTagging.token}`}/>
                </div>
                <div className="d-flex">
                  <div className="ml-auto d-xs-block">
                    <Button variant="secondary" onClick={onClickApplyTag(selectedVideoForTagging.videoId)} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10}}>{t('TAG_APPLY')}</Button>
                    <Button variant="secondary" onClick={showPlayerTags} style={{marginTop: 20, marginBottom: 20, marginLeft: 10, marginRight: 10}}>{t('TAG_CREATE_OR_UPDATE')}</Button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-sm-2 d-none d-md-block">
                <h3 style={{margin: 10}}>
                  {userTags.map(t => <Button key={t.id} style={{fontFamily:"Arial", backgroundColor: t.color, borderColor: t.color, marginRight: 5}} onClick={onClickApplyTag(selectedVideoForTagging.videoId, t)}>{t.label}</Button>)}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt-sm-2">
                <div>
                  <Formik
                    enableReinitialize
                    initialValues={{userVideoTags, selectAll: false}}
                    onSubmit={values => console.log(values)}
                  >
                    {({values, setFieldValue}) => {
                      const handleSelectAll = ev => {
                        const target = !values.selectAll;
                        setFieldValue('selectAll', target);
                        values.userVideoTags.map((t, index) => setFieldValue(`userVideoTags.${index}.selected`, target));
                      };

                      return (
                        <Form>
                          <div className="row" style={{color: '#000'}}>
                            <div className="col-12">
                              <Field
                                name="selectAll"
                                type="checkbox"
                                style={{marginRight: 10}}
                                onChange={handleSelectAll}
                              />
                              {t('SELECT_ALL')}
                            </div>
                          </div>
                          <hr className="hr_blue"/>
                          <FieldArray name="userVideoTags">
                            {() => (
                              <div>
                                {values.userVideoTags.length > 0 &&
                                values.userVideoTags.map((userVideoTag, index) => (
                                  <Fragment key={index}>
                                    <div className="row" style={{color: '#000', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                      <div className="col-1">
                                        <Field
                                          name={`userVideoTags.${index}.selected`}
                                          type="checkbox"
                                        />
                                      </div>
                                      <div className="col-2">
                                        <Button onClick={setMarker(userVideoTag.startTime)}>{new Date(userVideoTag.startTime * 1000).toISOString().slice(11, 19)}</Button>
                                      </div>
                                      <div className="col-5">
                                        {userVideoTag.description}
                                      </div>
                                      <div className="col-4">
                                        <Button key={t.id} style={{whiteSpace: 'nowrap', backgroundColor: userVideoTag.userTag.color, borderColor: userVideoTag.userTag.color, marginRight: 5}} onClick={editUserVideoTagModal(userVideoTag)}>{userVideoTag.userTag.label}</Button>
                                      </div>
                                    </div>
                                    <hr className="hr_blue"/>
                                  </Fragment>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                          <Button disabled={!values.userVideoTags.some(t => t.selected)} variant="secondary" style={{marginRight: 10, marginTop: 10}} type="button" onClick={onRemove(values.userVideoTags, selectedVideoForTagging.videoId)}>{t('REMOVE_TAG')}</Button>
                          <Button disabled={!values.userVideoTags.some(t => t.selected)} variant="primary" style={{marginRight: 10, marginTop: 10}} type="button" onClick={() => showCameraModal("highlight", values.userVideoTags.filter(t => t.selected))}>{t('EXPORT_HIGHLIGHT')}</Button>
                          <Button disabled={!values.userVideoTags.some(t => t.selected)} type="button" style={{backgroundColor: '#00162f', borderColor: '#00162f', color: 'fff', marginTop: 10}} onClick={() => showCameraModal("playlist", values.userVideoTags.filter(t => t.selected))}>{t('EXPORT_PLAYLIST')}</Button>
                          <Dialog
                            ref={el => {
                              dialog = el;
                            }}/>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PartnerVideoTag/>
      <Modal show={isCameraModalVisible} onHide={hideCameraModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('CAMERAS')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('TAG_CHOOSE_CAMERAS')}</p>
          {
          <Formik
            initialValues={{selectedUserVideoTags, cameras: selectedVideoForTagging.cameras, selectedCameras: selectedVideoForTagging.cameras.length === 1 ? selectedVideoForTagging.cameras : []}}
            onSubmit={values => exportHighlightOrPlaylist === "highlight" ? exportSelected(values.selectedCameras) : exportPlaylist(values.selectedCameras)}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values
            }) => {

              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                  <Form.Label>{t('CAMERAS')}</Form.Label>
                    <div style={{color: 'black'}}>
                      <MultiSelect
                        options={values.cameras}
                        value={values.selectedCameras}
                        onChange={value => setFieldValue('selectedCameras', value)}
                        labelledBy="SelectCamera"
                        overrideStrings={{
                          "allItemsAreSelected": t('ALL_CAMERAS'),
                          "search": t('SEARCH'),
                          "selectAll": t('SELECT_ALL'),
                          "selectSomeItems": t('CAMERA_SELECT'),
                          "selectAllFiltered": t('SELECT_ALL'),
                        }}
                        ClearSelectedIcon={null}
                      />
                      <input
                        name="hiddenRequired"
                        style={{ height: 0, width: 0, opacity: 0}}
                        value={values.selectedCameras.length ? "1" : ""}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Button variant="secondary" type="submit">{t('SAVE')}</Button>
                </Form>
              );
            }}
          </Formik>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideCameraModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

PartnerVideoTagging.propTypes = {
  gotoAllTaggetVideo: PropTypes.func.isRequired,
  selectedVideoForTagging: PropTypes.object,
  userTags: PropTypes.array,
  showUserVideoTagModal: PropTypes.func.isRequired,
  userVideoTags: PropTypes.array,
  deleteUserVideoTags: PropTypes.func.isRequired,
  editUserVideoTagModal: PropTypes.func.isRequired,
  showPlayerTags: PropTypes.array.isRequired
};

PartnerVideoTagging.defaultProps = {
  selectedVideoForTagging: null,
  userTags: [],
  userVideoTags: []
};

const mapStateToProps = ({partner: {selectedVideoForTagging, userTags, userVideoTags, isCameraModalVisible, exportHighlightOrPlaylist, selectedUserVideoTags}}) => ({
  selectedVideoForTagging,
  userTags,
  userVideoTags,
  isCameraModalVisible,
  exportHighlightOrPlaylist,
  selectedUserVideoTags
});

const mapDispatchToProps = dispatch => ({
  gotoAllTaggetVideo: () => {
    dispatch(selectVideoForTaggingPartner(null));
    dispatch(getTaggedUserVideosPartner());
  },
  showUserVideoTagModal: (videoId, stopTime, userTagId = null, startTime = 0) => {
    dispatch(newUserVideoTag(videoId, stopTime, userTagId, startTime));
    dispatch(showUserVideoTagModal());
  },
  deleteUserVideoTags: (uservideoTags, videoId) => {
    dispatch(deleteUserVideoTags(uservideoTags.filter(t => t.selected), videoId));
  },
  editUserVideoTagModal: userVideoTag => () => {
    dispatch(userVideoTagSelected(userVideoTag));
    dispatch(showUserVideoTagModal());
  },
  exportHighlights: (uservideoTags, videoId, selectedCameras) => {
    for (const tag of uservideoTags) { 
      if(tag.description === null)
        tag.description = "---";
    }

    dispatch(exportHighlights(uservideoTags, videoId, selectedCameras));
  },
  exportPlaylists: (uservideoTags, videoId, selectedCameras) => {
    dispatch(exportPlaylists(uservideoTags, videoId, selectedCameras));
  },
  showPlayerTags: () => {
    dispatch(showUserTagModal());
  },
  showCameraModal: (highlightOrPlaylist, selectedUserVideoTags) => {
    dispatch(highlightOrPlaylistSelected(highlightOrPlaylist));
    dispatch(setSelectedUserVideoTags(selectedUserVideoTags));
    dispatch(showCameraModal());
  },
  hideCameraModal: () => {
    dispatch(highlightOrPlaylistSelected(null));
    dispatch(clearSelectedUserVideoTags());
    dispatch(hideCameraModal());
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerVideoTagging));
