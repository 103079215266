const defaultState = {
  token: null,
  profile: null,
  isRecoveryPasswordModalVisible: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESSFULL': {
      return {token: action.token, profile: action.profile};
    }

    case 'LOGOUT': {
      return defaultState;
    }

    case 'PROFILE_RECEIVED': {
      return Object.assign({}, {token: state.token}, {profile: action.profile});
    }

    case 'LOGIN_SHOW_RECOVERY_PASSWORD_MODAL': {
      return Object.assign({}, state, {isRecoveryPasswordModalVisible: true});
    }

    case 'LOGIN_HIDE_RECOVERY_PASSWORD_MODAL': {
      return Object.assign({}, state, {isRecoveryPasswordModalVisible: false});
    }

    default:
      return state;
  }
};
