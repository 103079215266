import React from 'react';
import {render} from 'react-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import Cookies from 'universal-cookie';
import rootReducer from './reducers';
import Root from './containers/root';
import {checkToken} from './actions/login';
import i18n from './locale/i18n';
import Configuration from './config';
import './css/bootstrap.css';
import './css/app.css';
import './css/style.css';
import './css/custom-fonts.css';

export const history = createBrowserHistory();

const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk
    )/*,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
  )
);

const cookies = new Cookies();
const auth = cookies.get('replayerAuth');
const replayerLang = cookies.get('replayerLang');
if (auth) {
  store.dispatch(checkToken(auth, window.location.href.toLowerCase().startsWith(Configuration.sharedVideoBaseUrl.toLowerCase())));
}

if (replayerLang) {
  i18n.changeLanguage(replayerLang);
}

render(<Root store={store} history={history}/>, document.getElementById('root'));
