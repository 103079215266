import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerServiceCalendar from '../components/partner-service-calendar';
import {showSlotModal, hideSlotModal, newOwnedFieldSlot, saveFieldSlot, ownedFieldSlotSelected, deleteOwnedFieldSlots, getOwnedFieldSlots} from '../actions/partner';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({partner: {ownedService, isSlotModalVisible, ownedFieldSlot, ownedFieldSlots}, login: {token, profile}}) => ({
  profile,
  ownedService,
  isSlotModalVisible,
  isLogged: token !== null,
  ownedFieldSlot,
  ownedFieldSlots
});

const mapDispatchToProps = dispatch => ({
  editSlot: ev => {
    if (ev.slot.bookingId) {
      dispatch(showMessage('CALENDAR', 'CALENDAR_CANNOT_MODIFY_BOOKED_EVENT'));
    } else {
      dispatch(ownedFieldSlotSelected(ev.slot));
      dispatch(showSlotModal());
    }
  },
  newSlot: serviceId => ev => {
    dispatch(newOwnedFieldSlot({serviceId, fieldId: null, startDatetime: ev.start.toISOString(), endDatetime: ev.end.toISOString(), weekDays: [], videoSellPermission: false, rtvOrAcademy: null, price: 0}));
    dispatch(showSlotModal());
  },
  hideSlotModal: () => {
    dispatch(hideSlotModal());
  },
  saveSlot: slot => {
    dispatch(saveFieldSlot(slot));
  },
  deleteSlot: slotData => {
    dispatch(deleteOwnedFieldSlots(slotData));
  },
  getSlotsByWeek: serviceId => targetDate => {
    dispatch(getOwnedFieldSlots(serviceId, false, 'service', targetDate));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerServiceCalendar));