const defaultState = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SHOW_MESSAGE': {
      return {title: action.title, body: action.body, options: action.options};
    }

    case 'HIDE_MESSAGE': {
      return defaultState;
    }

    default:
      return state;
  }
};
