import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, ToggleButtonGroup, ToggleButton, Form, Fade, Container, Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import CommonMetaTag from './common-meta-tag';
import {printReport} from '../actions/partner';
import r from '../images/title_r.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {SLOT_STATES} from '../constants';
import {useTranslation} from 'react-i18next';
import it from 'date-fns/locale/it';
registerLocale('it', it);

const PartnerBookingsReport = ({createReport, ownedFields, ownedServices}) => {
  const {t, i18n} = useTranslation();

  const setValueForField = (f, id) => {
    f('field', id);
    f('service', null);
  };

  const setValueForService = (f, id) => {
    f('field', null);
    f('service', id);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{start: null, end: null, field: ownedFields[0] ? ownedFields[0].id : null, service: null, bookingsType: SLOT_STATES.available, email: null, surname: null}}
      onSubmit={createReport}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <img
                      src={r}
                      height="30"
                      className="mx-auto d-block"
                      alt={t('LOGO')}
                    />
                    <Row className="justify-content-center" style={{marginTop: 20}}>
                      <h1>Replayer</h1>
                    </Row>
                    <Row className="justify-content-center">
                      <h4>{t('MOTTO')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <h4 style={{marginTop: 30}}>{t('BOOKING_REPORT')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <p style={{marginTop: 30, textAlign: 'center'}}>{t('USE_FOLLOWING_FILTERS')}</p>
                    </Row>
                    <Row className="justify-content-center">
                      <p style={{marginBottom: 30, textAlign: 'center', fontWeight: 'bold'}}>{t('REPORT_MAX_DAYS')}</p>
                    </Row>
                    <Form onSubmit={handleSubmit}>
                      <Form.Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>{t('START_DATE')}</Form.Label><br/>
                            <DatePicker
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              selected={(values.start && new Date(values.start)) || null}
                              onChange={val => setFieldValue('start', val ? new Date(val).toISOString() : null)}/>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>{t('END_DATE')}</Form.Label><br/>
                            <DatePicker
                              locale="it"
                              className="form-control"
                              dateFormat="dd/MM/yyyy"
                              selected={(values.end && new Date(values.end)) || null}
                              onChange={val => setFieldValue('end', val ? new Date(val.setHours(23, 59, 59)).toISOString() : null)}/>
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      {
                        [...ownedFields, ...ownedServices].map(f => (
                          <Form.Group key={f.id}>
                            <Form.Check required name="field" type="radio" label={f.name} checked={values.field === f.id || values.service === f.id} onChange={() => f.sport ? setValueForField(setFieldValue,f.id) : setValueForService(setFieldValue,f.id)}/>
                          </Form.Group>
                        ))
                      }
                      <Form.Group>
                        <Form.Label>{t('BOOKINGS_TYPE')}*</Form.Label>
                        <Form.Check required name="bookingsType" type="radio" label={t('BOOKED_SLOT')} checked={values.bookingsType === SLOT_STATES.available} onChange={() => setFieldValue('bookingsType', SLOT_STATES.available)}/>
                        <Form.Check required name="bookingsType" type="radio" label={t('RESERVED_SLOT')} checked={values.bookingsType === SLOT_STATES.reserved} onChange={() => setFieldValue('bookingsType', SLOT_STATES.reserved)}/>
                      </Form.Group>
                      {
                        values.bookingsType === SLOT_STATES.available ?
                          <Fragment>
                            <Form.Group>
                              <Form.Label>{t('BOOKING_EMAIL')}</Form.Label>
                              <Form.Control name="email" type="text" maxLength={255} placeholder={t('BOOKING_EMAIL')} value={values.email || ''} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('BOOKING_SURNAME')}</Form.Label>
                              <Form.Control name="surname" type="text" maxLength={255} placeholder={t('BOOKING_SURNAME')} value={values.surname || ''} onChange={handleChange}/>
                            </Form.Group>
                          </Fragment>
                        : null
                      }
                      <Button type="submit" variant="secondary" style={{marginTop: 50, marginBottom: 10}}>{t('CREATE_REPORT')}</Button>
                        <p style={{marginBottom: 30, textAlign: 'left'}}>{t('REPORT_WAITING')}</p>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

PartnerBookingsReport.propTypes = {
  createReport: PropTypes.func.isRequired,
  ownedFields: PropTypes.array.isRequired,
  ownedServices: PropTypes.array.isRequired
};

const mapStateToProps = ({partner: {ownedFields, ownedServices}}) => ({
  ownedFields,
  ownedServices
});

const mapDispatchToProps = dispatch => ({
  createReport: values => {
    dispatch(printReport(values));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerBookingsReport));
