import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Form, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import {hidePlayerNewSubscriptionModal, setSubscription} from '../actions/player';
import {subscriptionKind} from '../constants';
import {useTranslation} from 'react-i18next';

const PlayerHowPurchase = ({isPlayerNewSubscriptionModalVisible, hidePlayerNewSubscriptionModal, saveSubscription, selectedPartner, selectedRtvChannel}) => {
  const {t, i18n} = useTranslation();
  if (selectedPartner === null) {
    return null;
  }

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isPlayerNewSubscriptionModalVisible} onHide={hidePlayerNewSubscriptionModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('WATCH_RTV')}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{kind: selectedPartner ? 0 : 1, partnerId: selectedPartner ? selectedPartner.uid : null, channelId: selectedRtvChannel ? selectedRtvChannel.id : null}}
        onSubmit={saveSubscription}
      >
        {({
          handleSubmit,
          values,
          setFieldValue
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <h6 className="mt-3">
                {t('WELCOME_TO')}
                </h6>
                <label className="form-check-label">
                  <p className="lightblue_text">
                  {selectedRtvChannel && selectedRtvChannel.type === 1 ? t('WATCH_UPDATED_PARTNER_VIDEO_PROGRAMMING') : t('WATCH_UPDATED_VIDEO_PROGRAMMING')}
                  </p>
                </label>
                <label className="form-check-label">
                  <p className="lightblue_text">
                  {selectedRtvChannel && selectedRtvChannel.type === 1 ? t('CHOOSE_RIGHT_SUBSCRIPTION_PARTNER_VIDEO') : t('CHOOSE_RIGHT_SUBSCRIPTION') }
                  </p>
                </label>
                {
                  selectedPartner && selectedRtvChannel ?
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio1" checked={values.kind === subscriptionKind.rtvDedicated} onClick={() => setFieldValue('kind', subscriptionKind.rtvDedicated)}/>
                      <label className="form-check-label" htmlFor="GuardaReplayerTVradio1">
                        <span className="bold_title_rp">{selectedRtvChannel.type === 1 ? t('REPLAYER_TV_SUBSCRIPTIONS_PARTNER_VIDEO', {partner: selectedRtvChannel.name}) :t('REPLAYER_TV_SUBSCRIPTIONS', {partner: selectedRtvChannel.name})}</span>
                        <p className="lightblue_text">
                          {selectedRtvChannel.type === 1 ? t('REPLAYER_TV_SUBSCRIPTIONS_PARTNER_VIDEO_DESC', {channel: selectedRtvChannel.name}) : t('REPLAYER_TV_SUBSCRIPTIONS_DESC', {channel: selectedRtvChannel.name})}
                          <span className="bold_title_rp">{t('REPLAYER_TV_PRICE', {price: parseFloat(selectedRtvChannel.rtvPrice/100).toFixed(2)})}</span>
                        </p>
                      </label>
                    </div>
                  : null
                }
                {!selectedRtvChannel || selectedRtvChannel.type === 0 ?
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="GuardaReplayerTVradio" id="GuardaReplayerTVradio2" checked={values.kind === subscriptionKind.rtvUnlimited} onClick={() => setFieldValue('kind', subscriptionKind.rtvUnlimited)}/>
                  <label className="form-check-label" htmlFor="GuardaReplayerTVradio2">
                    <span className="bold_title_rp">{t('UNLIMITED_SUBSCRIPTION_RTV')}</span>
                    <p className="lightblue_text">
                      {t('DO_NOT_MISS_VIDEO')}
                      <span className="bold_title_rp"> {t('ALL_SPORTS_CENTERS_CHANNELS')} {t('REPLAYER_TV_PRICE_PROMO', {price: '7,99'})} </span><br/>
                    </p>
                  </label>
                </div>
                : null}
              </Modal.Body>
              <div className="modal-footer">
                <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('SUBSCRIBE')}</button>
                <button type="button" className="btn btn_red_outline_rp" onClick={hidePlayerNewSubscriptionModal}>{t('CANCEL')}</button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

PlayerHowPurchase.propTypes = {
  hidePlayerNewSubscriptionModal: PropTypes.func.isRequired,
  isPlayerNewSubscriptionModalVisible: PropTypes.bool,
  saveSubscription: PropTypes.func.isRequired,
  selectedPartner: PropTypes.object.isRequired,
  subscriptions: PropTypes.object,
  selectedRtvChannel: PropTypes.object.isRequired,
};

PlayerHowPurchase.defaultProps = {
  isPlayerNewSubscriptionModalVisible: false,
  subscriptions: null
};

const mapStateToProps = ({login: {token}, player: {isPlayerNewSubscriptionModalVisible, selectedPartner, subscriptions, selectedRtvChannel}}) => ({
  token,
  isPlayerNewSubscriptionModalVisible,
  selectedPartner,
  subscriptions,
  selectedRtvChannel
});

const mapDispatchToProps = dispatch => ({
  hidePlayerNewSubscriptionModal: () => {
    dispatch(hidePlayerNewSubscriptionModal());
  },
  saveSubscription: values => {
    dispatch(setSubscription(values.kind, values.partnerId, values.channelId));
    dispatch(hidePlayerNewSubscriptionModal());
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerHowPurchase));
