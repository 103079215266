import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Form, Fade, Container, Col, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import CommonMetaTag from './common-meta-tag';
import {reportPlayers} from '../actions/partner';
import r from '../images/title_r.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import it from 'date-fns/locale/it';
registerLocale('it', it);

const PartnerReportPlayers = ({createReport}) => {
  const {t, i18n} = useTranslation();

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
        <Container>
          <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <Col xs={12} sm={8} md={6}>
              <img
                src={r}
                height="30"
                className="mx-auto d-block"
                alt={t('LOGO')}
              />
              <Row className="justify-content-center" style={{marginTop: 20}}>
                <h1>{t('TITLE_REPLAYER')}</h1>
              </Row>
              <Row className="justify-content-center">
                <h4>{t('MOTTO')}</h4>
              </Row>
              <Row className="justify-content-center">
                <h4 style={{marginTop: 30}}>{t('LIST_PLAYER')}</h4>
              </Row>
              <Row className="justify-content-center">
                <p>{t('LIST_PLAYER_DOWNLOAD_PARTNER')}</p>
              </Row>
              <Row className="justify-content-center">
                <Button type="button" variant="secondary" style={{marginTop: 50, marginBottom: 50}} onClick={createReport}>{t('EXTRACT_XLS')}</Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

PartnerReportPlayers.propTypes = {
  createReport: PropTypes.func.isRequired
};

const mapStateToProps = () => ({
});
  
const mapDispatchToProps = dispatch => ({
    createReport:() => {
      dispatch(reportPlayers());
    }
});
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PartnerReportPlayers));