/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Row} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import r from '../images/title_r.svg';
import {reportAllPlayers} from '../actions/admin';
import {useTranslation} from 'react-i18next';

const AdminReportAllPlayers = ({
  token,
  generateReport
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
        <Container>
          <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <Col xs={12} sm={8} md={6}>
              <img
                src={r}
                height="30"
                className="mx-auto d-block"
                alt={t('LOGO')}
              />
              <Row className="justify-content-center" style={{marginTop: 20}}>
                <h1>{t('TITLE_REPLAYER')}</h1>
              </Row>
              <Row className="justify-content-center">
                <h4>{t('MOTTO')}</h4>
              </Row>
              <Row className="justify-content-center">
                <h4 style={{marginTop: 30}}>{t('LIST_PLAYER')}</h4>
              </Row>
              <Row className="justify-content-center">
                <p>{t('LIST_PLAYER_DOWNLOAD')}</p>
              </Row>
              <Row className="justify-content-center">
                <Button type="button" variant="secondary" style={{marginTop: 50, marginBottom: 50}} onClick={generateReport}>Scarica</Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

AdminReportAllPlayers.propTypes = {
  token: PropTypes.string,
  generateReport: PropTypes.func.isRequired
};

AdminReportAllPlayers.defaultProps = {
  token: null
};

const mapStateToProps = ({login: {token}}) => ({
  token
});

const mapDispatchToProps = dispatch => ({
  generateReport: () => {
    dispatch(reportAllPlayers());
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportAllPlayers));
