import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Fade, Container, Row, Col, Form} from 'react-bootstrap';
import Configuration from '../config';
import Dialog from 'react-bootstrap-dialog';
import DatePicker, {registerLocale} from 'react-datepicker';
import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {Formik} from 'formik';
import {Redirect} from 'react-router-dom';
import CommonMetaTag from './common-meta-tag';
import {imageTypes} from '../constants';
import {useTranslation} from 'react-i18next';
import {getOwnedEvents} from '../actions/partner';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImagePreview);

const PartnerEvent = ({
  ownedEvent,
  save,
  removeFile,
  token,
  deleteEvent,
  goto,
  ownedRtvChannels
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (ownedEvent === null) {
    return null;
  }

  const imageEvent = (ownedEvent.imageName || []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  let dialog = null;
  const onRemove = id => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('SPECIAL_EVENT_DELETE_CONFIRM'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteEvent(id),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={Object.assign({}, ownedEvent)}
      onSubmit={save}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100}}>
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={6}>
                    <Row>
                      <Col>
                        <h4>{values.id ? values.title : t('NEW_SPECIAL_EVENT')}</h4>
                      </Col>
                    </Row>
                    {
                      ownedEvent.id === null ?
                        null :
                        <Fragment>
                          <Form.Label>{t('SPECIAL_EVENT_IMAGE')}</Form.Label>
                          <FilePond
                            files={imageEvent}
                            server={
                              {
                                url: `${Configuration.apiEndPoint}/partners/`,
                                process: {
                                  url: `./events/${ownedEvent.id}/images`,
                                  ondata: formData => {
                                    formData.append('image_type', imageTypes.event);
                                    return formData;
                                  }
                                },
                                revert: `./events/${ownedEvent.id}/images`,
                                restore: `./events/${ownedEvent.id}/images`,
                                fetch: `./events/${ownedEvent.id}/images`,
                                load: `./events/${ownedEvent.id}/images?load=`,
                                headers: {Authorization: `Bearer ${token}`},
                                remove: (source, load) => {
                                  removeFile(source, ownedEvent.id);
                                  load();
                                }
                              }
                            }
                          />
                        </Fragment>
                    }
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('TITLE')}*</Form.Label>
                        <Form.Control required name="title" type="text" placeholder={t('TITLE')} maxLength="255" value={values.title || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('TITLE')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                        <Form.Control required name="description" type="text" placeholder={t('DESCRIPTION')} maxLength="255" value={values.description || ''} onChange={handleChange}/>
                        <Form.Text className="text-muted">
                          {t('DESCRIPTION')}
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('START_DATE')}*</Form.Label><br/>
                        <DatePicker
                          required
                          locale="it"
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          selected={(values.startDatetime && new Date(values.startDatetime)) || null}
                          onChange={val => setFieldValue('startDatetime', val ? new Date(val).toISOString() : null)}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('END_DATE')}*</Form.Label><br/>
                        <DatePicker
                          required
                          locale="it"
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          selected={(values.endDatetime && new Date(values.endDatetime)) || null}
                          onChange={val => setFieldValue('endDatetime', val ? new Date(val).toISOString() : null)}/>
                      </Form.Group>
                      <Form.Group>
                      <Form.Label>{t('RTV_EVENT_CHANNEL_ID')}*</Form.Label>
                        <Form.Control required as="select" name="rtvChannelId" value={values.rtvChannelId || ''} onChange={handleChange}>
                          <option value="">{t('RTV_EVENT_CHANNEL_ID')}</option>
                          {ownedRtvChannels.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                        </Form.Control>
                      </Form.Group>
                      <Button type="submit" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                      <Button type="button" variant="outline-primary" style={{marginBottom: 50, marginLeft: 10}} onClick={goto('partner-events', getOwnedEvents)}>{t('CANCEL')}</Button>
                      {
                        ownedEvent.id === null ?
                          null :
                          <Button type="button" variant="secondary" style={{marginBottom: 50, marginLeft: 10}} onClick={onRemove(ownedEvent.id)}>{t('DELETE')}</Button>
                      }
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
            <Dialog
              ref={el => {
                dialog = el;
              }}/>
          </Fragment>
        );
      }}
    </Formik>
  );
};

PartnerEvent.propTypes = {
  ownedEvent: PropTypes.object,
  save: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  token: PropTypes.string,
  goto: PropTypes.func.isRequired,
  ownedRtvChannels: PropTypes.array
};

PartnerEvent.defaultProps = {
  ownedEvent: null,
  token: null,
  ownedRtvChannels: []
};

export default PartnerEvent;
