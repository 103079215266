import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {RiDeleteBinLine} from 'react-icons/ri';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Form, Modal, Button, Col, Accordion} from 'react-bootstrap';
import {Formik} from 'formik';
import Dialog from 'react-bootstrap-dialog';
import {saveGroup, hidePartnerGroupModal, removePlayerFromGroup, getGroupPlayers, removeGroup, createGroupPlayerSubscription, acceptPlayerinGroup} from '../actions/partner';
import {groupTypes, subscriptionKind} from '../constants';
import {useTranslation} from 'react-i18next';
import checkIcon from '../images/membership_check_24.svg';

const PartnerGroup = ({
  profile,
  selectedGroup,
  saveGroup,
  hidePartnerGroupModal,
  isPartnerGroupModalVisible,
  selectedGroupPlayers,
  removePlayerFromGroup,
  searchPlayers,
  removeGroup,
  ownedRtvChannels,
  createGroupPlayerSubscription,
  acceptRequest
}) => {
  const {t, i18n} = useTranslation();
  const keyToShow = profile.isSpecialist ? ["premium"] : ["soci", "maestri", "coach", "tesserati", "member", "under16", "socio_padel"];
  const selectableGroupType = Object.keys(groupTypes).filter(key => !profile.groups.map(g => g.groupType).includes(groupTypes[key]) && key !== 'rtv' && keyToShow.includes(key));
  const selectableRtvChannel = ownedRtvChannels.filter(c => !c.blocked && !profile.groups.map(g => g.rtvChannelId).includes(c.id)).map(c => "RTV " + c.name);

  let dialog = null;
  const onRemovePlayer = (groupId, playerId, accepted) => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('CONFIRM_DELETE_PLAYER_FROM_GROUP'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => removePlayerFromGroup(groupId, playerId, accepted),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const onRemoveGroup = groupId => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('CONFIRM_DELETE_GROUP'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => removeGroup(groupId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const onCreateGroupPlayerSubscription = (playerName, data) => () => {
    const rtvChannel = ownedRtvChannels.find(c => c.id === data.rtvChannel);
    dialog.show({
      title: t('CONFIRM'),
      body: t('CONFIRM_CREATE_GROUP_PLAYER_SUBSCRIPTION', {playerName, channelName: rtvChannel.name, price: rtvChannel.rtvPriceMembership}),
      actions: [
        Dialog.Action(
          t('CONFIRM'),
          () => createGroupPlayerSubscription(data),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isPartnerGroupModalVisible} onHide={hidePartnerGroupModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{selectedGroup && selectedGroup.groupId ? t('MEMBERSHIP_ADMINISTRATION') : t('MEMBERSHIP_ADD')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={selectedGroup}
          onSubmit={values => {
            if (String(values.groupType).startsWith("RTV ")) {
              const rtvChannelId = ownedRtvChannels.find(c => c.name === values.groupType.substring(4)).id;
              values.rtvChannelId = rtvChannelId
              values.groupType = groupTypes.rtv;
            }
            if(values.groupType === groupTypes.rtv){
              values.visibilityCalendar = profile.defaultVisibilityCalendar;
            }
            if (values.discount === '' || values.discount === null || values.discount === undefined) {
              values.discount = 0;
            }
            saveGroup(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {
                  selectedGroup.groupId ?
                    <h6>{
                      selectedGroup.rtvChannelId ?
                      "RTV " + ownedRtvChannels.find(c => c.id === selectedGroup.rtvChannelId).name :
                      Object.keys(groupTypes).find(key => groupTypes[key] === selectedGroup.groupType).toUpperCase().replace(/_/g, ' ')
                    }</h6> :
                    <Form.Group>
                      <Form.Label>{t('MEMBERSHIP')}*</Form.Label>
                      <Form.Control required as="select" name="groupType" value={values.groupType || ''} onChange={handleChange}>
                        <option value="">{t('MEMBERSHIP_TYPE')}</option>
                        {selectableGroupType.map(gt => <option key={gt} value={groupTypes[gt]}>{gt.toUpperCase().replace(/_/g, ' ')}</option>)}
                        {selectableRtvChannel.map(c => <option key={c} value={c}>{c}</option>)}
                      </Form.Control>
                    </Form.Group>
                }
                {
                  Number(values.groupType) !== groupTypes.coach && Number(values.groupType) !== groupTypes.rtv && !String(values.groupType).startsWith("RTV ") ?
                    <Form.Group>
                      <Form.Label>{t('MEMBERSHIP_VISIBILITY_CALENDAR')}*</Form.Label>
                      <Form.Control required name="visibilityCalendar" type="number" min="0" max="90" step="1" value={values.visibilityCalendar} onChange={handleChange}/>
                    </Form.Group> :
                    null
                }
                {
                  Number(values.groupType) !== groupTypes.rtv && !String(values.groupType).startsWith("RTV ") ?
                  <Form.Group>
                    <Form.Label>{t('DISCOUNT')}</Form.Label>
                    <Form.Control name="discount" type="number" min="0" max="100" step="1" placeholder={t('DISCOUNT')} value={values.discount} onChange={handleChange}/>
                  </Form.Group> :
                  null
                }
                <Form.Group>
                  <Form.Label>{t('DESCRIPTION')}</Form.Label>
                  <Form.Control name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                </Form.Group>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('SAVE')}</button>
                  <button type="button" className="btn btn_red_outline_rp" onClick={hidePartnerGroupModal}>{t('CANCEL')}</button>
                  {values.groupId != null ? 
                    <button type="button" className="btn btn-danger" onClick={onRemoveGroup(selectedGroup.groupId)}>{t('DELETE')}</button> 
                  : null}
                </div>
              </Form>
            );
          }}
        </Formik>
        <Fragment>
          <h6>{t('MEMBERSHIP_PLAYERS')}</h6>
          <Accordion>
            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: '#DD004A'}}>
              {t('FILTERS')}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Formik
                enableReinitialize
                initialValues={{name: null, surname: null}}
                onSubmit={values => searchPlayers(selectedGroup.groupId, values.name, values.surname)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Row>
                        <Col xs="12" md="6">
                          <Form.Group>
                            <Form.Label>{t('NAME')}</Form.Label>
                            <Form.Control name="name" type="text" value={values.name || ''} onChange={handleChange}/>
                          </Form.Group>
                        </Col>
                        <Col xs="12" md="6">
                          <Form.Group>
                            <Form.Label>{t('SURNAME')}</Form.Label>
                            <Form.Control name="surname" type="text" value={values.surname || ''} onChange={handleChange}/>
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <div className="modal-footer">
                        <button type="submit" className="btn btn-secondary" data-dismiss="modal">{t('SEARCH')}</button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Accordion.Collapse>
          </Accordion>
          {selectedGroupPlayers.map(p => (
            <p key={p.uid} style={p.subscriptionExpired ? {color: '#999'} : {}}>
              <Button variant="outline-secondary" style={{marginRight: 10}} onClick={onRemovePlayer(selectedGroup.groupId, p.uid, p.accepted)}>
                <RiDeleteBinLine size="1.5em"/>
              </Button>
              {!p.accepted ?
              <Button variant="outline-primary" style={{marginRight: 10}} onClick={acceptRequest(selectedGroup.groupId, p.uid)}>
                <img src={checkIcon} alt={t('CHECK_ICON')} style={{width: '1.5em', height: '1.5em'}}/>
              </Button> : null}{p.surname} {p.name} ({p.email})
              {
                p.subscriptionExpired ? 
                <Button variant="primary" size="sm" onClick={onCreateGroupPlayerSubscription(`${p.name} ${p.surname}`, {groupId: selectedGroup.groupId, playerId: p.uid, kind: subscriptionKind.rtvDedicated, rtvChannel: selectedGroup.rtvChannelId})}><small>{t('ACTIVATE_SUBSCRIPTION')}</small></Button> :
                null
              }
            </p>))}
        </Fragment>
      </Modal.Body>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Modal>
  );
};

PartnerGroup.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  saveGroup: PropTypes.func.isRequired,
  hidePartnerGroupModal: PropTypes.func.isRequired,
  isPartnerGroupModalVisible: PropTypes.bool,
  selectedGroupPlayers: PropTypes.array,
  removePlayerFromGroup: PropTypes.func.isRequired,
  searchPlayers: PropTypes.func.isRequired,
  removeGroup: PropTypes.func.isRequired,
  ownedRtvChannels: PropTypes.array,
  createGroupPlayerSubscription: PropTypes.func.isRequired,
};

PartnerGroup.defaultProps = {
  isPartnerGroupModalVisible: false,
  selectedGroupPlayers: [],
  ownedRtvChannels: [],
};

const mapStateToProps = ({login: {profile}, partner: {isPartnerGroupModalVisible, selectedGroup, selectedGroupPlayers, ownedRtvChannels}}) => ({
  profile,
  isPartnerGroupModalVisible,
  selectedGroup,
  selectedGroupPlayers,
  ownedRtvChannels
});

const mapDispatchToProps = dispatch => ({
  saveGroup: group => {
    dispatch(saveGroup(group));
  },
  hidePartnerGroupModal: () => {
    dispatch(hidePartnerGroupModal());
  },
  removePlayerFromGroup: (groupId, playerId, accepted) => {
    dispatch(removePlayerFromGroup(groupId, playerId, accepted));
  },
  searchPlayers: (groupId, name, surname) => {
    dispatch(getGroupPlayers(groupId, name, surname));
  },
  removeGroup: groupId => {
    dispatch(removeGroup(groupId));
  },
  createGroupPlayerSubscription: data => {
    dispatch(createGroupPlayerSubscription(data));
  },
  acceptRequest: (groupId, playerId) => () => {
    dispatch(acceptPlayerinGroup(groupId, playerId));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerGroup));
