import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Button, Fade, Modal, Row} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import CommonMetaTag from './common-meta-tag';
import ServiceSlot from './service-slot';
import {calendarMessages} from '../constants';
import {useTranslation} from 'react-i18next';

const ServiceCalendar = ({profile, ownedService, isLogged, ownedFieldSlots, editSlot, newSlot, isSlotModalVisible, hideSlotModal, ownedFieldSlot, saveSlot, deleteSlot, getSlotsByWeek}) => {
  const {t, i18n} = useTranslation();
  if (!isLogged) {
    return (
      <Redirect to="/login"/>
    );
  }

  const localizer = momentLocalizer(moment);
  const events = ownedFieldSlots.map(slot => {
    return {
      id: slot.slotId,
      title: slot.state === 'reserved' ? `${slot.videoSellPermission ? '[video]' : ''} ${slot.description}` : slot.state === 'unavailable' ? `${slot.description}` : slot.bookingId ? '' : slot.presetPlaces > 1 ? t('EVENT_DESCRIPTION_PLACES', {description: slot.description, places: slot.availablePlaces}) : slot.price > 0 ? t('EVENT_DESCRIPTION', {price: slot.price}) : t('AVAILABLE'),
      start: new Date(slot.startDatetime),
      end: new Date(slot.endDatetime),
      slot
    };
  });

  let dialog = null;
  const onRemove = values => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('EVENT_DELETE_CONFIRM'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteSlot(values),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const onUpload = id => () => {
    window.location.href = "/upload?slot_id=" + id;
  };

  const minTime = new Date();
  minTime.setHours(7, 0, 0);
  const maxTime = new Date();
  maxTime.setHours(23, 58, 59);

  const eventStyleGetter = event => {
    const backgroundColor = event.slot.state === 'reserved' ? event.slot.color ? event.slot.color : '#ADB5BD' : event.slot.state === "unavailable" ? '#444444' : event.slot.bookingId ? '#3598DB' : '#31BC8C';
    const color = '#fff';
    const style = {
      backgroundColor,
      borderRadius: '5px',
      opacity: 1,
      color,
      border: '1px',
      borderStyle: 'solid',
      borderColor: '#00152F',
      display: 'block'
    };
    return {
      style
    };
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 100}}>
        <Row className="justify-content-center">
          <h4 className="text-center" style={{width: '100%'}}>{ownedService.name} : {t('PLAN_CALENDAR')}</h4>
          <h6 className="text-left" style={{width: '80%'}}>{ownedService.bookingPolicy}</h6>
          <Calendar
            selectable
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="week"
            min={minTime}
            max={maxTime}
            events={events}
            step={15}
            timeslots={4}
            views={['day', 'week']}
            style={{minHeight: 600}}
            eventPropGetter={eventStyleGetter}
            culture={i18n.language ===  'en' ? 'en-GB' : i18n.language}
            messages={i18n.language === 'it' ? calendarMessages : null}
            onSelectEvent={editSlot}
            onSelectSlot={newSlot(ownedService.id)}
            onNavigate={getSlotsByWeek(ownedService.id)}
          />
        </Row>
      </Fade>
      <Modal show={isSlotModalVisible} onHide={hideSlotModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('EVENT')} {ownedFieldSlot === null || ownedFieldSlot.id === null ? "("+t('NEW')+")" : "("+t('CHANGE')+")"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ServiceSlot slot={ownedFieldSlot} save={saveSlot} remove={values => onRemove(values)}/>
        </Modal.Body>
        <Modal.Footer>
          {/*
            ownedFieldSlot === null || ownedFieldSlot.id === null || ownedFieldSlot.state === 'unavailable' ?
              null :
              <Button variant="warning" onClick={onUpload(ownedFieldSlot.slotId)}>
                {t('VIDEO_UPLOAD')}
              </Button>
        */}
          <Button variant="primary" style={{marginLeft: 10}} onClick={hideSlotModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

ServiceCalendar.propTypes = {
  isLogged: PropTypes.bool,
  profile: PropTypes.object,
  ownedService: PropTypes.object,
  ownedFieldSlots: PropTypes.array,
  ownedFieldSlot: PropTypes.object,
  isSlotModalVisible: PropTypes.bool,
  editSlot: PropTypes.func.isRequired,
  newSlot: PropTypes.func.isRequired,
  hideSlotModal: PropTypes.func.isRequired,
  saveSlot: PropTypes.func.isRequired,
  deleteSlot: PropTypes.func.isRequired,
  getSlotsByWeek: PropTypes.func.isRequired
};

ServiceCalendar.defaultProps = {
  isLogged: false,
  ownedService: null,
  profile: null,
  ownedFieldSlots: [],
  ownedFieldSlot: null,
  isSlotModalVisible: false
};

export default ServiceCalendar;