import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerEvent from '../components/partner-event';
import {saveOwnedEvent, deletePartnerEvent, deleteEventImageFile} from '../actions/partner';
import {push} from 'connected-react-router';

const mapStateToProps = ({partner: {ownedEvent, ownedRtvChannels}, login: {token}}) => ({
  ownedEvent,
  ownedRtvChannels,
  token
});

const mapDispatchToProps = dispatch => ({
  save: eventData => {
    dispatch(saveOwnedEvent(eventData));
  },
  deleteEvent: eventId => {
    dispatch(deletePartnerEvent(eventId));
  },
  removeFile: (fileName, eventId) => {
    dispatch(deleteEventImageFile(fileName, eventId));
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerEvent));
