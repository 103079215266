/* eslint complexity: ["error", 40] */

import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Carousel, Container, Fade, Form} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import {
  partnerSelected,
  getPartnerFields,
  getPartnerServices,
  getPartnerGroups,
  getPlayerGroups,
  filterBookingFieldId,
  getSlots,
  addPartnerToFavourite,
  removePartnerFromFavourite,
  showPlayerNewSubscriptionModal,
  getRtvVideos,
  filterBookableFields,
  getPartnerEvents,
  showPlayerChooseRtvChannelModal,
  rtvChannelSelected
} from '../actions/player';
import {useTranslation} from 'react-i18next';
import Configuration from '../config';
import restaurant from '../images/ristorante.svg';
import bar from '../images/bar_icon.svg';
import accessibility from '../images/disabled_icon.svg';
import showers from '../images/shower_icon.svg';
import shop from '../images/negozio.svg';
import parking from '../images/parking_icon.svg';
import relax from '../images/relax_icon.svg';
import petFriendly from '../images/pets_icon.svg';
import lockerRooms from '../images/locker_room_icon.svg';
import tournaments from '../images/tournaments_icon.svg';
import picnic from '../images/picnic_area_icon.svg';
import camIcon from '../images/cam_icon.svg';
import replayerTvIcon from '../images/replayer_tv_icon.svg';
import PlayerNewSubscription from './player-new-subscription';
import PlayerInsufficientFundsSubscription from './player-insufficient-funds';
import {subscriptionKind, subscriptionStatuses} from '../constants';
import PlayerChooseRtvChannel from './player-choose-rtv-channel';

const PlayerShowSportSpecialists = ({
  token,
  partners,
  showPartner,
  showCalendar,
  toggleFavourite,
  favouritePartners,
  subscriptions,
  chooseReplayerTV,
  gotoReplayerTvHome
}) => {
  const {t} = useTranslation();

  const [partnerFilter, setPartnerFilter] = useState("");

  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const isFavourite = uid => favouritePartners.some(p => p.uid === uid);

  const renderEmptyPageString = () => {
    let channelNumber = 0;
    partners.forEach(p => {
      if(p.videoStore && (partnerFilter === "" || p.name.toLowerCase().indexOf(partnerFilter.toLowerCase()) > -1)){
        channelNumber++;
      }
    });
    if (channelNumber === 0) {
      return <h6>{t('SEARCH_PARTNER_OR_PLAYER_RESULT_FAILED')}</h6>;
    }
    return null;
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp_sport_specialist" data-parallax="true"/>
          <div className="main main_raised_rp">
            <div className="section" style={{paddingBottom: '500px'}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <h4 className="section_title_neg_rp">{"SPORT SPECIALISTS"}</h4>
                    <div style={{paddingBottom: 20, paddingTop: 20}}>
                      <Form>
                        <Form.Group>
                          <Form.Control name="partnerFilter" style={{maxWidth: 250}} placeholder={t('SEARCH_PARTNER')} type="text" maxLength="50" onChange={e => setPartnerFilter(e.target.value)} onKeyPress={event => {if (event.key === 'Enter') {event.preventDefault();}}}/>
                        </Form.Group>
                      </Form>
                      {renderEmptyPageString()}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {
                    partners.filter(p => p.isSpecialist).map(p =>
                      (
                        partnerFilter === "" || p.name.toLowerCase().indexOf(partnerFilter.toLowerCase()) > -1 ?
                        <div key={p.uid} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                          <div className="card sportspecialist_card_bg_rp">
                              {
                                p.imagesNames.length === 0 ?
                                  <div className="card_image_rp">
                                    <div className="videoWrapper card_image_sportcenter_rp"/>
                                  </div> :
                                  <Carousel style={{marginBottom: 10}} interval={null}>
                                    {
                                      p.imagesNames.map(i =>
                                        (
                                          <Carousel.Item key={i}>
                                            <img
                                              className="d-block w-100"
                                              src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${i}`}
                                              alt={t('FIRST_SLIDE')}
                                              style={{width: '100%'}}
                                            />
                                          </Carousel.Item>
                                        )
                                      )
                                    }
                                  </Carousel>
                              }
                            <div className="card-body d-flex flex-column">
                              <h5 className="card-title">{p.name}</h5>
                              {
                                isFavourite(p.uid) ?
                                  <div className="favorites_card_heart_btn_active" onClick={toggleFavourite(p.uid, true)}/> :
                                  <div className="favorites_card_heart_btn" onClick={toggleFavourite(p.uid, false)}/>
                              }
                              <ul className="list-unstyled">
                                <li className="list_location_pointer">{p.city}</li>
                                <li className="font-italic">{p.description}</li>
                              </ul>
                              <ul className="card_facilities_list_icons">
                                {
                                  p.facilities && p.facilities.parking ?
                                    <li className="card_facilities_list_icons_item"><img src={parking} alt={t('ICON_PARK')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.lockerRooms ?
                                    <li className="card_facilities_list_icons_item"><img src={lockerRooms} alt={t('ICON_LOCKER_ROOMS')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.showers ?
                                    <li className="card_facilities_list_icons_item"><img src={showers} alt={t('ICON_SHOWER')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.restaurant ?
                                    <li className="card_facilities_list_icons_item"><img src={restaurant} alt={t('ICON_RESTAURANTS')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.bar ?
                                    <li className="card_facilities_list_icons_item"><img src={bar} alt={t('ICON_BAR')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.shop ?
                                    <li className="card_facilities_list_icons_item"><img src={shop} alt={t('ICON_SHOP')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.picnic ?
                                    <li className="card_facilities_list_icons_item"><img src={picnic} alt={t('ICON_PIC_NIC')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.tournaments ?
                                    <li className="card_facilities_list_icons_item"><img src={tournaments} alt={t('ICON_TOURNAMENTS')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.petFriendly ?
                                    <li className="card_facilities_list_icons_item"><img src={petFriendly} alt={t('ICON_ANIMAL')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.accessibility ?
                                    <li className="card_facilities_list_icons_item"><img src={accessibility} alt={t('ICON_DISABLED')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.relaxArea ?
                                    <li className="card_facilities_list_icons_item"><img src={relax} alt={t('RELAX_AREA')}/></li> :
                                    null
                                }
                              </ul>
                            </div>
                            {
                              p.recording ?
                                <div className="d-flex favorites_card_badge mb-3">
                                  <img src={camIcon} className="favorites_card_badge_icon" alt={t('ICON_FAVORITES')}/>
                                  <p>{t('HD_VIDEO_SERVICE')}</p>
                                  {
                                    p.rtvChannels.length > 0 ?
                                      <a href="#" className="btn btn_red_rp ml-auto" onClick={chooseReplayerTV(p)}><img src={replayerTvIcon}/></a> :
                                      null
                                  }
                                </div> :
                                null
                            }
                            <div className="text-center mt-auto mb-3">
                              <p>{t('CALENDAR_INFO')}</p>
                              {
                                p.bookableServices ?
                                  <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showCalendar(p, null, true)}>{t('BOOK')}</a> :
                                  null
                              }
                              {
                                p.notBookableServices ?
                                  <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showCalendar(p, null, false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zm0-12H5V5h14v2zm-2 5h-5v5h5v-5z"></path></svg> 
                                    {t('CALENDAR')}</a> :
                                  null
                              }
                              <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showPartner(p)}>{t('CENTER_DETAILS')}</a>
                            </div>
                          </div>
                        </div>
                      : null)
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <PlayerNewSubscription/>
          <PlayerInsufficientFundsSubscription/>
          <PlayerChooseRtvChannel/>
        </Container>
      </Fade>
    </Fragment>
  );
};

PlayerShowSportSpecialists.propTypes = {
  token: PropTypes.string,
  partners: PropTypes.array,
  favouritePartners: PropTypes.array,
  showPartner: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  toggleFavourite: PropTypes.func.isRequired,
  subscriptions: PropTypes.object,
  chooseReplayerTV: PropTypes.func.isRequired,
  gotoReplayerTvHome: PropTypes.func.isRequired
};

PlayerShowSportSpecialists.defaultProps = {
  token: null,
  partners: [],
  favouritePartners: [],
  subscriptions: null
};

const mapStateToProps = ({login: {token, profile}, message, loading, player: {partners, favouritePartners, subscriptions}}) => ({
  token,
  profile,
  message,
  loading,
  partners,
  favouritePartners,
  subscriptions
});

const mapDispatchToProps = dispatch => ({
  showPartner: partner => () => {
    dispatch(partnerSelected(partner));
    dispatch(getPartnerFields(partner.uid));
    dispatch(getPartnerServices(partner.uid));
    dispatch(getPartnerEvents(partner.uid));
    dispatch(getPartnerGroups(partner.uid));
    dispatch(getPlayerGroups());
    dispatch(push('show-partner-specialist'));
  },
  showCalendar: (partner, fieldId = null, filterBookable = false) => () => {
    dispatch(partnerSelected(partner));
    dispatch(getPartnerFields(partner.uid));
    dispatch(getPartnerServices(partner.uid));
    dispatch(filterBookingFieldId(fieldId));
    dispatch(filterBookableFields(filterBookable));
    dispatch(getSlots(partner.uid, fieldId));
    dispatch(push('player-calendars'));
  },
  chooseReplayerTV: (partner) => () => {
    dispatch(rtvChannelSelected(null));
    dispatch(partnerSelected(partner));
    dispatch(showPlayerChooseRtvChannelModal());
  },
  gotoReplayerTvHome: () => {
    dispatch(push('player-replayer-tv-home'));
  },
  toggleFavourite: (uid, isFavourite) => () => {
    if (isFavourite) {
      dispatch(removePartnerFromFavourite(uid));
    } else {
      dispatch(addPartnerToFavourite(uid));
    }
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerShowSportSpecialists));