import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button, Form, Modal} from 'react-bootstrap';
import {BsPencil, BsPlusCircle} from 'react-icons/bs';
import {Formik} from 'formik';
import Dialog from 'react-bootstrap-dialog';
import {hideUserTagModal, newUserTag, userTagSelected, saveUserTag, deleteUserTag} from '../actions/player';
import {useTranslation} from 'react-i18next';

const PlayerTags = ({isUserTagModalVisible, hideUserTagModal, selectedUserTag, newUserTag, saveUserTag, cancelSelection, userTags, selectUserTag, deleteTag, selectedVideoForTagging}) => {
  const {t} = useTranslation();

  let dialog = null;
  const onRemove = id => () => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('TAG_CONFIRM_DELETE'),
      actions: [
        Dialog.Action(
          t('YES'),
          () => deleteTag(id),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isUserTagModalVisible} onHide={hideUserTagModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('TAG_CREATE_OR_UPDATE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-4">
          <div className="col-12">
            <div>
              <div className="d-flex">
                <div className="ml-auto d-xs-block"><Button variant="secondary" onClick={newUserTag}><BsPlusCircle style={{marginRight: 5}}/>{t('TAG_CREATE')}</Button></div>
              </div>
              {
                selectedUserTag ?
                  <Formik
                    enableReinitialize
                    initialValues={selectedUserTag}
                    onSubmit={values => saveUserTag(values, selectedVideoForTagging ? selectedVideoForTagging.videoId : null)}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values
                    }) => {
                      return (
                        <div className="card-body d-flex flex-column">
                          <Form onSubmit={handleSubmit}>
                            <Form.Group>
                              <Form.Label>{t('TAG_LABEL')}*</Form.Label>
                              <Form.Control required name="label" type="text" placeholder={t('TAG_LABEL')} maxLength={20} value={values.label || ''} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('TAG_DURATION')}*</Form.Label>
                              <Form.Control required name="duration" type="number" placeholder={t('TAG_DURATION')} max={180} min={1} value={values.duration || ''} onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>{t('TAG_COLOR')}*</Form.Label>
                              <Form.Control
                                required
                                name="color"
                                type="color"
                                defaultValue={values.color}
                                value={values.color}
                                title={t('TAG_COLOR')}
                                onChange={handleChange}
                              />
                            </Form.Group>
                            <div className="d-flex flex-row-reverse">
                              {
                                selectedUserTag && selectedUserTag.id ?
                                  <Button className="btn btn_red_outline_rp ml-1" onClick={onRemove(selectedUserTag.id)}>{t('DELETE')}</Button> :
                                  null
                              }
                              <Button className="btn btn-primary ml-1" onClick={cancelSelection}>{t('CANCEL')}</Button>
                              <Button className="btn btn-secondary ml-1 mr-1" type="submit">{t('SAVE')}</Button>
                            </div>
                          </Form>
                        </div>
                      );
                    }}
                  </Formik> :
                  null
              }
              <hr className="hr_blue"/>
              <div className="row">
                <div className="col-lg-12 mt-sm-2">
                  <h3>
                    {userTags.map(t => <Button key={t.id} style={{fontFamily:"Arial", backgroundColor: t.color, borderColor: t.color, marginRight: 5}} onClick={selectUserTag(t)}><BsPencil style={{marginRight: 5}}/>{t.label}</Button>)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={hideUserTagModal}>{t('CLOSE_BUTTON')}</button>
      </div>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Modal>
  );
};

PlayerTags.propTypes = {
  hideUserTagModal: PropTypes.func.isRequired,
  newUserTag: PropTypes.func.isRequired,
  saveUserTag: PropTypes.func.isRequired,
  cancelSelection: PropTypes.func.isRequired,
  selectUserTag: PropTypes.func.isRequired,
  isUserTagModalVisible: PropTypes.bool,
  selectedUserTag: PropTypes.object,
  userTags: PropTypes.array,
  deleteTag: PropTypes.func.isRequired
};

PlayerTags.defaultProps = {
  isUserTagModalVisible: false,
  selectedUserTag: null,
  userTags: []
};

const mapStateToProps = ({login: {token}, player: {isUserTagModalVisible, selectedUserTag, newUserTag, userTags, selectedVideoForTagging}}) => ({
  token,
  isUserTagModalVisible,
  selectedUserTag,
  newUserTag,
  userTags,
  selectedVideoForTagging
});

const mapDispatchToProps = dispatch => ({
  hideUserTagModal: () => {
    dispatch(hideUserTagModal());
  },
  newUserTag: () => {
    dispatch(newUserTag());
  },
  saveUserTag: (values, videoId) => {
    dispatch(saveUserTag(values, videoId));
    dispatch(userTagSelected(null));
  },
  selectUserTag: userTag => () => {
    dispatch(userTagSelected(userTag));
  },
  cancelSelection: () => {
    dispatch(userTagSelected(null));
  },
  deleteTag: id => {
    dispatch(deleteUserTag(id));
    dispatch(userTagSelected(null));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerTags));
