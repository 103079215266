/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import {createPartner} from '../actions/admin';
import {useTranslation} from 'react-i18next';

const AdminCreatePartner = ({
  token,
  createPartner
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Formik
      initialValues={{businessName: null, IVA: null, email: null, password: null, passwordConfirm: null, registeredOffice: null, sdiPec: null}}
      onSubmit={createPartner}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4 style={{marginTop: 30}}>{t('CREATE_PARTNER')}</h4>
                    <p>{t('CREATE_PARTNER_DESCRIPTION')}</p>
                    <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>{t('PARTNER_TYPE')}</Form.Label>
                        <Form.Check required name="isSpecialist" type="radio" label={t('SPORTS_CENTER')} checked={values.isSpecialist === false} onChange={() => setFieldValue('isSpecialist', false)}/>
                        <Form.Check required name="isSpecialist" type="radio" label="Sport Specialist" checked={values.isSpecialist === true} onChange={() => setFieldValue('isSpecialist', true)}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('PARTNER_NAME')}</Form.Label>
                        <Form.Control required name="businessName" placeholder={t('PARTNER_NAME')} type="text" maxLength="255" value={values.businessName || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('REGISTERED_OFFICE')}</Form.Label>
                        <Form.Control required name="registeredOffice" placeholder={t('REGISTERED_OFFICE')} type="text" maxLength="255" value={values.registeredOffice || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('VAT_NUMBER')}</Form.Label>
                        <Form.Control required name="IVA" placeholder={t('VAT_NUMBER')} type="text" maxLength="20" value={values.IVA || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('SDI_PEC')}</Form.Label>
                        <Form.Text>{t('SDI_PEC_INFO')}</Form.Text>
                        <Form.Control required name="sdiPec" placeholder={t('SDI_PEC')} type="text" maxLength="255" value={values.sdiPec || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('EMAIL')}</Form.Label>
                        <Form.Control required name="email" placeholder={t('EMAIL')} type="text" maxLength="255" value={values.email || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('PASSWORD')}</Form.Label>
                        <Form.Control required name="password" placeholder={t('PASSWORD')} type="password" maxLength="255" value={values.password || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('PASSWORD_CONFIRM')}</Form.Label>
                        <Form.Control required name="passwordConfirm" placeholder={t('PASSWORD_CONFIRM')} type="password" maxLength="255" value={values.passwordConfirm || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="submit">{t('CREATE_USER')}</Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

AdminCreatePartner.propTypes = {
  token: PropTypes.string,
  createPartner: PropTypes.func.isRequired
};

AdminCreatePartner.defaultProps = {
  token: null
};

const mapStateToProps = ({login: {token}}) => ({
  token
});

const mapDispatchToProps = dispatch => ({
  createPartner: partner => {
    dispatch(createPartner(partner));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCreatePartner));
