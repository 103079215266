import React, {Fragment} from 'react';
import { Formik, Field, ErrorMessage, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import {withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Carousel, Form } from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';
import Configuration from '../config';
import {groupTypes, rtvType} from '../constants';
import {saveRtvChannels, deleteRtvChannel} from '../actions/admin';

const AdimPartnerRtvChannels = ({partner, rtvChannels, saveRtvChannels, deleteRtvChannel}) => {
  const {t, i18n} = useTranslation();
  const rtvGroup = rtvChannelId => partner.groups.find(g => g.groupType === groupTypes.rtv && g.rtvChannelId === rtvChannelId);

  let dialog = null;

  const onRemoveRtvChannel = rtvChannel => {
    dialog.show({
      title: t('DELETE_RTV_CHANNEL', {name: rtvChannel.name}),
      body: t('CONFIRM_DELETE_RTV_CHANNEL'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteRtvChannel(rtvChannel.id),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const rtvTypes = {
    replayerVideo: 0,
    partnerVideo: 1
  };

  return (    
      <Formik
        enableReinitialize
        initialValues={{rtvChannels}}
        onSubmit={saveRtvChannels}
      >
        {({
          handleSubmit,
          handleChange,
          values
        }) => (
            <Form onSubmit={handleSubmit}>               
              <FieldArray name="rtvChannels">
                {({ insert, remove, push }) => (
                  <Fragment>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => push({uid: partner.uid, name: '', rtvPrice: 0, rtvKeepVideos: 0, rtvPriceMembership: 0, rtvPriceEvent: 0, blocked: false, type: rtvTypes["replayerVideo"]})}
                    >
                      {t("ADD_RTV_CHANNEL")}
                    </Button>
                    <Button
                      disabled={values.rtvChannels.filter(c => c.type === rtvTypes["partnerVideo"]).length > 0}
                      type="button"
                      style={{marginLeft: 10}}
                      variant="primary"
                      onClick={() => push({uid: partner.uid, name: '', rtvPrice: 0, rtvKeepVideos: 0, rtvPriceMembership: 0, rtvPriceEvent: 0, blocked: false, type: rtvTypes["partnerVideo"]})}
                    >
                      {t("ADD_RTV_CHANNEL_PARTNER_VIDEO")}
                    </Button>
                    <div className="row" style={{ minHeight: 400, marginTop: 20 }}>
                      {values.rtvChannels.length > 0 &&
                        values.rtvChannels.map((rtvChannel, index) => (                    
                            <div key={rtvChannel.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                              <div className={rtvChannel.type === rtvTypes.partnerVideo ?  "card video_partner_card_bg_rp" : "card sportcenter_card_bg_rp"}>
                                <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                                  <Form.Group>
                                    <Form.Label>{t('NAME')}</Form.Label>
                                    <Form.Control required name={`rtvChannels.${index}.name`} placeholder={t('NAME')} type="text" maxLength={50} value={rtvChannel.name} onChange={handleChange}/>
                                  </Form.Group>
                                </div>
                                <div className="card-body d-flex flex-column card_border_bottom_white mb-3">
                                  <Form.Group>
                                    <Form.Label>{t('REPLAYER_TV_PRICE_DESC')}</Form.Label>
                                    <Form.Control name={`rtvChannels.${index}.rtvPrice`} placeholder={t('REPLAYER_TV_PRICE_DESC')} type="number" min={0} max={100} step={0.01} value={rtvChannel.rtvPrice} onChange={handleChange}/>
                                  </Form.Group>
                                  <Form.Group>                                    
                                    <Form.Label>{t('REPLAYER_TV_PRICE_MEMBERSHIP')}</Form.Label>
                                    <Form.Control name={`rtvChannels.${index}.rtvPriceMembership`} placeholder={t('REPLAYER_TV_PRICE_MEMBERSHIP')} type="number" min={0} max={100} step={0.01} value={rtvChannel.rtvPriceMembership} onChange={handleChange}/>
                                    {
                                      rtvGroup(rtvChannel.id) ?
                                      <Form.Text className="text-muted color-green">{t('REPLAYER_TV_PRICE_MEMBERSHIP_ACTIVE')}</Form.Text>
                                      :                                        
                                        <Form.Text className="text-muted color-red">{t('REPLAYER_TV_PRICE_MEMBERSHIP_INACTIVE')}</Form.Text>
                                    }
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Label>{t('REPLAYER_TV_PRICE_SPECIAL_EVENT')}</Form.Label>
                                    <Form.Control name={`rtvChannels.${index}.rtvPriceEvent`} placeholder={t('REPLAYER_TV_PRICE_SPECIAL_EVENT')} type="number" min={0} max={100} step={0.01} value={rtvChannel.rtvPriceEvent} onChange={handleChange}/>
                                  </Form.Group>                                  
                                  <Form.Group>
                                    <Form.Check
                                      type="checkbox"
                                      name={`rtvChannels.${index}.blocked`}
                                      label={t("RTV_BLOCKED")}
                                      checked={rtvChannel.blocked}
                                      onChange={handleChange}/>
                                  </Form.Group>
                                  <div className="row" style={{ margin: 0 }}>
                                    <Button type="submit" style={{marginRight: 10}}>{t("SAVE")}</Button>
                                    {
                                      rtvChannel.id ?
                                        <Button
                                          type="button"
                                          variant="secondary"
                                          onClick={() => onRemoveRtvChannel(rtvChannel)}
                                        >
                                          {t('DELETE')}
                                        </Button> :
                                        <Button
                                          type="button"
                                          variant="secondary"
                                          onClick={() => remove(index)}
                                        >
                                          {t('DELETE')}
                                        </Button>
                                    }
                                  </div>
                                </div>                                
                              </div>
                            </div>
                        ))}
                    </div>
                  </Fragment>
                )}
              </FieldArray>
              <Dialog
              ref={el => {
                dialog = el;
              }}/>
            </Form>
        )}
      </Formik>
    
  )
};

AdimPartnerRtvChannels.propTypes = {
  partner: PropTypes.object,
  token: PropTypes.string,
  rtvChannels: PropTypes.array,
  saveRtvChannels: PropTypes.func.isRequired,
  deleteRtvChannel: PropTypes.func.isRequired,
};

AdimPartnerRtvChannels.defaultProps = {
  partner: null,
  token: null,
  rtvChannels: [],
};

const mapStateToProps = ({login: {token}, admin: {selectedPartner, selectedPartnerRtvChannels}}) => ({
  token,
  partner: selectedPartner,  
  rtvChannels: selectedPartnerRtvChannels
});

const mapDispatchToProps = dispatch => ({
  saveRtvChannels: values => {
    dispatch(saveRtvChannels(values.rtvChannels));
  },
  deleteRtvChannel: rtvChannelId => {
    dispatch(deleteRtvChannel(rtvChannelId));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdimPartnerRtvChannels));