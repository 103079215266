/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Container, Fade, Tabs, Tab} from 'react-bootstrap';
import {push} from 'connected-react-router';
import Dialog from 'react-bootstrap-dialog';
import CommonMetaTag from './common-meta-tag';
import {purchaseVideo, deleteBooking, deleteServiceBooking, archiveBooking} from '../actions/player';
import {useTranslation} from 'react-i18next';

const PlayerBookings = ({
  token,
  profile,
  partners,
  bookings,
  deleteBooking,
  archiveBooking,
  purchaseVideo,
  goto
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  let dialog = null;
  const confirmPurchase = (slots, price) => () => {
    dialog.show({
      title: t('CONFIRM_PURCHASE_TITLE'),
      body: t('CONFIRM_PURCHASE_BODY', {price}),
      actions: [
        Dialog.Action(
          t('YES'),
          () => purchaseVideo(slots),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const deleteBookingDialog = b => () => {
    dialog.show({
      title: t('BOOKING_DELETE'),
      body: b.groupBooking ? t('BOOKING_DELETE_CONFIRM_GROUP') : t('BOOKING_DELETE_CONFIRM'),
      actions: [
        Dialog.Action(
          t('CONFIRM_DELETE'),
          () => deleteBooking(b.groupBooking || !b.multiBooking ? b.bookingId : b.users.filter(u => u.uid === profile.uid)[0].id, b.partnerId, b.sport === undefined, !b.groupBooking && b.multiBooking, partners.find(p => p.uid === b.partnerId).phoneNumber),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('DO_NOT_DELETE'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const confirmArchiveBooking = bookingId => () => {
    dialog.show({
      title: t('CONFIRM_ARCHIVE_TITLE'),
      body: t('CONFIRM_ARCHIVE_DESC'),
      actions: [
        Dialog.Action(
          t('ARCHIVE_BUTTON'),
          () => archiveBooking(bookingId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const getPurchaseStatusText = b => {
    if (b.videoPurchasable && !b.videoBought && !b.videoReady) {
      return t('VIDEO_STATUS_PURCHASABLE', {price: b.videoPrice});
    }

    if (b.videoBought && !b.videoReady) {
      return t('VIDEO_STATUS_PROCESSING');
    }

    if (b.videoBought && b.videoReady) {
      return t('VIDEO_STATUS_DOWNLOADABLE');
    }

    return '';
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12">
                    <div><h4 className="section_title_neg_rp">{t('BOOKINGS')}</h4></div>
                    <Tabs defaultActiveKey={'next_bookings'} className="myvideo-tabs" style={{marginTop: 20}}>
                      <Tab eventKey="next_bookings" title={t('NEXT')} tabClassName="myvideo-tab">
                        <div className="row" style={{marginTop: 64}}>
                          {
                            bookings.filter(b => new Date(b.startDatetime) > new Date()).length != 0 ?
                            bookings.filter(b => new Date(b.startDatetime) > new Date()).map(b =>
                            (
                              <div key={b.bookingId} className="col-md-12 col-lg-6 mb-sm-4 card-group">
                                <div className="card reservation_bg_rp">
                                  <div className="card-horizontal">
                                    <div className="card_image_rp col-sm-3 col-lg-4 pl-0 pr-0">
                                      <div className="card_image_overlay_rp">
                                        <div className="card_image_icon_overlay_rp"/>
                                      </div>
                                    </div>
                                    <div className="card-body d-flex flex-column col-sm-9 col-lg-8">
                                      <h5 className="card-title">{b.partnerName}</h5>
                                      <ul className="list-unstyled">
                                        <li>{b.fieldName || b.serviceName}</li>
                                        <li>{new Date(b.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'})}</li>
                                        <li>{t('FROM_TO_TIME', {fromTime: new Date(b.startDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'}), toTime: new Date(b.endDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'})})}</li>
                                        <li className="font-italic">{getPurchaseStatusText(b)}</li>
                                      </ul>
                                      <div className="mt-auto mb-2 reservation_buttons_rp">
                                        {
                                          b.videoPurchasable && !b.videoBought ?
                                          <a href="#" className="btn btn_red_rp" onClick={confirmPurchase(b.slots, b.videoPrice)}>{t('PURCHASE_VIDEO_BUTTON')}</a> :
                                          null
                                        }
                                        <a href="#" className="btn btn_red_outline_rp ml-0 ml-sm-3" onClick={deleteBookingDialog(b)}>{t('BOOKING_DELETE')}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>))
                              :
                              <div className="col-md-12 col-lg-6 mb-sm-4 card-group">
                                <div className="card empty_slot reservation_bg_rp">
                                  <div className="card-horizontal">
                                    <div className="card_image_rp col-sm-3 col-lg-4 pl-0 pr-0">
                                      <div className="card_image_overlay_rp">
                                        <div className="card_image_icon_overlay_rp"/>
                                      </div>
                                    </div>
                                    <div className="card-body d-flex flex-column col-sm-9 col-lg-8">
                                      <h5 className="card-title">{t('PLAYER_HOME_NO_RESERVATION_TITLE')}</h5>
                                      <ul className="list-unstyled">
                                        <li>{t('PLAYER_HOME_NO_RESERVATION_DESC')}</li>
                                      </ul>
                                      <div className="mt-auto mb-2 reservation_buttons_rp">
                                        <a href="#" className="btn btn_red_rp" onClick={goto('player-show-sport-centers')}>{t('SPORT_CENTERS')}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          }
                        </div>
                      </Tab>
                      <Tab eventKey="past_bookings" title={t('PAST')} tabClassName="myvideo-tab">
                        <p style={{marginTop: 20}}>{t('BOOKINGS_INFO')}</p>
                        <div className="row">
                          {bookings.filter(b => new Date(b.startDatetime) <= new Date()).sort((a, b) => new Date(b.startDatetime) - new Date(a.startDatetime)).map(b =>
                            (
                              <div key={b.bookingId} className="col-md-12 col-lg-6 mb-sm-4 card-group">
                                <div className="card reservation_bg_rp">
                                  <div className="card-horizontal">
                                    <div className="card_image_rp col-sm-3 col-lg-4 pl-0 pr-0">
                                      <div className="card_image_overlay_rp">
                                        <div className="card_image_icon_overlay_rp"/>
                                      </div>
                                    </div>
                                    <div className="card-body d-flex flex-column col-sm-9 col-lg-8">
                                      <h5 className="card-title">{b.partnerName}</h5>
                                      <ul className="list-unstyled">
                                        <li>{b.fieldName || b.serviceName}</li>
                                        <li>{new Date(b.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'})}</li>
                                        <li>{t('FROM_TO_TIME', {fromTime: new Date(b.startDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'}), toTime: new Date(b.endDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'})})}</li>
                                        <li className="font-italic">{getPurchaseStatusText(b)}</li>
                                      </ul>
                                      <div className="mt-auto mb-2 reservation_buttons_rp">
                                        {
                                          b.videoPurchasable && !b.videoBought ?
                                          <a href="#" className="btn btn_red_rp" onClick={confirmPurchase(b.slots, b.videoPrice)}>{t('PURCHASE_VIDEO_BUTTON')}</a> :
                                          null
                                        }
                                        <a href="#" className="btn btn_red_outline_rp ml-0 ml-sm-3" onClick={confirmArchiveBooking(b.bookingId)}>{t('ARCHIVE_BUTTON')}</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerBookings.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object.isRequired,
  goto: PropTypes.func.isRequired,
};

PlayerBookings.defaultProps = {
  token: null,
};

const mapStateToProps = ({login: {token, profile}, player: {bookings, partners}}) => ({
  token,
  profile,
  bookings,
  partners
});

const mapDispatchToProps = dispatch => ({
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  deleteBooking: (bookingId, partnerId, isService, multiBooking, phoneNumber) => {
    if(isService) dispatch(deleteServiceBooking(bookingId, partnerId, multiBooking, phoneNumber));
    else dispatch(deleteBooking(bookingId, partnerId, multiBooking, phoneNumber));
  },
  archiveBooking: bookingId => {
    dispatch(archiveBooking(bookingId));
  },
  purchaseVideo: slots => {
    dispatch(purchaseVideo(slots));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerBookings));