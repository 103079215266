import React, {Fragment} from 'react';
import Iframe from 'react-iframe'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Fade, Container, Row, Button, Modal} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Cookies from 'universal-cookie';
import videoIcon from '../images/icona_r_video.png';
import {getSharedVideo, hideLoginModal} from '../actions/player';
import Configuration from '../config';
import ReplayerCommunitySportivi from '../images/Replayer_community_sportivi.jpg';
import {useTranslation, Trans} from 'react-i18next';
import {push} from 'connected-react-router';

let firstAccess = true;
let isAuthorized = false;
const cookies = new Cookies();
const auth = cookies.get('replayerAuth');

const VideoSharing = ({match: {params: {sharedId}}, getSharedVideo, sharedVideo, token, isLoginModalVisible, hideLoginModal, goto}) => {

  const {t, i18n} = useTranslation();
  if (firstAccess && !auth) {
    getSharedVideo(sharedId);
    firstAccess = false;
  }

  if (token && !isAuthorized) {
    getSharedVideo(sharedId);
    isAuthorized = true;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{`Video Replayer: ${sharedVideo ? sharedVideo.name : ''}`}</title>
        <meta name="description" content={`Guarda il video! ${sharedVideo ? sharedVideo.description : ''}`}/>
        <meta property="og:site_name" content="Replayer"/>
        <meta property="og:title" content={`Video Replayer: ${sharedVideo ? sharedVideo.name : ''}`}/>
        <meta property="og:description" content={`Guarda il video! ${sharedVideo ? sharedVideo.description : ''}`}/>
        <meta property="og:image" content={`${Configuration.baseUrl}${ReplayerCommunitySportivi}`}/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta property="og:url" content={`${Configuration.sharedVideoBaseUrl}/${sharedId}`}/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="og:type" content="video.other"/>
        <meta property="og:video:url" content={`${Configuration.sharedVideoBaseUrl}/${sharedId}`}/>
        <meta property="og:video:secure_url" content={`${Configuration.sharedVideoBaseUrl}/${sharedId}`}/>
        <meta property="og:video:type" content="text/html"/>
        <meta property="og:video:width" content="1280"/>
        <meta property="og:video:height" content="720"/>
      </Helmet>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section" style={{paddingBottom: 200}}>
              <div className="container">
                <div className="row" style={{marginTop: 100}}>
                  <div className="col-md-12 ml-auto mr-auto">
                    <h3 className="text-center section_title_neg_rp">{t('WELCOME_VIDEO_SHARING')}</h3>
                  </div>
                </div>
                <div className="row" style={{marginTop: 100}}>
                  <div className="col-md-12 col-lg-12 mb-sm-12 card-group">
                    <div className="card video_card_bg_rp">
                      <div className="card_image_rp">
                        <div className="videoWrapper">
                          <Iframe
                            url={sharedVideo ?
                                  sharedVideo.videoId ?
                                    `${Configuration.krpanoPlayerUrl}?slotId=${sharedVideo.slotId}&videoId=${sharedVideo.videoId}&token=${sharedVideo.token}`
                                  : sharedVideo.id ?
                                    `${Configuration.krpanoPlayerUrl}?slotId=${sharedVideo.slotId}&playlistId=${sharedVideo.id}&token=${sharedVideo.token}`
                                  : `${Configuration.krpanoPlayerUrl}?slotId=${sharedVideo.slotId}&token=${sharedVideo.token}`
                                : ''}
                            allowFullScreen
                          />
                        </div>
                      </div>
                      <div className="card-body d-flex flex-column">
                        {sharedVideo ?
                        <Button style={{alignSelf: 'flex-end'}}variant="primary" onClick={() => 
                          sharedVideo.videoId ?
                          window.open(`${Configuration.krpanoPlayerUrl}?slotId=${sharedVideo.slotId}&videoId=${sharedVideo.videoId}&token=${sharedVideo.token}`, '_blank')
                          : sharedVideo.id ? 
                          window.open(`${Configuration.krpanoPlayerUrl}?slotId=${sharedVideo.slotId}&playlistId=${sharedVideo.id}&token=${sharedVideo.token}`, '_blank')
                          :window.open(`${Configuration.krpanoPlayerUrl}?slotId=${sharedVideo.slotId}&token=${sharedVideo.token}`, '_blank')}>
                            Full screen
                          </Button>
                        :null}
                        <h5 className="card-title" style={{marginTop: 20, marginBottom: 20}}>{sharedVideo ? sharedVideo.name : ''}</h5>
                        <ul className="list-unstyled" style={{marginBottom: 20}}>
                          <li>{sharedVideo ? sharedVideo.description : ''}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Modal show={isLoginModalVisible} onHide={hideLoginModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('VIDEO_SHARED')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p><Trans i18nKey='RESERVED_VIDEO_1' /></p>
          </div>
          <div>
            <p>{t('RESERVED_VIDEO_2')}</p>
          </div>
          <div>
            <p>{t('RESERVED_VIDEO_3')}</p>
          </div>
          <div className="d-flex flex-row-reverse">
          <Button variant="primary"  onClick={v => window.open(`/sign-up-player`, '_blank')}>{t('SIGN_IN_BUTTON')}</Button>
          <Button variant="secondary" style={{marginRight: 10}} onClick={v => window.open(`/login`, '_blank')}>{t('LOGIN_BUTTON')}</Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={hideLoginModal}>
            {t('CLOSE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

VideoSharing.propTypes = {
  match: PropTypes.object.isRequired,
  getSharedVideo: PropTypes.func.isRequired,
  sharedVideo: PropTypes.object,
  token: PropTypes.object
};

VideoSharing.defaultProps = {
  sharedVideo: null,
  token: null
};

export default (connect(
  ({player: {sharedVideo, isLoginModalVisible}, login: {token}}) => ({
    sharedVideo,
    token,
    isLoginModalVisible
  }),
  dispatch => ({
    getSharedVideo: sharedId => {
      dispatch(getSharedVideo(sharedId));
    },
    hideLoginModal: () => {
      dispatch(hideLoginModal());
    },
    goto: (path, action = null) => e => {
      e.preventDefault();
      if (action) {
        dispatch(action());
      }
  
      dispatch(push(path));
    },
  })
)(VideoSharing));

