/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Dropdown, DropdownButton, Fade, Form, Tabs, Tab} from 'react-bootstrap';
import {push} from 'connected-react-router';
import {Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import Dialog from 'react-bootstrap-dialog';
import queryString from 'query-string';
import CommonMetaTag from './common-meta-tag';
import {addVideoToPlayerArea, addPartnerVideoToPlayerArea, getVideosStore, purchaseVideo, showPlayerHowPurchaseModal, purchasePartnerVideo, myVideoActiveTab, setPlayerVideoStoreActiveTab} from '../actions/player';
import PlayerHowPurchase from './player-how-purchase';
import coming_soon from '../images/video_store_coming_soon.png';
import defaultFieldImage from '../images/default_field_image.png'
import {useTranslation} from 'react-i18next';
import Configuration from '../config';

const PlayerVideoStorePartner = ({
  token,
  selectedPartner,
  profile,
  goto,
  videosStore,
  videosStoreSoon,
  videosStorePartnerVideo,
  addVideoToPlayerArea,
  addPartnerVideoToPlayerArea,
  selectedPartnerFields,
  selectedPartnerServices,
  searchVideos,
  purchaseVideo,
  purchasePartnerVideo,
  rtvVideos,
  rtvPartnerVideos,
  showPlayerHowPurchaseModal,
  video,
  permanentVideos,
  permanentPartnerVideos,
  playerVideoStoreActiveTab,
  setActiveTab,
  location: {search}
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const params = queryString.parse(search);
  const confirmMessage = price => (
    <div className="row">
      <div className="col-lg-12 mt-sm-2">
        <p>{t('CONFIRM_PURCHASE', {price: price})}</p>
        <a href="#" onClick={showPlayerHowPurchaseModal}>{t('MORE_INFO')}</a>
      </div>
    </div>
  );

  let dialog = null;
  const confirmPurchase = (slots, price) => () => {
    dialog.show({
      title: t('CONFIRM_PURCHASE_TITLE'),
      body: confirmMessage(price),
      actions: [
        Dialog.Action(
          t('YES'),
          () => purchaseVideo(slots),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const confirmPurchasePartnerVideo = (videoId, price) => () => {
    dialog.show({
      title: t('CONFIRM_PURCHASE_TITLE'),
      body: confirmMessage(price),
      actions: [
        Dialog.Action(
          t('YES'),
          () => purchasePartnerVideo(videoId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const videoAlreadyPurchased = () => {
    dialog.show({
      title: t('MY_VIDEO'),
      body: t('VIDEO_ALREADY_COPIED'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const isPurchased = slotId => {
    if (video.find(v => v.slotId === slotId)) {
      return true;
    }

    if (permanentVideos.find(v => v.slotId === slotId)) {
      return true;
    }

    if (rtvVideos && rtvVideos.find(v => v.id === slotId)) {
      return true;
    }
    
    return false;
  };

  const isPartnerVideoPurchased = videoName => {
    if (permanentPartnerVideos.find(v => v.videoName === videoName)) {
      return true;
    }

    if (rtvPartnerVideos && rtvPartnerVideos.find(v => v.videoName === videoName)) {
      return true;
    }

    return false;
  };

  const setValueForField = (f, id) => {
    f('fieldId', id);
    f('serviceId', null);
  };

  const setValueForService = (f, id) => {
    f('fieldId', null);
    f('serviceId', id);
  };

  const getFieldImage = (partnerId, fieldId, fieldImage) => {
    return `${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage}`
  }

  const getCoverImage = (partnerId, coverImage, defaultImage, videoId) => {
    if (coverImage && coverImage[0]) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/video_partners/${videoId}/images?load=${coverImage[0]}`, backgroundSize: 'cover'};
    }
    if (defaultImage && defaultImage[0]) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/images/${partnerId}?load=${defaultImage[0]}`, backgroundSize: 'cover'};
    }

    return {backgroundImage: defaultFieldImage, backgroundSize: 'cover'};;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12">
                    <div><h4 className="section_title_neg_rp">{t('VIDEO_STORE_PARTNER', {partner: selectedPartner.name})}</h4></div>
                    <Tabs defaultActiveKey={selectedPartner.isSpecialist ? "partner_video" : "replayer_video"} className="myvideo-tabs" style={{marginTop: 20}}>
                      <Tab eventKey="replayer_video" title={t('REPLAYER_VIDEOS')} tabClassName="myvideo-tab">
                        <div className="row mb-4">
                          {selectedPartnerFields.filter(f => f.recording).length ?
                          <div className="col-12">
                            <div className="detail_head_rp video_store_head rounded" style={{background: '#0c213d'}}>
                              <div>
                                <Formik
                                  enableReinitialize
                                  initialValues={{partnerUid: selectedPartner.uid, fieldId: params.fieldId ? Number(params.fieldId) : null, serviceId: params.serviceId ? Number(params.serviceId) : null, fromDatetime: params.fromDatetime ? new Date(params.fromDatetime).toISOString() : null, toDatetime: params.toDatetime ? new Date(params.toDatetime).toISOString() : null}}
                                  onSubmit={searchVideos}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    values
                                  }) => {
                                    return (
                                      <Form className="d-flex flex-wrap" onSubmit={handleSubmit}>
                                        <div className="mr-md-4 video_store_head_filter_button">
                                          <label className="mr-1">{t('FIELD_GYM_POOL')}</label>
                                          <DropdownButton className="video_store_dropdown" title={values.fieldId === null && values.serviceId === null? t('SELECT') : values.serviceId === null ? selectedPartnerFields.find(sf => sf.id === values.fieldId).name : selectedPartnerServices.find(ss => ss.id === values.serviceId).name}>
                                            {
                                              selectedPartnerFields.map(f => (
                                                f.recording?
                                                <Dropdown.Item key={f.id} href="#" onClick={() => setValueForField(setFieldValue, f.id)}>{f.name}</Dropdown.Item>
                                                :null
                                              ))
                                            }
                                            {
                                              selectedPartnerServices.map(s => (
                                                s.recording?
                                                <Dropdown.Item key={s.id} href="#" onClick={() => setValueForService(setFieldValue, s.id)}>{s.name}</Dropdown.Item>
                                                :null
                                              ))
                                            }
                                          </DropdownButton>
                                        </div>
                                        <label className="mr-1">{t('PERIOD')}</label>
                                        <Form.Group>
                                          <DatePicker
                                            showTimeSelect
                                            locale="it"
                                            className="form-control"
                                            timeFormat="HH:mm"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            dropdownMode="select"
                                            selected={(values.fromDatetime && new Date(values.fromDatetime)) || null}
                                            onChange={val => setFieldValue('fromDatetime', val ? val.toISOString() : val)}/>
                                        </Form.Group>
                                        <Form.Group>
                                          <DatePicker
                                            showTimeSelect
                                            locale="it"
                                            className="form-control"
                                            timeFormat="HH:mm"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            dropdownMode="select"
                                            minTime={new Date().setHours(0,30)}
                                            maxTime={new Date().setHours(23,59)}
                                            selected={(values.toDatetime && (new Date(values.toDatetime).getHours() === 0 && new Date(values.toDatetime).getMinutes() === 0 ? new Date(values.toDatetime).setHours(23,59) : new Date(values.toDatetime))) || null}
                                            onChange={val => setFieldValue('toDatetime', val ? val.getHours() === 0 && val.getMinutes() === 0 ? new Date(val.setHours(23,59)).toISOString() : val.toISOString() : val)}/>
                                        </Form.Group>
                                        <div className="ml-auto ml-lg-0">
                                          <button type="submit" className="btn btn_red_rp video_store_head_filter_button">{t('FIND')}</button>
                                        </div>
                                        <div className="ml-auto mr-lg-0">
                                          <Button
                                            type="button"
                                            className="btn btn_blue_rp video_store_head_filter_button"
                                            onClick={() => searchVideos({partnerUid: selectedPartner.uid, fieldId: values.fieldId ? Number(values.fieldId) : null, serviceId: values.serviceId ? Number(values.serviceId) : null, fromDatetime: values.fromDatetime ? new Date(values.fromDatetime).toISOString() : null, toDatetime: values.toDatetime ? new Date(values.toDatetime).toISOString() : null})}
                                          >
                                            {t('UPDATE_VIDEOS')}
                                          </Button>
                                        </div>
                                      </Form>
                                    );
                                  }}
                                </Formik>
                              </div>
                              <hr className="hr_blue"/>
                              <div className="row">
                                <div className="col-lg-12 mt-sm-2">
                                  <p>{t('CHOOSE_VIEW_PREMIERE_AND_BUY_FULL_VIDEO')} <a style={{color: 'white', textDecoration: 'underline'}}href="#" onClick={showPlayerHowPurchaseModal}>{t('COST_DEDUCT_FROM_REPLAYER_CREDIT')}</a>{" " + t('CHOOSE_VIEW_PREMIERE_AND_BUY_FULL_VIDEO_TWO')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          selectedPartnerFields.filter(f => f.recording).length ?
                          <div className="col-lg-12 mt-sm-2 text-center" style={{paddingTop: 50}}>
                            <p><h5>{t('NO_VIDEO')}</h5></p>
                          </div>
                          : null}
                        </div>
                        <Tabs activeKey={playerVideoStoreActiveTab} className="videostore-tabs" style={{marginTop: 20}} onSelect={t => setActiveTab(t)}>
                          <Tab eventKey="watch_now" title={t('WATCH_NOW')} tabClassName="videostore-tab"> 
                            <div className="row">
                              {
                                videosStore && videosStore.length > 0 ?
                                  videosStore.map(v => (
                                    <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                      <div className="card video_card_bg_rp">
                                        <div>
                                          <img src={new Date(v.timestamp) > new Date() ? coming_soon: v.fieldId && v.fieldImage && v.partnerId ? getFieldImage(v.partnerId, v.fieldId, v.fieldImage) : defaultFieldImage} alt={t('VIDEO_STORE')} style={{width: '100%', height: '100%'}}/>
                                        </div>
                                        <div className="card-body d-flex flex-column card_border_bottom_white">
                                          <p className="lightblue_text mb-4">
                                            {v.description}
                                          </p>
                                          <h6 className="card-title regular_title_rp mb-1">{new Date(v.timestamp).toLocaleDateString()} {new Date(v.timestamp).toTimeString().slice(0, 5)}</h6>
                                          <h5 className="card-title">{v.fieldName}</h5>
                                          {
                                            v.event ?
                                              <div className="video_store_card_event_icon"/> :
                                              v.reserved ?
                                                <div className="video_store_card_club_icon"/> :
                                                <div className="video_store_card_player_icon"/>
                                          }
                                          <div className="d-flex lightblue_text">
                                            <span className="bold_title_rp mr-2">{t('DURATION')}</span>{new Date(v.duration * 1000).toISOString().slice(11, 19)}
                                          </div>
                                          {v.filesize ?
                                          <div className="d-flex lightblue_text">
                                            <span className="bold_title_rp mr-2">{t('FILE_SIZE')}</span>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB
                                          </div>
                                          : null }
                                        </div>
                                        <div style={{display: 'flex', justifyContent: !isPurchased(v.id) ? 'space-between' : 'flex-end', padding: 15}}>
                                        {
                                          !isPurchased(v.id) ?
                                          <div style={{paddingTop: 7}}>
                                            <span className="bold_title_rp mr-2">{t('PRICE')}:</span>{v.price} €
                                          </div>
                                          : null
                                        }
                                          <div>
                                            {
                                            isPurchased(v.id) ?
                                              <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={addVideoToPlayerArea(v.id)}>{t('COPY_TO_MY_VIDEO')}</a>
                                            : <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={confirmPurchase([v.id], v.price)}>{t('PURCHASE')}</a>
                                            }
                                          </div>
                                      </div>
                                      </div>
                                    </div>
                                  ))
                                : <Fragment>
                                      <div className="col-lg-12 mt-sm-2 text-center" style={{background: '#00152f50', paddingTop: 15}}>
                                        <p><h5>{t('NO_VIDEO')}</h5></p>
                                      </div>
                                  </Fragment>
                              }
                            </div>
                          </Tab>
                          <Tab eventKey="soon" title={t('SOON')} tabClassName="videostore-tab"> 
                            <div className="row">
                              {
                                videosStoreSoon && videosStoreSoon.length > 0 ?
                                  videosStoreSoon.map(v => (
                                    <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                      <div className="card video_card_bg_rp">
                                        <div>
                                          <img src={new Date(v.timestamp) > new Date() ? coming_soon: v.fieldId && v.fieldImage && v.partnerId ? getFieldImage(v.partnerId, v.fieldId, v.fieldImage) : defaultFieldImage} alt={t('VIDEO_STORE')} style={{width: '100%', height: '100%'}}/>
                                        </div>
                                        <div className="card-body d-flex flex-column card_border_bottom_white">
                                          <p className="lightblue_text mb-4">
                                            {v.description}
                                          </p>
                                          <h6 className="card-title regular_title_rp mb-1">{new Date(v.timestamp).toLocaleDateString()} {new Date(v.timestamp).toTimeString().slice(0, 5)}</h6>
                                          <h5 className="card-title">{v.fieldName}</h5>
                                          {
                                            v.event ?
                                              <div className="video_store_card_event_icon"/> :
                                              v.reserved ?
                                                <div className="video_store_card_club_icon"/> :
                                                <div className="video_store_card_player_icon"/>
                                          }
                                          <div className="d-flex lightblue_text">
                                            <span className="bold_title_rp mr-2">{t('DURATION')}</span>{new Date(v.duration * 1000).toISOString().slice(11, 19)}
                                          </div>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: !isPurchased(v.id) ? 'space-between' : 'flex-end', padding: 15}}>
                                        {
                                          !isPurchased(v.id) ?
                                          <div style={{paddingTop: 7}}>
                                            <span className="bold_title_rp mr-2">{t('PRICE')}:</span>{v.price} €
                                          </div>
                                          : null
                                        }
                                          <div>
                                            {
                                            isPurchased(v.id) ?
                                              <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={addVideoToPlayerArea(v.id)}>{t('COPY_TO_MY_VIDEO')}</a>
                                            : <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={confirmPurchase([v.id], v.price)}>{t('PURCHASE')}</a>
                                            }
                                          </div>
                                      </div>
                                      </div>
                                    </div>
                                  ))
                                : <Fragment>
                                      <div className="col-lg-12 mt-sm-2 text-center" style={{background: '#00152f50', paddingTop: 15}}>
                                        <p><h5>{t('NO_VIDEO')}</h5></p>
                                      </div>
                                  </Fragment>
                              }
                            </div>
                          </Tab>
                        </Tabs>
                      </Tab>
                      <Tab eventKey="partner_video" title={t('PARTNER_VIDEOS')} tabClassName="myvideo-tab">
                        <div className="row mb-4">
                          <div className="col-12">
                            <div className="detail_head_rp video_store_head rounded" style={{background: '#0c213d'}}>
                              <div className="row">
                                <div className="col-lg-12 mt-sm-2">
                                  <p>{t('CHOOSE_VIEW_PREMIERE_AND_BUY_FULL_VIDEO')} <a style={{color: 'white', textDecoration: 'underline'}}href="#" onClick={showPlayerHowPurchaseModal}>{t('COST_DEDUCT_FROM_REPLAYER_CREDIT')}</a>{" " + t('CHOOSE_VIEW_PREMIERE_AND_BUY_FULL_VIDEO_PARTNER')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {
                            videosStorePartnerVideo && videosStorePartnerVideo.length > 0 ?
                              videosStorePartnerVideo.map(v => (
                              <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                <div className="card video_partner_card_bg_rp">
                                  <div className="video_card_image" style={getCoverImage(v.partnerId, v.coverImage, v.defaultImage, v.videoId)}>
                                    <div className="videoWrapper"/>
                                  </div>
                                  <div className="card-body d-flex flex-column card_border_bottom_white">
                                    <h5 className="card-title">{v.title}</h5>
                                    <p className="lightblue_text mb-4">
                                      {selectedPartner.name}
                                    </p>
                                    <p className="lightblue_text mb-4">
                                      {v.description}
                                    </p>
                                    <p className="lightblue_text mb-4">
                                    {t('FILE_SIZE')}: {(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB
                                    </p>
                                  </div>
                                  <div style={{display: 'flex', justifyContent: !isPartnerVideoPurchased(v.videoName) ? 'space-between' : 'flex-end', padding: 15}}>
                                    {
                                      !isPartnerVideoPurchased(v.videoName) ?
                                      <div style={{paddingTop: 7}}>
                                        <span className="bold_title_rp mr-2">{t('PRICE')}:</span>{v.videoPrice} €
                                      </div>
                                      : null
                                    }
                                      <div>
                                        {
                                        isPartnerVideoPurchased(v.videoName) ?
                                          <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={addPartnerVideoToPlayerArea(v.videoId)}>{t('COPY_TO_MY_VIDEO')}</a>
                                        : <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={confirmPurchasePartnerVideo(v.videoId, v.videoPrice)}>{t('PURCHASE')}</a>
                                        }
                                      </div>
                                  </div>
                                </div>
                              </div>
                            ))
                            : <Fragment>
                                  <div className="col-lg-12 mt-sm-2 text-center" style={{background: '#00152f50', paddingTop: 15}}>
                                    <p><h5>{t('NO_VIDEO')}</h5></p>
                                  </div>
                              </Fragment>
                          }
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <PlayerHowPurchase/>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerVideoStorePartner.propTypes = {
  token: PropTypes.string,
  selectedPartner: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  goto: PropTypes.func.isRequired,
  videosStore: PropTypes.array,
  videosStoreSoon: PropTypes.array,
  addVideoToPlayerArea: PropTypes.func.isRequired,
  selectedPartnerFields: PropTypes.array,
  selectedPartnerServices: PropTypes.array,
  searchVideos: PropTypes.func.isRequired,
  purchaseVideo: PropTypes.func.isRequired,
  rtvVideos: PropTypes.array,
  rtvPartnerVideos: PropTypes.array,
  showPlayerHowPurchaseModal: PropTypes.func.isRequired,
  video: PropTypes.array,
  permanentVideos: PropTypes.array
};

PlayerVideoStorePartner.defaultProps = {
  token: null,
  videosStore: [],
  videosStoreSoon: [],
  selectedPartnerFields: [],
  selectedPartnerServices: [],
  video: [],
  permanentVideos: [],
  permanentPartnerVideos: [],
  rtvVideos: [],
  rtvPartnerVideos: []
};

const mapStateToProps = ({login: {token, profile}, player: {selectedPartner, selectedPartnerFields, selectedPartnerServices, videosStore, videosStoreSoon, video, permanentVideos, rtvVideos, rtvPartnerVideos, videosStorePartnerVideo, permanentPartnerVideos, playerVideoStoreActiveTab}}) => ({
  token,
  profile,
  selectedPartner,
  videosStore,
  videosStoreSoon,
  selectedPartnerFields,
  selectedPartnerServices,
  video,
  permanentVideos,
  permanentPartnerVideos,
  rtvVideos,
  rtvPartnerVideos,
  videosStorePartnerVideo,
  playerVideoStoreActiveTab
});

const mapDispatchToProps = dispatch => ({
  addVideoToPlayerArea: slotId => () => {
    dispatch(addVideoToPlayerArea(slotId));
    dispatch(push('player-video'));
  },
  addPartnerVideoToPlayerArea: videoId => () => {
    dispatch(addPartnerVideoToPlayerArea(videoId));
    dispatch(myVideoActiveTab("partner_videos"));
    dispatch(push('player-video'));
  },
  purchaseVideo: slots => {
    dispatch(purchaseVideo(slots));
  },
  purchasePartnerVideo: videoId => {
    dispatch(purchasePartnerVideo(videoId));
  },
  searchVideos: filters => {
    dispatch(getVideosStore(filters.partnerUid, filters.fieldId, filters.serviceId, filters.fromDatetime, filters.toDatetime));
  },
  showPlayerHowPurchaseModal: () => {
    dispatch(showPlayerHowPurchaseModal());
  },
  setActiveTab: t => {
    dispatch(setPlayerVideoStoreActiveTab(t));
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerVideoStorePartner));
