/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Container, Fade} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';
import {sponsorType} from '../constants';
import advBanner from '../images/3_pubblicita_ADV.png';

const PlayerSponsor = ({
  token,
  sponsors
}) => {
  const {t} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter replayer_adv_sponsor_hero" data-parallax="true">
            <div className="replayer_adv_sponsor_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_REPLAYER_ADV')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_INFO')}</p>
                          <p>{t('SPONSOR_MORE_INFO')} <a href="https://www.replayer.it/it/replayeradv-diventa-sponsor/" target="_blank" rel="noreferrer">ReplayerAdv</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_GLOBAL')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_GLOBAL_INFO')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {sponsors.filter(s => s.sponsorType === sponsorType.global && s.image && s.image.length > 0).sort(() => Math.random() - 0.5).map(s =>
                    (
                      <div key={s.uid} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group">
                        <a href={s.link} target={s.link ? "_blank" : null} rel="noreferrer" className="card video_store_bg_rp ease_fx">
                          <div className="card-horizontal">
                            <div className="col-sm-12 col-lg-12 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                              {
                                s.image && s.image.length > 0 ?
                                  <img
                                    className="img-fluid"
                                    src={`${Configuration.apiEndPoint}/sponsors/${s.id}/image?load=${s.image[0]}`}
                                    alt={t('LOGO')}
                                    style={{width: '100%'}}
                                  /> :
                                  <div className="align-self-center video_store_nologo_overlay_rp"/>
                              }
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_LOCAL')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_LOCAL_INFO')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {sponsors.filter(s => s.sponsorType === sponsorType.local && s.image && s.image.length > 0).sort(() => Math.random() - 0.5).map(s =>
                    (
                      <div key={s.uid} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group">
                        <a href={s.link || '#'} target="_blank" rel="noreferrer" className="card video_store_bg_rp ease_fx">
                          <div className="card-horizontal">
                            <div className="col-sm-12 col-lg-12 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                              {
                                s.image && s.image.length > 0 ?
                                  <img
                                    className="img-fluid"
                                    src={`${Configuration.apiEndPoint}/sponsors/${s.id}/image?load=${s.image[0]}`}
                                    alt={t('LOGO')}
                                    style={{width: '100%'}}
                                  /> :
                                  <div className="align-self-center video_store_nologo_overlay_rp"/>
                              }
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_BECOME')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row justify-content-center">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_BECOME_INFO')}</p>
                          <p>{t('SPONSOR_BECOME_MORE_INFO')} <a href="https://www.replayer.it/it/replayeradv-diventa-sponsor/" target="_blank" rel="noreferrer">ReplayerAdv</a></p>
                        </div>
                        <div className="col-md-12 col-lg-10 card-group">
                          <a href="https://www.replayer.it/it/replayeradv-diventa-sponsor/" target="_blank" rel="noreferrer" className="card video_store_bg_rp ease_fx">
                            <div className="card-horizontal">
                              <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                                <h5 className="card-title mb-0">{t('SPONSOR_BECOME_BANNER_INFO')}</h5>
                              </div>
                              <div className="col-sm-6 col-lg-6 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                                <img
                                  className="img-fluid"
                                  src={advBanner}
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </Fragment>
  );
};

PlayerSponsor.propTypes = {
  token: PropTypes.string,
  sponsors: PropTypes.array
};

PlayerSponsor.defaultProps = {
  token: null,
  sponsors: []
};

const mapStateToProps = ({login: {token}, player: {sponsors}}) => ({
  token,
  sponsors
});

export default withRouter(connect(
  mapStateToProps,
  null
)(PlayerSponsor));
