import {push} from 'connected-react-router';
import jwt from 'jsonwebtoken';
import * as api from '../api';
import {showMessage} from './messages';
import {logout} from './login';
import {subscriptionKind} from '../constants';

export const playerProfileReceived = profile => ({
  type: 'PROFILE_RECEIVED',
  profile
});

export const refreshPlayerProfile = () => async (dispatch, getState) => {
  const {login: {token, profile}} = getState();
  const tokenData = jwt.decode(token);
  const res = await api.getPlayerProfile({uid: tokenData.id, email: profile.user.email, role: tokenData.role}, token);
  if (res.status === 200) {
    dispatch(playerProfileReceived(res.json));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const savePlayerProfile = profile => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.savePlayerProfile(profile, token);
  if (res.status === 200) {
    dispatch(refreshPlayerProfile());
    dispatch(showMessage('PROFILE', 'PROFILE_UPDATED'));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const deletePlayerImageFile = file => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteImageFile(token, file);

  if (res.status === 200) {
    dispatch(refreshPlayerProfile());
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnersReceived = partners => ({
  type: 'PLAYER_PARTNERS_RECEIVED',
  partners
});

export const getPartners = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartners(token);
  if (res.status === 200) {
    dispatch(partnersReceived(res.json));
  } else {
    dispatch(showMessage('PARTNERS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const bookingsReceived = bookings => ({
  type: 'PLAYER_BOOKINGS_RECEIVED',
  bookings
});

export const getBookings = (resourceType = 'all') => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getBookings(token, null, 'MONTH', resourceType);
  if (res.status === 200) {
    dispatch(bookingsReceived(res.json));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnerSelected = partner => ({
  type: 'PLAYER_PARTNER_SELECTED',
  partner
});

export const partnerFieldsReceived = partnerFields => ({
  type: 'PLAYER_PARTNER_FIELDS_RECEIVED',
  partnerFields
});

export const partnerServicesReceived = partnerServices => ({
  type: 'PLAYER_PARTNER_SERVICES_RECEIVED',
  partnerServices
});


export const getPartnerFields = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerFields(partnerId, token);
  if (res.status === 200) {
    dispatch(partnerFieldsReceived(res.json));
  } else {
    dispatch(showMessage('FIELDS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const getPartnerServices = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerServices(partnerId, token);
  if (res.status === 200) {
    dispatch(partnerServicesReceived(res.json));
  } else {
    dispatch(showMessage('SERVICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnerGroupsReceived = partnerGroups => ({
  type: 'PLAYER_PARTNER_GROUPS_RECEIVED',
  partnerGroups
});

export const getPartnerGroups = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerGroups(partnerId, token);
  if (res.status === 200) {
    dispatch(partnerGroupsReceived(res.json));
  } else {
    dispatch(showMessage('MEMBERSHIP', `Error ${res.status}: ${res.json.error}`));
  }
};

export const playerGroupsReceived = playerGroups => ({
  type: 'PLAYER_GROUPS_RECEIVED',
  playerGroups
});

export const getPlayerGroups = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerGroups(token);
  if (res.status === 200) {
    dispatch(playerGroupsReceived(res.json));
  } else {
    dispatch(showMessage('MEMBERSHIP', `Error ${res.status}: ${res.json.error}`));
  }
};

export const slotsReceived = slots => ({
  type: 'PLAYER_SLOTS_RECEIVED',
  slots
});

export const getSlots = (partnerId, fieldId = null, targetDate = new Date(), resourceType = 'field', searchRange = 'WEEK') => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res;
  if(resourceType === 'field')
    res = await api.getFieldSlots(partnerId, token, fieldId, false, targetDate, searchRange);
  else res = await api.getServiceSlots(partnerId, token, fieldId, false, targetDate, searchRange);
  if (res.status === 200) {
    dispatch(slotsReceived(res.json));
  } else {
    dispatch(showMessage('SLOT', `Error ${res.status}: ${res.json.error}`));
  }
};

export const bookingSlotSelected = bookingSlot => ({
  type: 'PLAYER_BOOKING_SLOT_SELECTED',
  bookingSlot
});

export const bookingMultiSlotsSelected = bookingSlots => ({
  type: 'PLAYER_BOOKING_MULTI_SLOT_SELECTED',
  bookingSlots
});

export const bookingSelected = booking => ({
  type: 'PLAYER_BOOKING_SELECTED',
  booking
});

export const showBookingSlotModal = () => ({
  type: 'PLAYER_SHOW_BOOKING_SLOT_MODAL'
});

export const hideBookingSlotModal = () => ({
  type: 'PLAYER_HIDE_BOOKING_SLOT_MODAL'
});

export const showBookingServiceSlotModal = () => ({
  type: 'PLAYER_SHOW_SERVICE_BOOKING_SLOT_MODAL'
});

export const hideBookingServiceSlotModal = () => ({
  type: 'PLAYER_HIDE_SERVICE_BOOKING_SLOT_MODAL'
});

export const saveBooking = bookingData => async (dispatch, getState) => {
  const {login: {profile, token}, player: {selectedPartner, bookingFieldId, bookingSlot, bookingSlots}} = getState();
  let res = null;
  bookingData["partnerBookingName"] = profile.name;
  bookingData["partnerBookingSurname"] = profile.surname;
  bookingData["partnerBookingEmail"] = profile.user.email;
  bookingData["partnerBookingPhoneNumber"] = profile.phoneNumber;
  if (bookingData.bookingId) {
    res = await api.updateBooking(bookingData, token);
  } else {
    res = await api.saveBooking(bookingData, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else if (res.json.error === 'not_enough_credit') {
    dispatch(showMessage('BOOKINGS', 'NOT_ENOUGH_CREDIT'));
    dispatch(push('user-top-up-account'));
  } else if (res.json.error === 'slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_TOO_LONG'));
  } else if (res.json.error === 'old_slot') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
  } else if (res.json.error === 'slot_already_booked') {
    dispatch(showMessage('BOOKINGS', 'EVENT_ALREADY_BOOKED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  const targetDate = bookingSlot ? new Date(bookingSlot.startDatetime) : new Date(bookingSlots[0].startDatetime);
  dispatch(getSlots(selectedPartner.uid, bookingFieldId, targetDate));
  dispatch(getBookings('all'));
  dispatch(hideBookingSlotModal());
  dispatch(refreshPlayerProfile());
};

export const saveServiceBooking = bookingData => async (dispatch, getState) => {
  const {login: {profile, token}, player: {selectedPartner, bookingFieldId, bookingSlot, bookingSlots}} = getState();
  let res = null;
  bookingData["partnerBookingName"] = profile.name;
  bookingData["partnerBookingSurname"] = profile.surname;
  bookingData["partnerBookingEmail"] = profile.user.email;
  bookingData["partnerBookingPhoneNumber"] = profile.phoneNumber;
  if (bookingData.bookingId) {
    res = await api.updateServiceBooking(bookingData, token);
  } else {
    res = await api.saveServiceBooking(bookingData, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else if (res.json.error === 'not_enough_credit') {
    dispatch(showMessage('BOOKINGS', 'NOT_ENOUGH_CREDIT'));
    dispatch(push('user-top-up-account'));
  } else if (res.json.error === 'slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_TOO_LONG'));
  } else if (res.json.error === 'old_slot') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
  } else if (res.json.error === 'slot_already_booked') {
    dispatch(showMessage('BOOKINGS', 'EVENT_ALREADY_BOOKED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  const targetDate = bookingSlot ? new Date(bookingSlot.startDatetime) : new Date(bookingSlots[0].startDatetime);
  dispatch(getSlots(selectedPartner.uid, bookingFieldId, targetDate, 'service'));
  dispatch(getBookings('all'));
  dispatch(hideBookingServiceSlotModal());
  dispatch(refreshPlayerProfile());
};

export const deleteBooking = (bookingId, partnerId, multiBooking, phoneNumber=null) => async (dispatch, getState) => {
  const {login: {token}, player: {bookingSlot}} = getState();
  const targetDate = bookingSlot ? new Date(bookingSlot.startDatetime) : new Date();
  const res = !multiBooking ? await api.deleteBooking(bookingId, token) : await api.deleteUserBooking(bookingId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_DELETE_REQUESTED'));
  } else {
    if(res.json.error === 'delete_not_allowed')
      dispatch(showMessage('BOOKINGS', 'BOOKING_HOW_CANCEL', {phoneNumber}));
    else if(res.json.error === 'delete_booking_rejected')
      dispatch(showMessage('BOOKINGS', "BOOKING_DELETE_REJECTED",{hours: res.json.unbookable_hours}));
    else dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(getBookings('all'));
  dispatch(getSlots(partnerId, null, targetDate));
  dispatch(hideBookingSlotModal());
  dispatch(refreshPlayerProfile());
};

export const deleteServiceBooking = (bookingId, partnerId, multiBooking, phoneNumber=null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = !multiBooking ? await api.deleteServiceBooking(bookingId, token) : await api.deleteServiceUserBooking(bookingId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_DELETE_REQUESTED'));
  } else {
    if(res.json.error === 'delete_not_allowed')
      dispatch(showMessage('BOOKINGS', 'BOOKING_HOW_CANCEL', {phoneNumber}));
    else if(res.json.error === 'delete_booking_rejected')
      dispatch(showMessage('BOOKINGS', "BOOKING_DELETE_REJECTED",{hours: res.json.unbookable_hours}));
    else dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getBookings('all'));
  dispatch(getSlots(partnerId, null, new Date(), 'service'));
  dispatch(hideBookingServiceSlotModal());
  dispatch(refreshPlayerProfile());
};

export const filterBookingFieldId = bookingFieldId => ({
  type: 'PLAYER_BOOKING_FIELD_ID',
  bookingFieldId
});

export const filterBookableFields = filterBookableFields => ({
  type: 'PLAYER_FILTER_BOOKABLE_FIELDS',
  filterBookableFields
});

export const deletePlayer = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlayer(token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('PROFILE', 'PROFILE_DELETED'));
  } else {
    dispatch(showMessage('PROFILE', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(logout());
};

export const newBooking = (initialValues = {}) => ({
  type: 'PLAYER_NEW_BOOKING',
  initialValues
});

export const addToBookingQueue = slotId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addToBookingQueue(slotId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_QUEUE_RECEIVED'));
  } else if (res.json.error === 'queue_already_registered') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_QUEUE_ALREADY_REGISTERED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const addToGroup = groupId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addToGroup(groupId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('GROUP_MEMBERSHIP', 'GROUP_MEMBERSHIP_REQUEST'));
    dispatch(getPlayerGroups(res.json));
  } else {
    dispatch(showMessage('GROUP_MEMBERSHIP', `Error ${res.status}: ${res.json.error}`));
  }
};

export const purchaseVideo = (slots, fromLiveVideosPage = false) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.purchaseVideo(slots, token);

  if (res.status === 200 || res.status === 201) {
    if(fromLiveVideosPage) {
      dispatch(showMessage('PURCHASE_VIDEO', 'VIDEO_PURCHASED_FROM_LIVE_VIDEOS_PAGE'));
    }
    else{
    dispatch(showMessage('PURCHASE_VIDEO', 'VIDEO_PURCHASED'));
    }
  } else if (res.json.error === 'not_enough_credit') {
    dispatch(showMessage('PURCHASE_VIDEO', 'NOT_ENOUGH_CREDIT'));
    dispatch(push('user-top-up-account'));
  } else if (res.json.error === 'privacy_set') {
    dispatch(showMessage('PURCHASE_VIDEO', 'PURCHASE_VIDEO_PRIVACY_SET'));
  } else if (res.json.error === 'video_already_bought') {
    dispatch(showMessage('PURCHASE_VIDEO', 'VIDEO_ALREADY_BOUGHT'));
  } else {
    dispatch(showMessage('PURCHASE_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(refreshPlayerProfile());
  dispatch(getBookings('all'));
  dispatch(getVideo());
  dispatch(getVideoStreaming());
  dispatch(getPlayerPermanentVideos());
  dispatch(getLiveVideos());
};

export const purchasePartnerVideo = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.purchasePartnerVideo(videoId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('PURCHASE_VIDEO', 'VIDEO_PURCHASED'));
  } else if (res.json.error === 'not_enough_credit') {
    dispatch(showMessage('PURCHASE_VIDEO', 'NOT_ENOUGH_CREDIT'));
    dispatch(push('user-top-up-account'));
  } else if (res.json.error === 'exceeded_quota') {
    dispatch(showMessage('PURCHASE_VIDEO', 'MY_VIDEO_EXCEEDED_QUOTA'));
  } else if (res.json.error === 'video_already_bought') {
    dispatch(showMessage('PURCHASE_VIDEO', 'VIDEO_ALREADY_BOUGHT'));
  } else {
    dispatch(showMessage('PURCHASE_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(refreshPlayerProfile());
  dispatch(getPlayerPermanentPartnerVideos());
};

export const videoReceived = video => ({
  type: 'PLAYER_VIDEO_RECEIVED',
  video
});

export const permanentVideosReceived = videos => ({
  type: 'PLAYER_PERMANENT_VIDEOS_RECEIVED',
  videos
});

export const permanentPartnerVideosReceived = videos => ({
  type: 'PLAYER_PERMANENT_PARTNER_VIDEOS_RECEIVED',
  videos
});

export const playlistsReceived = playlists => ({
  type: 'PLAYER_PLAYLISTS_RECEIVED',
  playlists
});


export const getVideo = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getVideo(token);
  if (res.status === 200) {
    dispatch(videoReceived(res.json));
  } else {
    dispatch(showMessage('VIDEOS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const videoStreamingReceived = video => ({
  type: 'PLAYER_VIDEO_STREAMING_RECEIVED',
  video
});

export const getVideoStreaming = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getVideoStreaming(token);
  if (res.status === 200) {
    dispatch(videoStreamingReceived(res.json));
  } else {
    dispatch(showMessage('VIDEOS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const storagePricesReceived = prices => ({
  type: 'PLAYER_STORAGE_PRICES_RECEIVED',
  prices
});

export const playerGetStoragePrices = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getStoragePrices(token);
  if (res.status === 200) {
    dispatch(storagePricesReceived(res.json));
  } else {
    dispatch(showMessage('STORAGE PRICES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const confirmPlayerGroup = (groupId, playerId, token) => async dispatch => {
  const res = await api.confirmPlayerGroup(groupId, playerId, token);
  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('CONFIRM_AFFILIATION', 'AFFILIATION_SUCCESSFUL'));
  } else if (res.json.error === 'player_already_registered') {
    dispatch(showMessage('CONFIRM_AFFILIATION', 'ALREADY_AFFILIATED'));
  } else if (res.json.error === 'subscription_dedicated_already_active') {
    dispatch(showMessage('CONFIRM_AFFILIATION', 'AFFILIATION_ALREADY_ACTIVE'));
  } else if (res.json.error === 'insufficient_funds') {
    dispatch(showMessage('CONFIRM_AFFILIATION', 'AFFILIATION_INSUFFICIENT_FUNDS'));
    //dispatch(push('user-top-up-account'));
  } else {
    dispatch(showMessage('CONFIRM_AFFILIATION', 'AFFILIATION_FAILED'));
  }

  dispatch(push('/login'));
};

export const archiveBooking = bookingId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.archiveBooking(bookingId, token);
  if (res.status === 200) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_ARCHIVED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getBookings('all'));
};

export const addVideoToPlayerArea = slotId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addVideoToPlayerArea(slotId, token);
  if (res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_ADDED'));
  } else if (res.json.error === 'exceeded_quota') {
      dispatch(showMessage('MY_VIDEO', 'MY_VIDEO_EXCEEDED_QUOTA'));
  } else if(res.json.error === 'video_already_there') {
      dispatch(showMessage('MY_VIDEO', 'VIDEO_ALREADY_COPIED'));
  } else if(res.json.error === 'video_in_streaming') {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_IN_STREAMING'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getPlayerPermanentVideos());
};

export const addPartnerVideoToPlayerArea = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addPartnerVideoToPlayerArea(videoId, token);
  if (res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_ADDED'));
  } else if (res.json.error === 'exceeded_quota') {
      dispatch(showMessage('MY_VIDEO', 'MY_VIDEO_EXCEEDED_QUOTA'));
  } else if(res.json.error === 'video_already_there') {
      dispatch(showMessage('MY_VIDEO', 'VIDEO_ALREADY_COPIED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getPlayerPermanentPartnerVideos());
};

export const getPlayerPermanentVideos = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerPermanentVideos(token);
  if (res.status === 200) {
    dispatch(permanentVideosReceived(res.json));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const getPlayerPermanentPartnerVideos = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerPermanentPartnerVideos(token);
  if (res.status === 200) {
    dispatch(permanentPartnerVideosReceived(res.json));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const getPlayerPlaylists = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerPlaylists(token);
  if (res.status === 200) {
    dispatch(playlistsReceived(res.json));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const acceptPrivacy = () => async (dispatch, getState) => {
  const {login: {token, profile}} = getState();
  profile.privacy1 = true;
  const res = await api.savePlayerProfile(profile, token);
  dispatch(refreshPlayerProfile());
};

export const showVideoHighlightModal = () => ({
  type: 'PLAYER_SHOW_VIDEO_HIGHLIGHT_MODAL'
});

export const hideVideoHighlightModal = () => ({
  type: 'PLAYER_HIDE_VIDEO_HIGHLIGHT_MODAL'
});

export const showVideoSharingModal = () => ({
  type: 'PLAYER_SHOW_VIDEO_SHARING_MODAL'
});

export const hideVideoSharingModal = () => ({
  type: 'PLAYER_HIDE_VIDEO_SHARING_MODAL'
});

export const permanentVideoSelected = selectedPermanentVideo => ({
  type: 'PLAYER_PERMANENT_VIDEO_SELECTED',
  selectedPermanentVideo
});

const setIntervalX = (callback, delay, repetitions) => {
  let x = 0;
  const intervalID = window.setInterval(() => {
    callback();
    if (++x === repetitions) {
      window.clearInterval(intervalID);
    }
  }, delay);
};

export const saveHighlight = videoData => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.cutPlayerPermanentVideo(videoData.videoId, videoData, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_OPERATION_IN_PROGRESS'));
  } else if (res.json.error === 'tracking_error') {
    dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_BAD_START_TIME'));
  } else {
    dispatch(showMessage('HIGHLIGHT', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(hideVideoHighlightModal());
  dispatch(permanentVideoSelected(null));
  setIntervalX(() => {
    dispatch(getPlayerPermanentVideos());
  }, 10000, 5);
};

export const deletePlayerPermanentVideo = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlayerPermanentVideo(videoId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_DELETED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(refreshPlayerProfile());
  dispatch(getPlayerPermanentVideos());
  dispatch(getTaggedUserVideos());
};

export const deletePlayerPermanentPartnerVideo = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlayerPermanentPartnerVideo(videoId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'VIDEO_DELETED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(refreshPlayerProfile());
  dispatch(getPlayerPermanentPartnerVideos());
};

export const modifyPlayerPermanentVideo = (videoId, videoData) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  await api.modifyPlayerPermanentVideo(videoId, videoData, token);
  dispatch(getPlayerPermanentVideos());
};

export const deletePlaylist = playlistId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deletePlaylist(playlistId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('MY_VIDEO', 'PLAYLIST_DELETED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
  dispatch(refreshPlayerProfile());
  dispatch(getPlayerPlaylists());
};

export const modifyPlaylist = (playlistId, videoData) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  await api.modifyPlaylist(playlistId, videoData, token);
  dispatch(getPlayerPlaylists());
};

export const reportBookings = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.reportBookings(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const reportSubscriptions = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.reportSubscriptions(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const reportTransactions = filters => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.reportTransactions(filters, token);
  if (res.status !== 200) {
    dispatch(showMessage('REPORT', 'REPORT_EMPTY'));
  }
};

export const changeStorageType = newStorageType => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.changeStorageType(newStorageType, token);
  if (res.status == 200) {
    dispatch(refreshPlayerProfile());
    if (res.json.upgrade)
      dispatch(showMessage('UPGRADE_DONE', 'NEW_STORAGE_LIMIT',{limit: res.json.storage_limit, date: new Date(res.json.expiry_date).toLocaleDateString({weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}));
    else dispatch(showMessage('DOWNGRADE_DONE', 'NEW_STORAGE_LIMIT',{limit: res.json.storage_limit, date: new Date(res.json.expiry_date).toLocaleDateString({weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}));
  } else {
      if(res.json.error === 'storage_type_already_active') dispatch(showMessage('MY_VIDEO_MANAGE_SPACE', 'STORAGE_ALREADY_ACTIVE'));
      else if(res.json.error === 'not_enough_money') dispatch(showMessage('MY_VIDEO_MANAGE_SPACE', 'NOT_ENOUGH_CREDIT'));
      else if(res.json.error === 'not_enough_free_space') dispatch(showMessage('DOWNGRADE_NOT_ALLOWED_NO_FREE_SPACE', 'NOT_ENOUGH_FREE_SPACE'));
      else dispatch(showMessage('MY_VIDEO_MANAGE_SPACE', `Error ${res.status}: ${res.json.error}`));
    }
};

export const favouritePartnersReceived = favouritePartners => ({
  type: 'PLAYER_FAVOURITE_PARTNERS_RECEIVED',
  favouritePartners
});

export const getFavouritePartners = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getFavouritePartners(token);
  if (res.status === 200) {
    dispatch(favouritePartnersReceived(res.json));
  } else {
    dispatch(showMessage('PARTNERS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const addPartnerToFavourite = uid => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addPartnerToFavourite(uid, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('FAVORITES', 'FAVORITES_CENTER_ADDED'));
  } else {
    dispatch(showMessage('FAVORITES', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getFavouritePartners());
};

export const removePartnerFromFavourite = uid => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.removePartnerFromFavourite(uid, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('FAVORITES', 'FAVORITES_CENTER_REMOVED'));
  } else {
    dispatch(showMessage('FAVORITES', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getFavouritePartners());
};

export const permanentVideosSharing = (videoId, sharing, playlistId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.permanentVideosSharing(videoId, sharing, playlistId, token);

  if (res.status === 200 || res.status === 201) {
    // dispatch(showMessage('Condivisione', 'Il video è stato condiviso.'));
  } else {
    dispatch(showMessage('SHARING', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getPlayerPermanentVideos());
  dispatch(getPlayerPlaylists());
};

export const sharedVideoReceived = sharedVideo => ({
  type: 'PLAYER_SHARED_VIDEO_RECEIVED',
  sharedVideo
});

export const getSharedVideo = sharedId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getSharedVideo(sharedId, token);
  if (res.status === 200) {
    dispatch(sharedVideoReceived(res.json));
  } else if (res.json.error.startsWith('no_video')) {
    dispatch(showMessage('VIDEO_SHARED', 'VIDEO_NOT_AVAILABLE'));
  } else { 
    dispatch(showLoginModal());
  }
};

export const showLoginModal = () => ({
  type: 'PLAYER_SHOW_LOGIN_MODAL',
});

export const hideLoginModal = () => ({
  type: 'PLAYER_HIDE_LOGIN_MODAL',
});

// Subscriptions
export const subscriptionsReceived = subscriptions => ({
  type: 'PLAYER_SUBSCRIPTIONS_RECEIVED',
  subscriptions
});

export const subscriptionSelected = subscription => ({
  type: 'PLAYER_SUBSCRIPTION_SELECTED',
  subscription
});

export const getSubscriptions = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPlayerSubscriptions(token);
  if (res.status === 200) {
    dispatch(subscriptionsReceived(res.json));
  } else {
    dispatch(subscriptionsReceived(null));
  }
};

export const setSubscription = (kind, partnerId, rtvChannelId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res;
  if (partnerId) {
    res = await api.setPlayerSubscription({kind, partner: partnerId, rtvChannel: rtvChannelId}, token);
  } else {
    res = await api.setPlayerSubscription({kind}, token);
  }
  if (res.status === 200) {
    dispatch(getSubscriptions());
    dispatch(refreshPlayerProfile());
    dispatch(getLiveVideos());
    if (kind === subscriptionKind.rtvEvent) {
      dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_SUCCESSFUL_EVENT'));
    }
    else {
      dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_SUCCESSFUL'));
    }
  } else if (res.json.error === 'insufficient_funds') {
    dispatch(showPlayerInsufficientFundsModal());
  } else if (res.json.error === 'subscription_dedicated_already_active') {
    dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_DEDICATED_ALREADY_ACTIVE'));
  } else if (res.json.error === 'subscription_unlimited_already_active') {
    dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_UNLIMITED_ALREADY_ACTIVE'));
  } else {
    dispatch(showMessage('SUBSCRIPTION', `Error ${res.status}: ${res.json.error}`));
  }
};

export const changeSubscription = subscription => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.changePlayerSubscription(subscription, token);
  if (res.status === 200) {
    dispatch(getSubscriptions());
    dispatch(refreshPlayerProfile());
    if (res.json.result === 'set_autorenew') {
    dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_AUTORENEW_CHANGED'));
    } else if (res.json.result === 'archived') {
      dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_ARCHIVED'));
    }
  } else {
    dispatch(showMessage('SUBSCRIPTION', `Error ${res.status}: ${res.json.error}`));
  }
};

export const renewSubscription = () => async (dispatch, getState) => {
  const {login: {token}, player: {selectedSubscription}} = getState();
  const res = await api.renewPlayerSubscription(selectedSubscription, token);
  if (res.status === 200) {
    dispatch(subscriptionSelected(null));
    dispatch(getSubscriptions());
    dispatch(refreshPlayerProfile());
    dispatch(showMessage('SUBSCRIPTION', 'SUBSCRIPTION_SUCCESSFUL'));
  } else if (res.json.error === 'insufficient_funds') {
    dispatch(showPlayerInsufficientFundsModal());
  } else {
    dispatch(showMessage('SUBSCRIPTION', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showPlayerNewSubscriptionModal = () => ({
  type: 'PLAYER_SHOW_NEW_SUBSCRIPTION_MODAL'
});

export const hidePlayerNewSubscriptionModal = () => ({
  type: 'PLAYER_HIDE_NEW_SUBSCRIPTION_MODAL'
});

export const showPlayerNewEventSubscriptionModal = () => ({
  type: 'PLAYER_SHOW_NEW_EVENT_SUBSCRIPTION_MODAL'
});

export const hidePlayerNewEventSubscriptionModal = () => ({
  type: 'PLAYER_HIDE_NEW_EVENT_SUBSCRIPTION_MODAL'
});

export const rtvVideosReceived = (rtvVideos, rtvVideosSoon) => ({
  type: 'PLAYER_RTV_VIDEOS_RECEIVED',
  rtvVideos,
  rtvVideosSoon
});

export const getRtvVideos = (partnerUid, rtvChannelId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res1 = await api.getRtvVideos(token, partnerUid, rtvChannelId);
  const res2 = await api.getRtvVideosSoon(token, partnerUid, rtvChannelId);
  if (res1.status === 200 && res2.status === 200) {
    dispatch(rtvVideosReceived(res1.json, res2.json));
  } else {
    dispatch(rtvVideosReceived(null));
  }
};

export const rtvPartnerVideosReceived = rtvPartnerVideos => ({
  type: 'PLAYER_RTV_PARTNER_VIDEOS_RECEIVED',
  rtvPartnerVideos,
});

export const getRtvPartnerVideos = (partnerUid, rtvChannelId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getRtvPartnerVideos(token, partnerUid, rtvChannelId);
  if (res.status === 200) {
    dispatch(rtvPartnerVideosReceived(res.json));
  } else {
    dispatch(rtvPartnerVideosReceived(null));
  }
};

export const deleteRtvVideo = (videoId) => async (dispatch, getState) => {
  const {login: {token}, player:{selectedPartner}} = getState();
  const res = await api.deleteRtvVideo(videoId, token);

  if (res.status === 200) {
    dispatch(showMessage('MY_VIDEO', 'MY_VIDEO_REMOVED'));
  } else {
    dispatch(showMessage('MY_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getRtvVideos(selectedPartner.uid));
};

export const showPlayerInsufficientFundsModal = () => ({
  type: 'PLAYER_SHOW_INSUFFICIENT_FUNDS_MODAL'
});

export const hidePlayerInsufficientFundsModal = () => ({
  type: 'PLAYER_HIDE_INSUFFICIENT_FUNDS_MODAL'
});

export const videosStoreReceived = (videosStore, videosStoreSoon) => ({
  type: 'PLAYER_VIDEOS_STORE_RECEIVED',
  videosStore,
  videosStoreSoon
});

export const getVideosStore = (partnerId, fieldId = null, serviceId = null, fromDatetime = null, toDatetime = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res1 = await api.getVideosStore(token, partnerId, fieldId, serviceId, fromDatetime, toDatetime);
  const res2 = await api.getVideosStoreSoon(token, partnerId, fieldId, serviceId, fromDatetime, toDatetime);
  if (res1.status === 200 && res2.status === 200) {
    dispatch(videosStoreReceived(res1.json, res2.json));
  } else {
    dispatch(videosStoreReceived(null));
  }
};

export const videosStorePartnerVideoReceived = videosStorePartnerVideo => ({
  type: 'PLAYER_PARTNER_VIDEOS_STORE_RECEIVED',
  videosStorePartnerVideo
});

export const getVideosStorePartnerVideo = (partnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getVideosStorePartnerVideo(token, partnerId);
  if (res.status === 200) {
    dispatch(videosStorePartnerVideoReceived(res.json));
  } else {
    dispatch(videosStorePartnerVideoReceived(null));
  }
};

export const showPlayerHowPurchaseModal = () => ({
  type: 'PLAYER_SHOW_HOW_PURCHASE_MODAL'
});

export const hidePlayerHowPurchaseModal = () => ({
  type: 'PLAYER_HIDE_HOW_PURCHASE_MODAL'
});

export const addPermanentVideoPlaying = videoId => ({
  type: 'PLAYER_ADD_PERMANENT_VIDEO_PLAYING', videoId
});

export const addNewVideoPlaying = videoId => ({
  type: 'PLAYER_ADD_NEW_VIDEO_PLAYING', videoId
});

export const addRtvVideoPlaying = videoId => ({
  type: 'PLAYER_ADD_RTV_VIDEO_PLAYING', videoId
});

export const updatePlayerLanguage = language => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.updateUserLanguage(language, token);

  if (res.status !== 200) {
    dispatch(showMessage('CHANGE_LANGUAGE', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(refreshPlayerProfile());
};

export const eventsReceived = events => ({
  type: 'PLAYER_EVENTS_RECEIVED',
  events
});

export const getEvents = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getEvents(token);
  if (res.status === 200) {
    dispatch(eventsReceived(res.json));
  } else {
    dispatch(showMessage('SPECIAL_EVENTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const partnerEventsReceived = partnerEvents => ({
  type: 'PLAYER_PARTNER_EVENTS_RECEIVED',
  partnerEvents
});

export const getPartnerEvents = partnerId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getPartnerEvents(partnerId, token);
  if (res.status === 200) {
    const partnerEvents = res.json;
    dispatch(partnerEventsReceived(partnerEvents));
  } else {
    dispatch(showMessage('SPECIAL_EVENTS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const sponsorsReceived = sponsors => ({
  type: 'PLAYER_SPONSORS_RECEIVED',
  sponsors
});

export const getSponsors = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getSponsors(token);
  if (res.status === 200) {
    dispatch(sponsorsReceived(res.json));
  } else {
    dispatch(showMessage('SPONSORS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showUserTagModal = () => ({
  type: 'PLAYER_SHOW_USER_TAG_MODAL'
});

export const hideUserTagModal = () => ({
  type: 'PLAYER_HIDE_USER_TAG_MODAL'
});

export const showStorageModal = () => ({
  type: 'PLAYER_SHOW_STORAGE_MODAL'
});

export const hideStorageModal = () => ({
  type: 'PLAYER_HIDE_STORAGE_MODAL'
});

export const userTagsReceived = userTags => ({
  type: 'PLAYER_USER_TAGS_RECEIVED',
  userTags
});

export const getUserTags = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getUserTags(token);
  if (res.status === 200) {
    dispatch(userTagsReceived(res.json));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const saveUserTag = (userTag, videoId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  if (userTag.id) {
    res = await api.updateUserTag(userTag, token);
  } else {
    res = await api.saveUserTag(userTag, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('TAGS', 'TAG_SAVED'));
    if (videoId !== null) {
      dispatch(getUserVideoTags(videoId));
    }
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getUserTags());
};

export const newUserTag = () => ({
  type: 'PLAYER_NEW_USER_TAG'
});

export const userTagSelected = selectedUserTag => ({
  type: 'PLAYER_USER_TAG_SELECTED',
  selectedUserTag
});

export const deleteUserTag = userTagId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.deleteUserTag(token, userTagId);

  if (res.status === 200) {
    dispatch(getUserTags());
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const taggedUserVideosReceived = taggedUserVideos => ({
  type: 'PLAYER_TAGGED_USER_VIDEOS_RECEIVED',
  taggedUserVideos
});

export const getTaggedUserVideos = (tags = []) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getUserVideos(tags, token);
  if (res.status === 200) {
    dispatch(taggedUserVideosReceived(res.json));
  } else {
    dispatch(showMessage('USER_VIDEO', `Error ${res.status}: ${res.json.error}`));
  }
};

export const myVideoActiveTab = activeTab => ({
  type: 'PLAYER_MY_VIDEO_ACTIVE_TAB',
  activeTab
});

export const selectVideoForTagging = video => ({
  type: 'PLAYER_VIDEO_FOR_TAGGING_SELECTED',
  video
});

// USER VIDEO TAGS

export const showUserVideoTagModal = () => ({
  type: 'PLAYER_SHOW_USER_VIDEO_TAG_MODAL'
});

export const hideUserVideoTagModal = () => ({
  type: 'PLAYER_HIDE_USER_VIDEO_TAG_MODAL'
});

export const userVideoTagsReceived = userVideoTags => ({
  type: 'PLAYER_USER_VIDEO_TAGS_RECEIVED',
  userVideoTags
});

export const getUserVideoTags = videoId => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getUserVideoTags(videoId, token);
  if (res.status === 200) {
    dispatch(userVideoTagsReceived(res.json));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }
};

export const saveUserVideoTag = userVideoTag => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let res = null;
  const payload = {...userVideoTag};
  delete payload.startStr;
  delete payload.endStr;
  if (payload.id) {
    res = await api.updateUserVideoTag(payload, token);
  } else {
    res = await api.saveUserVideoTag(payload, token);
  }

  if (res.status === 200 || res.status === 201) {
    dispatch(hideUserVideoTagModal());
    dispatch(showMessage('TAGS', 'TAG_SAVED'));
  } else if (res.json.error === 'duration_error') {
    dispatch(showMessage('TAGS', 'TAG_BAD_START_TIME'));
  } else {
    dispatch(showMessage('TAGS', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getUserVideoTags(payload.userVideoId));
};

export const newUserVideoTag = (videoId, stopTime, userTagId, startTime) => ({
  type: 'PLAYER_NEW_USER_VIDEO_TAG',
  videoId,
  stopTime,
  userTagId,
  startTime
});

export const userVideoTagSelected = selectedUserVideoTag => ({
  type: 'PLAYER_USER_VIDEO_TAG_SELECTED',
  selectedUserVideoTag
});

export const deleteUserVideoTags = (userVideoTags, videoId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const promises = userVideoTags.map(async t => {
    const p = await api.deleteUserVideoTag(token, t.id);
    return p;
  });
  await Promise.all(promises);
  dispatch(getUserVideoTags(videoId));
};

export const exportHighlights = (userVideoTags, videoId, selectedCameras) => async (dispatch, getState) => {
  const {login: {token}} = getState();

  dispatch(showMessage('HIGHLIGHT', 'HIGHLIGHT_OPERATION_IN_PROGRESS'));

  const promises = userVideoTags.map(async t => {
    const tracking = [
      {
        seconds: t.startTime,
      },
      {
        seconds: t.stopTime,
      }
    ];

    const p = await api.cutPlayerPermanentVideo(videoId, {videoId, name: t.userTag.label, description: t.description, tracking, selectedCameras}, token);
    return p;
  });
  await Promise.all(promises);
  setIntervalX(() => {
    dispatch(getPlayerPermanentVideos());
  }, 10000, 5);
};

export const exportPlaylists = (userVideoTags, videoId, selectedCameras) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  let body = [];
  userVideoTags.forEach(t => {
    body.push({
      name: t.userTag.label,
      description: t.description,
      tracking: [{seconds: t.startTime}, {seconds: t.stopTime}],
      selectedCameras: selectedCameras
    })
  });
  const res = await api.createPlaylist(videoId, body, token);
  if (res.status === 200) {
    dispatch(showMessage('Playlist', 'PLAYLIST_OPERATION_IN_PROGRESS'));
    setIntervalX(() => {
      dispatch(getPlayerPlaylists());
    }, 10000, 5);
  } else {
    dispatch(showMessage('ERROR', `Error ${res.status}: ${res.json.error}`));
  }
};

export const addTaggedVideoPlaying = videoId => ({
  type: 'PLAYER_ADD_TAGGED_VIDEO_PLAYING', videoId
});

export const showPlayerChooseRtvChannelModal = () => ({
  type: 'PLAYER_SHOW_CHOOSE_RTV_CHANNEL_MODAL'
});

export const hidePlayerChooseRtvChannelModal = () => ({
  type: 'PLAYER_HIDE_CHOOSE_RTV_CHANNEL_MODAL'
});

export const rtvChannelSelected = rtvChannel => ({
  type: 'PLAYER_RTV_CHANNEL_SELECTED',
  rtvChannel
});

export const favouriteRtvChannelsReceived = favouriteRtvChannels => ({
  type: 'PLAYER_FAVOURITE_RTV_CHANNELS_RECEIVED',
  favouriteRtvChannels
});

export const getFavouriteRtvChannels = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getFavouriteRtvChannels(token);
  if (res.status === 200) {
    dispatch(favouriteRtvChannelsReceived(res.json));
  } else {
    dispatch(showMessage('FAVORITES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const addRtvChannelToFavourite = rtv_channel_id => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.addRtvChannelToFavourite(rtv_channel_id, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('FAVORITES', 'FAVORITES_RTV_CHANNEL_ADDED'));
  } else {
    dispatch(showMessage('FAVORITES', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getFavouriteRtvChannels());
};

export const removeRtvChannelFromFavourite = rtv_channel_id => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.removeRtvChannelFromFavourite(rtv_channel_id, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('FAVORITES', 'FAVORITES_RTV_CHANNEL_REMOVED'));
  } else {
    dispatch(showMessage('FAVORITES', `Error ${res.status}: ${res.json.error}`));
  }

  dispatch(getFavouriteRtvChannels());
};

export const showPlayersModal = () => ({
  type: 'PLAYER_SHOW_BOOKING_PLAYERS_MODAL'
});

export const hidePlayersModal = () => ({
  type: 'PLAYER_HIDE_BOOKING_PLAYERS_MODAL'
});

export const playersReceived = players => ({
  type: 'PLAYER_PLAYERS_LIST_RECEIVED',
  players
});

export const cleanPlayersReceived = () => ({
  type: 'PLAYER_PLAYERS_CLEAN_LIST_RECEIVED',
});

export const searchPlayers = (nickname, name, surname, affiliated, slotId, partnerId) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.playerGetPlayers(nickname, name, surname, affiliated, slotId, partnerId, token);
  if (res.status === 200) {
    dispatch(playersReceived(res.json));
  } else {
    dispatch(showMessage('SEARCH', `Error ${res.status}: ${res.json.error}`));
  }
};

export const showCameraModal = () => ({
  type: 'PLAYER_SHOW_CAMERA_MODAL'
});

export const hideCameraModal = () => ({
  type: 'PLAYER_HIDE_CAMERA_MODAL'
});

export const highlightOrPlaylistSelected = value => ({
  type: 'HIGHLIGHT_OR_PLAYLIST_SELECTED',
  value
});

export const setSelectedUserVideoTags = tags => ({
  type: 'PLAYER_SET_SELECTED_USER_VIDEO_TAGS',
  tags
});

export const clearSelectedUserVideoTags = () => ({
  type: 'PLAYER_CLEAR_SELECTED_USER_VIDEO_TAG'
});

export const postUsageData = (usageType, partnerId = null, rtvChannelId = null, slotId = null, streaming = null, contextPage = null, videoPartnerTitle = null, videoPartnerName = null) => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.postUsageData(usageType, partnerId, rtvChannelId, slotId, streaming, contextPage, videoPartnerTitle, videoPartnerName, token);

  if (res.status !== 200 && res.status !== 201) {
    dispatch(showMessage('USAGE_DATA', `Error ${res.status}: ${res.json.error}`));
  }
};

export const rtvCategoriesReceived = categories => ({
  type: 'PLAYER_RTV_CATEGORIES_RECEIVED',
  categories
});

export const playerGetRtvCategories = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.playerGetRtvCategories(token);
  if (res.status === 200) {
    dispatch(rtvCategoriesReceived(res.json));
  } else {
    dispatch(showMessage('RTV_CATEGORIES', `Error ${res.status}: ${res.json.error}`));
  }
};

export const selectCategory = category => ({
  type: 'PLAYER_SELECT_RTV_CATEGORY',
  category
});

export const showEditPlayersModal = () => ({
  type: 'PLAYER_SHOW_BOOKING_EDIT_PLAYERS_MODAL'
});

export const hideEditPlayersModal = () => ({
  type: 'PLAYER_HIDE_BOOKING_EDIT_PLAYERS_MODAL'
});

export const saveEditedPlayers = (invitedPlayers, invitedGuests, slotId, bookingId) => async (dispatch, getState) => {
  const {login: {profile, token}, player: {selectedPartner, bookingFieldId, bookingSlot, bookingSlots}} = getState();

  const bookingData = {invitedPlayers, invitedGuests, slotIds: [slotId]}

  const res = await api.saveEditedPlayers(bookingData, bookingId, token);

  if (res.status === 200 || res.status === 201) {
    dispatch(showMessage('BOOKINGS', 'BOOKING_SAVED'));
  } else if (res.json.error === 'not_enough_credit') {
    dispatch(showMessage('BOOKINGS', 'NOT_ENOUGH_CREDIT'));
    dispatch(push('user-top-up-account'));
  } else if (res.json.error === 'slot_too_long') {
    dispatch(showMessage('BOOKINGS', 'SLOT_TOO_LONG'));
  } else if (res.json.error === 'old_slot') {
    dispatch(showMessage('BOOKINGS', 'BOOKING_EVENT_HAS_PASSED'));
  } else {
    dispatch(showMessage('BOOKINGS', `Error ${res.status}: ${res.json.error}`));
  }

  const targetDate = bookingSlot ? new Date(bookingSlot.startDatetime) : new Date(bookingSlots[0].startDatetime);
  dispatch(getSlots(selectedPartner.uid, bookingFieldId, targetDate));
  dispatch(getBookings('all'));
  dispatch(hideEditPlayersModal());
  dispatch(hideBookingSlotModal());
  dispatch(refreshPlayerProfile());
};

export const setPlayerVideoStoreActiveTab = activeTab => ({
  type: 'PLAYER_VIDEO_STORE_ACTIVE_TAB',
  activeTab
});

export const liveVideosReceived = liveVideos => ({
  type: 'PLAYER_LIVE_VIDEOS_RECEIVED',
  liveVideos
});

export const getLiveVideos = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getLiveVideos(token);
  if (res.status === 200) {
    dispatch(liveVideosReceived(res.json));
  } else {
    dispatch(showMessage('LIVE', `Error ${res.status}: ${res.json.error}`));
  }
}

export const comingSoonVideosReceived = comingSoonVideos => ({
  type: 'PLAYER_COMING_SOON_VIDEOS_RECEIVED',
  comingSoonVideos
});

export const getComingSoonVideos = () => async (dispatch, getState) => {
  const {login: {token}} = getState();
  const res = await api.getComingSoonVideos(token);
  if (res.status === 200) {
    dispatch(comingSoonVideosReceived(res.json));
  } else {
    dispatch(showMessage('COMING SOON', `Error ${res.status}: ${res.json.error}`));
  }
}

export const showBuySubscriptionOrVideoModal = () => ({
  type: 'PLAYER_SHOW_NEW_SUBSCRIPTION_OR_VIDEO_MODAL'
});

export const hideBuySubscriptionOrVideoModal = () => ({
  type: 'PLAYER_HIDE_NEW_SUBSCRIPTION_OR_VIDEO_MODAL'
});