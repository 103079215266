import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import MyNavbar from '../components/navbar';
import App from './app';
import Login from './login';
import SignUpPartner from './sign-up-partner';
import SignUpPlayer from './sign-up-player';
import PlayerProfile from './player-profile';
import PlayerVideo from './player-video';
import PartnerProfile from './partner-profile';
import PartnerEvents from './partner-events';
import PartnerEvent from './partner-event';
import PartnerFields from './partner-fields';
import PartnerField from './partner-field';
import PartnerService from './partner-service';
import PartnerFieldCalendar from './partner-field-calendar';
import PartnerServiceCalendar from './partner-service-calendar';
import ShowPartner from './show-partner';
import ShowPartnerSpecialist from './show-partner-specialist';
import PlayerBookingCalendar from './player-booking-calendar';
import PlayerServiceBookingCalendar from './player-service-booking-calendar';
import PartnerBookingCalendar from './partner-booking-calendar';
import PartnerServiceBookingCalendar from './partner-service-booking-calendar';
import VerifyEmail from '../components/verify-email';
import ResetPassword from '../components/reset-password';
import ConfirmPlayerGroup from '../components/confirm-player-group';
import PartnerBookingsReport from '../components/partner-bookings-report';
import ConfirmChangeEmail from '../components/confirm-change-email';
import AdminAdditionalSpace from '../components/admin-additional-space.js';
import UserTopUpAccount from '../components/user-top-up-account';
import PlayerShowPartners from '../components/player-show-partners';
import AdminPlayerManagement from '../components/admin-player-management';
import AdminSlotManagement from '../components/admin-slot-management';
import VideoSharing from '../components/video-sharing';
import AdminCreatePartner from '../components/admin-create-partner';
import withTracker from '../components/with-tracker';
import PlayerReplayerTv from '../components/player-replayer-tv';
import PlayerReplayerTvVideoPartner from '../components/player-replayer-tv-video-partner';
import PlayerVideoStore from '../components/player-video-store';
import PlayerVideoStorePartner from '../components/player-video-store-partner';
import AdminPartnerManagement from '../components/admin-partner-management';
import AdminShowPartner from '../components/admin-show-partner';
import PlayerReplayerTvHome from '../components/player-replayer-tv-home';
import AdminSponsor from '../components/admin-sponsor';
import PlayerSponsor from '../components/player-sponsor';
import PlayerCalendars from '../components/player-calendars';
import AdminReport from '../components/admin-report';
import AdminNvrTracking from '../components/admin_nvr_tracking';
import AdminRtvCategories from '../components/admin-rtv-categories'
import PartnerReport from '../components/partner-report';
import PlayerShowSportSpecialists from '../components/player-show-sport-specialists';
import PartnerVideoStore from '../components/partner-video-store';
import PartnerReplayerTvHome from '../components/partner-replayer-tv-home';
import PartnerReplayerTv from '../components/partner-replayer-tv';
import PartnerVideo from './partner-video';
import PlayerBookings from '../components/player-bookings';
import PlayerLiveVideos from '../components/player-live-videos';
import PlayerComingSoonVideos from '../components/player-coming-soon-videos';

const Root = ({store, history}) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MyNavbar/>
      <Switch>
        <Fragment>
          <Route exact path="/" component={withTracker(App)}/>
          <Route path="/login" component={withTracker(Login)}/>
          <Route path="/sign-up-player" component={withTracker(SignUpPlayer)}/>
          <Route path="/sign-up-partner" component={withTracker(SignUpPartner)}/>
          <Route path="/player-profile" component={withTracker(PlayerProfile)}/>
          <Route path="/player-video" component={withTracker(PlayerVideo)}/>
          <Route path="/partner-video" component={withTracker(PartnerVideo)}/>
          <Route path="/partner-profile" component={withTracker(PartnerProfile)}/>
          <Route path="/partner-events" component={withTracker(PartnerEvents)}/>
          <Route path="/partner-event" component={withTracker(PartnerEvent)}/>
          <Route path="/partner-fields" component={withTracker(PartnerFields)}/>
          <Route path="/partner-field" component={withTracker(PartnerField)}/>
          <Route path="/partner-service" component={withTracker(PartnerService)}/>
          <Route path="/partner-field-calendar" component={withTracker(PartnerFieldCalendar)}/>
          <Route path="/partner-service-calendar" component={withTracker(PartnerServiceCalendar)}/>
          <Route path="/partner-report" component={withTracker(PartnerReport)}/>
          <Route path="/show-partner" component={withTracker(ShowPartner)}/>
          <Route path="/show-partner-specialist" component={withTracker(ShowPartnerSpecialist)}/>
          <Route path="/player-booking-calendar" component={withTracker(PlayerBookingCalendar)}/>
          <Route path="/player-service-booking-calendar" component={withTracker(PlayerServiceBookingCalendar)}/>
          <Route path="/player-calendars" component={withTracker(PlayerCalendars)}/>
          <Route path="/partner-booking-calendar" component={withTracker(PartnerBookingCalendar)}/>
          <Route path="/partner-video-store" component={withTracker(PartnerVideoStore)}/>
          <Route path="/partner-service-booking-calendar" component={withTracker(PartnerServiceBookingCalendar)}/>
          <Route path="/verify-email/:token" component={withTracker(VerifyEmail)}/>
          <Route path="/reset-password/:token" component={withTracker(ResetPassword)}/>
          <Route path="/confirm-player-group" component={withTracker(ConfirmPlayerGroup)}/>
          <Route path="/confirm-change-email/:token" component={withTracker(ConfirmChangeEmail)}/>
          <Route path="/admin-report" component={withTracker(AdminReport)}/>
          <Route path="/admin-additional-space" component={withTracker(AdminAdditionalSpace)}/>
          <Route path="/user-top-up-account" component={withTracker(UserTopUpAccount)}/>
          <Route path="/player-show-sport-centers" component={withTracker(PlayerShowPartners)}/>
          <Route path="/player-show-sport-specialists" component={withTracker(PlayerShowSportSpecialists)}/>
          <Route path="/admin-player-management" component={withTracker(AdminPlayerManagement)}/>
          <Route path="/admin-slot-management" component={withTracker(AdminSlotManagement)}/>
          <Route path="/v/:sharedId" component={withTracker(VideoSharing)}/>
          <Route path="/admin-create-partner" component={withTracker(AdminCreatePartner)}/>
          <Route path="/player-replayer-tv" component={withTracker(PlayerReplayerTv)}/>
          <Route path="/player-replayer-tv-video-partner" component={withTracker(PlayerReplayerTvVideoPartner)}/>
          <Route path="/player-video-store" component={withTracker(PlayerVideoStore)}/>
          <Route path="/player-video-store-partner" component={withTracker(PlayerVideoStorePartner)}/>
          <Route path="/admin-partner-management" component={withTracker(AdminPartnerManagement)}/>
          <Route path="/admin-show-partner" component={withTracker(AdminShowPartner)}/>
          <Route path="/player-replayer-tv-home" component={withTracker(PlayerReplayerTvHome)}/>
          <Route path="/partner-replayer-tv-home" component={withTracker(PartnerReplayerTvHome)}/>
          <Route path="/partner-replayer-tv" component={withTracker(PartnerReplayerTv)}/>
          <Route path="/admin-sponsor" component={withTracker(AdminSponsor)}/>
          <Route path="/player-sponsor" component={withTracker(PlayerSponsor)}/>
          <Route path="/admin-rtv-categories" component={withTracker(AdminRtvCategories)}/>
          <Route path="/admin-nvr-tracking" component={withTracker(AdminNvrTracking)}/>
          <Route path="/player-bookings" component={withTracker(PlayerBookings)}/>
          <Route path="/player-live-videos" component={withTracker(PlayerLiveVideos)}/>
          <Route path="/player-coming-soon-videos" component={withTracker(PlayerComingSoonVideos)}/>
        </Fragment>
      </Switch>
      <footer className="footer bg_blue_rp py-3">
        <div className="container text-center">
          <p>Copyrights &copy; {new Date().getFullYear()} by Replayer. All Rights Reserved. | Replayer S.r.l. P.IVA 02685150993 capitale sociale versato 216.066,48 &euro;</p>
        </div>
      </footer>
    </ConnectedRouter>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
