/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import {push} from 'connected-react-router';
import CommonMetaTag from './common-meta-tag';
import {searchPartners, selectPartner, getPartnerFields, getPartnerServices, getPartnerRtvChannels} from '../actions/admin';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';

const AdminPartnerManagement = ({
  token,
  searchPartners,
  partners,
  selectPartner
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  return (
    <Formik
      initialValues={{businessName: null}}
      onSubmit={searchPartners}
    >
      {({
        handleSubmit,
        handleChange,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4 style={{marginTop: 30}}>{t('SEARCH_PARTNER')}</h4>
                    <p>{t('SEARCH_PARTNER_DESC')}</p>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('BUSINESS_NAME')}</Form.Label>
                        <Form.Control required name="businessName" placeholder={t('BUSINESS_NAME_DESC')} type="text" maxLength="255" value={values.businessName || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="submit">{t('SEARCH')}</Button>
                    </Form>
                  </Col>
                </Row>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4 style={{marginTop: 30}}>{t('SEARCH_PARTNER_RESULT_SUCCESSFUL')}</h4>
                    {
                      partners && partners.length > 0 ?
                        null :
                        <p>{t('SEARCH_PARTNER_OR_PLAYER_RESULT_FAILED')}</p>
                    }
                  </Col>
                </Row>
                <div className="row">
                  {partners.map(p =>
                    (
                      <div key={p.uid} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group">
                        <a href="#" className="card video_store_bg_rp ease_fx" onClick={selectPartner(p)}>
                          <div className="card-horizontal">
                            <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                              {
                                p.storeImage && p.storeImage.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.storeImage[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  <div className="align-self-center video_store_nologo_overlay_rp"/>
                              }
                            </div>
                            <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                              <h5 className="card-title mb-0">{p.businessName}</h5>
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  )}
                </div>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

AdminPartnerManagement.propTypes = {
  token: PropTypes.string,
  searchPartners: PropTypes.func.isRequired,
  partners: PropTypes.array,
  selectPartner: PropTypes.func.isRequired
};

AdminPartnerManagement.defaultProps = {
  token: null,
  partners: []
};

const mapStateToProps = ({login: {token}, admin: {partners, selectedPartner, isChangePasswordModalVisible}}) => ({
  token,
  partners,
  isChangePasswordModalVisible,
  selectedPartner
});

const mapDispatchToProps = dispatch => ({
  searchPartners: values => {
    dispatch(searchPartners(values.businessName));
  },
  selectPartner: partner => () => {
    dispatch(selectPartner(partner));
    dispatch(getPartnerFields(partner.uid));
    dispatch(getPartnerServices(partner.uid));
    dispatch(getPartnerRtvChannels(partner.uid));
    dispatch(push('/admin-show-partner'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPartnerManagement));
