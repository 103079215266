import React, {Fragment} from 'react';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Tab, Tabs} from 'react-bootstrap';
import AdminReportAllPlayers from './admin-report-all-players';
import AdminReportTransactions from './admin-report-transactions';
import CommonMetaTag from './common-meta-tag';
import {useTranslation} from 'react-i18next';
import AdminReportSubscriptions from './admin-report-subscriptions';
import AdminReportUsage from './admin-report-usage';


const AdminReport = () => {
    const {t, i18n} = useTranslation();

    return(
        <Fragment>
            <CommonMetaTag/>
            <Tabs defaultActiveKey="transactions" className="myvideo-tabs" style={{marginTop: 100, marginLeft: 130, marginRight: 130}} mountOnEnter unmountOnExit>
                <Tab eventKey="transactions" title={t('TRANSACTIONS')} tabClassName="myvideo-tab">
                    <AdminReportTransactions/>
                </Tab>
                <Tab eventKey="players" title={'Player'}  tabClassName="myvideo-tab">
                    <AdminReportAllPlayers/>
                </Tab>
                <Tab eventKey="subscriptions" title={t('SUBSCRIPTIONS')} tabClassName="myvideo-tab">
                    <AdminReportSubscriptions/>
                </Tab>
                <Tab eventKey="usage_statistics" title={t('USAGE_STATISTICS')} tabClassName="myvideo-tab">
                    <AdminReportUsage/>
                </Tab>
            </Tabs>
        </Fragment>
    );
};

export default AdminReport;