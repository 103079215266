import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import it from './it.json';
import en from './en.json';
import fr from './fr.json';
import de from './de.json';

const resources = {en, it, fr, de};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
