import React, {Fragment} from 'react';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Tab, Tabs} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerBookingsReport from './partner-bookings-report';
import PartnerReportTransaction from './partner-report-transaction';
import PartnerReportSubscriptions from './partner-report-subscriptions';
import PartnerReportPlayers from './partner-report-players';

const PartnerReport = ({ownedRtvChannels}) => {
    const {t, i18n} = useTranslation();

    return(
        <Fragment>
            <CommonMetaTag/>
            <Tabs defaultActiveKey="transactions" className="myvideo-tabs" style={{marginTop: 100, marginLeft: 130, marginRight: 130}} mountOnEnter unmountOnExit>
                <Tab eventKey="transactions" title={t('TRANSACTIONS')} tabClassName="myvideo-tab">
                    <PartnerReportTransaction/>
                </Tab>
                <Tab eventKey="bookings" title={t('BOOKINGS')} tabClassName="myvideo-tab">
                    <PartnerBookingsReport/>
                </Tab>
                {ownedRtvChannels.length > 0 ?
                <Tab eventKey="subscriptions" title={t('SUBSCRIPTIONS')} tabClassName="myvideo-tab">
                    <PartnerReportSubscriptions/>
                </Tab>
                : null}
                <Tab eventKey="players" title={t('PLAYER')} tabClassName="myvideo-tab">
                    <PartnerReportPlayers/>
                </Tab>
            </Tabs>
        </Fragment>
    );
};

const mapStateToProps = ({partner: {ownedRtvChannels}}) => ({
    ownedRtvChannels
  });

  export default withRouter(connect(
    mapStateToProps,
  )(PartnerReport));