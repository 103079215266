/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Fade} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import {getRtvVideos, ownedRtvChannelSelected, selectCategory} from '../actions/partner';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';
import rIcon from '../images/Replayer_favicon.png'
import allChannelsIcon from '../images/apps_white_24dp.svg'

const PartnerReplayerTvHome = ({
  token,
  profile,
  ownedRtvChannels,
  goto,
  showReplayerTV,
  rtvCategories,
  selectCategory,
  deselectCategory,
  selectedCategory,
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }
  if(ownedRtvChannels.length === 1){
    showReplayerTV(profile.uid, ownedRtvChannels[0]);
  }

  const getCategoryLogo = (categoryId, imageName) => {
    if (imageName.length > 0) {
      return `${Configuration.apiEndPoint}/admin/categories/image/${categoryId}?load=${imageName[0]}`;
    }
    return rIcon;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
        <div className="page-header header-filter clear-filter replayertv_sportcenter_hero_rp" data-parallax="true">
            <div className="replayertv_sportcenter_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div>
                        <h4 className="section_title_neg_rp">{t('REPLAYER_TV')}</h4>
                      </div>
                      <p>{t('VIDEO_STREAMING_INFO')}</p>
                      <hr className="hr_blue"/>
                      <div className="row">
                      {rtvCategories ?
                        <div className="col-lg-12 mt-sm-2">
                          <Button onClick={() => selectCategory({})} variant="categoryButton" style={{borderColor: "white", backgroundColor: Object.keys(selectedCategory).length === 0 ? "#FFFFff40" : null}}>
                            <img style={{height: 40, width: 40}} src={allChannelsIcon} alt={t('ALL_CHANNELS_LOGO')}/>
                            {" " + t('ALL_CHANNELS')}
                          </Button>
                          {rtvCategories.map(c => 
                          <Button key={c.id} onClick={() => c.id === selectedCategory.id ? deselectCategory() : selectCategory(c)} variant="categoryButton" style={{borderColor: c.color, backgroundColor: c.id === selectedCategory.id ? c.color : null}}>
                            <img style={{height: 40, width: 40}} src={getCategoryLogo(c.id, c.imageName)} alt={t('CATEGORY_LOGO')}/>
                            {" " + c.label}
                          </Button>
                          )}
                        </div>
                      : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {ownedRtvChannels.filter(c => c.type === 0).map(channel =>
                    (
                      Object.keys(selectedCategory).length === 0 || selectedCategory.rtvChannels.includes(channel.id) ?
                      <div key={channel.id} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group">
                        <a href="#" className="card video_store_bg_rp ease_fx" onClick={() =>showReplayerTV(profile.uid, channel)}>
                          <div className="card-horizontal">
                            <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                              {
                                channel.logoNames && channel.logoNames.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/partners/rtv_channels/${channel.id}/images?load=${channel.logoNames[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  profile.storeImage && profile.storeImage.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/images/${profile.uid}?load=${profile.storeImage[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  <div className="align-self-center video_store_nologo_overlay_rp"/>
                              }
                            </div>
                            <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                              <h5 className="card-title mb-0">{channel.name}</h5>                              
                            </div>
                          </div>
                        </a>
                      </div>
                    : null)
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </Fragment>
  );
};

PartnerReplayerTvHome.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object.isRequired,
  goto: PropTypes.func.isRequired,
  showReplayerTV: PropTypes.func.isRequired,
  ownedRtvChannels: PropTypes.array.isRequired,
};

PartnerReplayerTvHome.defaultProps = {
  token: null,
  ownedRtvChannels: []
};

const mapStateToProps = ({login: {token, profile}, partner: {ownedRtvChannels, rtvCategories, selectedCategory}}) => ({
  token,
  profile,
  ownedRtvChannels,
  rtvCategories,
  selectedCategory
});

const mapDispatchToProps = dispatch => ({
  showReplayerTV: (uid, rtvChannel) => {  
    dispatch(ownedRtvChannelSelected(rtvChannel));
    dispatch(getRtvVideos(uid, rtvChannel.id));
    dispatch(push('/partner-replayer-tv'));
    return;
  },
  goto: (path, action = null) => e => {
    e.preventDefault();
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  selectCategory: category => {
    dispatch(selectCategory(category));
  },
  deselectCategory: () => {
    dispatch(selectCategory({}));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerReplayerTvHome));