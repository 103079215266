import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import {changeStoragePrices} from '../actions/admin';
import {useTranslation} from 'react-i18next';

const AdminAdditionalSpace = ({token, storagePrices, changeStoragePrices}) => {
    const {t, i18n} = useTranslation();
    if (token === null) {
      return (
        <Redirect to="/login"/>
      );
    }
  
    return (
        <Formik
      enableReinitialize
      initialValues={storagePrices}
      onSubmit={changeStoragePrices}
    >
      {({
        handleSubmit,
        handleChange,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4 style={{marginTop: 30}}>{t('ADDITIONAL_SPACE')}</h4>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{'XS - entry level <= 20GB'}</Form.Label>
                        <Form.Control required name="xs"  type="number"  value={values.xs || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{'small <= 30GB'}</Form.Label>
                        <Form.Control required name="s" type="number" value={values.s || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{'medium <= 50GB'}</Form.Label>
                        <Form.Control required name="m"  type="number" value={values.m || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{'large <= 100GB'}</Form.Label>
                        <Form.Control required name="l"  type="number" value={values.l || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{'XL <= 200GB'}</Form.Label>
                        <Form.Control required name="xl"  type="number" value={values.xl || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{'XXL <= 500GB'}</Form.Label>
                        <Form.Control required name="xxl"  type="number" value={values.xxl || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{'XXXL <= 1000GB'}</Form.Label>
                        <Form.Control required name="xxxl"  type="number" value={values.xxxl || 0} onChange={handleChange} step={0.01}/>
                      </Form.Group>
                      <Button type="submit">{t('SAVE')}</Button>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
    );
};

AdminAdditionalSpace.propTypes = {
    token: PropTypes.string,
    storagePrices: PropTypes.object,
  };
  
  AdminAdditionalSpace.defaultProps = {
    token: null,
    storagePrices: {}
  };
  
  const mapStateToProps = ({login: {token}, admin: {storagePrices}}) => ({
    token,
    storagePrices,
  });

  const mapDispatchToProps = dispatch => ({
    changeStoragePrices: values => {
      Object.keys(values).forEach(e => {
        if(values[e] === ""){
          values[e] = 0;
        }
      });
      dispatch(changeStoragePrices(values));
    }
  });
  
  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminAdditionalSpace));