import React from 'react';
import {Helmet} from 'react-helmet';
import ReplayerCommunitySportivi from '../images/Replayer_community_sportivi.jpg';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';

const CommonMetaTag = () => {
  const {t, i18n} = useTranslation();
  return (
    <Helmet>
      <title>Replayer.it ⟩ book ⟩ play ⟩ replay &amp; share</title>
      <meta name="description" content={t('REPLAYER_DESCRIPTION')}/>
      <meta property="og:site_name" content="Replayer"/>
      <meta property="og:title" content="Replayer.it ⟩ book ⟩ play ⟩ replay &amp; share"/>
      <meta property="og:description" content={t('REPLAYER_DESCRIPTION')}/>
      <meta property="og:image" content={`${Configuration.baseUrl}${ReplayerCommunitySportivi}`}/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta name="twitter:card" content="summary_lar/ge_image"/>
      <meta name="twitter:site" content="@Replayer"/>
      <meta name="twitter:title" content="Replayer.it ⟩ book ⟩ play ⟩ replay &amp; share"/>
      <meta name="twitter:description" content={t('REPLAYER_DESCRIPTION')}/>
      <meta name="twitter:image" content={`${Configuration.baseUrl}${ReplayerCommunitySportivi}`}/>
    </Helmet>
  );
};

export default CommonMetaTag;
