import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {Fade, Form, Button, Row, Col, Container} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import DatePicker, {registerLocale} from 'react-datepicker';
import stadium from '../images/sport.svg';
import r from '../images/title_r.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from 'react-i18next';
import it from 'date-fns/locale/it';
registerLocale('it', it);

const SignUpPlayer = ({
  signUp
}) => {
  const {t, i18n} = useTranslation();
  return (
    <Formik
      initialValues={{name: null, surname: null, birthDate: null, email: null, phoneNumber: null, password: null, passwordConfirm: null, privacy0: false, privacy1: false, privacy2: false, privacy3: false, privacy4: false}}
      onSubmit={signUp}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => {

        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear'}}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col xs={12} style={{backgroundColor: '#fff', color: '#000'}}>
                    <img
                      src={r}
                      height="30"
                      className="mx-auto d-block"
                      alt={t('LOGO')}
                      style={{marginTop: 100}}
                    />
                    <Row className="justify-content-center" style={{marginTop: 20}}>
                      <h1>{t('TITLE_REPLAYER')}</h1>
                    </Row>
                    <Row className="justify-content-center">
                      <h4>{t('MOTTO')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <h4 style={{marginTop: 30}}>{t('ENTER_IN')}</h4>
                    </Row>
                    <Row className="justify-content-center">
                      <h5 className="text-center">
                      {t('CREATE_ACCOUNT')}
                      </h5>
                    </Row>
                    <Row className="justify-content-center" style={{marginBottom: 50}}>
                      <h6 className="text-center">
                      {t('HAVE_ACCOUNT')} <a href="/login">{t('ENTER')}</a>
                      </h6>
                    </Row>
                  </Col>
                  <Col className="d-flex justify-content-center" style={{backgroundColor: '#fff', color: '#000'}}>
                    <Col xs={12} sm={12} md={6} className="d-flex justify-content-center">
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                          <Col xs={12} sm={6} md={6}>
                            <Form.Group>
                              <Form.Label>{t('NAME')}*</Form.Label>
                              <Form.Control required name="name" placeholder={t('NAME')} type="text" maxLength={50} value={values.name || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                              {t('NAME')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} md={6}>
                            <Form.Group>
                              <Form.Label>{t('SURNAME')}*</Form.Label>
                              <Form.Control required name="surname" type="text" placeholder={t('SURNAME')} maxLength={50} value={values.surname || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                              {t('SURNAME')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Form.Row>
                          <Col xs={12} sm={6} md={6}>
                            <Form.Group>
                              <Form.Label>{t('BIRTH_DATE')}*</Form.Label><br/>
                              <DatePicker
                                required
                                showMonthDropdown
                                showYearDropdown
                                peekNextMonth
                                locale="it"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select"
                                maxDate={new Date()}
                                selected={(values.birthDate && new Date(values.birthDate).setHours(12)) || null}
                                onChange={val => setFieldValue('birthDate', val)}/>
                              <Form.Text className="text-muted">
                              {t('BIRTH_DATE')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} md={6}>
                            <Form.Group>
                              <Form.Label>{t('TELEPHONE')}*</Form.Label>
                              <Form.Control required name="phoneNumber" type="tel" placeholder={t('TELEPHONE')} value={values.phoneNumber || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                              {t('TELEPHONE')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <Form.Group>
                          <Form.Label>{t('EMAIL')}*</Form.Label>
                          <Form.Control required name="email" type="email" placeholder={t('EMAIL')} value={values.email || ''} onChange={handleChange}/>
                          <Form.Text className="text-muted">
                          {t('EMAIL')}
                          </Form.Text>
                        </Form.Group>
                        <Form.Row>
                          <Col xs={12} sm={6} md={6}>
                            <Form.Group>
                              <Form.Label>{t('PASSWORD')}*</Form.Label>
                              <Form.Control required name="password" type="password" placeholder={t('PASSWORD')} value={values.password || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                              {t('PASSWORD')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                          <Col xs={12} sm={6} md={6}>
                            <Form.Group>
                              <Form.Label>{t('PASSWORD_CONFIRM')}*</Form.Label>
                              <Form.Control required name="passwordConfirm" type="password" placeholder={t('PASSWORD_CONFIRM')} value={values.passwordConfirm || ''} onChange={handleChange}/>
                              <Form.Text className="text-muted">
                                {t('PASSWORD_CONFIRM')}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Form.Row>
                        <h4>{t('PRIVACY')}</h4>
                        <Form.Label>
                        {t('PRIVACY_AUTHORIZATIONS')} <a href="http://www.replayer.it" target="_blank" rel="noopener noreferrer"> www.replayer.it </a> {t('LEGAL_INFO')}
                        </Form.Label>
                        <Form.Group>
                          <Form.Check required name="privacy0" type="checkbox" label={t('CHECKBOX_SUBSCRIBER')} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check name="privacy1" type="checkbox" label={t('LABEL_AUTHORIZATION')} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check name="privacy2" type="checkbox" label={t('STREAMING')} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check name="privacy3" type="checkbox" label={t('NEWSLETTER')}onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group>
                          <Form.Check name="privacy4" type="checkbox" label={t('DATA_TRANSFER')} onChange={handleChange}/>
                        </Form.Group>
                        <Button variant="secondary" style={{marginBottom: 100}} type="submit" size="lg">
                        {t('SEND')}
                        </Button>
                      </Form>
                    </Col>
                  </Col>
                </Row>
                <Row className="justify-content-center" style={{marginTop: 50, marginBottom: 50}}>
                  <Col xs={12} sm={12} md={6} style={{marginLeft: 10, marginRight: 10}}>
                    <h4>{t('SPORT_MANAGER')}</h4>
                    <p>
                    {t('REPLAYER_BENEFITS')}
                    </p>
                    <Button href="https://www.replayer.it/diventa-partner/" target="_blank" rel="noopener noreferrer" variant="primary" size="lg">{t('INFO')}</Button>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </Fragment>
        );
      }}
    </Formik>
  );
};

SignUpPlayer.propTypes = {
  signUp: PropTypes.func.isRequired
};

export default SignUpPlayer;
