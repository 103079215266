import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerFieldCalendar from '../components/partner-field-calendar';
import {showSlotModal, hideSlotModal, newOwnedFieldSlot, saveFieldSlot, ownedFieldSlotSelected, deleteOwnedFieldSlots, getOwnedFieldSlots} from '../actions/partner';
import {showMessage} from '../actions/messages';

const mapStateToProps = ({partner: {ownedField, isSlotModalVisible, ownedFieldSlot, ownedFieldSlots}, login: {token, profile}}) => ({
  profile,
  ownedField,
  isSlotModalVisible,
  isLogged: token !== null,
  ownedFieldSlot,
  ownedFieldSlots
});

const mapDispatchToProps = dispatch => ({
  editSlot: ev => {
    if (ev.slot.bookingId) {
      dispatch(showMessage('CALENDAR', 'CALENDAR_CANNOT_MODIFY_BOOKED_EVENT'));
    } else {
      dispatch(ownedFieldSlotSelected(ev.slot));
      dispatch(showSlotModal());
    }
  },
  newSlot: fieldId => ev => {
    dispatch(newOwnedFieldSlot({fieldId, serviceId: null, startDatetime: ev.start.toISOString(), endDatetime: ev.end.toISOString(), weekDays: [], videoSellPermission: false, rtvOrAcademy: null, price: 0, minimumPlayers: null}));
    dispatch(showSlotModal());
  },
  hideSlotModal: () => {
    dispatch(hideSlotModal());
  },
  saveSlot: (slot, selectedPlayers) => {
    if(slot.presetPlaces === 1)
      slot.minimumPlayers = null;
    
    slot.selectedPlayers = []
    selectedPlayers.forEach(p => {
      slot.selectedPlayers.push(p.uid)
    });

    dispatch(saveFieldSlot(slot));
  },
  deleteSlot: slotData => {
    dispatch(deleteOwnedFieldSlots(slotData));
  },
  getSlotsByWeek: fieldId => targetDate => {
    dispatch(getOwnedFieldSlots(fieldId, false, 'field', targetDate));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerFieldCalendar));
