import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Fade, Container, Row} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {verifyEmail} from '../actions/login';
import {useTranslation} from 'react-i18next';

const VerifyEmail = ({match: {params: {token}}, verifyEmail}) => {
  const {t, i18n} = useTranslation();
  verifyEmail(token);
  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
        <Container>
          <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <h4>{t('VERIFY_EMAIL_IN_PROGRESS')}</h4>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

VerifyEmail.propTypes = {
  match: PropTypes.object.isRequired,
  verifyEmail: PropTypes.func.isRequired
};

export default (connect(
  null,
  dispatch => ({
    verifyEmail: token => {
      dispatch(verifyEmail(token));
    }
  })
)(VerifyEmail));

