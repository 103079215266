import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Fade, Container, Row} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {confirmChangeEmail} from '../actions/login';
import {useTranslation} from 'react-i18next';

const ConfirmChangeEmail = ({match: {params: {token}}, confirmChangeEmail}) => {
  const {t, i18n} = useTranslation();
  confirmChangeEmail(token);
  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
        <Container>
          <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
            <h4>{t('CHANGE_EMAIL_CONFIRM')}</h4>
          </Row>
        </Container>
      </Fade>
    </Fragment>
  );
};

ConfirmChangeEmail.propTypes = {
  match: PropTypes.object.isRequired,
  confirmChangeEmail: PropTypes.func.isRequired
};

export default (connect(
  null,
  dispatch => ({
    confirmChangeEmail: token => {
      dispatch(confirmChangeEmail(token));
    }
  })
)(ConfirmChangeEmail));

