import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import Login from '../components/login';
import {login, showRecoveryPasswordModal, hideRecoveryPasswordModal, recoveryPassword} from '../actions/login';

const mapStateToProps = ({login: {profile, isRecoveryPasswordModalVisible}}) => ({
  profile,
  isRecoveryPasswordModalVisible
});

const mapDispatchToProps = dispatch => ({
  login: credential => {
    dispatch(login(credential));
  },
  goto: (path, action = null) => () => {
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  showRecoveryPasswordModal: () => {
    dispatch(showRecoveryPasswordModal());
  },
  hideRecoveryPasswordModal: () => {
    dispatch(hideRecoveryPasswordModal());
  },
  recoveryPassword: email => {
    dispatch(recoveryPassword(email));
    dispatch(hideRecoveryPasswordModal());
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));
