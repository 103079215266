/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Carousel, Container, Fade, Col, Card, Row, Button} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import {push} from 'connected-react-router';
import Dialog from 'react-bootstrap-dialog';
import {useTranslation} from 'react-i18next';
import {
  partnerSelected,
  getPartnerFields,
  getPartnerGroups,
  getPlayerGroups,
  filterBookingFieldId,
  getSlots,
  purchaseVideo,
  archiveBooking,
  addVideoToPlayerArea,
  addPartnerToFavourite,
  removePartnerFromFavourite,
  showPlayerNewSubscriptionModal,
  showPlayerNewEventSubscriptionModal,
  getRtvVideos,
  getRtvPartnerVideos,
  addNewVideoPlaying,
  filterBookableFields,
  getPartnerEvents,
  getPartnerServices,
  deleteBooking,
  deleteServiceBooking,
  showPlayerChooseRtvChannelModal,
  rtvChannelSelected,
  removeRtvChannelFromFavourite,
  addRtvChannelToFavourite,
  getUserTags,
  getVideo,
  getVideoStreaming,
  postUsageData,
  getLiveVideos,
} from '../actions/player';
import {showMessage} from '../actions/messages';
import Configuration from '../config';
import restaurant from '../images/ristorante.svg';
import bar from '../images/bar_icon.svg';
import accessibility from '../images/disabled_icon.svg';
import showers from '../images/shower_icon.svg';
import shop from '../images/negozio.svg';
import parking from '../images/parking_icon.svg';
import petFriendly from '../images/pets_icon.svg';
import lockerRooms from '../images/locker_room_icon.svg';
import tournaments from '../images/tournaments_icon.svg';
import picnic from '../images/picnic_area_icon.svg';
import relax from '../images/relax_icon.svg';
import camIcon from '../images/cam_icon.svg';
import replayerTvIcon from '../images/replayer_tv_icon.svg';
import buyIcon from '../images/buy_button_icon.svg'
import positionIcon from '../images/location_pointer_icon.svg';
import Iframe from 'react-iframe';
import {subscriptionKind, subscriptionStatuses} from '../constants';
import PlayerNewSubscription from './player-new-subscription';
import PlayerNewEventSubscription from './player-new-event-subscription';
import PlayerInsufficientFunds from './player-insufficient-funds';
import playArrowWhite from '../images/play_arrow_white_48dp.png';
import replayerlogoBianco from '../images/replayer_logo_bianco_40x32px.png';
import noLogo from '../images/club_icon_grey.svg';
import PlayerChooseRtvChannel from './player-choose-rtv-channel';

const PlayerHome = ({
  token,
  profile,
  partners,
  favouritePartners,
  bookings,
  video,
  videoStreaming,
  showPartner,
  deleteBooking,
  showCalendar,
  purchaseVideo,
  archiveBooking,
  addVideoToPlayerArea,
  goto,
  toggleFavourite,
  subscriptions,
  showReplayerTVEvent,
  permanentVideos,
  newVideosPlaying,
  addNewVideoPlaying,
  events,
  chooseReplayerTV,
  gotoReplayerTvHome,
  favouriteRtvChannels,
  toggleFavouriteRtvChannel,
  showReplayerTV,
  showReplayerTVPartnerVideo,
  refreshNewVideos,
  sendUsageData,
  gotoLiveVideos,
}) => {
  const {t, i18n} = useTranslation();

  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  let dialog = null;
  const confirmPurchase = (slots, price) => () => {
    dialog.show({
      title: t('CONFIRM_PURCHASE_TITLE'),
      body: t('CONFIRM_PURCHASE_BODY', {price}),
      actions: [
        Dialog.Action(
          t('YES'),
          () => purchaseVideo(slots),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const confirmArchiveBooking = bookingId => () => {
    dialog.show({
      title: t('CONFIRM_ARCHIVE_TITLE'),
      body: t('CONFIRM_ARCHIVE_DESC'),
      actions: [
        Dialog.Action(
          t('ARCHIVE_BUTTON'),
          () => archiveBooking(bookingId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const deleteBookingDialog = b => () => {
    dialog.show({
      title: t('BOOKING_DELETE'),
      body: b.groupBooking ? t('BOOKING_DELETE_CONFIRM_GROUP') : t('BOOKING_DELETE_CONFIRM'),
      actions: [
        Dialog.Action(
          t('CONFIRM_DELETE'),
          () => deleteBooking(b.groupBooking || !b.multiBooking ? b.bookingId : b.users.filter(u => u.uid === profile.uid)[0].id, b.partnerId, b.sport === undefined, !b.groupBooking && b.multiBooking, partners.find(p => p.uid === b.partnerId).phoneNumber),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('DO_NOT_DELETE'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const videoInProgress = () => () => {
    dialog.show({
      title: t('VIDEO_IN_PROGRESS'),
      body: t('VIDEO_IN_PROGRESS_INFO'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const getPurchaseStatusText = b => {
    if (b.videoPurchasable && !b.videoBought && !b.videoReady) {
      return t('VIDEO_STATUS_PURCHASABLE', {price: b.videoPrice});
    }

    if (b.videoBought && !b.videoReady) {
      return t('VIDEO_STATUS_PROCESSING');
    }

    if (b.videoBought && b.videoReady) {
      return t('VIDEO_STATUS_DOWNLOADABLE');
    }

    return '';
  };

  const getSubscriptionStatus = (partner, channel) => {
    if (subscriptions) {
      const sub = subscriptions.subscriptions.find(s => s.partner === partner.uid && s.rtv_channel_id === channel.id);
      if (sub && new Date(sub.expiry) >= new Date()) {
        return {status: subscriptionStatuses.valid};
      }

      const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
      if (unlimitedSubscription) {
        return {status: subscriptionStatuses.unlimited};
      }

      if (sub && new Date(sub.expiry) < new Date()) {
        return {status: subscriptionStatuses.expired, subscriptionId: sub.id};
      }

      return {status: subscriptionStatuses.noSubscription};
    }

    return {status: subscriptionStatuses.noSubscription};
  };

  const getSubscriptionStatusEvent = (partnerId, rtvChannelId) => {
    if (subscriptions) {
      const sub = subscriptions.subscriptions.find(s => s.partner === partnerId && s.rtv_channel_id === rtvChannelId);
      if (sub && new Date(sub.expiry) >= new Date()) {
        return {status: subscriptionStatuses.valid};
      }

      const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
      if (unlimitedSubscription) {
        return {status: subscriptionStatuses.unlimited};
      }
    }

    return {status: subscriptionStatuses.canRequestEvent};
  };

  const isInAreaVideo = slotId => {
    if (permanentVideos.find(v => v.slotId === slotId)) {
      return true;
    }

    return false;
  };

  const getPartnerLogo = partnerId => {
    const partner = partners.find(p => p.uid === partnerId);
    if (partner && partner.storeImage && partner.storeImage.length > 0) {
      return `${Configuration.apiEndPoint}/images/${partnerId}?load=${partner.storeImage[0]}`;
    }

    return noLogo;
  };

  const styleBackgroundImage = (partnerId, fieldId, fieldImage) => {
    if (partnerId && fieldImage) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage})`, backgroundSize: 'cover'};
    }

    return null;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <h4 className="section_title_neg_rp">{t('WATCH_LIVE')}</h4>
                    <p>{t('WATCH_LIVE_DESC')}</p>
                    <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: -68, marginBottom: '10px'}} className="live-now-mobile">
                      <div class="wrapper">
                        <a class="cta" onClick={gotoLiveVideos} href='#'>
                          <span className="live-now-span" >LIVE NOW</span>
                          <span className="live-now-span">
                            <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                              <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path class="one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#DD004A"></path>
                                <path class="two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#DD004A"></path>
                                <path class="three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#DD004A"></path>
                              </g>
                            </svg>
                          </span> 
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="player-home-new-videos">
                  <div className="row">
                    <div className="col-md-12 ml-auto mr-auto">
                      <h4 className="section_title_neg_rp">{t('PLAYER_HOME_NEW_VIDEOS')}</h4>
                      <p>{t('HIT_REFRESH_BUTTON')}</p>
                      <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: '10px'}}>
                        <Button type="button" className="btn btn_blue_rp video_store_head_filter_button" onClick={refreshNewVideos}>
                          {t('UPDATE_VIDEOS')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                {
                    videoStreaming.map(v =>
                      (
                        <div key={v.slotId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                          <div className="card video_card_bg_rp">
                            <div className= "video_card_image" style={styleBackgroundImage(v.partnerId, v.fieldId, v.fieldImage)}>
                              <div className="videoWrapper" onClick={() => {sendUsageData(v.partnerId, v.slotId, true); window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&token=${v.token}`, '_blank');}}>
                                <Fragment>
                                  <div className="preload h-100 justify-content-center align-items-center">
                                    <img className="play_arrow" src={playArrowWhite}/>
                                  </div>
                                  <div className="preload h-100 justify-content-start align-items-start">
                                    <img className="logo-replayer" src={replayerlogoBianco}/>
                                  </div>
                                  <div className="preload h-100 justify-content-end align-items-start">
                                    <img className="logo-partner" src={getPartnerLogo(v.partnerId)}/>
                                  </div>
                                  <div className="preload h-100 justify-content-center align-items-end">
                                    <div className="video-description justify-content-center align-items-center">{v.description ? v.description.substring(0,60) : ""}</div>
                                  </div>
                                </Fragment>
                              </div>
                            </div>
                            <div className="card-body d-flex flex-column">
                              <h5 className="card-title" style={{marginTop: 20, marginBottom: 20}}>{v.partnerName}</h5>
                              <ul className="list-unstyled" style={{marginBottom: 20}}>
                                <li>{v.fieldName}</li>
                                <li>{new Date(v.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'})}</li>
                                <li>{t('FROM_TO_TIME', {fromTime: new Date(v.startDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'}), toTime: new Date(v.endDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'})})}</li>
                                <li className="font-italic">{v.description}</li>
                              </ul>
                              <div className="text-center mt-auto">
                                <a href="#" className="btn btn_blue_rp" onClick={videoInProgress()} style={{marginBottom: 20}}>{t('VIDEO_IN_PROGRESS')}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  }
                  {
                    video.map(v =>
                      (
                        isInAreaVideo(v.slotId) ?
                          null :
                          <div key={v.slotId} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                            <div className="card video_card_bg_rp">
                              <div className= "video_card_image" style={styleBackgroundImage(v.partnerId, v.fieldId, v.fieldImage)}>
                                <div className="videoWrapper" onClick={() => {sendUsageData(v.partnerId, v.slotId, false); window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.slotId}&token=${v.token}`, '_blank');}}>
                                  <Fragment>
                                    <div className="preload h-100 justify-content-center align-items-center">
                                      <img className="play_arrow" src={playArrowWhite}/>
                                    </div>
                                    <div className="preload h-100 justify-content-start align-items-start">
                                      <img className="logo-replayer" src={replayerlogoBianco}/>
                                    </div>
                                    <div className="preload h-100 justify-content-end align-items-start">
                                      <img className="logo-partner" src={getPartnerLogo(v.partnerId)}/>
                                    </div>
                                    <div className="preload h-100 justify-content-center align-items-end">
                                      <div className="video-description justify-content-center align-items-center">{v.description ? v.description.substring(0,60) : ""}</div>
                                    </div>
                                  </Fragment>
                                </div>
                              </div>
                              <div className="card-body d-flex flex-column">
                                <h5 className="card-title" style={{marginTop: 20, marginBottom: 20}}>{v.partnerName}</h5>
                                <ul className="list-unstyled" style={{marginBottom: 20}}>
                                  <li>{v.fieldName}</li>
                                  <li>{new Date(v.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'})}</li>
                                  <li>{t('FROM_TO_TIME', {fromTime: new Date(v.startDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'}), toTime: new Date(v.endDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'})})}</li>
                                  <li className="font-italic">{v.description}</li>
                                </ul>
                                {v.filesize ?
                                <div className="d-flex" style={{marginBottom: 20}}>
                                  <span className="bold_title_rp mr-2">{t('FILE_SIZE')}</span>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB
                                </div>
                                : null}
                                <div className="text-center mt-auto">
                                  <a href="#" className="btn btn_red_rp mb-2" onClick={addVideoToPlayerArea(v.slotId)}>{t('PLAYER_HOME_MOVE_TO_VIDEO_AREA')}</a>
                                  <p className="card-text note_card_rp">{t('PLAYER_HOME_VIDEO_DELETE_DATE')} {new Date(v.videoExpiry).toLocaleDateString(i18n.language)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                      )
                    )
                  }
                  <div className="col-md-12 col-lg-4 mb-sm-4 card-group">
                    <div className="empty_slot card video_card_bg_rp">
                      <div className="card_image_rp">
                        <div className="videoWrapper"/>
                      </div>
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title" style={{marginTop: 20, marginBottom: 20}}>{t('PLAYER_HOME_ADD_NEW_VIDEO_TITLE')}</h5>
                        <p style={{marginBottom: 20}}>{t('PLAYER_HOME_ADD_NEW_VIDEO_DESC')}</p>
                        <div className="text-center mt-auto">
                          <a href="#" className="btn btn_red_rp mb-2" onClick={goto('player-show-sport-centers')}>{t('SPORT_CENTERS')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="container pt-5">
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <h4 className="section_title_pos_rp">{t('PLAYER_HOME_YOUR_RESERVATIONS')}</h4>
                  </div>
                </div>
                <div className="row">
                {
                  bookings.filter(b => new Date(b.startDatetime) > new Date()).length != 0 ?
                  bookings.filter(b => new Date(b.startDatetime) > new Date()).slice(0,2).map(b =>
                  (
                    <div key={b.bookingId} className="col-md-12 col-lg-6 mb-sm-4 card-group">
                      <div className="card reservation_bg_rp">
                        <div className="card-horizontal">
                          <div className="card_image_rp col-sm-3 col-lg-4 pl-0 pr-0">
                            <div className="card_image_overlay_rp">
                              <div className="card_image_icon_overlay_rp"/>
                            </div>
                          </div>
                          <div className="card-body d-flex flex-column col-sm-9 col-lg-8">
                            <h5 className="card-title">{b.partnerName}</h5>
                            <ul className="list-unstyled">
                              <li>{b.fieldName || b.serviceName}</li>
                              <li>{new Date(b.startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'})}</li>
                              <li>{t('FROM_TO_TIME', {fromTime: new Date(b.startDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'}), toTime: new Date(b.endDatetime).toLocaleTimeString(i18n.language, {hour: '2-digit', minute: '2-digit'})})}</li>
                              <li className="font-italic">{getPurchaseStatusText(b)}</li>
                            </ul>
                            <div className="mt-auto mb-2 reservation_buttons_rp">
                              {
                                b.videoPurchasable && !b.videoBought ?
                                <a href="#" className="btn btn_red_rp" onClick={confirmPurchase(b.slots, b.videoPrice)}>{t('PURCHASE_VIDEO_BUTTON')}</a> :
                                null
                              }
                              <a href="#" className="btn btn_red_outline_rp ml-0 ml-sm-3" onClick={deleteBookingDialog(b)}>{t('BOOKING_DELETE')}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>))
                    :
                    <div className="col-md-12 col-lg-6 mb-sm-4 card-group">
                      <div className="card empty_slot reservation_bg_rp">
                        <div className="card-horizontal">
                          <div className="card_image_rp col-sm-3 col-lg-4 pl-0 pr-0">
                            <div className="card_image_overlay_rp">
                              <div className="card_image_icon_overlay_rp"/>
                            </div>
                          </div>
                          <div className="card-body d-flex flex-column col-sm-9 col-lg-8">
                            <h5 className="card-title">{t('PLAYER_HOME_NO_RESERVATION_TITLE')}</h5>
                            <ul className="list-unstyled">
                              <li>{t('PLAYER_HOME_NO_RESERVATION_DESC')}</li>
                            </ul>
                            <div className="mt-auto mb-2 reservation_buttons_rp">
                              <a href="#" className="btn btn_red_rp" onClick={goto('player-show-sport-centers')}>{t('SPORT_CENTERS')}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
                </div>
                <div>
                  <a href="#" className="btn btn_red_rp" onClick={goto('player-bookings')}>{t('SEE_ALL')}</a>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="container pt-5">
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <h4 className="section_title_pos_rp">{t('SPECIAL_EVENTS')}</h4>
                  </div>
                </div>
                <div className="row">
                  {
                    events.map(e =>
                      (
                        <Col key={e.id} xs={12} sm={12} md={12} xl={4} lg={4}>
                        <Card style={{marginBottom: 10}}>
                          <Card.Body>
                          <div className="preload h-100 justify-content-start align-items-start" style={{marginBottom: 10}}>
                            <img className="logo-partner" style={{width:"25px"}} src={getPartnerLogo(e.uid)}/> <strong>{e.businessName}</strong>
                          </div>
                          {
                            e.imageName.length === 0 ?
                              <Fragment>
                                <div className="card event_card_bg_rp">
                                  <div className="card_image_rp">
                                    <div className="videoWrapper card_image_sportcenter_rp"/>
                                </div>
                              </div> 
                              </Fragment> :
                              <Carousel style={{marginBottom: 10}} interval={null}>
                                {
                                  e.imageName.map(i =>
                                    (
                                      <Carousel.Item key={i}>
                                        <img
                                          className="d-block w-100"
                                          src={`${Configuration.apiEndPoint}/partners/events/${e.id}/images?load=${i}`}
                                          alt={t('FIRST_SLIDE')}
                                          style={{width: '100%'}}
                                        />
                                      </Carousel.Item>
                                    )
                                  )
                                }
                              </Carousel>
                            }
                            <Card.Title style={{marginTop: 30}}>
                              {e.title}
                            </Card.Title>
                            <Card.Text>
                              {e.description}
                            </Card.Text>
                            <Card.Text>
                            <img src={positionIcon}/>{e.city}
                            </Card.Text>
                            <Card.Text>
                             {t('SPECIAL_EVENT_DATES', {start_date: new Date(e.startDatetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'}), end_date: new Date(e.endDatetime).toLocaleDateString(i18n.language, {year: 'numeric', month: 'numeric', day: 'numeric'})})}
                            </Card.Text>
                          </Card.Body>
                          {
                            e.recording ?
                              <div className="d-flex favorites_card_badge mb-3">
                                <img src={replayerTvIcon} className="favorites_card_badge_icon" alt={t('ICON_FAVORITES')}/>
                                <p>{t('SPECIAL_SUBSCRIPTION') + " \"" + e.title + "\""}</p>
                                {
                                  e.rtv ?
                                    <a href="#" className="btn btn_red_rp ml-auto" style={{margin: 'auto', maxHeight: '3.3rem'}} onClick={showReplayerTVEvent(e.rtvChannelId, partners.find(p => p.uid === e.uid), getSubscriptionStatusEvent(e.uid, e.rtvChannelId))}><img style={{width: '3.75rem', height: 'auto'}} src={buyIcon}/></a> :
                                    null
                                }
                              </div> :
                              null
                          }
                        </Card>
                      </Col>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="bg_lightgrey_rp mt-5 pb-5">
            <div className="section">
              <div className="container pt-5">
                <div className="row">
                  <div className="col-md-12 ml-auto mr-auto">
                    <h4 className="section_title_pos_rp">{t('PLAYER_HOME_FAVORITES')}</h4>
                  </div>
                </div>
                <div className="row" style={{ marginTop: 50 }}>
                  <div className="col-md-12 ml-auto mr-auto">
                    <h5 className="section_title_pos_rp">{t('RTV_CHANNELS')}</h5>
                  </div>
                </div>
                <div className="row">
                  {partners.map(p => favouriteRtvChannels.filter(c => c.uid === p.uid).map(channel =>
                    (
                      <div key={p.uid} className="col-md-12 col-lg-6 mb-sm-4 card-group" style={{ minHeight: 200 }}>
                        <a href="#" className={channel.type === 1 ? "card video_store_bg_rp_specialist ease_fx" : "card video_store_bg_rp ease_fx"} onClick={channel.type === 0 ? showReplayerTV(p, channel, getSubscriptionStatus(p, channel)) : showReplayerTVPartnerVideo(p, channel, getSubscriptionStatus(p, channel))}>
                          <div className="card-horizontal">
                            <div className="col-sm-3 col-lg-4 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                              {
                                channel.logoNames && channel.logoNames.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/partners/rtv_channels/${channel.id}/images?load=${channel.logoNames[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  p.storeImage && p.storeImage.length > 0 ?
                                  <div className="align-self-center">
                                    <img
                                      className="img-fluid"
                                      src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${p.storeImage[0]}`}
                                      alt={t('LOGO')}
                                    />
                                  </div> :
                                  <div className="align-self-center video_store_nologo_overlay_rp"/>
                              }
                            </div>
                            <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                              <h5 className="card-title mb-0">{channel.name}</h5>                              
                            </div>
                            <div className="card-body d-flex align-items-center col-sm-1 col-lg-1">
                              <div className="white_favorites_card_heart_btn_active" onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleFavouriteRtvChannel(channel.id, true);
                              }}/>
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  ))}
                  <div className="col-md-12 col-lg-6 mb-sm-4 card-group">
                    <div className="card empty_slot reservation_bg_rp">
                      <div className="card-horizontal">
                        <div className="card_image_rp col-sm-3 col-lg-4 pl-0 pr-0">
                          <div className="card_image_overlay_rp d-flex align-items-stretch justify-content-center">
                            <div className="align-self-center video_store_nologo_overlay_rp"/>
                          </div>
                        </div>
                        <div className="card-body d-flex flex-column align-items-center col-sm-9 col-lg-8">
                          <h5 className="card-title">{t('PLAYER_HOME_ADD_RTV_CHANNEL_TO_FAVORITES_TITLE')}</h5>
                          <ul className="list-unstyled">
                            <li>{t('PLAYER_HOME_ADD_RTV_CHANNEL_TO_FAVORITES_DESC')}</li>
                          </ul>
                          <div className="mt-auto mb-2">
                            <a href="#" className="btn btn_red_rp" onClick={gotoReplayerTvHome}>{t('REPLAYER_TV')}</a>
                          </div>                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: 50 }}>
                  <div className="col-md-12 ml-auto mr-auto">
                    <h5 className="section_title_pos_rp">{t('SPORT_CENTERS_AND_SPORT_SPECIALISTS')}</h5>
                  </div>
                </div>
                <div className="row">
                  {
                    favouritePartners.map(p =>
                      (
                        <div key={p.uid} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                          <div className={p.isSpecialist ? "card sportspecialist_card_bg_rp" :"card sportcenter_card_bg_rp"}>
                              {
                                p.imagesNames.length === 0 ?
                                <div className="card_image_rp">
                                  <div className="videoWrapper card_image_sportcenter_rp"/>
                                </div> :
                                  <Carousel style={{marginBottom: 10}} interval={null}>
                                    {
                                      p.imagesNames.map(i =>
                                        (
                                          <Carousel.Item key={i}>
                                            <img
                                              className="d-block w-100"
                                              src={`${Configuration.apiEndPoint}/images/${p.uid}?load=${i}`}
                                              alt={t('FIRST_SLIDE')}
                                              style={{width: '100%'}}
                                            />
                                          </Carousel.Item>
                                        )
                                      )
                                    }
                                  </Carousel>
                              }
                            <div className="card-body d-flex flex-column">
                              <h5 className="card-title">{p.name}</h5>
                              <div className="favorites_card_heart_btn_active" onClick={toggleFavourite(p.uid, true)}/>
                              <ul className="list-unstyled">
                                <li className="list_location_pointer">{p.city}</li>
                                {!p.isSpecialist ?
                                  <li><strong>{t('SPORTS')}:</strong> {p.sports.join(',')}</li>
                                : null}
                                <li className="font-italic">{p.description}</li>
                              </ul>
                              <ul className="card_facilities_list_icons">
                                {
                                  p.facilities && p.facilities.parking ?
                                    <li className="card_facilities_list_icons_item"><img src={parking} alt={t('ICON_PARK')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.lockerRooms ?
                                    <li className="card_facilities_list_icons_item"><img src={lockerRooms} alt={t('ICON_LOCKER_ROOMS')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.showers ?
                                    <li className="card_facilities_list_icons_item"><img src={showers} alt={t('ICON_SHOWER')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.restaurant ?
                                    <li className="card_facilities_list_icons_item"><img src={restaurant} alt={t('ICON_RESTAURANTS')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.bar ?
                                    <li className="card_facilities_list_icons_item"><img src={bar} alt={t('ICON_BAR')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.shop ?
                                    <li className="card_facilities_list_icons_item"><img src={shop} alt={t('ICON_SHOP')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.picnic ?
                                    <li className="card_facilities_list_icons_item"><img src={picnic} alt={t('ICON_PIC_NIC')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.tournaments ?
                                    <li className="card_facilities_list_icons_item"><img src={tournaments} alt={t('ICON_TOURNAMENTS')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.petFriendly ?
                                    <li className="card_facilities_list_icons_item"><img src={petFriendly} alt={t('ICON_ANIMAL')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.accessibility ?
                                    <li className="card_facilities_list_icons_item"><img src={accessibility} alt={t('ICON_DISABLED')}/></li> :
                                    null
                                }
                                {
                                  p.facilities && p.facilities.relaxArea ?
                                    <li className="card_facilities_list_icons_item"><img src={relax} alt={t('RELAX_AREA')}/></li> :
                                    null
                                }
                              </ul>
                            </div>
                            {
                              p.recording ?
                                <Fragment>
                                  <div className="d-flex favorites_card_badge mb-3">
                                  <img src={camIcon} className="favorites_card_badge_icon" alt={t('ICON_FAVORITES')}/>
                                  <p>{t('HD_VIDEO_SERVICE')}</p>
                                  {
                                    p.rtvChannels.length > 0 ?
                                      <a href="#" className="btn btn_red_rp ml-auto" onClick={chooseReplayerTV(p)}><img src={replayerTvIcon}/></a>
                                    : null
                                  }
                                  </div>
                                </Fragment>
                              : null
                            }
                            <div className="text-center mt-auto mb-3">
                            {
                              (p.bookableFields && !p.isSpecialist) || (p.bookableServices && p.isSpecialist) ?
                              <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showCalendar(p, null, true)}>{t('BOOK')}</a> :
                              null
                            }
                            {
                              (p.notBookableFields && !p.isSpecialist) || (p.notBookableServices && p.isSpecialist) ?
                              <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showCalendar(p, null, false)}>
                                {p.isSpecialist ? <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V9h14v10zm0-12H5V5h14v2zm-2 5h-5v5h5v-5z"></path></svg> : null}
                                {t('CALENDAR')}</a> :
                              null
                            }
                            <a href="#" className="btn btn_red_rp mb-2 ml-1 mr-1" onClick={showPartner(p)}>{t('CENTER_DETAILS')}</a>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  }
                  <div className="col-md-12 col-lg-4 mb-sm-4 card-group">
                    <div className="empty_slot card favorites_card_bg_rp">
                      <div className="card_image_rp">
                        <div className="videoWrapper"/>
                      </div>
                      <div className="card-body d-flex flex-column">
                        <h5 className="card-title">{t('PLAYER_HOME_ADD_TO_FAVORITES_TITLE')}</h5>
                        <p>{t('PLAYER_HOME_ADD_TO_FAVORITES_DESC')}</p>
                        <div className="text-center mt-auto">
                          <a href="#" className="btn btn_red_rp mb-2" onClick={goto('player-show-sport-centers')} style={{marginRight: 10}}>{t('SPORT_CENTERS')}</a>
                          <a href="#" className="btn btn_red_rp mb-2" onClick={goto('player-show-sport-specialists')}>{t('Sport Specialists')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
          <PlayerNewSubscription/>
          <PlayerNewEventSubscription/>
          <PlayerInsufficientFunds/>          
          <PlayerChooseRtvChannel/>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerHome.propTypes = {
  token: PropTypes.string,
  partners: PropTypes.array,
  favouritePartners: PropTypes.array,
  bookings: PropTypes.array,
  video: PropTypes.array,
  showPartner: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  showCalendar: PropTypes.func.isRequired,
  purchaseVideo: PropTypes.func.isRequired,
  archiveBooking: PropTypes.func.isRequired,
  addVideoToPlayerArea: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired,
  toggleFavourite: PropTypes.func.isRequired,
  subscriptions: PropTypes.object,
  showReplayerTVEvent: PropTypes.func.isRequired,
  permanentVideos: PropTypes.array,
  newVideosPlaying: PropTypes.array,
  addNewVideoPlaying: PropTypes.func.isRequired,
  events: PropTypes.array,
  chooseReplayerTV: PropTypes.func.isRequired,
  gotoReplayerTvHome: PropTypes.func.isRequired,
  favouriteRtvChannels: PropTypes.array,
  toggleFavouriteRtvChannel: PropTypes.func.isRequired,
  showReplayerTV: PropTypes.func.isRequired,
};

PlayerHome.defaultProps = {
  token: null,
  partners: [],
  favouritePartners: [],
  bookings: [],
  video: [],
  subscriptions: null,
  permanentVideos: [],
  newVideosPlaying: [],
  events: [],
  favouriteRtvChannels: []
};

const mapStateToProps = ({login: {token, profile}, message, loading, player: {partners, favouritePartners, bookings, video, videoStreaming, subscriptions, permanentVideos, newVideosPlaying, events, favouriteRtvChannels}}) => ({
  token,
  profile,
  message,
  loading,
  partners,
  favouritePartners,
  bookings,
  video,
  videoStreaming,
  subscriptions,
  permanentVideos,
  newVideosPlaying,
  events,
  favouriteRtvChannels
});

const mapDispatchToProps = dispatch => ({
  showPartner: partner => () => {
    dispatch(partnerSelected(partner));
    dispatch(getPartnerFields(partner.uid));
    dispatch(getPartnerServices(partner.uid));
    dispatch(getPartnerEvents(partner.uid));
    dispatch(getPartnerGroups(partner.uid));
    dispatch(getPlayerGroups());
    if (!partner.isSpecialist) dispatch(push('show-partner'));
    else dispatch(push('show-partner-specialist'));
  },
  showCalendar: (partner, fieldId = null, filterBookable = false) => () => {
    dispatch(partnerSelected(partner));
    dispatch(getPartnerFields(partner.uid));
    dispatch(getPartnerServices(partner.uid));
    dispatch(filterBookingFieldId(fieldId));
    dispatch(filterBookableFields(filterBookable));
    dispatch(getSlots(partner.uid, fieldId));
    dispatch(push('player-calendars'));
  },
  deleteBooking: (bookingId, partnerId, isService, multiBooking, phoneNumber) => {
    if(isService) dispatch(deleteServiceBooking(bookingId, partnerId, multiBooking, phoneNumber));
    else dispatch(deleteBooking(bookingId, partnerId, multiBooking, phoneNumber));
  },
  purchaseVideo: slots => {
    dispatch(purchaseVideo(slots));
  },
  archiveBooking: bookingId => {
    dispatch(archiveBooking(bookingId));
  },
  addVideoToPlayerArea: slotId => () => {
    dispatch(addVideoToPlayerArea(slotId));
    dispatch(getUserTags());
    dispatch(push('player-video'));
  },
  goto: (path, action = null) => e => {
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
  toggleFavourite: (uid, isFavourite) => () => {
    if (isFavourite) {
      dispatch(removePartnerFromFavourite(uid));
    } else {
      dispatch(addPartnerToFavourite(uid));
    }
  },
  showReplayerTVEvent: (rtvChannelId, partner, subscriptionStatus) => () => {
    const rtvEventChannel = partner.rtvChannels.find(c => c.id === rtvChannelId);
    if (!rtvEventChannel) {
      dispatch(showMessage('RTV_CHANNELS', 'NO_RTV_EVENT_CHANNEL'));
    }

    dispatch(rtvChannelSelected(rtvEventChannel));
    dispatch(partnerSelected(partner));

    if (rtvEventChannel.type === 0 && (subscriptionStatus.status === subscriptionStatuses.valid || subscriptionStatus.status === subscriptionStatuses.unlimited || (rtvEventChannel.rtvPrice === 0 && rtvEventChannel.rtvPriceEvent === 0 && rtvEventChannel.rtvPriceMembership === 0))) {
      dispatch(getRtvVideos(partner.uid, rtvChannelId));
      dispatch(push('/player-replayer-tv'));
      return;
    }

    if (rtvEventChannel.type === 1 && subscriptionStatus.status === subscriptionStatuses.valid) {
      dispatch(getRtvPartnerVideos(partner.uid, rtvChannelId));
      dispatch(push('/player-replayer-tv-video-partner'));
      return;
    }

    if (subscriptionStatus.status === subscriptionStatuses.canRequestEvent) {
      dispatch(showPlayerNewEventSubscriptionModal());
      return;
    }

    dispatch(showPlayerNewSubscriptionModal());
    return;
  },
  addNewVideoPlaying: videoId => () => {
    dispatch(addNewVideoPlaying(videoId));
  },
  chooseReplayerTV: (partner) => () => {
    dispatch(rtvChannelSelected(null));
    dispatch(partnerSelected(partner));
    dispatch(showPlayerChooseRtvChannelModal());
  },
  gotoReplayerTvHome: () => {
    dispatch(push('player-replayer-tv-home'));
  },
  toggleFavouriteRtvChannel: (rtv_channel_id, isFavourite) => {
    if (isFavourite) {
      dispatch(removeRtvChannelFromFavourite(rtv_channel_id));
    } else {
      dispatch(addRtvChannelToFavourite(rtv_channel_id));
    }
  },
  showReplayerTV: (partner, rtvChannel, subscriptionStatus) => () => {    
    dispatch(partnerSelected(partner));
    dispatch(rtvChannelSelected(rtvChannel));
    if (subscriptionStatus.status === subscriptionStatuses.valid || subscriptionStatus.status === subscriptionStatuses.unlimited || (rtvChannel.rtvPrice === 0 && rtvChannel.rtvPriceEvent === 0 && rtvChannel.rtvPriceMembership === 0)) {
      dispatch(getRtvVideos(partner.uid, rtvChannel.id));
      dispatch(push('/player-replayer-tv'));
      return;
    }

    if (rtvChannel.blocked) {
      dispatch(showMessage('RTV_CHANNELS', 'RTV_CHANNEL_IS_BLOCKED'));
      return;
    }
    
    dispatch(showPlayerNewSubscriptionModal());
    return;
  },
  showReplayerTVPartnerVideo: (partner, rtvChannel, subscriptionStatus) => () => {    
    dispatch(partnerSelected(partner));
    dispatch(rtvChannelSelected(rtvChannel));
    if (subscriptionStatus.status === subscriptionStatuses.valid || (rtvChannel.rtvPrice === 0 && rtvChannel.rtvPriceEvent === 0 && rtvChannel.rtvPriceMembership === 0)) {
      dispatch(getRtvPartnerVideos(partner.uid, rtvChannel.id));
      dispatch(push('/player-replayer-tv-video-partner'));
      return;
    }

    if (rtvChannel.blocked) {
      dispatch(showMessage('RTV_CHANNELS', 'RTV_CHANNEL_IS_BLOCKED'));
      return;
    }
    
    dispatch(showPlayerNewSubscriptionModal());
    return;
  },
  refreshNewVideos: () => {
    dispatch(getVideo());
    dispatch(getVideoStreaming());
  },
  sendUsageData : (partnerId, slotId, streaming) => {
    dispatch(postUsageData(1, partnerId, null, slotId, streaming, 0))
  },
  gotoLiveVideos: () => {
    dispatch(getLiveVideos());
    dispatch(push('/player-live-videos'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerHome));
