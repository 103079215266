import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-bootstrap-dialog';
import {Button, Form, Row, Col} from 'react-bootstrap';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {deleteUserBooking, saveUserBooking, deleteServiceUserBooking, saveServiceUserBooking} from '../actions/partner';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {AiOutlineUser} from 'react-icons/ai';
import {FaShieldAlt} from 'react-icons/fa';

const PartnerBooking = ({booking, slot, slots, field, partner, save, resourceType = 'field', deleteUserBooking, saveUserBooking, deleteServiceUserBooking, saveServiceUserBooking, ownedFieldSlots, discount = 100}) => {
  const {t, i18n} = useTranslation();
  const resizeArray = (arr, newSize, defaultValue) => {
    if (newSize > arr.length) {
      return [...arr, ...new Array(Math.max(newSize - arr.length, 0)).fill(defaultValue)];
    }

    arr.length = newSize;
    return arr;
  };

  const startSlot = () => {
    if ((slot && slot.bookingId) || (slots && slots[0] && slots[0].bookingId)) {
      let s = ownedFieldSlots.filter(s => s.bookingId === slot.bookingId).reverse();
      if (s.length > 0) {
        return s[0];
      }
    }
    if (slots) {
      return slots[0];
    }
    return slot;
  };

  const endSlot = () => {
    if ((slot && slot.bookingId) || (slots && slots[0] && slots[0].bookingId)) {
      let s = ownedFieldSlots.filter(s => s.bookingId === slot.bookingId).reverse();
      if (s.length > 0) {
        return s[s.length - 1];
      }
    }
    if (slots) {
      return slots[slots.length - 1];
    }

    return slot;
  };

  let dialog = null;
  const onRemoveUserBooking = (fieldId, userBookingId) => {
    dialog.show({
      title: t('CONFIRM'),
      body: t('CONFIRM_DELETE_USER_FROM_BOOKING'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => resourceType === 'field' ? deleteUserBooking(fieldId, userBookingId) : deleteServiceUserBooking(fieldId, userBookingId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const changeUserBooking = (fieldId, user) => () => {
    dialog.show({
      title: t('USERS_BOOKING_LIST'),
      body: <Formik
              initialValues={user}
              onSubmit={resourceType === 'field' ? saveUserBooking : saveServiceUserBooking}
            >
              {({
                handleSubmit,
                handleChange,
                values
              }) => {    
                return (
                  <Form key={values.id} onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>{t('NAME')}</Form.Label>
                      <Form.Control name="partnerBookingName" placeholder={t('NAME')} type="text" maxLength="50" value={values.partnerBookingName || ''} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('SURNAME')}</Form.Label>
                      <Form.Control name="partnerBookingSurname" placeholder={t('SURNAME')} type="text" maxLength="50" value={values.partnerBookingSurname || ''} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('EMAIL')}</Form.Label>
                      <Form.Control name="partnerBookingEmail" placeholder={t('EMAIL')} type="text" maxLength="255" value={values.partnerBookingEmail || ''} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('TELEPHONE')}</Form.Label>
                      <Form.Control name="partnerBookingPhoneNumber" type="tel" placeholder={t('TELEPHONE')} value={values.partnerBookingPhoneNumber || ''} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Check name="payment" type="checkbox" label={t('PAID')} checked={values.payment} onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t('BOOKING_NOTES')}</Form.Label>
                      <Form.Control name="bookingNotes" as="textarea" rows={4} maxLength={255} placeholder={t('BOOKING_NOTES')} value={values.bookingNotes || ''} onChange={handleChange}/>
                    </Form.Group>
                    <Button type="submit" variant="secondary" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                  </Form>
                );
              }}
            </Formik>,
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        ),
        Dialog.Action(
          t('DELETE'),
          () => startSlot().minimumPlayers == null ? onRemoveUserBooking(fieldId, user.id) :  deleteUserBooking(fieldId, user.id),
          'btn btn-secondary'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const slotPrice = () => {
    if (slots) {
      var price = 0;
      for (slot of slots) {
        price += slot.price;
      }
      return price;
    }
    else
    {
      return slot.price;
    }
  }

  const description = slot ? slot.description ? slot.description : "" : slots[0].description ? slots[0].description : "";
  const bookingNotes = booking.users ? booking.users[0].bookingNotes : "";
  const payment = booking.users ? booking.users[0].payment : false;
  const otherPlayersPrice = booking.otherPlayersPrice;
  otherPlayersPrice[0] = otherPlayersPrice[0] === 0 ? slotPrice() + slot.costsPrice/100 : otherPlayersPrice[0];
  let rtvChannels = slots && slots.length > 0 ? [...new Map(slots.map(s => s.rtvChannels).flat().map((item) => [item["id"], item])).values()] : slot.rtvChannels;
  
  const rtvChannelAssociations = slots && slots.length > 0 ? [...new Map(slots.map(s => s.rtvChannelAssociations).flat().map((item) => [item["rtvChannelId"], item])).values()] : slot.rtvChannelAssociations;
  rtvChannels.map(c => c.canPublish = rtvChannelAssociations.find(a => a.rtvChannelId === c.id).canPublish)

  return (
    <Formik
      enableReinitialize
      initialValues={Object.assign({}, booking, {description, bookingNotes, payment, otherPlayersPrice ,discount, rtvChannels})}
      onSubmit={save}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue
      }) => {

        if(values.description === "") values.description = null;

        const playerNumberChange = args => {
          const num = parseInt(args.target.value, 10);
          values.otherPlayers = resizeArray(values.otherPlayers, num, '');
          values.otherPlayersPrice = resizeArray(values.otherPlayersPrice, num, 0);
          values.otherPlayersPayment = resizeArray(values.otherPlayersPayment, num, false);
          setFieldValue('playersNumber', num);
        };

        const handleSelectAll = ev => {
          const target = !values.payment;
          setFieldValue('payment', target);
          values.otherPlayersPayment.map((t, index) => setFieldValue(`otherPlayersPayment[${index}]`, target));
        };

        return (
          <Form onSubmit={handleSubmit}>
            <Form.Label>{booking.bookingId ? resourceType === 'field' ? t('BOOKED_FIELD') : t('BOOKED_SERVICE') : resourceType === 'field' ? t('BOOKING_FIELD') : t('BOOKING_SERVICE')}: {field.name}, {field.sport}</Form.Label>
            <Form.Label>{t('BOOKING_AT')} {partner.name}, {partner.city}</Form.Label>
            <Form.Label>{t('BOOKING_DAY', {day: new Date(startSlot().startDatetime).toLocaleDateString(i18n.language, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})})} {t('FROM_TO_TIME', {fromTime: new Date(startSlot().startDatetime).toLocaleTimeString(i18n.language).slice(0, 5), toTime: new Date(endSlot().endDatetime).toLocaleTimeString(i18n.language).slice(0, 5)})}</Form.Label>
            {
              slots && slots.length > 1 ?
                <Form.Label>{t('BOOKING_MULTI_SLOT')} ({slots.length})</Form.Label> :
                null
            }

            {
              // Quando si ha la prenotazione multi slot (e quindi esiste slots), gli slots sono per forza monoposto
              (slot && slot.presetPlaces === 1) || (slots && slots.length === 1 && slots[0].presetPlaces === 1) || (slots && slots.length > 1) ?
                <Fragment>
                  {
                    booking.bookingId ?
                      <Fragment>
                        <Form.Label>{t('BOOKING_OWNER')} {values.users[0].bookingOwner}</Form.Label>
                        <Form.Label>{t('BOOKING_INFO', {phoneNumber: values.users[0].phoneNumber, email: values.users[0].email})}</Form.Label>
                      </Fragment>
                    : null
                  }
                  <Form.Group>
                    <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                    <Form.Control required name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('BOOKING_NOTES')}</Form.Label>
                    <Form.Control name="bookingNotes" as="textarea" rows={4} maxLength={255} placeholder={t('BOOKING_NOTES')} value={values.bookingNotes || ''} onChange={handleChange}/>
                  </Form.Group>
                  {                      
                    values.rtvChannels.length > 0 ?
                      <Fragment>
                        <Form.Label>
                          {t('WHERE_PUBLISH')}
                        </Form.Label>
                        {
                          values.rtvChannels.map((c, index) => (
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                label={`Rtv ${c.name}`}
                                checked={c.canPublish}
                                onChange={() => {
                                  values.rtvChannels[index].canPublish = !values.rtvChannels[index].canPublish;
                                  setFieldValue('rtvChannels', values.rtvChannels);                                
                                }}/>                              
                            </Form.Group>
                          ))
                        }
                        <Form.Label>
                          {t('BOOKING_REPLAYER_TV_DESC')}
                        </Form.Label>
                      </Fragment> :
                      null
                  }
                  <Form.Group>
                    <Form.Check name="payment" type="checkbox" label={t('PAID_FOR_ALL')} checked={values.payment} onChange={handleSelectAll}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{resourceType === 'field' ? t('BOOKING_PLAYERS_NUMBER') : t('BOOKING_USERS_NUMBER')}*</Form.Label>
                    <Form.Control required as="select" name="playersNumber" value={values.playersNumber || ''} onChange={playerNumberChange}>
                      <option value="">{t('BOOKING_PLAYERS_NUMBER_DESC')}</option>
                      {
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(nPlayer => (<option value={nPlayer}>{nPlayer}</option>))
                      }
                    </Form.Control>
                  </Form.Group>
                  <Row>
                    <Col>
                      {
                        values.otherPlayers ?
                          values.otherPlayers.map((others, index) => (
                            <Form.Group key={`player_${index}`}>
                              <Form.Label>{resourceType === 'field' ? t('BOOKING_PLAYERS_NUMBER_INDEX', {index: index + 1}) : t('BOOKING_USERS_NUMBER_INDEX', {index: index + 1})}</Form.Label>
                              <Form.Control name={`otherPlayers[${index}]`} type="text" maxLength={50} value={values.otherPlayers[index] || ''} onChange={handleChange}/>
                            </Form.Group>
                          ))
                        : null
                      }
                    </Col>
                    <Col>
                      {
                        values.otherPlayersPrice ?
                          values.otherPlayersPrice.map((others, index) => (
                            <Form.Group key={`player_price_${index}`}>
                              <Form.Label>{t('AMOUNT', {index: index + 1})}</Form.Label>
                              <Form.Control name={`otherPlayersPrice[${index}]`} type="number" min={0.00} max={100} step={0.01} value={values.otherPlayersPrice[index] || 0} onChange={handleChange}/>
                            </Form.Group>
                          ))
                        : null
                      }
                    </Col>
                    <Col>      
                      {
                        values.otherPlayersPayment ?
                          values.otherPlayersPayment.map((others, index) => (
                            <Form.Group key={`player_payment_${index}`}>
                              <Form.Label>{t('PAID', {index: index + 1})}</Form.Label>
                              <Form.Control name={`otherPlayersPayment[${index}]`} type="checkbox" maxLength={50} checked={values.otherPlayersPayment[index]} onChange={handleChange}/>
                            </Form.Group>
                          ))
                        : null
                      }
                    </Col>
                  </Row>
                  <Button type="submit" variant="secondary" style={{marginBottom: 50}}>{t('SAVE')}</Button>
                </Fragment>
              : 
                <Fragment>
                  {
                    values.presetPlaces > 1 && startSlot.minimumPlayers !== null ?
                      <Form.Group>
                        <Form.Label>{t('DESCRIPTION')}*</Form.Label>
                        <Form.Control required name="description" as="textarea" maxLength={255} placeholder={t('DESCRIPTION')} value={values.description || ''} onChange={handleChange}/>
                      </Form.Group>
                    :
                      <Form.Group>
                      <Form.Label>{t('DESCRIPTION')}: {values.description || ''} </Form.Label>
                      </Form.Group>
                    }
                  {
                      startSlot().minimumPlayers != null ?
                      <Form.Group>
                        <Form.Label>{t('GROUP_BOOKING_ACTIVE')}</Form.Label>
                      </Form.Group>
                      : null
                    }
                  {
                    values.users ?
                      <Form.Group>
                      {values.users.map(u => (
                          <Fragment key={u.id}>
                            <p>
                              {
                                u.payment ?
                                  <Button key={u.id} className="btn-block text-left" variant="outline-primary" style={{marginBottom: 20}} onClick={changeUserBooking(field.id, u)}>
                                    {
                                      u.uid === partner.uid ?
                                        <FaShieldAlt size="1.5em" style={{marginRight: 10}}/>
                                      : <AiOutlineUser size="1.5em" style={{marginRight: 10}}/>
                                    }
                                    {u.bookingOwner}{u.inviteType === 0 ? " - "+t('REFERENT') : u.inviteType === 1 ? " - "+t('PARTECIPANT') : u.inviteType === 2 ? " - "+t('GUEST') : ""}, {u.payment ? t('PAID') : t('TO_PAY')}
                                  </Button>
                                :
                                  <Button key={u.id} className="btn-block text-left" variant="outline-secondary" style={{marginBottom: 20}} onClick={changeUserBooking(field.id, u)}>
                                    {
                                      u.uid === partner.uid ?
                                        <FaShieldAlt size="1.5em" style={{marginRight: 10}}/>
                                      : <AiOutlineUser size="1.5em" style={{marginRight: 10}}/>
                                    }                                    
                                    {u.bookingOwner}, {(u.payment ? t('PAID') : t('TO_PAY')) + (u.uid === partner.uid ? ` ${(slotPrice() + slot.costsPrice/100).toFixed(2)}€` : "")}
                                  </Button>
                              }
                            </p>                            
                          </Fragment>
                          ))}
                      </Form.Group>
                    : null
                  }
                  <h4 style={{marginTop: 30}}>{t('NEW_BOOKING')}</h4>
                  <Form.Group>
                    <Form.Label>{t('NAME')}</Form.Label>
                    <Form.Control name="partnerBookingName" placeholder={t('NAME')} type="text" maxLength="50" value={values.partnerBookingName || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('SURNAME')}</Form.Label>
                    <Form.Control name="partnerBookingSurname" placeholder={t('SURNAME')} type="text" maxLength="50" value={values.partnerBookingSurname || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('EMAIL')}</Form.Label>
                    <Form.Control name="partnerBookingEmail" placeholder={t('EMAIL')} type="text" maxLength="255" value={values.partnerBookingEmail || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('TELEPHONE')}</Form.Label>
                    <Form.Control name="partnerBookingPhoneNumber" type="tel" placeholder={t('TELEPHONE')} value={values.partnerBookingPhoneNumber || ''} onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('BOOKING_NOTES')}</Form.Label>
                    <Form.Control name="bookingNotes" as="textarea" rows={4} maxLength={255} placeholder={t('BOOKING_NOTES')} onChange={handleChange}/>
                  </Form.Group>
                  <Button type="submit" variant="secondary" style={{marginBottom: 50}} onClick={() => values.multiBooking=true}>{t('BOOK')}</Button>
                </Fragment>
            }
            <Dialog
              ref={el => {
                dialog = el;
              }}/>
          </Form>
        );
      }}
    </Formik>
  );
};

PartnerBooking.propTypes = {
  booking: PropTypes.object,
  slot: PropTypes.object,
  field: PropTypes.object,
  partner: PropTypes.object,
  save: PropTypes.func.isRequired,
  slots: PropTypes.array,
  deleteUserBooking: PropTypes.func.isRequired,
  saveUserBooking: PropTypes.func.isRequired,
  deleteServiceUserBooking: PropTypes.func.isRequired,
  saveServiceUserBooking: PropTypes.func.isRequired,
  ownedFieldSlots: PropTypes.array
};

PartnerBooking.defaultProps = {
  booking: null,
  slot: null,
  field: null,
  partner: null,
  slots: null,
  ownedFieldSlots: []
};

const mapDispatchToProps = dispatch => ({
  deleteUserBooking: (fieldId, userBookingId) => {
    dispatch(deleteUserBooking(fieldId, userBookingId));
  },
  saveUserBooking: booking => {
    dispatch(saveUserBooking(booking));
  },
  deleteServiceUserBooking: (serviceId, userBookingId) => {
    dispatch(deleteServiceUserBooking(serviceId, userBookingId));
  },
  saveServiceUserBooking: booking => {
    dispatch(saveServiceUserBooking(booking));
  },
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(PartnerBooking));