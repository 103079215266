import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {push} from 'connected-react-router';
import {hidePlayerHowPurchaseModal} from '../actions/player';
import {useTranslation, Trans} from 'react-i18next';

const PlayerNewSubscription = ({isPlayerHowPurchaseModalVisible, hidePlayerHowPurchaseModal, topUpAccount}) => {
  const {t, i18n} = useTranslation();
  return (
    <Modal className="modal_rp" contentClassName="bg_blue_rp" show={isPlayerHowPurchaseModalVisible} onHide={hidePlayerHowPurchaseModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">{t('HOW_TO_BUY_VIDEO')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        <Trans i18nKey='STORE_INFO_1' />
        </p>
        <p>
        <Trans i18nKey='STORE_INFO_2' />
        </p>
        <p>
        <Trans i18nKey='STORE_INFO_3' />
        </p>
        <p>
        <Trans i18nKey='STORE_INFO_4' />
        </p>
        <p>
        <Trans i18nKey='STORE_INFO_5' />
        </p>
        <p>
        <Trans i18nKey='STORE_INFO_6' />
        </p>
      </Modal.Body>
      <div className="modal-footer">
        <button type="submit" className="btn btn-secondary" data-dismiss="modal" onClick={topUpAccount}>{t('ACCOUNT_TOPUP')}</button>
        <button type="button" className="btn btn_red_outline_rp" onClick={hidePlayerHowPurchaseModal}>{t('CANCEL')}</button>
      </div>
    </Modal>
  );
};

PlayerNewSubscription.propTypes = {
  hidePlayerHowPurchaseModal: PropTypes.func.isRequired,
  isPlayerHowPurchaseModalVisible: PropTypes.bool,
  topUpAccount: PropTypes.func.isRequired
};

PlayerNewSubscription.defaultProps = {
  isPlayerHowPurchaseModalVisible: false
};

const mapStateToProps = ({login: {token}, player: {isPlayerHowPurchaseModalVisible}}) => ({
  token,
  isPlayerHowPurchaseModalVisible
});

const mapDispatchToProps = dispatch => ({
  hidePlayerHowPurchaseModal: () => {
    dispatch(hidePlayerHowPurchaseModal());
  },
  topUpAccount: () => {
    dispatch(hidePlayerHowPurchaseModal());
    dispatch(push('user-top-up-account'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerNewSubscription));
