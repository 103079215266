import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PartnerVideo from '../components/partner-video';
import {changeStorageType, permanentVideoSelected, showVideoHighlightModal, hideVideoHighlightModal, myVideoActiveTab, selectVideoForTaggingPartner, getTaggedUserVideosPartner, showStorageModal, hideStorageModal, getUserVideoTags, showUserTagModal, deletePartnerPermanentVideo, deletePlaylist, modifyPartnerPermanentVideo, modifyPlaylist, showVideoSharingModal, showPartnerVideoCreationModal, hidePartnerVideoCreationModal, createPartnerVideo, deletePartnerVideo, selectPartnerVideo, hidePartnerVideoEditModal, editPartnerVideo, deletePartnerVideoImageFile, getPartnerVideos, showPlayersModal, hidePlayersModal, searchPlayers, cleanPlayersReceived} from '../actions/partner'
import { postUsageData } from '../actions/player';
import {push} from 'connected-react-router';
import { showMessage } from '../actions/messages';

const mapStateToProps = ({login: {token, profile}, partner: {storagePrices, permanentVideos, selectedPermanentVideo, isVideoHighlightModalVisible, userTags, myVideoActiveTab, selectedVideoForTagging, taggedUserVideos, isStorageModalVisible, playlists, isPartnerVideoCreationVisible, partnerVideos, isPartnerVideoEditModalVisible, selectedPartnerVideo, isPlayersModalVisible, playersList}}) => ({
  token,
  profile,
  permanentVideos,
  storagePrices,
  selectedPermanentVideo,
  isVideoHighlightModalVisible,
  userTags,
  myVideoActiveTab,
  selectedVideoForTagging,
  taggedUserVideos,
  isStorageModalVisible,
  playlists,
  isPartnerVideoCreationVisible,
  partnerVideos,
  isPartnerVideoEditModalVisible,
  selectedPartnerVideo,
  isPlayersModalVisible,
  playersList,
});

const mapDispatchToProps = dispatch => ({
  selectVideoForHighlight: permanentVideo => () => {
    dispatch(permanentVideoSelected(permanentVideo));
    dispatch(showVideoHighlightModal());
  },
  hideVideoHighlightModal: () => {
    dispatch(hideVideoHighlightModal());
    dispatch(permanentVideoSelected(null));
  },
  deletePartnerPermanentVideo: videoId => {
    dispatch(deletePartnerPermanentVideo(videoId));
  },
  modifyPartnerPermanentVideo: (videoId, videoData) => {
    dispatch(modifyPartnerPermanentVideo(videoId, videoData));
  },
  deletePlaylist: playlistId => {
    dispatch(deletePlaylist(playlistId));
  },
  modifyPlaylist: (playlistId, videoData) => {
    dispatch(modifyPlaylist(playlistId, videoData));
  },
  showVideosSharing: permanentVideo => () => {
    dispatch(permanentVideoSelected(permanentVideo));
    dispatch(showVideoSharingModal());
  },
  showPartnerTags: () => {
    dispatch(showUserTagModal());
  },
  setActiveTab: t => {
    dispatch(myVideoActiveTab(t));
    dispatch(selectVideoForTaggingPartner(null));
  },
  showVideoTagging: video => () => {
    dispatch(getUserVideoTags(video.videoId));
    dispatch(selectVideoForTaggingPartner(video));
    dispatch(myVideoActiveTab('tags'));
  },
  searchTaggedVideo: tagIds => {
    dispatch(getTaggedUserVideosPartner(tagIds));
  },
  changeStorageType: newStorageType => {
    dispatch(changeStorageType(newStorageType));
    dispatch(hideStorageModal());
  },
  hideStorageModal: () => {
    dispatch(hideStorageModal());
  },
  showStorageModal: () => {
    dispatch(showStorageModal());
  },
  hidePartnerVideoCreationModal: () => {
    dispatch(hidePartnerVideoCreationModal());
  },
  showPartnerVideoCreationModal: () => {
    dispatch(showPartnerVideoCreationModal());
  },
  createPartnerVideo: values => {
    dispatch(createPartnerVideo(values));
    dispatch(hidePartnerVideoCreationModal());
  },
  deletePartnerVideo: video_id => {
    dispatch(deletePartnerVideo(video_id));
  },
  showPartnerVideoEditModal: video => {
    dispatch(selectPartnerVideo(video));
  },
  hidePartnerVideoEditModal: () => {
    dispatch(hidePartnerVideoEditModal());
    dispatch(getPartnerVideos());
  },
  editPartnerVideo: (videoId, values, selectedPlayers) => {
    var selected_uids = []
    selectedPlayers.forEach(p => selected_uids.push(p.uid));
    values.selectedPlayers = selected_uids;
    dispatch(editPartnerVideo(videoId, values));
  },
  removeFile: (fileName, videoPartnerId, video) => {
    dispatch(deletePartnerVideoImageFile(fileName, videoPartnerId));
    dispatch(getPartnerVideos());
    dispatch(selectPartnerVideo({...video, coverImage: []}))
  },
  showPlayersModal: () => {
    dispatch(showPlayersModal());
  },
  hidePlayersModal: () => {
    dispatch(cleanPlayersReceived());
    dispatch(hidePlayersModal());
  },
  searchPlayers: (playerNickname, playerName, playerSurname, playerAffiliate) => {
    if (playerAffiliate === undefined) playerAffiliate = false;
    if (playerName) playerName = playerName.replace(/ /g,'_');
    if (playerSurname) playerSurname = playerSurname.replace(/ /g,'_');
    if (playerNickname) playerNickname = playerNickname.replace(/ /g,'_');
    const validRegex = /^[A-Za-z0-9_àèéìòù'-]*$/;
    if ((playerName && !playerName.match(validRegex)) || (playerSurname && !playerSurname.match(validRegex)) || (playerNickname && !playerNickname.match(validRegex)))
      dispatch(showMessage('ACCEPTED_CHARACTERS', "ACCEPTED_CHARACTERS_INFO"));
    else dispatch(searchPlayers(playerNickname, playerName, playerSurname, playerAffiliate));
  },
  sendUsageData: (usageType, partnerId, slotId, videoPartnerTitle = null, videoPartnerName = null) => {
    dispatch(postUsageData(usageType, partnerId, null, slotId, false, 2, videoPartnerTitle, videoPartnerName))
  },
  goto: (path, action = null) => e => {
    if (action) {
      dispatch(action());
    }

    dispatch(push(path));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerVideo));
