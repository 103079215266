import {ROLES, subscriptionKind, subscriptionStatuses} from '../constants';

export const getNameFromProfile = profile => {
  if (profile === null) {
    return null;
  }

  if (profile.user.role === ROLES.player) {
    return `${profile.name} ${profile.surname}`;
  }

  if (profile.user.role === ROLES.partner) {
    return `${profile.businessName}`;
  }

  if (profile.user.role === ROLES.admin) {
    return `${profile.name} ${profile.surname}`;
  }

  return `NO_NAME_ROLE_${profile.user.role}`;
};

export const getSubscriptionStatus = (partner, subscriptions) => {
  if (subscriptions) {
    const sub = subscriptions.subscriptions.find(s => s.partner === partner.uid);
    if (sub && new Date(sub.expiry) >= new Date()) {
      return {status: subscriptionStatuses.valid};
    }

    const unlimitedSubscription = subscriptions.subscriptions.find(s => new Date(s.expiry) >= new Date() && s.kind === subscriptionKind.rtvUnlimited);
    if (unlimitedSubscription) {
      return {status: subscriptionStatuses.unlimited};
    }

    if (sub && new Date(sub.expiry) < new Date()) {
      return {status: subscriptionStatuses.expired, subscriptionId: sub.id};
    }
  }

  return {status: subscriptionStatuses.noSubscription};
};