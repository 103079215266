/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Col, Container, Fade, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import {searchSlot, deleteSlot} from '../actions/admin';
import {useTranslation} from 'react-i18next';
import Dialog from 'react-bootstrap-dialog';
import { sl } from 'date-fns/locale';

const AdminSlotManagement = ({
  token,
  searchSlot,
  slot,
  deleteSlot,
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  let dialog = null;

  const transactionCause = {
    1: 'acquisto video',
    5: 'prenotazione',
    6: 'rimborso prenotazione',
    11: 'rimborso video'
  }

  const deleteSlotDialog = slotId => {
    dialog.show({
      title: t('DELETE_SLOT'),
      body: t('DELETE_SLOT_INFO'),
      actions: [
        Dialog.Action(
          t('DELETE'),
          () => deleteSlot(slotId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('NO'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Formik
      initialValues={{slotId: null}}
      onSubmit={searchSlot}
    >
      {({
        handleSubmit,
        handleChange,
        values
      }) => {
        return (
          <Fragment>
            <CommonMetaTag/>
            <Fade in appear style={{transition: 'opacity 0.30s linear', marginTop: 100, marginBottom: 200}}>
              <Container>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4 style={{marginTop: 30}}>{t('SEARCH_SLOT')}</h4>
                    <p>{t('SEARCH_SLOT_INFO')}</p>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>Slot id</Form.Label>
                        <Form.Control required name="slotId" placeholder="Slot id" type="text" value={values.slotId || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Button type="submit">{t('SEARCH')}</Button>
                    </Form>
                  </Col>
                </Row>
                <Row className="justify-content-center" style={{marginTop: 20, marginBottom: 20}}>
                  <Col xs={12} sm={8} md={6}>
                    <h4 style={{marginTop: 30}}>{t('SEARCH_SLOT_RESULT_SUCCESSFUL')}</h4>
                    {
                      slot ?
                        <div>
                          <h6>{'Slot id: ' +slot.slotInfo.slotId}</h6>
                          <h6>{t('DESCRIPTION') + ': ' +slot.slotInfo.description}</h6>
                          <h6>{t('START_DATE') + ': ' + slot.slotInfo.startDatetime}</h6>
                          <h6>{'Campo/Servizio: ' +slot.slotInfo.fieldOrServiceName}</h6>
                          {slot.listTransactions && slot.listTransactions.length > 0 ? 
                            <div>
                              <h5>{t('TRANSACTION_LIST')}:</h5>
                              <ul>
                              {slot.listTransactions.map(transaction => 
                                <li>
                                <h6>email: {transaction.userEmail}</h6>
                                <h6>id: {transaction.userId}</h6>
                                <h6>{t('AMOUNT')}: {transaction.amount/100}€</h6>
                                <h6>{t('CAUSE')}: {transactionCause[transaction.cause]}</h6>
                                <h6>{t('PURCHASE_DATE')}: {transaction.date}</h6>
                                </li>)
                              }
                              </ul>
                            </div> :
                            <h6>{t('NO_TRANSACTIONS')}</h6>
                          }
                          <Button variant="secondary" onClick={()=> deleteSlotDialog(slot.slotInfo.slotId)}>{t('DELETE')}</Button>
                        </div> :
                        <p>{t('SEARCH_PARTNER_OR_PLAYER_RESULT_FAILED')}</p>
                    }
                  </Col>
                </Row>
              </Container>
            </Fade>
            <Dialog
            ref={el => {
            dialog = el;
            }}/>
          </Fragment>
        );
      }}
    </Formik>
  );
};

AdminSlotManagement.propTypes = {
  token: PropTypes.string,
};

AdminSlotManagement.defaultProps = {
  token: null,
};

const mapStateToProps = ({login: {token}, admin: {slot}}) => ({
  token,
  slot,
});

const mapDispatchToProps = dispatch => ({
    searchSlot: values => {
        dispatch(searchSlot(values.slotId));
    },
    deleteSlot: slotId => {
        dispatch(deleteSlot(slotId));
    },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSlotManagement));
