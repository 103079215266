import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SignUpPartner from '../components/sign-up-partner';
import {signUpPartner} from '../actions/login';

const mapDispatchToProps = dispatch => ({
  signUp: signUpData => {
    dispatch(signUpPartner(signUpData));
  }
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(SignUpPartner));
