/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Dropdown, DropdownButton, Fade, Form, Tabs, Tab} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import {push} from 'connected-react-router';
import {Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import queryString from 'query-string';
import CommonMetaTag from './common-meta-tag';
import coming_soon from '../images/video_store_coming_soon.png';
import defaultFieldImage from '../images/default_field_image.png'
import {useTranslation} from 'react-i18next';
import Configuration from '../config';
import {getVideosStorePartner, addVideoToPartnerArea, requestVideo} from '../actions/partner';

const PartnerVideoStore = ({
  token,
  profile,
  videosStore,
  videosStoreSoon,
  addVideoToPartnerArea,
  requestVideo,
  ownedFields,
  ownedServices,
  searchVideos,
  location: {search}
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const params = queryString.parse(search);

  const setValueForField = (f, id) => {
    f('fieldId', id);
    f('serviceId', null);
  };

  const setValueForService = (f, id) => {
    f('fieldId', null);
    f('serviceId', id);
  };

  const getFieldImage = (partnerId, fieldId, fieldImage) => {
    return `${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage}`
  }

  let dialog = null;
  const videoScheduled = () => () => {
    dialog.show({
      title: t('VIDEO_SCHEDULED_TITLE'),
      body: t('VIDEO_SCHEDULED_INFO'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter dashboard_hero_rp" data-parallax="true">
            <div className="dashboard_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('VIDEO_STORE') + " " + t('REPLAYER_VIDEOS')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div>
                        <Formik
                          enableReinitialize
                          initialValues={{fieldId: params.fieldId ? Number(params.fieldId) : null, serviceId: params.serviceId ? Number(params.serviceId) : null, fromDatetime: params.fromDatetime ? new Date(params.fromDatetime).toISOString() : null, toDatetime: params.toDatetime ? new Date(params.toDatetime).toISOString() : null}}
                          onSubmit={searchVideos}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            values
                          }) => {
                            return (
                              <Form className="d-flex flex-wrap" onSubmit={handleSubmit}>
                                <div className="mr-md-4 video_store_head_filter_button">
                                  <label className="mr-1">{t('FIELD_GYM_POOL')}</label>
                                  <DropdownButton className="video_store_dropdown" title={values.fieldId === null && values.serviceId === null? t('SELECT') : values.serviceId === null ? ownedFields.find(sf => sf.id === values.fieldId).name : ownedServices.find(ss => ss.id === values.serviceId).name}>
                                    {
                                      ownedFields.map(f => (
                                        f.recording?
                                        <Dropdown.Item key={f.id} href="#" onClick={() => setValueForField(setFieldValue, f.id)}>{f.name}</Dropdown.Item>
                                        :null
                                      ))
                                    }
                                    {
                                      ownedServices.map(s => (
                                        s.recording?
                                        <Dropdown.Item key={s.id} href="#" onClick={() => setValueForService(setFieldValue, s.id)}>{s.name}</Dropdown.Item>
                                        :null
                                      ))
                                    }
                                  </DropdownButton>
                                </div>
                                <label className="mr-1">{t('PERIOD')}</label>
                                <Form.Group>
                                  <DatePicker
                                    showTimeSelect
                                    locale="it"
                                    className="form-control"
                                    timeFormat="HH:mm"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    dropdownMode="select"
                                    selected={(values.fromDatetime && new Date(values.fromDatetime)) || null}
                                    onChange={val => setFieldValue('fromDatetime', val ? val.toISOString() : val)}/>
                                </Form.Group>
                                <Form.Group>
                                  <DatePicker
                                    showTimeSelect
                                    locale="it"
                                    className="form-control"
                                    timeFormat="HH:mm"
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    dropdownMode="select"
                                    minTime={new Date().setHours(0,30)}
                                    maxTime={new Date().setHours(23,59)}
                                    selected={(values.toDatetime && (new Date(values.toDatetime).getHours() === 0 && new Date(values.toDatetime).getMinutes() === 0 ? new Date(values.toDatetime).setHours(23,59) : new Date(values.toDatetime))) || null}
                                    onChange={val => setFieldValue('toDatetime', val ? val.getHours() === 0 && val.getMinutes() === 0 ? new Date(val.setHours(23,59)).toISOString() : val.toISOString() : val)}/>
                                </Form.Group>
                                <div className="ml-auto ml-lg-0">
                                  <button type="submit" className="btn btn_red_rp video_store_head_filter_button">{t('FIND')}</button>
                                </div>
                                <div className="ml-auto mr-lg-0">
                                  <Button
                                    type="button"
                                    className="btn btn_blue_rp video_store_head_filter_button"
                                    onClick={() => searchVideos({fieldId: values.fieldId ? Number(values.fieldId) : null, serviceId: values.serviceId ? Number(values.serviceId) : null, fromDatetime: values.fromDatetime ? new Date(values.fromDatetime).toISOString() : null, toDatetime: values.toDatetime ? new Date(values.toDatetime).toISOString() : null})}
                                    >
                                    {t('UPDATE_VIDEOS')}
                                  </Button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('CHOOSE_VIDEO_PARTNER')}</p>
                        </div>
                      </div>
                    </div>
                    <Tabs defaultActiveKey="watch_now" className="videostore-tabs" style={{marginTop: 20}}>
                      <Tab eventKey="watch_now" title={t('WATCH_NOW')} tabClassName="videostore-tab"> 
                        <div className="row">
                          {
                            videosStore && videosStore.length > 0 ?
                              videosStore.map(v => (
                                <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                  <div className="card video_card_bg_rp">
                                    <div>
                                      <img src={new Date(v.timestamp) > new Date() ? coming_soon: v.fieldId && v.fieldImage && v.partnerId ? getFieldImage(v.partnerId, v.fieldId, v.fieldImage) : defaultFieldImage} alt={t('VIDEO_STORE')} style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    <div className="card-body d-flex flex-column card_border_bottom_white">
                                      <p className="lightblue_text mb-4">
                                        {v.description}
                                      </p>
                                      <h6 className="card-title regular_title_rp mb-1">{new Date(v.timestamp).toLocaleDateString()} {new Date(v.timestamp).toTimeString().slice(0, 5)}</h6>
                                      <h5 className="card-title">{v.fieldName}</h5>
                                      {
                                        v.event ?
                                          <div className="video_store_card_event_icon"/> :
                                          v.reserved ?
                                            <div className="video_store_card_club_icon"/> :
                                            <div className="video_store_card_player_icon"/>
                                      }
                                      <div className="d-flex lightblue_text">
                                        <span className="bold_title_rp mr-2">{t('DURATION')}</span>{new Date(v.duration * 1000).toISOString().slice(11, 19)}
                                      </div>
                                      {v.filesize ?
                                      <div className="d-flex lightblue_text">
                                        <span className="bold_title_rp mr-2">{t('FILE_SIZE')}</span>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB
                                      </div>
                                      : null}
                                    </div>
                                    <div className="text-center ml-auto p-3">
                                      {v.videoReady ?
                                        <a href="#" data-toggle="modal" className="btn btn_red_rp" onClick={addVideoToPartnerArea(v.id)}>{t('COPY_TO_MY_VIDEO')}</a>
                                      : v.videoBought?
                                        <a href="#" data-toggle="modal" className="btn btn_blue_rp" onClick={e => e.preventDefault()}>{t('VIDEO_IN_PROGRESS')}</a>
                                      : <a href="#" data-toggle="modal" className="btn btn_blue_rp" onClick={requestVideo(v.id)}>{t('REQUEST_VIDEO')}</a>}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : <Fragment>
                                  <div className="col-lg-12 mt-sm-2 text-center" style={{background: '#00152f50', paddingTop: 15}}>
                                    <p><h5>{t('NO_VIDEO')}</h5></p>
                                  </div>
                              </Fragment>
                          }
                        </div>
                      </Tab>
                      <Tab eventKey="soon" title={t('SOON')} tabClassName="videostore-tab">
                      <div className="row">
                          {
                            videosStoreSoon && videosStoreSoon.length > 0 ?
                              videosStoreSoon.map(v => (
                                <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                  <div className="card video_card_bg_rp">
                                    <div>
                                      <img src={new Date(v.timestamp) > new Date() ? coming_soon: v.fieldId && v.fieldImage && v.partnerId ? getFieldImage(v.partnerId, v.fieldId, v.fieldImage) : defaultFieldImage} alt={t('VIDEO_STORE')} style={{width: '100%', height: '100%'}}/>
                                    </div>
                                    <div className="card-body d-flex flex-column card_border_bottom_white">
                                      <p className="lightblue_text mb-4">
                                        {v.description}
                                      </p>
                                      <h6 className="card-title regular_title_rp mb-1">{new Date(v.timestamp).toLocaleDateString()} {new Date(v.timestamp).toTimeString().slice(0, 5)}</h6>
                                      <h5 className="card-title">{v.fieldName}</h5>
                                      {
                                        v.event ?
                                          <div className="video_store_card_event_icon"/> :
                                          v.reserved ?
                                            <div className="video_store_card_club_icon"/> :
                                            <div className="video_store_card_player_icon"/>
                                      }
                                      <div className="d-flex lightblue_text">
                                        <span className="bold_title_rp mr-2">{t('DURATION')}</span>{new Date(v.duration * 1000).toISOString().slice(11, 19)}
                                      </div>
                                    </div>
                                    <div className="text-center ml-auto p-3">
                                      <a href="#" className="btn btn_blue_rp" onClick={videoScheduled()}>{t('VIDEO_SCHEDULED')}</a>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : <Fragment>
                                  <div className="col-lg-12 mt-sm-2 text-center" style={{background: '#00152f50', paddingTop: 15}}>
                                    <p><h5>{t('NO_VIDEO_SOON')}</h5></p>
                                  </div>
                              </Fragment>
                          }
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PartnerVideoStore.propTypes = {
  token: PropTypes.string,
  profile: PropTypes.object.isRequired,
  goto: PropTypes.func.isRequired,
  videosStore: PropTypes.array,
  videosStoreSoon: PropTypes.array,
  addVideoToPartnerArea: PropTypes.func.isRequired,
  ownedFields: PropTypes.array,
  ownedServices: PropTypes.array,
  searchVideos: PropTypes.func.isRequired,
  purchaseVideo: PropTypes.func.isRequired,
  rtvVideos: PropTypes.array,
  video: PropTypes.array,
  permanentVideos: PropTypes.array
};

PartnerVideoStore.defaultProps = {
  token: null,
  videosStore: [],
  videosStoreSoon: [],
  ownedFields: [],
  ownedServices: [],
  video: [],
  permanentVideos: [],
  rtvVideos: []
};

const mapStateToProps = ({login: {token, profile}, partner: {ownedFields, ownedServices, videosStore, videosStoreSoon}}) => ({
  token,
  profile,
  videosStore,
  videosStoreSoon,
  ownedFields,
  ownedServices,
});

const mapDispatchToProps = dispatch => ({
  addVideoToPartnerArea: slotId => () => {
    dispatch(addVideoToPartnerArea(slotId));
    dispatch(push('partner-video'));
  },
  searchVideos: filters => {
    dispatch(getVideosStorePartner(filters.fieldId, filters.serviceId, filters.fromDatetime, filters.toDatetime));
  },
  requestVideo: slotId => () => {
    dispatch(requestVideo(slotId));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerVideoStore));
