/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Container, Fade, Tabs, Tab, Button} from 'react-bootstrap';
import CommonMetaTag from './common-meta-tag';
import Dialog from 'react-bootstrap-dialog';
import binIcon from '../images/bin_icon.svg';
import Configuration from '../config';
import {push} from 'connected-react-router';
import {addVideoToPlayerArea, deleteRtvVideo, addRtvVideoPlaying, getRtvVideos, postUsageData} from '../actions/player';
import playArrowWhite from '../images/play_arrow_white_48dp.png';
import replayerlogoBianco from '../images/replayer_logo_bianco_40x32px.png';
import noLogo from '../images/club_icon_grey.svg';
import specialEvent from '../images/special_event.svg';
import { rtvType } from '../constants';
import {useTranslation} from 'react-i18next';

const PlayerReplayerTv = ({
  token,
  selectedPartner,
  rtvVideos,
  rtvVideosSoon,
  addVideoToPlayerArea,
  deleteRtvVideo,
  profile,
  rtvVideosPlaying,
  addRtvVideoPlaying,
  updateVideos,
  selectedRtvChannel,
  sendUsageData,
}) => {
  const {t, i18n} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  if (!selectedPartner || !selectedRtvChannel) {
    return null;
  }

  const styleBackgroundImage = () => {
    if (selectedRtvChannel.imagesNames && selectedRtvChannel.imagesNames.length > 0) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/rtv_channels/${selectedRtvChannel.id}/images?load=${selectedRtvChannel.imagesNames[0]})`};
    }

    return null;
  };

  let dialog = null;
  const confirmDeleteRtvVideo = slotId => () => {
    dialog.show({
      title: t('CONFIRM_DELETION_FROM_RTV'),
      body: t('DEACTIVATING_OPTION_VIDEO_FROM_RTV'),
      actions: [
        Dialog.Action(
          t('YES'),
          deleteRtvVideo(slotId),
          'btn btn-secondary'
        ),
        Dialog.Action(
          t('CANCEL'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const videoInProgress = () => () => {
    dialog.show({
      title: t('VIDEO_IN_PROGRESS'),
      body: t('VIDEO_IN_PROGRESS_INFO'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const videoScheduled = () => () => {
    dialog.show({
      title: t('VIDEO_SCHEDULED_TITLE'),
      body: t('VIDEO_SCHEDULED_INFO'),
      actions: [
        Dialog.Action(
          t('CLOSE_BUTTON'),
          null,
          'btn btn_red_outline_rp'
        )
      ],
      bsSize: 'small',
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  const getPartnerLogo = () => {
    if(selectedRtvChannel.logoNames && selectedRtvChannel.logoNames.length > 0) {
      return `${Configuration.apiEndPoint}/partners/rtv_channels/${selectedRtvChannel.id}/images?load=${selectedRtvChannel.logoNames[0]}`
    }
    if (selectedPartner && selectedPartner.storeImage && selectedPartner.storeImage.length > 0) {
      return `${Configuration.apiEndPoint}/images/${selectedPartner.uid}?load=${selectedPartner.storeImage[0]}`;
    }

    return noLogo;
  };

  const styleBackgroundImageVideoWrapper = (partnerId, fieldId, fieldImage) => {
    if (partnerId && fieldImage) {
      return {backgroundImage: `url(${Configuration.apiEndPoint}/partners/fields/${fieldId}/images?load=${fieldImage})`, backgroundSize: 'cover'};
    }

    return null;
  };

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_lightgrey_rp" style={{transition: 'opacity 0.30s linear'}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter replayertv_sportcenter_hero_rp" style={styleBackgroundImage()} data-parallax="true">
            <div className={selectedPartner.isSpecialist ? "replayertv_sportcenter_hero_overlay_rp_specialist" : "replayertv_sportcenter_hero_overlay_rp"}/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container">
                <div className="row mb-4">
                  <div className="col-12 d-flex align-items-center flex-column">
                    <div className="replayertv_sportcenter_logo mb-2">
                      {
                        selectedRtvChannel.logoNames && selectedRtvChannel.logoNames.length > 0 ?
                        <div className="align-self-center">
                          <img
                            className="img-fluid"
                            src={`${Configuration.apiEndPoint}/partners/rtv_channels/${selectedRtvChannel.id}/images?load=${selectedRtvChannel.logoNames[0]}`}
                            alt={t('LOGO')}
                          />
                        </div> :
                          selectedPartner.storeImage && selectedPartner.storeImage.length > 0 ?
                            <div className="align-self-center">
                              <img
                                className="img-fluid"
                                src={`${Configuration.apiEndPoint}/images/${selectedPartner.uid}?load=${selectedPartner.storeImage[0]}`}
                                alt={t('LOGO')}
                              />
                            </div> :
                            <div className="align-self-center replayertv_nologo_overlay_rp"/>
                      }
                    </div>
                    <h4 className="section_title_neg_rp text-center">{selectedRtvChannel.name} <span className="lightblue_text">Replayer.tv</span></h4>
                  </div>
                  <div className="col-12">
                    <Tabs defaultActiveKey="watch_now" className="myvideo-tabs" style={{marginTop: 20}}>
                      <Tab eventKey="watch_now" title={t('WATCH_NOW')} tabClassName="myvideo-tab">
                        <div className="col-12" style={{background: '#00152f50', paddingTop: 15}}>
                          <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: '10px'}}>
                            <Button
                              type="button"
                              variant="primary"
                              onClick={() => 
                                updateVideos({partnerUid: selectedPartner.uid, id: selectedRtvChannel.id})}
                              >
                              {t('UPDATE_VIDEOS')}
                            </Button>
                          </div>
                          <p className="replayertv_sportcenter_introtext">
                          {t('SEE_ALL_EVENTS_TO_SHARE')}
                          </p>
                          <p className="replayertv_sportcenter_introtext">
                          {t('SEE_ALL_EVENTS_TO_SHARE_NOW_ONLINE')}
                          </p>
                        </div>
                        <div className="row">
                          {
                            rtvVideos && rtvVideos.length > 0 ?
                              rtvVideos.map(v =>
                                (
                                  <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                    <div className="card video_card_bg_rp replayer_tv_card">
                                      <div className= "video_card_image" style={styleBackgroundImageVideoWrapper(selectedPartner.uid, v.fieldId, v.fieldImage)}>
                                        <div className="videoWrapper" onClick={() => {sendUsageData(selectedPartner.uid, selectedRtvChannel.id, v.id, !v.videoComplete); window.open(`${Configuration.krpanoPlayerUrl}?slotId=${v.id}&token=${v.token}`, '_blank');}}>
                                          <Fragment>
                                            <div className="preload h-100 justify-content-center align-items-center">
                                              <img className="play_arrow" src={playArrowWhite}/>
                                            </div>
                                            <div className="preload h-100 justify-content-start align-items-start">
                                              <img className="logo-replayer" src={v.event ? specialEvent : replayerlogoBianco}/>
                                            </div>
                                            <div className="preload h-100 justify-content-end align-items-start">
                                              <img className="logo-partner" src={getPartnerLogo()}/>
                                            </div>
                                            <div className="preload h-100 justify-content-center align-items-end">
                                              <div className="video-description justify-content-center align-items-center">{v.description ? v.description.substring(0,60) : null}</div>
                                            </div>
                                          </Fragment>
                                        </div>
                                      </div>
                                      <div className="card-body d-flex flex-column card_border_bottom_white">
                                        <p className="mb-4">
                                          {v.description}
                                          {
                                            v.rtvOrAcademy === rtvType.academy?
                                              <div className="replayertv_card_academy_icon"/>
                                            : null
                                          }        
                                        </p>
                                        <h6 className="card-title regular_title_rp mb-1">{new Date(v.timestamp).toLocaleDateString()} {new Date(v.timestamp).toTimeString().slice(0, 5)}</h6>
                                        <h5 className="card-title">{v.fieldName}</h5>
                                        <div className="d-flex">
                                          <span className="bold_title_rp mr-2">{t('DURATION')}</span>{new Date(v.duration * 1000).toISOString().slice(11, 19)}
                                        </div>
                                        {v.filesize ?
                                        <div className="d-flex">
                                          <span className="bold_title_rp mr-2">{t('FILE_SIZE')}</span>{(Math.floor(v.filesize)/(1000000000)).toFixed(2)}GB
                                        </div>
                                        : null}
                                      </div>
                                      <div className="text-center ml-auto p-3">
                                        {
                                          v.ownerId === profile.uid ?
                                            <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeleteRtvVideo(v.id)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li> :
                                            null
                                        }
                                        {v.videoComplete ?
                                        <a href="#" className="btn btn_red_rp" onClick={addVideoToPlayerArea(v.id)}>{t('COPY_TO_MY_VIDEO')}</a>
                                        : 
                                        <a href="#" className="btn btn_blue_rp" onClick={videoInProgress()}>{t('VIDEO_IN_PROGRESS')}</a>}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : <Fragment>
                                <div className="col-12 text-center" style={{background: '#00152f50', marginBottom: 400, paddingTop: 15}}>
                                  <p><h5>{t('NO_VIDEO')}</h5></p>
                                </div>
                              </Fragment>
                          }
                        </div>
                      </Tab>
                      <Tab eventKey="soon" title={t('SOON')} tabClassName="myvideo-tab"> 
                        <div className="col-12" style={{background: '#00152f50', paddingTop: 15}}>
                          <div style={{display: 'flex', flexDirection: 'row-reverse', marginBottom: '10px'}}>
                            <Button
                              type="button"
                              variant="primary"
                              onClick={() => 
                                updateVideos({partnerUid: selectedPartner.uid, id: selectedRtvChannel.id})}
                              >
                              {t('UPDATE_VIDEOS')}
                            </Button>
                          </div>
                          <p className="replayertv_sportcenter_introtext">
                          {t('SEE_ALL_EVENTS_TO_SHARE_COMING_SOON')}
                          </p>
                        </div>
                        <div className="row">
                          {
                            rtvVideosSoon && rtvVideosSoon.length > 0 ?
                              rtvVideosSoon.map(v =>
                                (
                                  <div key={v.id} className="col-md-12 col-lg-4 mb-sm-4 card-group">
                                    <div className="card video_card_bg_rp replayer_tv_card">
                                      <div className= "video_card_image" style={styleBackgroundImageVideoWrapper(selectedPartner.uid, v.fieldId, v.fieldImage)}>
                                        <div className="videoWrapper">
                                          <Fragment>
                                            <div className="preload h-100 justify-content-start align-items-start">
                                              <img className="logo-replayer" src={v.event ? specialEvent : replayerlogoBianco}/>
                                            </div>
                                            <div className="preload h-100 justify-content-end align-items-start">
                                              <img className="logo-partner" src={getPartnerLogo()}/>
                                            </div>
                                            <div className="preload h-100 justify-content-center align-items-end">
                                              <div className="video-description justify-content-center align-items-center">{v.description ? v.description.substring(0,60) : null}</div>
                                            </div>
                                          </Fragment>
                                        </div>
                                      </div>
                                      <div className="card-body d-flex flex-column card_border_bottom_white">
                                        <p className="mb-4">
                                          {v.description}
                                          {
                                            v.rtvOrAcademy === rtvType.academy?
                                              <div className="replayertv_card_academy_icon"/>
                                            : null
                                          }        
                                        </p>
                                        <h6 className="card-title regular_title_rp mb-1">{new Date(v.timestamp).toLocaleDateString()} {new Date(v.timestamp).toTimeString().slice(0, 5)}</h6>
                                        <h5 className="card-title">{v.fieldName}</h5>
                                        <div className="d-flex">
                                          <span className="bold_title_rp mr-2">{t('DURATION')}</span>{new Date(v.duration * 1000).toISOString().slice(11, 19)}
                                        </div>
                                      </div>
                                      <div className="text-center ml-auto p-3">
                                        {
                                          v.ownerId === profile.uid ?
                                            <li className="card_video_archive_list_icons_item"><a href="#" onClick={confirmDeleteRtvVideo(v.id)}><img src={binIcon} alt={t('ICON_DELETE')}/></a></li> :
                                            null
                                        }
                                        <a href="#" className="btn btn_blue_rp" onClick={videoScheduled()}>{t('VIDEO_SCHEDULED')}</a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : <Fragment>
                                <div className="col-lg-12 mt-sm-2 text-center" style={{background: '#00152f50', marginBottom: 400, paddingTop: 15}}>
                                  <p><h5>{t('NO_VIDEO_SOON')}</h5></p>
                                </div>
                              </Fragment>
                          }
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      <Dialog
        ref={el => {
          dialog = el;
        }}/>
    </Fragment>
  );
};

PlayerReplayerTv.propTypes = {
  token: PropTypes.string,
  selectedPartner: PropTypes.object,
  rtvVideos: PropTypes.array,
  rtvVideosSoon: PropTypes.array,
  addVideoToPlayerArea: PropTypes.func.isRequired,
  deleteRtvVideo: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  rtvVideosPlaying: PropTypes.array,
  addRtvVideoPlaying: PropTypes.func.isRequired,
  updateVideos: PropTypes.func.isRequired,
  selectedRtvChannel: PropTypes.object,
}

PlayerReplayerTv.defaultProps = {
  token: null,
  selectedPartner: null,
  rtvVideos: [],
  rtvVideosSoon: [],
  rtvVideosPlaying: [],
  selectedRtvChannel: null
};

const mapStateToProps = ({login: {token, profile}, player: {selectedPartner, rtvVideos, rtvVideosSoon, rtvVideosPlaying, selectedRtvChannel}}) => ({
  token,
  selectedPartner,
  rtvVideos,
  rtvVideosSoon,
  profile,
  rtvVideosPlaying,
  selectedRtvChannel
});

const mapDispatchToProps = dispatch => ({
  addVideoToPlayerArea: slotId => () => {
    dispatch(addVideoToPlayerArea(slotId));
    dispatch(push('player-video'));
  },
  deleteRtvVideo: slotId => () => {
    dispatch(deleteRtvVideo(slotId));
  },
  addRtvVideoPlaying: videoId => () => {
    dispatch(addRtvVideoPlaying(videoId));
  },
  updateVideos: channel => {
    dispatch(getRtvVideos(channel.partnerUid, channel.id));
  },
  sendUsageData: (partnerId, rtvChannelId, slotId, streaming) => {
    dispatch(postUsageData(1, partnerId, rtvChannelId, slotId, streaming, 1));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerReplayerTv));
