/* eslint complexity: ["error", 40] */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {Button, Container, Fade, Form, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import CommonMetaTag from './common-meta-tag';
import Configuration from '../config';
import {useTranslation} from 'react-i18next';

import {FilePond, registerPlugin} from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import {hideSponsorModal, showSponsorModal, saveSponsor, newSponsor, sponsorSelected, deleteSponsorImageFile, getSponsors, deleteSponsor} from '../actions/admin';
import {sponsorType, imageTypes} from '../constants';

import advBanner from '../images/3_pubblicita_ADV.png';

const AdminSponsor = ({
  token,
  isSponsorModalVisible,
  hideSponsorModal,
  newSponsorModal,
  showSponsorModal,
  createSponsor,
  sponsors,
  selectedSponsor,
  removeFile,
  deleteSponsor
}) => {
  const {t} = useTranslation();
  if (token === null) {
    return (
      <Redirect to="/login"/>
    );
  }

  const imageBanner = (selectedSponsor ? selectedSponsor.image || [] : []).map(f => {
    return {
      source: f,
      options: {
        type: 'local'
      }
    };
  });

  return (
    <Fragment>
      <CommonMetaTag/>
      <Fade in appear className="bg_white" style={{transition: 'opacity 0.30s linear', minHeight: 1000}}>
        <Container fluid className="presentation-container">
          <div className="page-header header-filter clear-filter replayer_adv_sponsor_hero" data-parallax="true">
            <div className="replayer_adv_sponsor_hero_overlay_rp"/>
          </div>
          <div className="main main_raised_rp">
            <div className="section">
              <div className="container pt-5">
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_REPLAYER_ADV')}</h4></div>
                        <div className="ml-auto d-xs-block"><Button variant="secondary" onClick={newSponsorModal}>{t('SPONSOR_CREATE')}</Button></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_INFO')}</p>
                          <p>{t('SPONSOR_MORE_INFO')} <a href="https://www.replayer.it/it/replayeradv-diventa-sponsor/" target="_blank" rel="noreferrer">ReplayerAdv</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_GLOBAL')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_GLOBAL_INFO')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {sponsors.filter(s => s.sponsorType === sponsorType.global).map(s =>
                    (
                      <div key={s.uid} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group" onClick={showSponsorModal(s)}>
                        <a href="#" className="card video_store_bg_rp ease_fx">
                          <div className="card-horizontal">
                            {
                              s.image && s.image.length > 0 ?
                                <div className="col-sm-12 col-lg-12 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                                  <img
                                    className="img-fluid"
                                    src={`${Configuration.apiEndPoint}/sponsors/${s.id}/image?load=${s.image[0]}`}
                                    alt={t('LOGO')}
                                    style={{width: '100%'}}
                                  />
                                </div> :
                                <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                                  <h5 className="card-title mb-0">{s.name}</h5>
                                </div>
                            }
                          </div>
                        </a>
                      </div>
                    )
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_LOCAL')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_LOCAL_INFO')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {sponsors.filter(s => s.sponsorType === sponsorType.local).map(s =>
                    (
                      <div key={s.uid} className="col-md-6 col-lg-4 mb-2 mb-md-4 card-group" onClick={showSponsorModal(s)}>
                        <a href="#" className="card video_store_bg_rp ease_fx">
                          <div className="card-horizontal">
                            {
                              s.image && s.image.length > 0 ?
                                <div className="col-sm-12 col-lg-12 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                                  <img
                                    className="img-fluid"
                                    src={`${Configuration.apiEndPoint}/sponsors/${s.id}/image?load=${s.image[0]}`}
                                    alt={t('LOGO')}
                                    style={{width: '100%'}}
                                  />
                                </div> :
                                <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                                  <h5 className="card-title mb-0">{s.name}</h5>
                                </div>
                            }
                          </div>
                        </a>
                      </div>
                    )
                  )}
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <div className="detail_head_rp video_store_head rounded">
                      <div className="d-flex">
                        <div><h4 className="section_title_neg_rp">{t('SPONSOR_BECOME')}</h4></div>
                      </div>
                      <hr className="hr_blue"/>
                      <div className="row justify-content-center">
                        <div className="col-lg-12 mt-sm-2">
                          <p>{t('SPONSOR_BECOME_INFO')}</p>
                          <p>{t('SPONSOR_BECOME_MORE_INFO')} <a href="https://www.replayer.it/it/replayeradv-diventa-sponsor/" target="_blank" rel="noreferrer">ReplayerAdv</a></p>
                        </div>
                        <div className="col-md-12 col-lg-10 card-group">
                          <a href="https://www.replayer.it/it/replayeradv-diventa-sponsor/" target="_blank" rel="noreferrer" className="card video_store_bg_rp ease_fx">
                            <div className="card-horizontal">
                              <div className="card-body d-flex align-items-center col-sm-9 col-lg-8">
                                <h5 className="card-title mb-0">{t('SPONSOR_BECOME_BANNER_INFO')}</h5>
                              </div>
                              <div className="col-sm-6 col-lg-6 pl-0 pr-0 video_store_logo_bg_rp d-flex align-items-stretch justify-content-center">
                                <img
                                  className="img-fluid"
                                  src={advBanner}
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
      {
        isSponsorModalVisible ?
          <Modal show={isSponsorModalVisible} onHide={hideSponsorModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t('SPONSOR')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={selectedSponsor}
                onSubmit={createSponsor}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Form.Label>{t('SPONSOR_TYPE')}*</Form.Label>
                        <Form.Check required name="sponsorType" type="radio" label={t('SPONSOR_GLOBAL')} checked={values.sponsorType === sponsorType.global} onChange={() => setFieldValue('sponsorType', sponsorType.global)}/>
                        <Form.Check required name="sponsorType" type="radio" label={t('SPONSOR_LOCAL')} checked={values.sponsorType === sponsorType.local} onChange={() => setFieldValue('sponsorType', sponsorType.local)}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('SPONSOR_NAME')}</Form.Label>
                        <Form.Control required name="name" placeholder={t('SPONSOR_NAME')} type="text" maxLength="100" value={values.name || ''} onChange={handleChange}/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t('SPONSOR_LINK')}</Form.Label>
                        <Form.Control name="link" placeholder={t('SPONSOR_LINK')} type="text" maxLength="100" value={values.link || null} onChange={handleChange}/>
                      </Form.Group>
                      <Button variant="secondary" type="submit">{t('SAVE')}</Button>
                      {
                        selectedSponsor.id ?
                          <Button variant="danger" className="float-right" type="button" onClick={deleteSponsor(selectedSponsor.id)}>{t('DELETE')}</Button> :
                          null
                      }
                    </Form>
                  );
                }}
              </Formik>
              {
                selectedSponsor.id ?
                  <Fragment>
                    <Form.Label>{t('LOGO')}</Form.Label>
                    <Form.Text className="text-muted">{t('IMAGES_SIZE', {size1: '512', size2: '170'})}</Form.Text>
                    <FilePond
                      files={imageBanner}
                      server={
                        {
                          url: `${Configuration.apiEndPoint}/`,
                          process: {
                            url: `./sponsors/${selectedSponsor.id}/image`,
                            ondata: formData => {
                              formData.append('image_type', imageTypes.sponsor);
                              return formData;
                            }
                          },
                          revert: `./sponsors/${selectedSponsor.id}/image`,
                          restore: `./sponsors/${selectedSponsor.id}/image`,
                          fetch: `./sponsors/${selectedSponsor.id}/image`,
                          load: `./sponsors/${selectedSponsor.id}/image?load=`,
                          headers: {Authorization: `Bearer ${token}`},
                          remove: (source, load) => {
                            removeFile(source, selectedSponsor.id);
                            load();
                          }
                        }
                      }
                    />
                  </Fragment> :
                  null
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={hideSponsorModal}>
                {t('CLOSE_BUTTON')}
              </Button>
            </Modal.Footer>
          </Modal> :
          null
      }
    </Fragment>
  );
};

AdminSponsor.propTypes = {
  token: PropTypes.string,
  isSponsorModalVisible: PropTypes.bool.isRequired,
  hideSponsorModal: PropTypes.func.isRequired,
  newSponsorModal: PropTypes.func.isRequired,
  showSponsorModal: PropTypes.func.isRequired,
  createSponsor: PropTypes.func.isRequired,
  sponsors: PropTypes.array,
  selectedSponsor: PropTypes.object,
  removeFile: PropTypes.func.isRequired,
  deleteSponsor: PropTypes.func.isRequired
};

AdminSponsor.defaultProps = {
  token: null,
  sponsors: [],
  selectedSponsor: null
};

const mapStateToProps = ({login: {token}, admin: {isSponsorModalVisible, sponsors, selectedSponsor}}) => ({
  token,
  isSponsorModalVisible,
  sponsors,
  selectedSponsor
});

const mapDispatchToProps = dispatch => ({
  hideSponsorModal: () => {
    dispatch(getSponsors());
    dispatch(hideSponsorModal());
  },
  newSponsorModal: () => {
    dispatch(newSponsor());
    dispatch(showSponsorModal());
  },
  showSponsorModal: sponsor => () => {
    dispatch(sponsorSelected(sponsor));
    dispatch(showSponsorModal());
  },
  createSponsor: values => {
    dispatch(saveSponsor(values));
  },
  removeFile: (fileName, sponsorId) => {
    dispatch(deleteSponsorImageFile(fileName, sponsorId));
  },
  deleteSponsor: sponsorId => () => {
    dispatch(deleteSponsor(sponsorId));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSponsor));
