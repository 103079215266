import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {push} from 'connected-react-router';
import PartnerEvents from '../components/partner-events';
import {newOwnedEvent, ownedEventSelected} from '../actions/partner';

const mapStateToProps = ({partner: {ownedEvents}, login: {token, profile}}) => ({
  ownedEvents,
  isLogged: token !== null,
  profile
});

const mapDispatchToProps = dispatch => ({
  newEvent: () => {
    dispatch(newOwnedEvent());
    dispatch(push('/partner-event'));
  },
  showEvent: event => () => {
    dispatch(ownedEventSelected(event));
    dispatch(push('partner-event'));
  }
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerEvents));
